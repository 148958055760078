import * as React from 'react';
import { UIHeading2, UIText1 } from '@cian/ui-kit/typography';
import { plural } from '@cian/utils/lib/shared/plural';
import { mergeStyles } from '@cian/utils';

import * as styles from './index.css';

interface IReviewsMobileProps {
  count: number;
  rate: string | null;
  stars: JSX.Element | null;
  loadMore: JSX.Element | null;
  className?: string;
  newReviewButton: JSX.Element | null;
  children: React.ReactNode;
}

export const ReviewsMobile: React.FunctionComponent<IReviewsMobileProps> = ({
  count,
  rate,
  stars,
  loadMore,
  className,
  newReviewButton,
  children,
}) => {
  return (
    <div {...mergeStyles(styles['container'], className)}>
      <div className={styles['header']} data-name="ReviewsMobileTitle">
        <UIHeading2>Отзывы</UIHeading2>
        <div className={styles['header-actions']}>{newReviewButton}</div>
      </div>
      {rate && stars && (
        <div className={styles['rate_container']}>
          <div className={styles['rate']} data-name="ReviewsMobileRate">
            {rate}
          </div>
          <div className={styles['stars_container']} data-name="ReviewsMobileStars">
            <div className={styles['stars']}>{stars}</div>
            <UIText1 color="gray60_100">{`${count} ${plural(count, ['отзыв', 'отзыва', 'отзывов'])}`}</UIText1>
          </div>
        </div>
      )}
      <div className={styles['reviews_container']}>
        {React.Children.map(children, (child, index) => (
          <div key={index} className={styles['review']}>
            {child}
          </div>
        ))}
      </div>
      {loadMore}
    </div>
  );
};
