import * as React from 'react';

import * as styles from './Approval.css';

export interface IApprovalProps {
  message: string;
}

export const Approval = ({ message }: IApprovalProps) => {
  return (
    <div className={styles['container']}>
      <div className={styles['icon']} />
      <div className={styles['content']}>{message}</div>
    </div>
  );
};
