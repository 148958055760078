import { numberToPrettyString } from '@cian/utils';
import { plural } from '@cian/utils/lib/shared/plural';

import { IOffer, LandAreaUnitType } from '../../api/offer';

interface IOfferSearchType {
  name: string;
  getRightName(): string;
}

export function simplifiedCardHelper(offer: IOffer) {
  return {
    getFloorRange: () => getFloorRange(offer),
    getLandArea: () => getLandArea(offer),
    getRentPrice: () => getRentPrice(offer),
    getSalePrice: () => getSalePrice(offer),
    getSalePricePerMeter: () => getSalePricePerMeter(offer),
    getType: () => getType(offer),
  };
}

function getFloorRange(offer: IOffer): string | null {
  let floorInfo = null;

  if (offer.floorNumber) {
    floorInfo = `${offer.floorNumber}`;

    if (offer.building && offer.building.floorsCount) {
      floorInfo += `/${offer.building.floorsCount}`;
    }

    floorInfo += ' этаж';
  }

  return floorInfo;
}

function getLandArea(offer: IOffer) {
  if (offer.land && offer.land.area) {
    const unitTypes: { [K in LandAreaUnitType]: string } = {
      hectare: 'га',
      sotka: 'сот.',
    };

    return `${Number(offer.land.area)} ${(offer.land.areaUnitType && unitTypes[offer.land.areaUnitType]) || 'сот.'}`;
  }

  return undefined;
}

function getSalePrice(offer: IOffer): string | null {
  const formattedPrice = numberToPrettyString(offer.bargainTerms.priceRur);

  return `${formattedPrice} \u20bd`;
}

function getRentPrice(offer: IOffer): string {
  let price = offer.bargainTerms.priceRur;

  if (offer.bargainTerms.utilitiesTerms && !offer.bargainTerms.utilitiesTerms.includedInPrice) {
    price += offer.bargainTerms.utilitiesTerms.price;
  }

  return `${numberToPrettyString(price)} \u20bd/${getPaymentPeriod(offer)}`;
}

function getPaymentPeriod(offer: IOffer): string {
  return offer.category && offer.category.indexOf('daily') !== -1 ? 'сутки' : 'мес.';
}

function getSalePricePerMeter(offer: IOffer): React.ReactNode | null {
  if (!offer.bargainTerms) {
    return null;
  }

  if (offer.bargainTerms.priceType !== 'all') {
    return `${numberToPrettyString(offer.bargainTerms.priceRur)} \u20bd`;
  } else if (offer.totalArea) {
    const totalArea = parseFloat(offer.totalArea);

    if (totalArea > 0) {
      const pricePerMeter = Math.round(offer.bargainTerms.priceRur / totalArea);
      const pricePerMeterFormatted = numberToPrettyString(pricePerMeter);

      return `${pricePerMeterFormatted} \u20bd/м\u00b2`;
    }
  }

  return null;
}

function getType(offer: IOffer) {
  return findTypeById(
    [
      createSearchType('flat', () => {
        if (offer.flatType === 'studio') {
          return 'Студия';
        }

        if (offer.flatType === 'openPlan') {
          return 'Своб. планировка';
        }

        return offer.roomsCount ? `${offer.roomsCount}-комн. кв.` : '';
      }),
      createSearchType('newBuildingFlat', () => {
        if (offer.flatType === 'studio') {
          return 'Студия';
        }

        if (offer.flatType === 'openPlan') {
          return 'Своб. планировка';
        }

        return offer.roomsCount ? `${offer.roomsCount}-комн. кв.` : '';
      }),
      createSearchType('dailyFlat', () => {
        if (offer.flatType === 'studio') {
          return 'Студия';
        }

        if (offer.flatType === 'openPlan') {
          return 'Своб. планировка';
        }

        return offer.roomsCount ? `${offer.roomsCount}-комн. кв.` : '';
      }),
      createSearchType(
        'room',
        () =>
          (offer.roomsForSaleCount &&
            `${offer.roomsForSaleCount} ${plural(offer.roomsForSaleCount, ['комната', 'комнаты', 'комнат'])}`) ||
          '1 комната',
      ),
      createSearchType('dailyRoom', () => 'Комната'),
      createSearchType('dailyBed', () => 'Койко-место'),
      createSearchType('bed', () => 'Койко-место'),
      createSearchType('office', () => 'Офис'),
      createSearchType('shoppingArea', () => 'Торговая площадь'),
      createSearchType('warehouse', () => 'Склад'),
      createSearchType('freeAppointmentObject', () => 'Своб. назнач.'),
      createSearchType('industry', () => 'Помещение под производство'),
      createSearchType('house', () => 'Дом'),
      createSearchType('cottage', () => 'Коттедж'),
      createSearchType('dailyHouse', () => 'Дом'),
      createSearchType('townhouse', () => 'Таунхаус'),
      createSearchType('land', () => 'Участок'),
      createSearchType('commercialLand', () => 'Участок'),
      createSearchType('houseShare', () => {
        return offer.shareAmount ? offer.shareAmount + ' дома' : 'часть дома';
      }),
      createSearchType('flatShare', () => {
        return offer.shareAmount ? offer.shareAmount + ' квартиры' : 'доля';
      }),
      createSearchType('garage', () => {
        if (!offer.garage) {
          return 'Гараж';
        }

        switch (offer.garage.type) {
          case 'box':
            return 'Бокс';
          case 'garage':
            return 'Гараж';
          case 'parkingPlace':
            return 'Машиноместо';
          default:
            return '';
        }
      }),
    ],
    offer,
  );
}

function createSearchType(name: string, getRightName: () => string): IOfferSearchType {
  return {
    getRightName,
    name,
  };
}

function findTypeById(values: IOfferSearchType[], offer: IOffer) {
  return values.reduce((acc, item) => {
    if (offer.category === item.name + 'Rent') {
      return item.getRightName();
    } else if (offer.category === item.name + 'Sale') {
      return item.getRightName();
    }

    return acc;
  }, '');
}
