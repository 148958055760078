export interface IChatModal {
  isOpen: boolean;
}

interface IChatModalOpenedAction {
  type: 'IChatModalOpenedAction';
}

interface IChatModalClosedAction {
  type: 'IChatModalClosedAction';
}

export function openChatModal(): IChatModalOpenedAction {
  return {
    type: 'IChatModalOpenedAction',
  };
}

export function closeChatModal(): IChatModalClosedAction {
  return {
    type: 'IChatModalClosedAction',
  };
}

export type TChatModalActions = IChatModalClosedAction | IChatModalOpenedAction;

export const defaultChatModalsState: IChatModal = { isOpen: false };

export function chatModalReducer(state: IChatModal = defaultChatModalsState, action: TChatModalActions): IChatModal {
  switch (action.type) {
    case 'IChatModalOpenedAction':
      return {
        ...state,
        isOpen: true,
      };

    case 'IChatModalClosedAction':
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
}
