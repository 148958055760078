import * as React from 'react';
import { UIText1 } from '@cian/ui-kit/typography';

import { Link } from '../Link';
import { SocnetworkIcon } from '../socnetwork_icon';

import * as styles from './RealtorContacts.css';

interface ISocialItemProps {
  type: string;
  url?: string;
  text?: string;
}

export const SocialItem = ({ type, url, text }: ISocialItemProps) => {
  return (
    <div className={styles['socnetwork']}>
      <div className={styles['socnetwork-icon']}>
        <SocnetworkIcon type={type} link={url} />
      </div>
      {url ? (
        <Link href={url} rel="nofollow noopener" target="_blank">
          <UIText1>{text}</UIText1>
        </Link>
      ) : (
        <UIText1>{text}</UIText1>
      )}
    </div>
  );
};
