import * as React from 'react';
import { Image } from '@cian/ui-kit';
import { HonestWork } from '@cian/honestwork-component';

import * as styles from './index.css';

interface IProps {
  size: number;
  url?: string;
  hasFairplay?: boolean;
}

export const PhotoMobile = ({ size, url, hasFairplay }: IProps) => (
  <div className={styles['image_wrp']}>
    <Image src={url || ''} width={size} height={size} borderRadius="50%" objectFit="cover" />
    {hasFairplay && (
      <div className={styles['shield']}>
        <HonestWork isPopupDisabled />
      </div>
    )}
  </div>
);
