import * as React from 'react';
import { useSelector } from 'react-redux';

import { ResponseRateMobile, ResponseTimeMobile } from '../../../components/statistics';
import { getResponseTimeText } from '../../../components/aside_general_info/utils/responseTimeText';
import { selectChatStatistics } from '../../../selectors/chatStatistics/selectChatStatistics';
import { getRateType } from '../utils';

export const ChatStatisticsMobileContainer: React.FunctionComponent<{}> = () => {
  const { responseRate, averageResponseTime } = useSelector(selectChatStatistics);

  const rateType = getRateType(responseRate);
  const rateValue = Math.round(responseRate * 100);

  if (!responseRate && (!averageResponseTime || averageResponseTime <= 0)) {
    return null;
  }

  return (
    <>
      {!!responseRate && <ResponseRateMobile rateType={rateType} rateValue={rateValue} />}
      {averageResponseTime > 0 && (
        <ResponseTimeMobile responseTimeText={getResponseTimeText(averageResponseTime) || ''} />
      )}
    </>
  );
};
