import * as React from 'react';
import { Button, Responsive } from '@cian/ui-kit';

import * as styles from '../index.css';

type SubmitButtonProps = {
  text: string;
  isLoading: boolean;
  onSubmit: () => void;
};

export const SubmitButton = React.forwardRef(
  ({ text, isLoading, onSubmit }: SubmitButtonProps, ref: React.RefObject<HTMLDivElement>) => (
    <div className={styles['submit_button_wrapper']} ref={ref}>
      <Responsive phone tablet>
        <Button theme="fill_primary" size="XS" onClick={onSubmit} loading={isLoading}>
          {text}
        </Button>
      </Responsive>
      <Responsive desktop>
        <Button size="XS" theme="fill_primary" onClick={onSubmit} loading={isLoading}>
          {text}
        </Button>
      </Responsive>
    </div>
  ),
);

SubmitButton.displayName = 'SubmitButton';
