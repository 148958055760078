import * as React from 'react';
import { RoundButton } from '@cian/ui-kit';

import { IButtonsProps } from '../types';
import moreImage from '../icons/more.svg';

export type MoreButtonProps = Pick<IButtonsProps, 'buttonClassName'> & {
  onClick: () => void;
};

export const MoreButton = React.forwardRef(
  ({ buttonClassName, onClick }: MoreButtonProps, ref: React.RefObject<HTMLDivElement>) => {
    const icon = <img src={moreImage} alt="map icon" />;

    return (
      <div className={buttonClassName || ''} ref={ref}>
        <RoundButton size="XS" icon={icon} title="Ещё" theme="fill_light" onClick={onClick} />
      </div>
    );
  },
);

MoreButton.displayName = 'MoreButton';
