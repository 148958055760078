import { connect } from 'react-redux';

import { IAppState } from '../../../../store';
import {
  simplifiedOfferCardPopupCloseAction,
  simplifiedOfferCardPopupOpenAction,
} from '../../../../store/simplified_card_popups';
import { Agent, IAgentDispatchProps, IAgentOwnProps, IAgentStoreProps } from './index';
import { TThunkDispatch } from '../../../../types/redux';

function mapStateToProps(state: IAppState) {
  return {
    userIsAuthenticated: state.user.isAuthenticated,
    userLevelInfo: state.realtor.moderationInfo?.userLevelInfo,
  };
}

function mapDispatchToProps(dispatch: TThunkDispatch) {
  return {
    onPopupClose: (offerId: number) => dispatch(simplifiedOfferCardPopupCloseAction(offerId, 'agent')),
    onPopupOpen: (offerId: number) => dispatch(simplifiedOfferCardPopupOpenAction(offerId, 'agent')),
  };
}

export const AgentContainer = connect<IAgentStoreProps, IAgentDispatchProps, IAgentOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Agent);
