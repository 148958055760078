import * as React from 'react';
import { useSelector } from 'react-redux';
import { defaultTo } from 'ramda';

import { getCounters, getDescriptionText, getRatingSubtitle, isLongDescription } from './helpers';
import { AboutRealtorDesktop } from '../../../components/aboutRealtor/desktop/AboutRealtorDesktop';
import { getRieltorDescription } from '../../../components/aboutRealtor/desktop/helpers';
import { isAvatarRealtorRoundness } from '../../../utils/realtor';
import { selectOffersCount } from '../../../selectors/offers/selectOffersCount';
import { selectRealtor } from '../../../selectors/realtor/selectRealtor';
import { selectReviews } from '../../../selectors/reviews/selectReviews';
import { ContactsContainer } from '../../ContactsContainer';
import { getSuperAgentBadge } from '../../../components/SuperAgentLevelViewContainer';

export const AboutRealtorDesktopContainer = () => {
  const realtor = useSelector(selectRealtor);
  const offersCount = useSelector(selectOffersCount);
  const { totalCount, entityRate } = useSelector(selectReviews);

  const isAvatarRound = isAvatarRealtorRoundness(realtor);
  const {
    age,
    name,
    avatarUrl,
    companyName,
    regionsNames,
    description,
    experience,
    skills,
    moderationInfo,
    hasRgrParticipation,
  } = realtor;

  const [descriptionMinimized, setdescriptionMinimized] = React.useState(isLongDescription(description));
  const descriptionText = getDescriptionText(description, descriptionMinimized);

  const counters = getCounters({ experience, age, offersCount });
  const ratingSubtitle = getRatingSubtitle({ totalCount, entityRate });

  const handleDescriptionClick = React.useCallback(() => {
    setdescriptionMinimized(false);
  }, []);

  const about = getRieltorDescription({
    descriptionText,
    skills,
    regionsNames,
    companyName,
    onDescriptionOpen: descriptionMinimized ? handleDescriptionClick : undefined,
  });

  const badge = getSuperAgentBadge(defaultTo(null, moderationInfo));

  const props = {
    about,
    avatarUrl,
    counters,
    isAvatarRound,
    hasRgrParticipation: Boolean(hasRgrParticipation),
    name,
    rate: entityRate || 0,
    ratingSubtitle,
    userLevelInfo: moderationInfo?.userLevelInfo || undefined,
    onDescriptionClick: handleDescriptionClick,
    contacts: <ContactsContainer />,
    badge,
  };

  return <AboutRealtorDesktop {...props} />;
};
