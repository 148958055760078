/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetServiceStatusForSpecialistModel,
  IGetServiceStatusForSpecialistRequest,
  IMappers,
  IGetServiceStatusForSpecialistResponse,
  IGetServiceStatusForSpecialistResponseError,
  TGetServiceStatusForSpecialistResponse,
} from './types';

const defaultConfig: TGetServiceStatusForSpecialistModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'demand',
  pathApi: '/v2/get-service-status-for-specialist/',
  hostType: 'api',
} as TGetServiceStatusForSpecialistModel;

function createGetServiceStatusForSpecialistModel(
  parameters: IGetServiceStatusForSpecialistRequest,
): TGetServiceStatusForSpecialistModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetServiceStatusForSpecialistOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetServiceStatusForSpecialistRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetServiceStatusForSpecialist<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetServiceStatusForSpecialistOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetServiceStatusForSpecialistResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetServiceStatusForSpecialistModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetServiceStatusForSpecialistResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetServiceStatusForSpecialistResponseError);
    }
  }

  return { response, statusCode } as TGetServiceStatusForSpecialistResponse;
}

export { defaultConfig, createGetServiceStatusForSpecialistModel, fetchGetServiceStatusForSpecialist };
