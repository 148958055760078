import { ca } from '@cian/analytics';

type ContactType = 'site' | 'skype' | 'ok' | 'vk';

export const trackContactClick = (contactType: ContactType) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Specialist',
    action: 'click_contact',
    label: contactType,
  });
};
