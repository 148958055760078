import { connect } from 'react-redux';

import { AsideGeneralInfo, IAsideGeneralInfoDispatchProps, IAsideGeneralInfoProps } from '.';
import { IAppState } from '../../store';
import { agentChatReceived } from '../../store/chats';
import { selectHasVerifiedDocuments } from '../../selectors';
import { TThunkDispatch } from '../../types/redux';

function mapStateToProps(state: IAppState): IAsideGeneralInfoProps {
  return {
    abGroup: state.pageData.abGroup || 0,
    hasFairplay: state.realtor.hasFairplay,
    isChatsEnabled: state.realtor.isChatsEnabled,
    name: state.realtor.name || '',
    rate: state.reviews.entityRate,
    realtorChat: state.realtorChat,
    reviewsCount: state.reviews.totalCount,
    socialProfiles: state.realtor.socialProfiles,
    statistics: state.chatStatistics,
    user: state.user,
    userLevelInfo: state.realtor.moderationInfo?.userLevelInfo,
    hasVerifiedDocuments: selectHasVerifiedDocuments(state),
    deviceType: state.pageData.deviceType,
  };
}

function mapDispatchToProps(dispatch: TThunkDispatch): IAsideGeneralInfoDispatchProps {
  return {
    onChatMessageSent: (chatId: string) => dispatch(agentChatReceived(chatId)),
  };
}

export const AsideGeneralInfoContainer = connect<IAsideGeneralInfoProps, IAsideGeneralInfoDispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps,
)(AsideGeneralInfo);
