import { useCallback, useState } from 'react';

import { ILocation } from '../../../types/location';

export interface IUseLocationSuggestValue {
  location?: ILocation | null;

  onLocationChange(location?: ILocation | null): void;
  togglePopup(value: boolean): void;
  getLocationSuggest(value: string): void;
}
export const useLocationSuggestValue = ({
  location,
  getLocationSuggest,
  togglePopup,
  onLocationChange,
}: IUseLocationSuggestValue) => {
  const [suggestValue, setSuggestValue] = useState(location?.displayName || '');

  const onSuggestionValueChange = useCallback(
    (value: string) => {
      setSuggestValue(value);
      onLocationChange(null);
      getLocationSuggest(value);
    },
    [setSuggestValue, getLocationSuggest, onLocationChange],
  );

  const clearSuggestValue = useCallback(() => {
    setSuggestValue(location?.displayName || '');
    togglePopup(false);
  }, [location, setSuggestValue, togglePopup]);

  return {
    suggestValue: location?.displayName || suggestValue,
    onSuggestionValueChange,
    clearSuggestValue,
  };
};
