import * as React from 'react';
import { UIHeading1, UIText1 } from '@cian/ui-kit/typography';

import * as styles from './index.css';

interface IGeneralInfoMobileProps {
  name?: string;
  companyName?: string;
  age?: string;
}

export const GeneralInfoMobile: React.FunctionComponent<IGeneralInfoMobileProps> = ({ name, companyName, age }) => {
  return (
    <>
      <UIHeading1>{name}</UIHeading1>
      {!!companyName && !!age && (
        <div className={styles['description']} data-name="GeneralInfoMobileDescription">
          <UIText1>
            {!!companyName && companyName}
            {!!companyName && !!age && <br />}
            {!!age && `${age} на ЦИАН`}
          </UIText1>
        </div>
      )}
    </>
  );
};
