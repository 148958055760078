/* eslint-disable import/no-restricted-paths */

import * as React from 'react';
import { UIText1 } from '@cian/ui-kit';
import { plural } from '@cian/utils/lib/shared/plural';

import { IReview } from '../../types/review';
import { ModerationReviewContainer, ReviewContainer } from '../../containers/review';
import { AnswerFormContainer } from '../answer_form/container';
import { AnswersListContainer } from '../answers_list/container';
import { EAnswerFormState } from '../../store/answer_form';
import { EReviewFormState } from '../../store/review_form';
import { NoReviewsContainer } from '../../containers/NoReviewsContainer';
import { ReviewFormContainer } from '../review_form/container';
import { requestAuthentication } from '../../../browser/utils/authentication';
import { scrollToElement } from '../../../browser/utils/scroll';
import { timestampToDate } from '../../utils/time';

import * as styles from './index.css';

export interface IReviewsListStoreProps {
  reviews: IReview[];
  count: number;
  userIsAuthenticated: boolean;
  realtyUserId: number | undefined;
  answerFormAnswerId?: string;
  answerFormReviewId?: string;
  answerFormState?: EAnswerFormState;
  editFormReviewId?: string;
  editFormState?: EReviewFormState;
}

export interface IReviewsListDispatchProps {
  openAnswerForm(reviewId: string): void;
  openEditForm(reviewId: string, text: string, rating: number): void;
  fetchReviews(): void;
}

export type TReviewsListProps = IReviewsListStoreProps & IReviewsListDispatchProps;

let scrolledTo = false;

export class ReviewsList extends React.Component<TReviewsListProps, {}> {
  private wantsScrollTo: string | null = null;
  private needScrollTo: HTMLElement | null = null;

  public constructor(props: TReviewsListProps) {
    super(props);

    // tslint:disable-next-line
    if (typeof window !== 'undefined' && window.location.hash) {
      const hash = window.location.hash.slice(1);

      if (hash && hash.startsWith('review_')) {
        const { reviews } = props;

        if (reviews.some(review => `review_${review.reviewId}` === hash)) {
          this.wantsScrollTo = hash;
        }
      }
    }
  }

  public componentDidMount() {
    if (!scrolledTo && this.needScrollTo) {
      window.setTimeout(() => scrollToElement(this.needScrollTo), 0);
      scrolledTo = true;
    }
  }

  private renderReviews() {
    const { reviews, realtyUserId } = this.props;

    return reviews.map(review => {
      /**
       * Отображаем отклоненный отзыв только для хозяина отзыва
       */
      if (review.status === 'declined' && review.user.realtyUserId !== realtyUserId) {
        return null;
      }

      const needScrollTo = this.wantsScrollTo && this.wantsScrollTo === `review_${review.reviewId}`;

      return (
        <React.Fragment key={`review_${review.reviewId}`}>
          <ReviewContainer
            type="review"
            review={review}
            innerRef={needScrollTo ? ref => (this.needScrollTo = ref) : undefined}
            containerStyle={styles['review_container']}
            onReplyClick={this.isReplyAvailable(review) ? () => this.handleReplyClick(review.reviewId) : undefined}
            onEditClick={
              this.isEditAvailable(review)
                ? () => this.handleEditClick(review.reviewId, review.text, review.rate)
                : undefined
            }
          />
          <ModerationReviewContainer containerStyle={styles['review--refused']} moderation={review.moderation} />
          {this.renderEditForm(review)}
          {this.renderAnswerForm(review)}
          {review.answers.length > 0 && <AnswersListContainer reviewId={review.reviewId} answers={review.answers} />}
        </React.Fragment>
      );
    });
  }

  private renderShowMoreButton() {
    const { reviews, count } = this.props;

    if (reviews.length >= count) {
      return null;
    }

    const moreReviewsNumber = count - reviews.length;

    return (
      <div className={styles['show-more-reviews-button-container']}>
        <UIText1 onClick={this.handleShowMoreReviewsClick} color="primary_100">
          {`Ещё ${moreReviewsNumber} ${plural(moreReviewsNumber, ['отзыв', 'отзыва', 'отзывов'])}`}
        </UIText1>
      </div>
    );
  }

  public render() {
    if (!Array.isArray(this.props.reviews) || this.props.reviews.length === 0) {
      return <NoReviewsContainer />;
    }

    return (
      <div>
        {this.renderReviews()}
        {this.renderShowMoreButton()}
      </div>
    );
  }

  private handleShowMoreReviewsClick = () => {
    const { reviews } = this.props;

    if (!Array.isArray(reviews) || reviews.length === 0) {
      return null;
    }
    this.props.fetchReviews();

    return null;
  };

  private handleEditClick = (reviewId: string, text: string, rating: number) => {
    this.props.openEditForm(reviewId, text, rating);
  };

  private handleReplyClick = (reviewId: string) => {
    if (!this.props.userIsAuthenticated) {
      requestAuthentication(`review_${reviewId}`);
    } else {
      this.props.openAnswerForm(reviewId);
    }
  };

  private renderEditForm = (review: IReview) => {
    if (!this.isEditFormVisible(review.reviewId)) {
      return null;
    }

    return <ReviewFormContainer />;
  };

  private renderAnswerForm = (review: IReview) => {
    if (!this.isAnswerFormVisible(review.reviewId)) {
      return null;
    }

    return <AnswerFormContainer />;
  };

  private isEditAvailable(review: IReview) {
    const { realtyUserId, editFormState, editFormReviewId } = this.props;

    return (
      review.status !== 'declined' &&
      review.user.realtyUserId === realtyUserId &&
      timestampToDate(review.created + 20 * 60) > new Date() &&
      (typeof editFormState === 'undefined' || editFormReviewId !== review.reviewId)
    );
  }

  private isReplyAvailable(review: IReview) {
    const { editFormState, editFormReviewId, answerFormState, answerFormReviewId, answerFormAnswerId } = this.props;

    return (
      !this.isEditAvailable(review) &&
      review.status !== 'declined' &&
      (typeof answerFormState === 'undefined' ||
        answerFormReviewId !== review.reviewId ||
        typeof answerFormAnswerId !== 'undefined') &&
      (typeof editFormState === 'undefined' || editFormReviewId !== review.reviewId)
    );
  }

  private isEditFormVisible(reviewId: string) {
    const { editFormState, editFormReviewId } = this.props;

    return typeof editFormState !== 'undefined' && editFormReviewId === reviewId;
  }

  private isAnswerFormVisible(reviewId: string) {
    const { answerFormState, answerFormReviewId, answerFormAnswerId } = this.props;

    return (
      typeof answerFormState !== 'undefined' &&
      answerFormReviewId === reviewId &&
      typeof answerFormAnswerId === 'undefined'
    );
  }
}
