import { mergeStyles } from '@cian/utils/lib/shared/style';
import * as React from 'react';

import * as styles from './index.css';

type ISectionProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;

export class Section extends React.PureComponent<ISectionProps, {}> {
  public render() {
    const { children, className, style, ...sharedProps } = this.props;

    return (
      <section {...sharedProps} {...mergeStyles(styles['container'], className, style)}>
        {children}
      </section>
    );
  }
}
