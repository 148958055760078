import { IBoundedBy } from '../types/location';
import { IBoundedBySchema } from '../repositories/geo-temp-layer/entities/catalog_item/BoundedBySchema';
import { prepareBoundedByCorner } from './prepareBoundedByCorner';

export const prepareBoundedBy = ({ lowerCorner, upperCorner }: IBoundedBySchema): IBoundedBy => {
  return {
    lowerCorner: prepareBoundedByCorner(lowerCorner),
    upperCorner: prepareBoundedByCorner(upperCorner),
  };
};
