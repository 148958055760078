import { Image, Label } from '@cian/ui-kit';
import * as React from 'react';

import starSvg from './icons/star.svg';

import * as styles from './SuperAgentBadge.css';

export type TSuperAgentBadgeProps = {
  levelName: string;
};

export const SuperAgentBadge = ({ levelName }: TSuperAgentBadgeProps) => {
  return (
    <div data-testid="super-agent-badge" className={styles['wrapper']}>
      <Label
        data-testid={'badgeSuperAgent'}
        icon={<Image src={starSvg} height={16} width={16} />}
        background="#EBF9E6"
        color="#34ac0a"
      >
        {levelName}
      </Label>
    </div>
  );
};
