import * as React from 'react';
import { useState, useCallback } from 'react';
import { UIHeading2, UIText1 } from '@cian/ui-kit/typography';
import { IconArrowDown16 } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';

import { formatDescriptionText, needExpand } from './utils';

import * as styles from './index.css';

interface IDescriptionMobileProps {
  text: string;
  className?: string;
  onExpandClick?: () => void;
}

export const DescriptionMobile: React.FunctionComponent<IDescriptionMobileProps> = ({
  text,
  className,
  onExpandClick,
}) => {
  const [expanded, setExpanded] = useState(false);
  const onExpandClickHandler = useCallback(() => {
    setExpanded(true);
    if (onExpandClick) {
      onExpandClick();
    }
  }, []);

  return (
    <div {...mergeStyles(styles['container'], className)}>
      <div className={styles['header']}>
        <UIHeading2>О себе</UIHeading2>
      </div>
      <UIText1 whiteSpace="pre-line">{formatDescriptionText(text, expanded)}</UIText1>
      {needExpand(text) && !expanded && (
        <div className={styles['expand']} onClick={onExpandClickHandler}>
          <UIText1 color="primary_100">
            Читать дальше
            <span className={styles['expand_icon']}>
              <IconArrowDown16 color="primary_100" />
            </span>
          </UIText1>
        </div>
      )}
    </div>
  );
};
