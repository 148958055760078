/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAppState, IContext } from '.';
import { changeFavorite as apiChangeFavorite } from '../api/favorite';
import { IOffer } from '../api/offer';
import { showMotivationPopup } from './login_motivation_popup';
import { TThunkDispatch } from '../types/redux';

export const FavoriteChangedActionType = 'filters/favorite/FAVORITE_CHANGED';
export const FavoriteChangedErrorActionType = 'filters/favorite/FAVORITE_CHANGED_ERROR';

export interface IFavoriteChangedAction {
  type: 'filters/favorite/FAVORITE_CHANGED';
  offerId: number;
}

export interface IFavoriteChangedErrorAction {
  type: 'filters/favorite/FAVORITE_CHANGED_ERROR';
  offerId: number;
}

export type TAction = IFavoriteChangedAction | IFavoriteChangedErrorAction;

export function changeFavorite(offer: IOffer, isFavorite: boolean) {
  return (dispatch: TThunkDispatch, getState: () => IAppState, context: IContext) => {
    const state = getState();
    const offerId = offer.cianId || offer.id;
    const currentCount = (window as any).__header_favorites_count__ || 0;

    if (
      isFavorite &&
      !state.user.isAuthenticated &&
      currentCount >= 3 &&
      // tslint:disable-next-line:no-any
      (window as any).waitForLoginPopup
    ) {
      // tslint:disable-next-line:no-any
      (window as any).waitForLoginPopup('favorites');
      // tslint:disable-next-line:no-any
      if ((window as any).__headerLoginPopupShowMotivation) {
        // tslint:disable-next-line:no-any
        (window as any).__headerLoginPopupShowMotivation();
      }
    } else {
      dispatch({
        offerId,
        type: FavoriteChangedActionType,
      });

      if (isFavorite) {
        // tslint:disable-next-line:no-any
        (window as any).__header_favorites_count__ = currentCount + 1;

        if (!state.user.isAuthenticated && state.loginMotivationPopupOnFavorite.isActive) {
          // @ts-ignore @FIXME:
          dispatch(showMotivationPopup(offerId));
        }
      } else {
        // tslint:disable-next-line:no-any
        (window as any).__header_favorites_count__ = currentCount - 1;
      }

      apiChangeFavorite(context.httpApi, offer, isFavorite).then(
        () => {
          return window.__header_updateFavorites__ && window.__header_updateFavorites__(isFavorite);
        },
        () => {
          // tslint:disable-next-line:no-any
          (window as any).__header_favorites_count__ = currentCount - 1;

          changeFavoriteError(offerId);
        },
      );
    }
  };
}

export function changeFavoriteError(offerId: number): IFavoriteChangedErrorAction {
  return {
    offerId,
    type: FavoriteChangedErrorActionType,
  };
}
