/* eslint-disable max-lines */
// tslint:disable:max-file-line-count
import * as React from 'react';
import { IStyleProps, mergeStyles } from '@cian/utils';

import { VasComponent } from '../../../../node_modules/@cian/vas-tooltip-component';
import { IOffer, LandAreaUnitType } from '../../api/offer';
import { offerHelper } from '../../offer/presenters';
import { IAuthenticatedUser, TUser } from '../../store';
import { trackOfferClick, trackPhotoSlideChanged } from '../../tracking/offer';
import { getVasLabel } from '../../utils/getVasLabel';
import { isArrayWithItems } from '../../utils/is';
import { canUseHiddenBase } from '../../utils/user';
import { IOnFavoriteChange } from '../simplified_offer_card';
import { AddressContainer } from './address';
import { Column } from './column';
import { DescriptionContainer } from './description/container';
import { HasVideoBadge } from './has_video_badge';
import { HiddenBaseLabel } from './hidden_base_label';
import { HorizontalCard } from './horizontal_card';
import { Map } from './map';
import { OfferMenu } from './menu';
import { SimplePhoneButton } from './phone/simple_button';
import { PhotoContainer } from './photo';
import { PromoInfo } from './promo_info';
import { TimeLabel } from './time_label';
import { UserInfoContainer } from './user_info/container';

export interface ICommercialOfferCardProps {
  offer: IOffer;
  onPhotoSlideChangedOnce?: () => void;
  onMapOpened?: () => void;
  onMapClosed?: () => void;
  onMenuOpened?: () => void;
  onPDFLinkClicked?: () => void;
  onDocLinkClicked?: () => void;
  onPhoneOpened?: () => void;
  onFavoriteChange: IOnFavoriteChange;
  mortgageBanner?: React.ReactNode | undefined;

  user: TUser;
}

export interface ICommercialOfferCardState {
  galleryControlsHidden: boolean;
  isPhotoSlideChanged: boolean;
  animateHide: boolean;
  isHidden: boolean;
  isCommentAreaOpened: boolean;
  isCallbackModalOpened: boolean;

  // Menu
  isMapActive: boolean;
  isMoreActive: boolean;
  isReporting: boolean;

  isMount: boolean;
}

const commonStyle = require('./index.css');
const style = require('./commercial_card.css');

const prepareDescription = (str: string | undefined) =>
  str
    ? // 264 - максимальное количество символов, которые влезают в три строки
      str.length >= 264
      ? str.slice(0, 264).replace(/\W$/, '').trim().concat('...')
      : str
    : '';

const getCommercialLandTitleArea = (area: string | number | null, unitType: LandAreaUnitType | null) =>
  area ? `${parseFloat(area as string)} ${unitType === 'sotka' ? 'сот.' : 'га.'}` : '';

export class CommercialOfferCard extends React.Component<ICommercialOfferCardProps, ICommercialOfferCardState> {
  public constructor(props: ICommercialOfferCardProps) {
    super(props);

    this.state = {
      animateHide: false,
      galleryControlsHidden: true,
      isCallbackModalOpened: false,
      isCommentAreaOpened: false,
      isHidden: false,

      isMapActive: false,
      isMoreActive: false,
      isPhotoSlideChanged: false,
      isReporting: false,

      isMount: false,
    };
  }

  public componentDidMount() {
    this.setState({ isMount: true });
  }

  public render() {
    const { offer } = this.props;
    const isRepresentative = !!(offer.businessShoppingCenter && offer.businessShoppingCenter.fromRepresentative);

    return (
      <div
        {...mergeStyles(
          commonStyle['offer-container'],
          offer.isTop3 && commonStyle['offer-container--top3'],
          this.state.animateHide && commonStyle['offer--hide'],
          this.state.isHidden && commonStyle['offer--hidden'],
          !offer.isTop3 && !offer.isColorized && isRepresentative && commonStyle['represent'],
        )}
      >
        <HorizontalCard
          content={this.renderContent}
          containerStyle={mergeStyles(
            commonStyle['offer'],
            style['offer'],
            offer.isTop3 && commonStyle['offer--top3'],
            offer.isColorized && commonStyle['offer--colorized'],
          )}
          isPaid={offer.isPaid}
          isTop3={offer.isTop3}
          isColorized={offer.isColorized}
          isRepresentative={isRepresentative}
          mediaStyle={style['media']}
          onMouseEnter={this.onHoverOver}
          onTouchStart={this.onHoverOver}
          onMouseLeave={this.onHoverOut}
          onTouchMove={this.onHoverOut}
          media={
            <div className={commonStyle['photo-container']}>
              {offer.promoInfo && (
                <PromoInfo
                  isSpecProject={offer.promoInfo.specProject}
                  name={offer.promoInfo.name}
                  href={offer.fullUrl}
                />
              )}
              {isArrayWithItems(offer.videos) && <HasVideoBadge />}
              <PhotoContainer
                photos={offer.photos}
                containerStyle={commonStyle['photo']}
                galleryControlsHidden={this.state.galleryControlsHidden}
                onSlideChanged={this.handlePhotoSlideChangedOnce}
              />
            </div>
          }
        />

        {offer.geo && offer.geo.coordinates && this.state.isMapActive && <Map coordinates={offer.geo.coordinates} />}
      </div>
    );
  }

  public renderContent = () => {
    const { offer, user } = this.props;

    return (
      <div>
        <div className={style['content']}>
          <Column width={80}>
            {this.renderTitle()}
            <div>
              <AddressContainer containerStyle={style['address']} offer={offer} />
              {canUseHiddenBase(user as IAuthenticatedUser) && Boolean(offer.isInHiddenBase) && (
                <HiddenBaseLabel containerStyle={commonStyle['hidden-base-label']} />
              )}
            </div>
            <DescriptionContainer
              isRecidivist={offer.isRecidivist}
              isExcludedFromAction={offer.isExcludedFromAction}
              description={prepareDescription(offer.description)}
              descriptionWordsHighlighted={offer.descriptionWordsHighlighted}
              containerStyle={style['description']}
              recidivistStyle={style['recidivist']}
              excludedFromActionStyle={style['recidivist']}
              onComplainStateChanged={this.onComplainStateChanged}
            />
          </Column>
          <Column width={20}>{this.renderAutorInfo()}</Column>
        </div>
        <div className={style['footer']}>
          {this.renderFooterMenu({
            callbackButtonStyle: commonStyle['outside-hidden'],
          })}
        </div>
      </div>
    );
  };

  private renderTitle() {
    const { mortgageBanner, offer } = this.props;
    const { commercial, presenters } = offerHelper(offer);
    const term = offer.dealType === 'rent' ? presenters.getCommercialRentPrice() : presenters.getSalePrice();
    const subTerm =
      offer.dealType === 'rent'
        ? commercial.isCommercialLand()
          ? commercial.getCommercialLandPerMeterByYear()
          : presenters.getCommercialPerMeterByYear()
        : presenters.getSalePricePerMeter();
    const classType = commercial.getBuildingClassType();
    const totalArea = commercial.isCommercialLand()
      ? `${getCommercialLandTitleArea(commercial.getCommercialLandArea(), commercial.getCommercialLandUnitType())}`
      : `${presenters.getTotalArea()} м\u00B2`;
    const vatPrice = commercial.getVatTypeWithVatPrice();

    return (
      <div className={style['header']}>
        <h3 className={style['header-title']}>
          <a
            className={style['header-link']}
            href={offer.fullUrl}
            target="_blank"
            onClick={() => trackOfferClick(this.props.offer)}
            rel="noreferrer"
          >
            {commercial.getType()} {totalArea} за {term}
          </a>
          &nbsp;
          {classType && <span className={style['header-class-type']}>Класс {classType}</span>}
        </h3>
        <div className={style['header-sub-term']}>
          <ul className={style['header-sub-term-list']}>
            {vatPrice && <li className={style['header-sub-term-item']}>{vatPrice}</li>}
            {subTerm && <li className={style['header-sub-term-item']}>{subTerm}</li>}
          </ul>
          {mortgageBanner && <span className={style['mortgage-banner']}>{mortgageBanner}</span>}
        </div>
      </div>
    );
  }

  private renderAutorInfo() {
    const { offer } = this.props;

    return (
      <div className={style['author-info']}>
        {this.renderPhoneButton()}
        {offer.user && <UserInfoContainer offer={offer} containerStyle={style['user-info']} />}
      </div>
    );
  }

  private renderPhoneButton() {
    const { offer } = this.props;

    const phone = offerHelper(offer).presenters.getPhone();

    return phone ? (
      <div className={style['chats-block']}>
        <SimplePhoneButton phone={phone} onPhoneOpened={this.props.onPhoneOpened} containerStyle={style['phone']} />
      </div>
    ) : null;
  }

  private renderFooterMenu(props: {
    likeButtonStyle?: IStyleProps;
    callbackButtonStyle?: IStyleProps;
    mapButtonStyle?: IStyleProps;
    moreButtonStyle?: IStyleProps;
  }) {
    const { offer, user } = this.props;
    const locationId = (offer.geo && offer.geo.address.length > 0 && offer.geo.address[0].id) || undefined;

    const vasList = [];

    if (offer.isAuction) {
      vasList.push('auction');
    }
    if (offer.isColorized) {
      vasList.push('colorized');
    }
    if (offer.isTop3) {
      vasList.push('top3');
    }
    if (offer.isPremium) {
      vasList.push('premium');
    }
    if (offer.isPaid && (offer.dealType === 'sale' || locationId === undefined || locationId > 2)) {
      vasList.push('payed');
    }

    const vasLabel = getVasLabel(vasList[0]);

    return (
      <div {...mergeStyles(commonStyle['footer-menu'])}>
        <OfferMenu
          offer={offer}
          containerStyle={style['offer-menu']}
          {...props}
          isFavoriteActive={!!offer.isFavorite}
          isMapActive={this.state.isMapActive}
          isMoreActive={this.state.isMoreActive}
          toggleFavorite={(value: boolean) => this.props.onFavoriteChange(this.props.offer, value)}
          toggleMore={this.handleMoreToggle}
          user={user}
          isReporting={this.state.isReporting}
          onComplainStateChanged={this.onComplainStateChanged}
          isCallbackVisible={!!this.getAgentPhone()}
          onCallbackClick={this.toggleCallbackModal}
          onMapButtonClick={this.onMapButtonClick}
          onCommentClicked={this.handleCommentClick}
          onPDFLinkClicked={this.props.onPDFLinkClicked}
          onDocLinkClicked={this.props.onDocLinkClicked}
          onHideFromPrintClicked={() => {
            this.setState({ animateHide: true });
            setTimeout(() => {
              this.setState({ isHidden: true });
            }, 500);
          }}
        />
        <div className={commonStyle['footer-flags']}>
          <TimeLabel added={offer.added} humanizedTime={offer.humanizedTimedelta} />
          <div className={style['vas-list']}>
            <VasComponent label={vasLabel} vasList={vasList} uniqueKey={offer.id} />
          </div>
        </div>
      </div>
    );
  }

  private onComplainStateChanged = (isReporting: boolean) => {
    this.setState({
      isMoreActive: true,
      isReporting,
    });
  };

  private handlePhotoSlideChangedOnce = () => {
    const { isPhotoSlideChanged } = this.state;

    if (!isPhotoSlideChanged && this.props.onPhotoSlideChangedOnce) {
      trackPhotoSlideChanged();
      this.props.onPhotoSlideChangedOnce();
      this.setState({
        isPhotoSlideChanged: true,
      });
    }
  };

  private handleCommentClick = () => {
    if (this.state.isCommentAreaOpened) {
      return;
    }

    this.setState({
      isCommentAreaOpened: true,
    });
  };

  private handleMoreToggle = (value: boolean) => {
    this.setState({
      isMoreActive: value,
    });

    if (value && this.props.onMenuOpened) {
      this.props.onMenuOpened();
    }
  };

  private onHoverOver = () => {
    this.setState({
      galleryControlsHidden: false,
    });
  };

  private onHoverOut = () => {
    this.setState({
      galleryControlsHidden: true,
    });
  };

  private onMapButtonClick = () => {
    const { isMapActive } = this.state;

    this.setState({
      isMapActive: !isMapActive,
    });

    if (isMapActive && this.props.onMapClosed) {
      this.props.onMapClosed();
    } else if (!isMapActive && this.props.onMapOpened) {
      this.props.onMapOpened();
    }
  };
  /**
   * Open/close callback modal window
   */
  private toggleCallbackModal = () => {
    this.setState({
      isCallbackModalOpened: !this.state.isCallbackModalOpened,
    });
  };

  private getAgentPhone = (): string | undefined => {
    const {
      offer: { user },
    } = this.props;

    // Is callback user and has phones for callback
    if (user && user.isCallbackUser && isArrayWithItems(user.phoneNumbers)) {
      const firstPhoneNumber = user.phoneNumbers[0];

      return `${firstPhoneNumber.countryCode}${firstPhoneNumber.number}`;
    }

    return undefined;
  };
}
