/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IGetAgentOffersResponseSchema } from '../../entities/schemas/GetAgentOffersResponseSchema';

export type TGetAgentOffersModel = IModel<IGetAgentOffersRequest, TGetAgentOffersResponse>;

export interface IGetAgentOffersRequest {
  /** Cian id пользователя **/
  userId?: number | null;
}

export type TGetAgentOffersResponse = IGetAgentOffersResponse200 | IGetAgentOffersResponse400;

export interface IGetAgentOffersResponse200 extends IModelResponse<IGetAgentOffersResponse> {
  statusCode: 200;
}

export interface IGetAgentOffersResponse400 extends IModelResponse<IGetAgentOffersResponseError> {
  statusCode: 400;
}

export interface IGetAgentOffersResponse {
  data: IGetAgentOffersResponseSchema;
  status: EStatus;
}

export interface IGetAgentOffersResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetAgentOffersResponse | IGetAgentOffersResponseError;

export enum EStatus {
  /** undefined **/
  Ok = 'ok',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetAgentOffersResponse): TResponse200;
  400(response: IGetAgentOffersResponseError): TResponse400;
}
