import * as React from 'react';
import { IconArrowLeft16 } from '@cian/ui-kit';
import { UIText1 } from '@cian/ui-kit/typography';

export const AllRealtorLinkMobile = () => (
  <a href="/realtors/" style={{ textDecoration: 'none' }}>
    <span style={{ verticalAlign: 'middle' }}>
      <IconArrowLeft16 color="primary_100" />
    </span>
    <span> </span>
    <UIText1 color="primary_100">Все риелторы</UIText1>
  </a>
);
