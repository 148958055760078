import * as React from 'react';
import { mergeStyles } from '@cian/utils/lib/shared/style';

import { IOffer } from '../../../../api/offer';
import { isGoogleBot, isYandexBot } from '../../../../utils/user_agent';
import { AlarmSVG } from '../../../offer_card/description/icn-alarm';

export interface IPessimisationOwnProps {
  offer: IOffer;

  onComplainStateChanged(isComplainOpen: boolean): void;
}

export interface IPessimisationStoreProps {
  userAgent: string;
}

type IProps = IPessimisationOwnProps & IPessimisationStoreProps;

import * as styles from './index.css';

export class Pessimisation extends React.Component<IProps, {}> {
  public render() {
    let { isRecidivist, isExcludedFromAction } = this.props.offer;

    if (isGoogleBot(this.props.userAgent) || isYandexBot(this.props.userAgent)) {
      isRecidivist = false;
      isExcludedFromAction = false;
    }

    if (!isRecidivist && !isExcludedFromAction) {
      return null;
    }

    return (
      <div {...mergeStyles(styles['recidivist'], isExcludedFromAction && styles['excluded-from-action'])}>
        <div className={styles['svg-container']}>
          <AlarmSVG />
        </div>
        {isRecidivist ? this.renderHardPessimisation() : isExcludedFromAction ? this.renderLightPessimisation() : null}
      </div>
    );
  }

  private renderHardPessimisation = () => {
    const complain = (
      <span
        className={styles['complaint']}
        onClick={e => {
          e.stopPropagation();
          this.props.onComplainStateChanged(true);
        }}
      >
        оставьте жалобу
      </span>
    );

    return (
      <span className={styles['recidivist-description']} key="recidivistDescription">
        Этот агент замечен в размещении недостоверных предложений. Возможно, это объявление вымышленное. Служба качества
        ЦИАН не рекомендует звонить по этому объявлению! Если вы позвонили, но объекта нет, {complain}.{' '}
        <a
          className={styles['link']}
          href="#"
          onClick={e => {
            e.stopPropagation();

            if (typeof window !== 'undefined' && window.location) {
              window.open(`${window.location.host}/help/quality/37645/`, '_blank');
            }
          }}
        >
          Подробнее
        </a>
      </span>
    );
  };

  private renderLightPessimisation = () => {
    return (
      <span className={styles['recidivist-description']} key="excludedFromActionDescription">
        Информация от данного агента может быть неточной. Будьте внимательны!
      </span>
    );
  };
}
