/* eslint-disable react/forbid-elements */
import * as React from 'react';
import { mergeStyles } from '@cian/utils/lib/shared/style';

import { ControlsProps } from '../types';

import * as style from '../index.css';

export const Controls = React.memo(
  ({ onNextClick, onPrevClick, controlButtonsFullHeight, itemsTotal, sliderInitialized }: ControlsProps) => {
    if (!sliderInitialized || itemsTotal < 2) {
      return null;
    }

    return (
      <div {...mergeStyles(controlButtonsFullHeight && style['controls-full-height'])} data-name="Controls">
        <button
          data-testid="gallery-controls-prev"
          className={style['controls-prev']}
          onClick={e => {
            e.stopPropagation();
            if (onPrevClick) {
              onPrevClick();
            }
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.57 38" width="13" height="23">
            <path
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
              d="M18.54 2L2 18.86 18.57 36"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <button
          data-testid="gallery-controls-next"
          className={style['controls-next']}
          onClick={e => {
            e.stopPropagation();
            if (onNextClick) {
              onNextClick();
            }
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.57 38" width="13" height="23">
            <path
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
              d="M2.03 36l16.54-16.86L2 2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    );
  },
);

Controls.displayName = 'GalleryControls';
