import { getConfig } from '@cian/config/browser';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';

import { createStore, IAppState } from '../../../shared/store';

export function createAppState() {
  const config = getConfig();

  const defaultState = config.get<IAppState>('defaultState');

  const httpApi = getHttpApi();

  return createStore(
    {
      config,
      httpApi,
      logger: getLogger(),
    },
    defaultState as IAppState,
  );
}
