import * as React from 'react';
import * as PropTypes from 'prop-types';
import { IHttpApi } from '@cian/http-api/shared/http';
import { ILogger } from '@cian/logger/shared';
import { Spinner } from '@cian/ui-kit';
import { GenericError } from '@cian/peperrors/shared';

import { IOffer } from '../../../../../api/offer';
import { ReportPopupSubmitResult } from './ReportPopupSubmitResult';
import { IComplaintItem } from '../../../../../repositories/moderation-complaints/entities/Models/ComplaintItem';
import { ReportPopupForm } from './ReportPopupForm';
import { addComplaint, getComplaints, IAddComplaintParams } from '../../../../../api/complaints';
import { EComplaintState } from '../../../../../repositories/moderation-complaints/v2/create-complaint';

import * as styles from './index.css';

export interface IReportPopupProps {
  offer: IOffer;
  onClose: () => void;
}

export interface IReportPopupState {
  isLoading: boolean;
  isSubmitted: boolean;
  error?: string;
  complaints?: IComplaintItem[];
  selectionTree: number[];
  selected?: number;
  message?: string;
  messageError: boolean;
}

export interface IReportPopupContext {
  httpApi: IHttpApi;
  logger: ILogger;
}

export class ReportPopupClassComponent extends React.Component<IReportPopupProps, IReportPopupState> {
  public context: IReportPopupContext;

  public static contextTypes = {
    api: PropTypes.object,
    httpApi: PropTypes.object,
    logger: PropTypes.object,
    offer: PropTypes.object,
  };

  public constructor(props: IReportPopupProps) {
    super(props);

    this.state = {
      isLoading: false,
      isSubmitted: false,
      messageError: false,
      selectionTree: [],
    };
  }

  public componentDidMount() {
    const { httpApi, logger } = this.context;
    const { offer } = this.props;

    if (!offer.categoriesIds || offer.categoriesIds.length < 1) {
      const dataError = new GenericError({
        type: 'reportPopup',
        domain: 'reportPopup',
        message: `Failed to get complaints for offer ${offer.cianId || offer.id}: offer not categorized`,
      });
      logger.error(dataError);
      this.setState({
        error: 'Произошла внутренняя ошибка сервиса. Информация об ошибке была направлена нашим разработчикам.',
      });

      return;
    }

    this.setState({ isLoading: true });

    const categoryId = offer.categoriesIds[offer.categoriesIds.length - 1];

    getComplaints(httpApi, categoryId)
      .then(response => {
        const skipIds = ['89_1089'];
        let complaints = response.complaintTypes;
        if (!offer.isByHomeowner) {
          complaints = complaints.filter(item => item.id && skipIds.indexOf(item.id) < 0);
        }

        this.setState({ complaints, isLoading: false });
      })
      .catch(reason => {
        const dataError = new GenericError({
          type: 'reportPopup',
          domain: 'reportPopup',
          message: `Failed to get complaints for offer ${
            offer.cianId || offer.id
          }: offer not categorized. Reason: ${reason}`,
        });
        logger.error(dataError);
        this.setState({
          error:
            'Не удалось получить информацию о предложении. Проверьте своё интернет соединение и попробуйте еще раз.',
          isLoading: false,
        });
      });
  }

  public render() {
    const { isLoading, isSubmitted, error } = this.state;

    return (
      <div className={styles['report_container']}>
        {isLoading && (
          <div className={styles.preloader}>
            <Spinner size={48} />
          </div>
        )}
        {!!error && error}
        {isSubmitted && <ReportPopupSubmitResult />}
        {!isLoading && !error && !isSubmitted && this.renderForm()}
      </div>
    );
  }

  private renderForm() {
    const { complaints, selectionTree, selected, message, messageError } = this.state;

    if (!complaints) {
      return null;
    }

    const selectedComplaint = getSelectedComplaint(complaints, selectionTree);
    const selectedComplaints = (selectedComplaint && selectedComplaint.items) || complaints;

    return (
      <ReportPopupForm
        selectedComplaint={selectedComplaint}
        selectedComplaints={selectedComplaints}
        selectionTree={selectionTree}
        selected={selected}
        message={message}
        hasMessageError={messageError}
        onPrev={this.handlePrev}
        onNext={this.handleNext}
        onFinish={this.handleFinish}
        onDropdown={this.handleDropdown}
        onTextAreaValueChange={this.handleTextAreaValueChange}
        onSubmit={this.handleSubmit}
      />
    );
  }

  private handleTextAreaValueChange = (message: string) => {
    this.setState({ message, messageError: false });
  };

  private handlePrev = () => {
    this.setState({
      message: undefined,
      selected: undefined,
      selectionTree: this.state.selectionTree.slice(0, this.state.selectionTree.length - 1),
    });
  };

  private handleNext = (index: number) => {
    this.setState({
      message: undefined,
      selected: undefined,
      selectionTree: this.state.selectionTree.concat(index),
    });
  };

  private handleDropdown = (index: number) => {
    if (this.state.selected === index) {
      return;
    }

    this.setState({
      message: undefined,
      messageError: false,
      selected: index,
    });
  };

  private handleSubmit = (complaint: IComplaintItem) => {
    const { httpApi, logger } = this.context;
    const { offer } = this.props;
    const { message } = this.state;

    if (complaint.isCommentRequired && (!message || message.length < 1)) {
      this.setState({ messageError: true });

      return;
    }

    this.setState({ isLoading: true });

    const params: IAddComplaintParams = {
      realtyObjectId: offer.cianId || offer.id,
      complaintOnSiteId: complaint.id || null,
      message: message || null,
      complaintState: EComplaintState.New,
    };

    addComplaint(httpApi, params)
      .then(() => {
        this.setState({ isLoading: false, isSubmitted: true });
      })
      .catch((reason: string) => {
        const dataError = new GenericError({
          type: 'reportPopup',
          domain: 'reportPopup',
          message: `Failed to add complaint for offer ${offer.id}. Reason: ${reason}`,
        });
        logger.error(dataError);

        this.setState({
          error: 'Не удалось отправить жалобу. Проверьте своё интернет соединение и попробуйте еще раз.',
          isLoading: false,
        });
      });
  };

  private handleFinish = (complaint: IComplaintItem) => {
    const { httpApi, logger } = this.context;
    const { offer } = this.props;

    this.setState({ isLoading: true });

    const params: IAddComplaintParams = {
      realtyObjectId: offer.cianId || offer.id,
      complaintOnSiteId: complaint.id || null,
      message: null,
      complaintState: EComplaintState.New,
    };

    addComplaint(httpApi, params)
      .then(() => {
        this.setState({ isLoading: false, isSubmitted: true });
      })
      .catch((reason: string) => {
        const dataError = new GenericError({
          type: 'reportPopup',
          domain: 'reportPopup',
          message: `Failed to add complaint for offer ${offer.id}. Reason: ${reason}`,
        });
        logger.error(dataError);

        this.setState({
          error: 'Не удалось отправить жалобу. Проверьте своё интернет соединение и попробуйте еще раз.',
          isLoading: false,
        });
      });
  };
}

function getSelectedComplaint(complaints: IComplaintItem[], selectionTree: number[]): IComplaintItem | null {
  let selectedComplaint: IComplaintItem | null = null;
  selectionTree.forEach(value => {
    selectedComplaint =
      (selectedComplaint && selectedComplaint.items && selectedComplaint.items[value]) || complaints[value];
  });

  return selectedComplaint;
}

/**
 * Обертка для классового компонента Попапа Репорта.
 * Нужна для изоляции компонента в рамках рефакторинга родительских компонентов
 */
export const ReportPopup = React.forwardRef<HTMLDivElement, IReportPopupProps>((props, ref) => {
  return (
    <div ref={ref}>
      <ReportPopupClassComponent {...props} />
    </div>
  );
});

ReportPopup.displayName = 'ReportPopup';
