import { IAppState, IContext, IThunkActionCreator } from '.';
import { getAgentOffers, IOfferInfo } from '../api/offer';
import {
  FavoriteChangedActionType,
  FavoriteChangedErrorActionType,
  IFavoriteChangedAction,
  IFavoriteChangedErrorAction,
} from './favorite';
import { TThunkDispatch } from '../types/redux';

export interface IOffersRequested {
  type: 'IOffersRequested';
}

export interface IOffersRequestSucceed {
  type: 'IOffersRequestSucceed';
  offers: IOfferInfo[];
}

export interface IOffersRequestFailed {
  type: 'IOffersRequestFailed';
}

export function fetchOffers(userId: number, cookies: string = ''): IThunkActionCreator {
  return (dispatch: TThunkDispatch, getState: () => IAppState, context: IContext) => {
    dispatch<IOffersRequested>({
      type: 'IOffersRequested',
    });

    return getAgentOffers(
      context.httpApi,
      {
        userId,
      },
      cookies,
      context.logger,
    )
      .then(response => {
        return dispatch<IOffersRequestSucceed>({
          offers: response,
          type: 'IOffersRequestSucceed',
        });
      })
      .catch(() => {
        return dispatch<IOffersRequestFailed>({
          type: 'IOffersRequestFailed',
        });
      });
  };
}

const getChangedState = (
  state: IOfferInfo[],
  action: IFavoriteChangedAction | IFavoriteChangedErrorAction,
): IOfferInfo[] => {
  return [
    ...state.map(offerInfo => {
      return {
        ...offerInfo,
        offers: offerInfo.offers.map(offer => {
          return offer.cianId === action.offerId || offer.id === action.offerId
            ? Object.assign({}, offer, {
                isFavorite: !offer.isFavorite,
              })
            : offer;
        }),
      };
    }),
  ];
};

export type TOffersActions =
  | IOffersRequested
  | IOffersRequestSucceed
  | IOffersRequestFailed
  | IFavoriteChangedAction
  | IFavoriteChangedErrorAction;

const initialState: IOfferInfo[] = [];

export function offersReducer(state: IOfferInfo[] = initialState, action: TOffersActions): IOfferInfo[] {
  switch (action.type) {
    case 'IOffersRequested':
      return state;

    case 'IOffersRequestSucceed':
      return action.offers;

    case 'IOffersRequestFailed':
      return state;

    case FavoriteChangedActionType:
      return getChangedState(state, action);
    case FavoriteChangedErrorActionType:
      return getChangedState(state, action);

    default:
      return state;
  }
}
