import * as React from 'react';
import { mergeStyles } from '@cian/utils';

import { SliderState, SliderProps } from './types';

import * as style from './index.css';

export class SliderClassComponent extends React.Component<SliderProps, SliderState> {
  public constructor(props: SliderProps) {
    super(props);

    const slides = React.Children.toArray(props.children);

    this.state = {
      currentIndex: 0,
      currentOffset: 0,
      currentPos: 1,
      currentView: getDefaultView(slides.length, 0),
      slides,
      touched: false,
    };
  }

  public componentDidMount() {
    if (this.props.onSliderInitialized) {
      this.props.onSliderInitialized();
    }
  }

  public prev() {
    if (!this.state.touched) {
      this.setState(
        {
          touched: true,
        },
        this.prev.bind(this),
      );
    } else {
      const { slides, currentPos, currentOffset, currentView } = this.state;
      const nextIndex = currentView[currentPos - 1];
      const nextOffset = currentOffset - 1;
      let nextView;

      if (currentView.length < slides.length) {
        if (currentPos - 1 > 0) {
          nextView = currentView;
        } else {
          const firstSlide = currentView[0];
          nextView = [firstSlide > 0 ? firstSlide - 1 : slides.length - 1].concat(currentView);
        }
      } else if (slides.length < 3 && currentView.length < 4) {
        nextView = [currentView[1]].concat(currentView);
      } else {
        nextView = [currentView[currentView.length - 1]].concat(currentView.slice(0, currentView.length - 1));
      }

      this.setState(
        {
          currentIndex: nextIndex,
          currentOffset: nextOffset,
          currentPos: currentPos - 1 > 0 ? currentPos - 1 : 1,
          currentView: nextView,
        },
        () => {
          if (this.props.onSlideChange) {
            this.props.onSlideChange(nextIndex);
          }
        },
      );
    }
  }

  public next() {
    if (!this.state.touched) {
      this.setState(
        {
          touched: true,
        },
        this.next.bind(this),
      );
    } else {
      const { slides, currentPos, currentOffset, currentView } = this.state;
      const nextIndex = currentView[currentPos + 1];
      const nextOffset = currentOffset + 1;

      let nextView;

      if (currentView.length < slides.length) {
        if (currentPos + 1 < currentView.length - 1) {
          nextView = currentView;
        } else {
          const lastSlide = currentView[currentView.length - 1];
          nextView = currentView.concat(lastSlide < slides.length - 1 ? lastSlide + 1 : 0);
        }
      } else if (slides.length < 3 && currentView.length < 4) {
        nextView = currentView.concat(currentView[1]);
      } else {
        nextView = currentView.slice(1).concat(currentView[0]);
      }

      this.setState(
        {
          currentIndex: nextIndex,
          currentOffset: nextOffset,
          currentPos: currentPos + 1 < nextView.length - 1 ? currentPos + 1 : nextView.length - 2,
          currentView: nextView,
        },
        () => {
          if (this.props.onSlideChange) {
            this.props.onSlideChange(nextIndex);
          }
        },
      );
    }
  }

  public render() {
    return <div className={style['container']}>{this.renderSlider()}</div>;
  }

  private renderSlider() {
    const { slides } = this.state;

    if (slides.length === 0) {
      return null;
    }

    const sliderStyles: React.CSSProperties = {};
    if (this.state.touched) {
      const { currentPos } = this.state;
      sliderStyles.transform = `translateX(${-(currentPos + this.getOffset()) * 100}%)`;
    }

    return (
      <div
        {...mergeStyles(style['slides'], this.state.currentOffset === 0 && style['slides--no-transform'])}
        style={sliderStyles}
        onTransitionEnd={this.handleSlidesTransitionEnd}
        data-testid="gallery-slider"
      >
        {this.renderSlides()}
      </div>
    );
  }

  private renderSlides() {
    const { touched, slides, currentIndex, currentView } = this.state;

    if (slides.length === 0) {
      return null;
    }

    if (!touched) {
      return (
        <div className={style['slide']} onClick={() => this.onSlideClick()}>
          {slides[currentIndex]}
        </div>
      );
    }

    return currentView.map((slide, index) => {
      return (
        <div
          key={slides.length >= 3 ? `slide_${slide}` : `slide_${index}_${slide}`}
          className={style['slide']}
          style={{ left: `${(index + this.getOffset()) * 100}%` }}
          onClick={() => this.next()}
        >
          {slides[slide]}
        </div>
      );
    });
  }

  private onSlideClick = () => {
    if (this.state.slides.length > 1) {
      this.next();
    }
  };

  private getOffset = () => {
    const { slides, currentOffset } = this.state;

    if (currentOffset > 0) {
      if (currentOffset > slides.length - 1) {
        return currentOffset - (slides.length - 1);
      } else {
        return 0;
      }
    }

    return currentOffset;
  };

  private handleSlidesTransitionEnd = () => {
    const { currentIndex, slides } = this.state;

    this.setState({
      currentOffset: 0,
      currentPos: 1,
      currentView: getDefaultView(slides.length, currentIndex),
    });
  };
}

function getDefaultView(total: number, current: number) {
  return [current > 0 ? current - 1 : total - 1, current, current < total - 1 ? current + 1 : 0];
}

/**
 * Обертка для классового компонента Слайдера.
 * Нужна для изоляции компонента в рамках рефакторинга родительских компонентов
 */
export const Slider = React.forwardRef<SliderClassComponent, SliderProps>((props, ref) => {
  return <SliderClassComponent {...props} ref={ref} />;
});
