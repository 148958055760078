// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IPutReviewRequest,
  TPutReviewModel,
  IMappers,
  TPutReviewResponse,
  IPutReviewResponse,
  IPutReviewResponseError,
} from './types';

export const defaultConfig: TPutReviewModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'reviews',
  pathApi: '/v3/put-review/',
  requestType: 'json',
} as TPutReviewModel;

export function createPutReviewModel(parameters: IPutReviewRequest): TPutReviewModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IPutReviewOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IPutReviewRequest;
}

export async function fetchPutReview<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IPutReviewOptions<TResponse200, TResponse400>): Promise<TPutReviewResponse | TResponse200 | TResponse400> {
  const { statusCode, response, headers } = await httpApi.fetch(createPutReviewModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IPutReviewResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IPutReviewResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TPutReviewResponse;
}
