import * as React from 'react';

import { IUserLevelInfo } from '../../types/agentCard';
import { Approval } from '../Approval';
import { WORK_HONESTLY_MESSAGES } from '../../constants';

import * as styles from './index.css';

export interface IUserWorkHonestlyAsideInfoProps {
  isProAgent?: boolean;
  userLevelInfo?: IUserLevelInfo | null;
}

export const UserWorkHonestlyAsideInfo = ({ isProAgent, userLevelInfo }: IUserWorkHonestlyAsideInfoProps) => {
  if (!userLevelInfo || !userLevelInfo.levelOrder || !userLevelInfo.levelName) {
    return null;
  }

  const { hasVerifiedDocuments } = userLevelInfo;

  return (
    <div className={styles['container']}>
      <div className={styles['honest-title-container']}>
        {isProAgent && (
          <span className={styles['tag-pro']} title="Профессиональный пользователь">
            Pro
          </span>
        )}
      </div>
      {hasVerifiedDocuments && <Approval message={WORK_HONESTLY_MESSAGES.agent} />}
    </div>
  );
};
