import * as React from 'react';

import { CounterProps } from '../types';

import * as style from '../index.css';

export const Counter = React.memo(({ itemsTotal, sliderInitialized, currentIndex }: CounterProps) => {
  if (!sliderInitialized || itemsTotal < 1) {
    return null;
  }

  return (
    <div data-testid="gallery-counter" className={style['controls-counter']}>{`${
      currentIndex + 1
    } из ${itemsTotal}`}</div>
  );
});

Counter.displayName = 'GalleryCounter';
