import { useCallback, useEffect, useState } from 'react';

export enum EStatus {
  Idle = 'idle',
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
}

/**
 * Дженерик хук для работы с промисами
 * Взят из https://usehooks.com/useAsync/
 */
export const useAsync = <T = unknown, E = string>(
  asyncFunction: () => Promise<T>,
  immediate = false,
): [options: { status: EStatus; result: T | null; error: E | null }, execute: () => Promise<void>] => {
  const [status, setStatus] = useState<EStatus>(EStatus.Idle);
  const [result, setResult] = useState<T | null>(null);
  const [error, setError] = useState<E | null>(null);

  const execute = useCallback(async () => {
    setStatus(EStatus.Pending);
    setResult(null);
    setError(null);

    try {
      const res = await asyncFunction();
      setResult(res);
      setStatus(EStatus.Success);
    } catch (error) {
      setError(error);
      setStatus(EStatus.Error);
    }
  }, [asyncFunction]);

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return [{ status, result, error }, execute];
};
