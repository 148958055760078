import { ca } from '@cian/analytics';

export const trackCanDoOnlineDealLabelShow = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Specialist',
    action: 'Show_sopr',
    label: 'Label_deal',
  });
};
