import * as React from 'react';
import { mergeStyles } from '@cian/utils';
import { FormField } from '@cian/ui-kit';

import { IPersonalLeadFormProps } from '../../../index';
import { IPersonalLeadFormData } from '../../../../../store/personal_leads_form';

import * as styles from '../styles.css';

export interface ICommentProps {
  fieldData: IPersonalLeadFormData['comment'];
  onChange: IPersonalLeadFormProps['handleSetComment'];
  onBlur: () => void;
}

export const Comment = ({ fieldData, onChange, onBlur }: ICommentProps) => {
  const [focused, setFocused] = React.useState(false);

  const handleFocus = React.useCallback(() => {
    if (!focused) {
      setFocused(true);
    }
  }, [focused]);

  const handleBlur = React.useCallback(() => {
    if (focused) {
      setFocused(false);
    }
    onBlur();
  }, [focused, onBlur]);

  return (
    <FormField withoutMargin label="Комментарий (необязательно)">
      <div {...mergeStyles(styles['textarea-wrapper'], focused && styles['focused'])}>
        <textarea
          data-testid="textarea-comment"
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder="Например, ищу дом в коттеджном посёлке по направлению Новой Риги"
          className={styles['textarea']}
          onChange={onChange}
          value={fieldData.value}
          rows={3}
        />
      </div>
    </FormField>
  );
};
