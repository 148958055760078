/* eslint-disable max-lines */
import { Action, Dispatch } from 'redux';

import { IThunkActionCreator, IAppState, IContext } from '.';
import { putReview } from '../api/reviews';
import { getDescription } from '../utils/antiObscene';
import { IAnswerFormOpened } from './answer_form';
import { IReviewsTotalCountIncrement, reviewsTotalCountIncrement } from './reviews';
import { IReview } from '../types/review';
import { TThunkDispatch } from '../types/redux';

export enum EReviewFormState {
  Initial,
  Opened,
  Submitted,
  Succeed,
  Failed,
}

export interface IReviewFormState {
  state: EReviewFormState;
  lastOpen?: Date;
  edit?: boolean;
  reviewId?: string;
  rating?: number;
  text?: string;
  review?: IReview;
  textErrorMarked?: string;
  errorMessage?: string;
}

export interface IReviewFormSubmitError {
  key: string;
  code: string;
  message: string;
}

export type TReviewFormOpened = 'IReviewFormOpened';
export type TReviewFormRatingChanged = 'IReviewFormRatingChanged';
export type TReviewFormTextChanged = 'IReviewFormTextChanged';

export interface IReviewFormOpened extends Action<TReviewFormOpened> {
  type: 'IReviewFormOpened';
  edit: boolean;
  reviewId?: string;
  rating?: number;
  text?: string;
}

export function open(): IThunkActionCreator {
  return (dispatch: Dispatch<IReviewFormOpened>) => {
    dispatch<IReviewFormOpened>({
      edit: false,
      type: 'IReviewFormOpened',
    });
  };
}

export function edit(reviewId: string, text: string, rating: number): IThunkActionCreator {
  return (dispatch: TThunkDispatch) => {
    dispatch<IReviewFormOpened>({
      edit: true,
      rating,
      reviewId,
      text,
      type: 'IReviewFormOpened',
    });
  };
}

export interface IReviewFormRatingChanged extends Action<TReviewFormRatingChanged> {
  type: 'IReviewFormRatingChanged';
  rating: number;
}

export function changeRating(rating: number): IThunkActionCreator {
  return (dispatch: TThunkDispatch) => {
    dispatch<IReviewFormRatingChanged>({
      rating,
      type: 'IReviewFormRatingChanged',
    });
  };
}

export interface IReviewFormTextChanged extends Action<TReviewFormTextChanged> {
  type: 'IReviewFormTextChanged';
  text: string;
}

export function changeText(text: string): IThunkActionCreator {
  return (dispatch: TThunkDispatch) => {
    dispatch<IReviewFormTextChanged>({
      text,
      type: 'IReviewFormTextChanged',
    });
  };
}

export interface IReviewFormClickSubmitButton {
  type: 'IReviewFormClickSubmitButton';
}

export function clickSubmitButton(): IThunkActionCreator {
  return (dispatch: TThunkDispatch) => {
    dispatch<IReviewFormClickSubmitButton>({
      type: 'IReviewFormClickSubmitButton',
    });
  };
}

export interface IReviewFormSubmitted {
  type: 'IReviewFormSubmitted';
}

export interface IReviewFormSubmitSucceed {
  type: 'IReviewFormSubmitSucceed';
  review: IReview;
  edit: boolean;
  reviewId?: string;
}

export interface IReviewFormSubmitFailed {
  type: 'IReviewFormSubmitFailed';
  errors?: object[];
  message?: string;
}

export function submit(): IThunkActionCreator {
  return (dispatch: TThunkDispatch, getState: () => IAppState, context: IContext) => {
    // tslint:disable-next-line:no-shadowed-variable
    const {
      realtor,
      reviewForm: { edit, reviewId, rating, text },
    } = getState();

    dispatch<IReviewFormSubmitted>({
      type: 'IReviewFormSubmitted',
    });

    const putReviewOptions = {
      realtyUserId: realtor.realtyUserId,
      rate: rating as number,
      reviewId: edit ? reviewId : undefined,
      text: (text as string).trim(),
    };

    return putReview(context.httpApi, putReviewOptions)
      .then(response => {
        if (!edit) {
          dispatch<IReviewsTotalCountIncrement>(reviewsTotalCountIncrement());
        }

        if ('errors' in response) {
          return dispatch({
            errors: response.errors,
            message: response.message,
            type: 'IReviewFormSubmitFailed',
          });
        }

        return dispatch<IReviewFormSubmitSucceed>({
          edit: edit as boolean,
          review: response as IReview,
          reviewId,
          type: 'IReviewFormSubmitSucceed',
        });
      })
      .catch(() => {
        return dispatch<IReviewFormSubmitFailed>({
          type: 'IReviewFormSubmitFailed',
        });
      });
  };
}

export interface IReviewFormReseted {
  type: 'IReviewFormReseted';
}

export function reset(): IThunkActionCreator {
  return (dispatch: Dispatch<IReviewFormReseted>) => {
    dispatch<IReviewFormReseted>({
      type: 'IReviewFormReseted',
    });
  };
}

export type TReviewFormActions =
  | IReviewFormOpened
  | IReviewFormRatingChanged
  | IReviewFormTextChanged
  | IReviewFormSubmitted
  | IReviewFormClickSubmitButton
  | IReviewFormSubmitSucceed
  | IReviewFormSubmitFailed
  | IReviewFormReseted
  | IAnswerFormOpened;

const initialState: IReviewFormState = {
  state: EReviewFormState.Initial,
};

export function reviewFormReducer(
  state: IReviewFormState = initialState,
  action: TReviewFormActions,
): IReviewFormState {
  switch (action.type) {
    case 'IReviewFormOpened':
      if (!action.edit) {
        return {
          ...initialState,
          edit: false,
          errorMessage: undefined,
          lastOpen: new Date(),
          state: EReviewFormState.Opened,
          textErrorMarked: undefined,
        };
      }

      return {
        ...initialState,
        edit: true,
        errorMessage: undefined,
        lastOpen: new Date(),
        rating: action.rating,
        reviewId: action.reviewId,
        state: EReviewFormState.Opened,
        text: action.text,
        textErrorMarked: undefined,
      };

    case 'IReviewFormRatingChanged':
      return {
        ...state,
        rating: action.rating,
      };

    case 'IReviewFormTextChanged':
      return {
        ...state,
        text: action.text,
      };

    case 'IReviewFormSubmitted':
      return {
        ...state,
        errorMessage: undefined,
        state: EReviewFormState.Submitted,
        textErrorMarked: undefined,
      };

    case 'IReviewFormSubmitSucceed':
      return {
        ...state,
        errorMessage: undefined,
        review: action.review,
        state: EReviewFormState.Succeed,
        textErrorMarked: undefined,
      };

    case 'IReviewFormSubmitFailed':
      return {
        ...state,
        errorMessage: action.message,
        state: EReviewFormState.Failed,
        textErrorMarked: getDescription(state.text, action.errors),
      };

    case 'IReviewFormClickSubmitButton':
      return {
        ...state,
        errorMessage: undefined,
        state: EReviewFormState.Opened,
        textErrorMarked: undefined,
      };

    case 'IAnswerFormOpened':
    case 'IReviewFormReseted':
      return initialState;

    default:
      return state;
  }
}
