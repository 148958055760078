/* eslint-disable react/forbid-elements */
import * as React from 'react';
import { Outside, DropdownPopover } from '@cian/ui-kit';
import { IStyleProps, mergeStyles } from '@cian/utils';

import { IOffer, TDealType, TOfferType } from '../../../../api/offer';
import { MoreButtonContent, MoreButton } from './components';

import * as style from './index.css';

export interface IMoreButtonContainerProps {
  offer: IOffer;
  isActive: boolean;
  containerStyle?: IStyleProps;

  isReporting: boolean;
  onComplainStateChanged(isReporting: boolean): void;

  realtyUserId?: number;
  userCanModerateAnnouncements: boolean;
  userCanModerateUsers: boolean;
  userCanViewUsers: boolean;

  toggleActivity(value: boolean): void;

  onMenuOpened?(): void;
  onCommentClicked(): void;
  onPDFLinkClicked?(): void;
  onDocLinkClicked?(): void;
  onHideFromPrintClicked?(): void;
}

class MoreButtonContainer extends React.Component<IMoreButtonContainerProps> {
  public render() {
    const {
      offer,
      isActive,
      containerStyle,
      isReporting,
      realtyUserId,
      userCanModerateAnnouncements,
      userCanModerateUsers,
      userCanViewUsers,
      onDocLinkClicked,
      onPDFLinkClicked,
    } = this.props;

    return (
      <Outside onOutside={this.close}>
        <div {...mergeStyles(style['container'], containerStyle)}>
          <DropdownPopover
            open={isActive}
            flip={false}
            sameWidth="minWidth"
            content={
              <MoreButtonContent
                offer={offer}
                realtyUserId={realtyUserId}
                userCanModerateAnnouncements={userCanModerateAnnouncements}
                userCanModerateUsers={userCanModerateUsers}
                userCanViewUsers={userCanViewUsers}
                isReporting={isReporting}
                onDocLinkClicked={onDocLinkClicked}
                onPDFLinkClicked={onPDFLinkClicked}
                onReportClicked={this.handleReportClicked}
                onReportClosed={this.onReportClose}
              />
            }
          >
            <MoreButton isActive={isActive} onToggle={this.toggle} />
          </DropdownPopover>
        </div>
      </Outside>
    );
  }

  private handleReportClicked = () => {
    this.props.onComplainStateChanged(true);
  };

  private onReportClose = () => {
    this.props.onComplainStateChanged(false);
  };

  private toggle = () => {
    const { isActive, toggleActivity } = this.props;

    this.props.onComplainStateChanged(false);
    toggleActivity(!isActive);
  };

  private close = () => {
    if (!this.props.isActive || this.props.isReporting) {
      return;
    }

    this.props.onComplainStateChanged(false);
    this.props.toggleActivity(false);
  };
}

export function getCianType(dealType: TDealType, offerType: TOfferType) {
  const map: {
    [key in TDealType]: {
      [keyInner in TOfferType]: number;
    };
  } = {
    daily: {
      commercial: 3,
      flat: 0,
      suburban: 2,
    },
    rent: {
      commercial: 3,
      flat: 0,
      suburban: 2,
    },
    sale: {
      commercial: 3,
      flat: 1,
      suburban: 2,
    },
  };

  return map[dealType][offerType];
}

export { MoreButtonContainer as MoreButton };
