import { numberToPrettyString } from '@cian/utils';

import { IOffer, TOfferCategory } from '../../api/offer';
import { CommercialClassName } from '../../types/commercial';

const buildingTypes: { [key: string]: string } = {
  administrativeBuilding: 'Административное здание',
  businessCenter: 'Бизнес-центр',
  businessCenter2: 'Деловой центр',
  businessPark: 'Бизнес-парк',
  businessQuarter: 'Бизнес-квартал',
  free: 'Свободное',
  freeAppointmentObject: 'Объект свободного назначения',
  industrialComplex: 'Производственный комплекс',
  industrialPark: 'Индустриальный парк',
  industrialSite: 'Промплощадка',
  industrialWarehouseComplex: 'Производственно-складской комплекс',
  logisticsCenter: 'Логистический центр',
  logisticsComplex: 'Логистический комплекс',
  mansion: 'Особняк',
  manufactureBuilding: 'Производственное здание',
  manufacturingFacility: 'Производственный цех',
  modular: 'Модульное здание',
  multifunctionalComplex: 'Многофункциональный комплекс',
  officeAndHotelComplex: 'Офисно-гостиничный комплекс',
  officeAndResidentialComplex: 'Офисно-жилой комплекс',
  officeAndWarehouse: 'Офисно-складское',
  officeAndWarehouseComplex: 'Офисно-складской комплекс',
  officeBuilding: 'Офисное здание',
  officeIndustrialComplex: 'Офисно-производственный комплекс',
  old: 'Старый фонд',
  outlet: 'Аутлет',
  propertyComplex: 'Имущественный комплекс',
  residentialComplex: 'Жилой комплекс',
  residentialFund: 'Жилой фонд',
  residentialHouse: 'Жилой дом',
  shoppingAndBusinessComplex: 'Торгово-деловой комплекс',
  shoppingAndCommunityCenter: 'Торгово-общественный центр',
  shoppingAndEntertainmentCenter: 'Торгово-развлекательный центр',
  shoppingCenter: 'Торговый центр',
  specializedShoppingCenter: 'Специализированный торговый центр',
  standaloneBuilding: 'Отдельно стоящее здание',
  technopark: 'Технопарк',
  tradingOfficeComplex: 'Торгово-офисный комплекс',
  uninhabitedFund: 'Нежилой фонд',
  warehouse: 'Склад',
  warehouseComplex: 'Складской комплекс',
};

export function commercialHelper(offer: IOffer) {
  return {
    getBuildingClassType: () => getBuildingClassType(offer),
    getBuildingType: () => getBuildingType(offer),
    getCommercialLandArea: () => getCommercialLandArea(offer),
    getCommercialLandPerMeterByYear: () => getCommercialLandPerMeterByYear(offer),
    getCommercialLandUnit: () => getCommercialLandUnit(offer),
    getCommercialLandUnitType: () => getCommercialLandUnitType(offer),
    getMinArea: () => getMinArea(offer),
    getSpecialty: () => getSpecialty(offer),
    getType: () => getType(offer),
    getVatTypeWithVatPrice: () => getVatTypeWithVatPrice(offer),
    isCommercialLand: () => isCommercialLand(offer),
  };
}

function getType(offer: IOffer) {
  return findTypeById(
    [
      createSearchType('office', () => 'Офис'),
      createSearchType('shoppingArea', () => 'Торговая площадь'),
      createSearchType('warehouse', () => 'Склад'),
      createSearchType('freeAppointmentObject', () => 'Своб. назнач.'),
      createSearchType('industry', () => 'Помещение под производство'),
      createSearchType('publicCatering', () => 'Общепит'),
      createSearchType('manufacture', () => 'Производство'),
      createSearchType('autoService', () => 'Автосервис'),
      createSearchType('business', () => 'Готовый бизнес'),
      createSearchType('building', () => 'Здание'),
      createSearchType('domesticServices', () => 'Бытовые услуги'),
      createSearchType('commercialLand', () => 'Коммерческая земля'),
      createSearchType('garage', () => {
        if (!offer.garage) {
          return 'Гараж';
        }

        switch (offer.garage.type) {
          case 'box':
            return 'Бокс';
          case 'garage':
            return 'Гараж';
          case 'parkingPlace':
            return 'Машиноместо';
          default:
            return '';
        }
      }),
    ],
    offer,
  );
}

interface IOfferSearchType {
  name: string;
  getRightName(): string;
}

function createSearchType(name: string, getRightName: () => string): IOfferSearchType {
  return {
    getRightName,
    name,
  };
}

function findTypeById(values: IOfferSearchType[], offer: IOffer) {
  return values.reduce((acc, item) => {
    if (offer.category === item.name + 'Rent') {
      return item.getRightName();
    } else if (offer.category === item.name + 'Sale') {
      return item.getRightName();
    }

    return acc;
  }, '');
}

function getSpecialty(offer: IOffer) {
  if (offer.specialty && offer.specialty.specialties) {
    return offer.specialty.specialties.reduce((acc: string[], value) => {
      if (value.rusName && acc.length < 2) {
        acc.push(value.rusName.toLowerCase());
      }

      return acc;
    }, []);
  }

  return [];
}

function getBuildingType(offer: IOffer) {
  if (offer.building && offer.building.type) {
    const type = buildingTypes[offer.building.type];

    return type ? type.toLowerCase() : '';
  }

  return '';
}

function getBuildingClassType(offer: IOffer) {
  if (offer.businessShoppingCenter && offer.businessShoppingCenter.buildingClassType) {
    return CommercialClassName.get(offer.businessShoppingCenter.buildingClassType);
  }

  return undefined;
}

function getMinArea(offer: IOffer): string | undefined {
  return offer.minArea ? offer.minArea.split('.')[0] : undefined;
}

function isCommercialLand(offer: IOffer) {
  return offer.category && (['commercialLandRent', 'commercialLandSale'] as TOfferCategory[]).includes(offer.category);
}

function getCommercialLandUnit(offer: IOffer) {
  if (!offer.land || !offer.land.areaUnitType) {
    return null;
  }

  return {
    text: offer.land.areaUnitType === 'sotka' ? 'сот.' : 'га.',
    type: offer.land.areaUnitType,
  };
}

function getCommercialLandPerMeterByYear(offer: IOffer) {
  const unit = getCommercialLandUnit(offer);

  return `${numberToPrettyString(offer.pricePerUnitAreaPerYearRur)} руб. за ${unit ? unit.text : 'м\u00b2'} в год`;
}

function getCommercialLandArea(offer: IOffer) {
  return offer.land && offer.land.area ? offer.land.area : null;
}

function getCommercialLandUnitType(offer: IOffer) {
  return offer.land && offer.land.areaUnitType ? offer.land.areaUnitType : null;
}

function getVatPrice(to?: number | null) {
  if (to) {
    return `${to} руб.`;
  }

  return '';
}

function getVatTypeWithVatPrice(offer: IOffer) {
  const { bargainTerms, vatPriceTotalRur, vatPriceTotalPerMonthRur } = offer;
  const { vatType } = bargainTerms;

  switch (vatType) {
    case 'usn':
      return 'УСН';
    case 'included':
      if (offer.dealType === 'sale') {
        return `Включая НДС ${getVatPrice(vatPriceTotalRur)}`;
      } else if (offer.dealType === 'rent') {
        return `Включая НДС ${getVatPrice(vatPriceTotalPerMonthRur)}`;
      } else {
        return 'Включая НДС';
      }
    default:
      return undefined;
  }
}
