import * as React from 'react';
import { mergeStyles } from '@cian/utils';

import { ResponseRateType } from '../../statistics';

import * as styles from './AboutRealtorMobile.css';

interface IAboutRealtorMobileProps {
  responseRateType: ResponseRateType | null;
  headerBar: JSX.Element;
  generalInfo: JSX.Element;
  chatStatistics: JSX.Element;
  photo: JSX.Element;
  rate: JSX.Element;
  additionalInfo: JSX.Element;
  badge: React.ReactNode;
  className?: string;
}

export const AboutRealtorMobile: React.FunctionComponent<IAboutRealtorMobileProps> = ({
  responseRateType,
  headerBar,
  generalInfo,
  chatStatistics,
  photo,
  rate,
  additionalInfo,
  badge,
  className,
}) => {
  return (
    <div
      {...mergeStyles(
        styles['container'],
        responseRateType === 'high' ? styles['container_high_rate'] : '',
        responseRateType === 'low' ? styles['container_low_rate'] : '',
        className,
      )}
    >
      <div className={styles['header_bar']}>{headerBar}</div>
      <div className={styles['columns']}>
        <div className={styles['left_column']}>
          <div className={styles['general_info']}>{generalInfo}</div>
          {badge}
          {chatStatistics}
        </div>
        <div className={styles['right_column']}>
          {photo}
          <div className={styles['rate']}>{rate}</div>
        </div>
      </div>
      {additionalInfo}
    </div>
  );
};
