import { ca } from '@cian/analytics';

export const trackClickSendRequests = (realtorId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Specialist',
    action: 'Click',
    label: 'SendRequests',
    page: {
      pageType: 'CardAgent',
      siteType: 'mobile',
    },
    products: [
      {
        id: realtorId,
        offerType: 'agent',
      },
    ],
  });
};

export const trackOpenLeadFormModalMobile = (realtorId: number) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'page',
    action: 'newpage',
    label: 'open',
    page: {
      pageType: 'Personal_request_from',
      siteType: 'mobile',
    },
    products: [
      {
        id: realtorId,
        offerType: 'agent',
      },
    ],
  });
};
