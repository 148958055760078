import { mergeStyles } from '@cian/utils/lib';
import * as React from 'react';

import { IOffer } from '../../../../api/offer';
import { PhotoContainer } from '../../../../components/offer_card/photo';
import { trackPhotoSlideChanged } from '../../../../tracking/offer';
import { isArrayWithItems } from '../../../../utils/is';
import { HasVideoBadge } from '../../../offer_card/has_video_badge/index';
import { PromoInfo } from '../../../offer_card/promo_info';

import * as styles from './index.css';

export interface IPhotosProps {
  offer: IOffer;
  galleryControlsHidden: boolean;
  photoContainerStyle?: string;
}

export interface IPhotosState {
  isPhotoSlideChanged: boolean;
}

export class Photos extends React.Component<IPhotosProps, IPhotosState> {
  public constructor(props: IPhotosProps) {
    super(props);

    this.state = {
      isPhotoSlideChanged: false,
    };
  }

  public render() {
    const { offer, galleryControlsHidden, photoContainerStyle } = this.props;

    return (
      <div {...mergeStyles(styles['container'], photoContainerStyle)}>
        {offer.promoInfo && (
          <PromoInfo
            containerStyle={styles['promo-container']}
            isSpecProject={offer.promoInfo.specProject}
            name={offer.promoInfo.name}
            href={offer.fullUrl}
          />
        )}
        {isArrayWithItems(offer.videos) && <HasVideoBadge />}
        <PhotoContainer
          containerStyle={styles['photos']}
          controlButtonsFullHeight={true}
          photos={offer.photos}
          galleryControlsHidden={galleryControlsHidden}
          onSlideChanged={this.handlePhotoSlideChangedOnce}
        />
      </div>
    );
  }

  private handlePhotoSlideChangedOnce = () => {
    const { isPhotoSlideChanged } = this.state;

    if (!isPhotoSlideChanged) {
      trackPhotoSlideChanged();
      this.setState({
        isPhotoSlideChanged: true,
      });
    }
  };
}
