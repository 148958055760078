/* eslint-disable max-lines */
import { IHttpApi } from '@cian/http-api/shared/http';
import { IModelResponse } from '@cian/http-api/shared/model';
import { ILogger } from '@cian/logger/shared';

import { fetchGetAgentOffers, IGetAgentOffersRequest } from '../repositories/monolith-python/v1/get-agent-offers';
// tslint:disable:max-file-line-count
export type TBookingStatus = 'inReserve' | 'free';

export type TGetAgentOffersResponse = IGetAgentOffersResponse200 | IGetAgentOffersResponse400;

// Тело ответа в случае успешного запроса
export interface IGetAgentOffersSuccessResponseSchema {
  status: 'ok';
  data: IGetAgentOffersSuccessResponseDataSchema;
}

// Тело ответа в случае ошибки
export interface IGetAgentOffersErrorResponseSchema {
  status: 'badRequest';
  data: IGetAgentOffersErrorResponseDataSchema;
}

// Модель ответа в случае успешного запроса
export interface IGetAgentOffersResponse200 extends IModelResponse<IGetAgentOffersSuccessResponseSchema> {
  statusCode: 200;
}

// Модель ответа в случае ошибки
export interface IGetAgentOffersResponse400 extends IModelResponse<IGetAgentOffersErrorResponseSchema> {
  statusCode: 400;
}

// Данные ответа в случае успешного запроса
export interface IGetAgentOffersSuccessResponseDataSchema {
  offers: IOfferInfo[];
}

// Данные ответа в случае ошибки
export interface IGetAgentOffersErrorResponseDataSchema {
  errors: string[];
  message: string;
}

export interface IOfferInfo {
  count: string;
  link: string;
  name: string;
  offers: IOffer[];
}

export interface IOfferAnalyticsInfo {
  id?: number;
  offerType?: string;
  position: number;
}

export interface IOfferPhoto {
  id: number;
  fullUrl: string;
  thumbnailUrl: string;
  thumbnail2Url: string;
  rotateDegree: number;
  isDefault: boolean;
  miniUrl: string;
}

export interface IOfferVideo {
  duration: number | null;
  type: string | null;
  uploadDate: string | null;
  url: string | null;
}

export type TCommercialClass = 'aPlus' | 'a' | 'bPlus' | 'b' | 'bMinus' | 'cPlus' | 'c' | 'd';

export type TLeaseTerm = 'longTerm' | 'fewMonths' | null;
export const LeaseTerm = {
  FewMonths: 'fewMonths' as const,
  Long: 'longTerm' as const,
};

export type TLeaseType = 'direct' | 'sublease' | 'jointVenture';
export type TAccessType = 'free' | 'passSystem' | undefined;
export type TOfferDetailLayout = 'cabinet' | 'mixed' | 'openSpace';

export const ERegions = {
  moscow: 1 as number,
  moscowArea: 4593 as number,
  spb: 2 as number,
  spbArea: 4588 as number,
};

export const PrimaryMetroRegions = [ERegions.moscow, ERegions.moscowArea, ERegions.spb, ERegions.spbArea];

export type TBargainTermsSaleType = 'alternative' | 'dupt' | 'dzhsk' | 'free' | 'fz214' | 'investment' | 'pdkp';

export const BargainTermsSaleTypePresenter: {
  [index: string]: string;
} = {
  alternative: 'альтернатива',
  dupt: 'переуступка',
  dzhsk: 'договор ЖСК',
  free: 'свободная',
  fz214: '214-ФЗ',
  investment: 'договор инвестирования',
  pdkp: 'предварительный договор',
};

export interface IBuildingMaterialTypePresenter {
  [index: string]: string;
}

export const BuildingMaterialTypePresenter: IBuildingMaterialTypePresenter = {
  block: 'блочный',
  brick: 'кирпичный',
  monolith: 'монолитный',
  monolithBrick: 'кирпично-монолитный',
  panel: 'панельный дом',
  stalin: 'сталинский',
  wood: 'деревянный',
};

export type TBargainTermsPriceType = 'all' | 'hectare' | 'sotka' | 'squareMeter';

export interface IUtilitiesTerms {
  includedInPrice: boolean;
  price: number;
}

export interface IAgentBonus {
  currency: string | null;
  paymentType: 'percent' | 'fixed';
  value: number;
}

export type TVatType = 'included' | 'notIncluded' | 'usn';

export interface IBargainTerms {
  agentBonus: IAgentBonus | null;
  agentFee: number | null;
  bargainAllowed: boolean | null;
  clientFee: number | null;
  currency: 'eur' | 'rur' | 'usd';
  deposit: number | null;
  leaseTermType: TLeaseTerm;
  leaseType: TLeaseType;
  mortgageAllowed?: boolean;
  paymentPeriod: 'monthly' | 'daily';
  priceRur: number;
  priceType?: TBargainTermsPriceType;
  saleType?: TBargainTermsSaleType;
  utilitiesTerms: IUtilitiesTerms | null;
  vatType: TVatType | null;
  vatPrice: number | null;
}

export interface IAddress {
  id: number;
  name: string;
  fullName: string;
  shortName: string;
  type: 'location' | 'street' | 'house' | 'metro';
  isFormingAddress: boolean;
  locationTypeId: ELocationTypeId | null;
  qs: string;
}

export enum ELocationTypeId {
  City = 1,
}

export interface IUnderground {
  cianId: number | null;
  id: number;
  isDefault: boolean;
  lineColor: string;
  lineId: number;
  name: string;
  qs: string;
  time: number;
  transportType: 'walk' | 'transport';
}

export interface IDistrictInfo {
  id?: number;
  locationId?: number;
  name?: string;
  parentId?: number;
}

export type GarageGarageType =
  // Встроенный
  | 'builtIn'
  // Капитальный
  | 'capital'
  // Самострой
  | 'samostroy'
  // Ракушка
  | 'shell';

export type GarageMaterial =
  // Кирпичный
  | 'brick'
  // Металлический
  | 'metal';

export type GarageStatus =
  // По доверенности
  | 'byProxy'
  // Кооператив
  | 'cooperative'
  // Собственность
  | 'ownership';

export type GarageType =
  // Бокс
  | 'box'
  // Гараж
  | 'garage'
  // Машиноместо
  | 'parkingPlace';

export interface IGarage {
  // Тип гаража
  garageType?: GarageGarageType;
  // Тип бокса
  material?: GarageMaterial;
  // Статус
  status?: GarageStatus;
  // Тип
  type?: GarageType;
}

export interface IHighwayInfo {
  distance?: string | number;
  id?: number;
  isDefault?: boolean;
  name?: string;
}

export interface ICoordinates {
  lat: number;
  lng: number;
}

export interface IGeo {
  address: IAddress[];
  buildingAddress: string;
  coordinates: ICoordinates;
  countryId: number;
  districts: IDistrictInfo[];
  highways: IHighwayInfo[];
  undergrounds?: IUnderground[];
  userInput: string;
  jk?: IJK;
}

export interface ILivingAreaDetails {
  roomArea?: string;
  totalArea: string;
  livingArea: string;
  kitchenArea: string;
  roomsCount: number | null;
}

export interface ILand {
  area?: string | number;
  areaUnitType?: LandAreaUnitType;
  possibleToChangeStatus?: boolean;
  status?: LandStatus;
  type?: LandType;
}

export type LandAreaUnitType = 'hectare' | 'sotka';

export type LandStatus =
  // Фермерское хозяйство
  | 'farm'
  // Участок сельскохозяйственного назначения
  | 'forAgriculturalPurposes'
  // Участок лесного фонда
  | 'forestArea'
  // Садоводство
  | 'gardening'
  // Садовое некоммерческое товарищество
  | 'gardeningNonProfitPartnership'
  // Индивидуальное жилищное строительство
  | 'individualHousingConstruction'
  // Земля промышленного назначения
  | 'industrialLand'
  // Участок промышленности, транспорта, связи и иного не сельхоз. назначения
  | 'industryTransportCommunications'
  // Инвестпроект
  | 'investmentProject'
  // Особо охраняемых категорий
  | 'ofProtectedCategories'
  // Участок запаса
  | 'reserve'
  // Участок поселений
  | 'settlements'
  // Дачное некоммерческое партнерство
  | 'suburbanNonProfitPartnership'
  // Дачное некоммерческое партнерство поселений
  | 'suburbanNonProfitSettlementsPartnership'
  // Участок водного фонда
  | 'waterArea';

// Тип участка
export type LandType = 'owned' | 'rent';

export interface IFloorDetails {
  floorNumber: number | null;
  balconiesCount: number | null;
  loggiasCount: number | null;
}

export type IBuildingMaterialType =
  | 'monolith'
  | 'monolithBrick'
  | 'brick'
  | 'panel'
  | 'block'
  | 'wood'
  | 'stalin'
  | 'boards'
  | 'old';

export interface IBuldingQuarterPresenter {
  [index: string]: string;
}

export const BuildingQuarter: IBuldingQuarterPresenter = {
  first: '1',
  fourth: '4',
  second: '2',
  third: '3',
};

export interface IBuildingDeadline {
  isComplete: boolean;
  quarter: string;
  year: number;
}

export interface ISpecialty {
  // Английское название
  engName?: string;
  // ID
  id?: number;
  // Русское название
  rusName?: string;
}

export interface ICommercialSpecialty {
  // Дополнительные виды
  additionalTypes?: string[];
  // Cпециализация
  specialties?: ISpecialty[];
  // Коды специализаций помещений
  types?: string[];
}

export interface IBuilding {
  accessType?: TAccessType;
  buildYear?: number;
  cargoLiftsCount?: number;
  deadline?: IBuildingDeadline;
  floorsCount: number;
  materialType?: IBuildingMaterialType;
  passengerLiftsCount?: number;
  type?: TBuilding;
}

export interface IPhone {
  countryCode: string | null;
  number: string | null;
}

export interface IPromoInfo {
  priceBeforeDiscount: string | null;
  certificate: boolean;
  end: string;
  name: string;
  certificateUrl: string | null;
  specProject: boolean;
}

export type TrustLevelInvolved = 'involved';
export type UserTrustLevel = TrustLevelInvolved;
export const EUserTrustLevel = {
  Involved: 'involved' as TrustLevelInvolved,
};

export interface IOfferUser {
  agencyName: string | null;
  agentAvatarUrl?: string;
  accountType?: string | null;
  cianUserId: number;
  isAgent: boolean;
  isBuilder?: boolean;
  isCallbackUser?: boolean;
  isCianPartner: boolean;
  isChatsEnabled: boolean;
  isHidden: false;
  personalRating: 'negative' | 'positive' | null;
  phoneNumbers?: IPhone[];
  subAgentCompanyName: string | null;
  userTrustLevel: UserTrustLevel;
  profileUri?: string | null;
  /** Доступность агента */
  agentAvailability: IAgentAvailability | null;
}

export interface IAgentAvailability {
  /** Доступен ли агент в данный промежуток времени в таймзоне агента */
  available: boolean | null;
  /** тут пользователь - автор объявления */
  userId: string | null;
  /** Заголовок с текстом для не рабочего времени в таймзоне +3 */
  title?: string | null;
  /** Сообщение с текстом для не рабочего времени в таймзоне +3 */
  message?: string | null;
  /** Дата и Время в формате ISO8601 c указанием таймзоны агента */
  availableFrom: string | null;
  /** Дата и Время в формате ISO8601 c указанием таймзоны агента */
  availableTo: string | null;
  /** Дата и Время в формате ISO8601 c указанием таймзоны агента */
  vacationTo: string | null;
}

export interface IDeveloper {
  id?: number;
  name?: string;
}

export interface IJkGaGeo {
  cityId?: number;
  moId?: number;
  oblId?: number;
}

export interface IJKHouse {
  id?: number;
  name?: string;
}

export interface IJK {
  developer?: IDeveloper;
  displayName?: string;
  fullUrl?: string;
  gaGeo?: IJkGaGeo;
  house?: IJKHouse;
  id?: number;
  name?: string;
  webSiteUrl?: string;
}

export type TOfferCategory =
  | 'bedRent'
  | 'commercialLandRent'
  | 'commercialLandSale'
  | 'cottageRent'
  | 'cottageSale'
  | 'dailyBedRent'
  | 'dailyFlatRent'
  | 'dailyHouseRent'
  | 'dailyRoomRent'
  | 'flatRent'
  | 'flatSale'
  | 'flatShareSale'
  | 'freeAppointmentObjectRent'
  | 'freeAppointmentObjectSale'
  | 'garageRent'
  | 'garageSale'
  | 'houseRent'
  | 'houseSale'
  | 'houseShareRent'
  | 'houseShareSale'
  | 'industryRent'
  | 'industrySale'
  | 'landRent'
  | 'landSale'
  | 'newBuildingFlatSale'
  | 'officeRent'
  | 'officeSale'
  | 'roomRent'
  | 'roomSale'
  | 'shoppingAreaRent'
  | 'shoppingAreaSale'
  | 'townhouseRent'
  | 'townhouseSale'
  | 'warehouseRent'
  | 'warehouseSale';

export type TBuilding =
  | 'Бизнес-центр'
  | 'Торгово-развлекательный центр'
  | 'Старый фонд'
  | 'Офисное здание'
  | 'Бизнес-парк'
  | 'Офисно-производственный комплекс'
  | 'Офисно-складской комплекс'
  | 'Складской комплекс'
  | 'Производственно-складской комплекс'
  | 'Другое'
  | 'Логистический центр'
  | 'Склад'
  | 'Административное здание'
  | 'Индустриальный парк'
  | 'Логистический комплекс'
  | 'Торгово-деловой комплекс'
  | 'Многофункциональный комплекс'
  | 'Офисно-жилой комплекс'
  | 'Торгово-офисный комплекс'
  | 'Офисно-гостиничный комплекс'
  | 'Деловой центр'
  | 'Особняк'
  | 'Технопарк'
  | 'Бизнес-квартал'
  | 'Отдельно стоящее здание'
  | 'Жилой дом'
  | 'Жилой комплекс'
  | 'Торговый центр'
  | 'Специализированный торговый центр'
  | 'Торгово-общественный центр'
  | 'Аутлет'
  | 'Производственный комплекс'
  | 'Промплощадка'
  | 'Производственный цех'
  | 'Объект свободного назначения'
  | 'Имущественный комплекс'
  | 'Производственное здание'
  | 'Модульное здание'
  | 'Свободное'
  | 'Офисно-складское'
  | 'Нежилой фонд'
  | 'Жилой фонд';

export interface ISimilar {
  count?: number;
  url?: string;
}

export type IAllFromOffrep = ISimilar;

export type TOfferBSCenterType = 'businessCenter' | 'shoppingCenter';

export interface IOfferBSCenter {
  buildingClassType: TCommercialClass;
  fromRepresentative: boolean;
  fullUrl: string;
  name: string;
  parentId: string;
  parentName: string;
  parentUrl: string;
  type: TOfferBSCenterType;
  useParentName: boolean;
}

export type TDealType = 'rent' | 'sale' | 'daily';
export type TOfferType = 'flat' | 'suburban' | 'commercial';

export interface IPessimizationDetails {
  isRecidivist?: boolean;
  isExcludedFromAction?: boolean;
}

export interface IWorkTimeInfo {
  timezoneText: string;
  callFrom: string;
  callTo: string;
  isAvailableToCall: boolean;
}

export interface IBookingData {
  bookingDuration: number;
  cost: number;
  externalId: string; // id объявления ПИКа
  similarOffersUrl?: string;
  status: TBookingStatus;
}

export interface IOffer extends ILivingAreaDetails, IFloorDetails, IPessimizationDetails {
  allFromOffrep?: IAllFromOffrep;
  accessType: TAccessType;
  added: string;
  addedTimestamp: number;
  bargainTerms: IBargainTerms;
  bedroomsCount?: number;
  booking: IBookingData | null;
  building?: IBuilding;
  businessShoppingCenter?: IOfferBSCenter;
  categoriesIds?: number[];
  category?: TOfferCategory;
  cianId: number;
  cianUserId?: number;
  dealType: TDealType;
  description?: string;
  descriptionWordsHighlighted?: Array<[number, number]>;
  flatType?: string;
  fromDeveloper?: boolean;
  fullUrl: string;
  gaLabel?: string;
  garage: IGarage;
  geo?: IGeo;
  humanizedTimedelta: string;
  id: number;
  isAuction?: boolean;
  isByHomeowner?: boolean;
  isColorized?: boolean;
  isDuplicatedDescription?: boolean | null;
  isFavorite?: boolean;
  isImported: boolean;
  isInHiddenBase?: boolean;
  isPaid?: boolean;
  isPremium?: boolean;
  isPro?: boolean;
  isTop3?: boolean;
  jkUrl?: string;
  land: ILand;
  layout: TOfferDetailLayout;
  minArea?: string;
  modelVersion?: number;
  newbuilding?: {
    id?: string;
    name?: string;
    isFromBuilder?: boolean;
    isFromSeller?: boolean;
    isFromDeveloper?: boolean;
    showJkReliableFlag?: boolean;
  };
  notes: {
    offer?: {
      text: string;
      timestamp: string;
    } | null;
    realtor?: {
      text: string;
      timestamp: string;
    } | null;
  };
  offer: string;
  offerType: TOfferType;
  permittedUseType?: string;
  phones?: IPhone[];
  photos: IOfferPhoto[];
  videos: IOfferVideo[] | null;
  pricePerUnitAreaPerYearRur: number;
  priceTotalPerMonthRur: number;
  promoInfo?: IPromoInfo;
  roomsForSaleCount?: number;
  shareAmount: string;
  similar: ISimilar;
  specialty?: ICommercialSpecialty;
  suggestionDistances?: number;
  workTimeInfo?: IWorkTimeInfo;
  user: IOfferUser;
  userId?: number;
  minVatPriceTotalPerMonthRur?: number | null;
  vatPriceTotalPerMonthRur?: number | null;
  minVatPriceTotalRur?: number | null;
  vatPriceTotalRur?: number | null;
}

export function getAgentOffers(
  client: IHttpApi,
  options: IGetAgentOffersRequest,
  cookies: string,
  logger: ILogger,
): Promise<IOfferInfo[]> {
  return fetchGetAgentOffers({
    httpApi: client,
    parameters: options,
    config: {
      requestConfig: {
        headers: cookies ? [['Cookie', cookies]] : [],
      },
    },
  })
    .then((response: TGetAgentOffersResponse) => {
      if (response.statusCode !== 200) {
        const message = `Unable to get realtor offers with id ${options.userId}.`;
        const error = response.response.data.message;

        const meta = {
          realtorId: options.userId,
        };

        logger.error(`${message} ${error}`, meta);

        throw new Error(response.response.data.message);
      }

      return response.response.data.offers;
    })
    .catch(error => {
      const message = `Fail to get agent offers with id ${options.userId}.`;

      const meta = {
        realtorId: options.userId,
      };

      logger.error(`${message} ${error}`, meta);

      throw new Error(error);
    });
}
