// Утилиты для антимата
export const OBSCENE_MESSAGE_CODE = 'checkObsceneLexisFailed';
export const OBSCENE_ERROR_CODE = 'invalid';
export const OBSCENE_ERROR_KEY = 'obsceneWordFound';

export const getServerError = (errorMessageCode?: string): string | undefined => {
  if (errorMessageCode === OBSCENE_MESSAGE_CODE) {
    return 'В описании не должно быть нецензурных выражений';
  }

  return errorMessageCode;
};

// Возвращает исходное сообщение с промаркированными нецензурными словами
// Если нецензурных слов не найдено - возвращает пустую строку
export const getDescription = (
  text?: string,
  errors?: { key?: string; code?: string; message?: string }[] | undefined,
  tag: string = 'mark',
): string | undefined => {
  if (!errors || !errors.length || !text) {
    return undefined;
  }

  let isObscene = false;
  const words = text.split(' ');
  const markedWords: string[] = [];
  words.forEach(word => {
    let markedWord = word;
    errors.forEach(error => {
      if (error.key === OBSCENE_ERROR_KEY) {
        isObscene = true;
        const reg = new RegExp(`(^|[^а-яё])(${error.message})([^а-яё]|$)`, 'gi');
        markedWord = markedWord.replace(reg, `$1<${tag}>$2</${tag}>$3`);
      }
    });
    markedWords.push(markedWord);
  });
  const markedText = markedWords.join(' ');

  return isObscene ? markedText : undefined;
};
