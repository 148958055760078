import { useCallback, useState } from 'react';

import { ILocation } from '../../../types/location';

export interface IUseLocationSuggestPopup {
  onLocationChange(location?: ILocation): void;
  handleTrackSelectRegion(value: number): void;
}
export const useLocationSuggestPopup = ({ onLocationChange, handleTrackSelectRegion }: IUseLocationSuggestPopup) => {
  const [isSuggestShown, setIsSuggestShown] = useState(false);

  const togglePopup = useCallback((state: boolean) => setIsSuggestShown(state), []);

  const onItemClick = (location: ILocation) => {
    onLocationChange(location);
    handleTrackSelectRegion(location.id);
    setIsSuggestShown(false);
  };

  return {
    togglePopup,
    onItemClick,
    isSuggestShown,
  };
};
