import * as React from 'react';
import { IStyleConfig } from '@cian/utils/lib/shared/style';

import { IPhone, IWorkTimeInfo } from '../../../api/offer';
import { getFormattedPhoneNumber } from '../../../utils/formatter';
import { SimplePhoneButton } from './simple_button/index';

const style = require('./index.css');

export interface IPhoneProps {
  phone: IPhone;
  workTimeInfo?: IWorkTimeInfo;
  isSimplified?: boolean;
  onPhoneOpened?(): void;
}

enum EPhoneState {
  BUTTON,
  TEXT,
}

interface IPhoneState {
  type: EPhoneState;
  text: string;
  textStyle: IStyleConfig;
}

export class Phone extends React.Component<IPhoneProps, IPhoneState> {
  private phoneNumber: string;
  private hoverText = 'Показать телефон';

  public constructor(props: IPhoneProps) {
    super(props);
    this.phoneNumber = getFormattedPhoneNumber(`${props.phone.countryCode}${props.phone.number}`);
    this.state = {
      text: this.hideLastDigits(this.phoneNumber),
      textStyle: style['phone'],
      type: EPhoneState.BUTTON,
    };
  }

  public render() {
    const viewer = this.state.type === EPhoneState.BUTTON ? this.state.text : null;

    return (
      <SimplePhoneButton
        isSimplified={this.props.isSimplified}
        phone={this.props.phone}
        workTimeInfo={this.props.workTimeInfo}
        text={viewer}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onPhoneOpened={this.onClick}
      />
    );
  }

  private onMouseOver = () => {
    if (this.state.type === EPhoneState.BUTTON) {
      this.setState({
        text: this.hoverText,
        textStyle: style['hover-text'],
      });
    }
  };

  private onMouseOut = () => {
    if (this.state.type === EPhoneState.BUTTON) {
      this.setState({
        text: this.hideLastDigits(this.phoneNumber),
        textStyle: style['phone'],
      });
    }
  };

  private hideLastDigits = (phoneNumber: string) => {
    return phoneNumber.slice(0, -2).concat('…');
  };

  private onClick = () => {
    this.setState({
      text: this.phoneNumber,
      textStyle: style['phone'],
      type: EPhoneState.TEXT,
    });
    if (this.props.onPhoneOpened) {
      this.props.onPhoneOpened();
    }
  };
}
