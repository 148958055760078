import * as React from 'react';

import { IOffer } from '../../../../api/offer';
import { offerHelper } from '../../../../offer/presenters';

import * as styles from './index.css';

export interface ITitleaProps {
  offer: IOffer;
}

export class Title extends React.Component<ITitleaProps, {}> {
  public offer;

  public constructor(props: ITitleaProps) {
    super(props);

    this.offer = offerHelper(props.offer);
  }

  public render() {
    return (
      <div className={styles['title']}>
        {[
          this.getType(),
          this.renderArea(),
          this.renderFloor(),
          this.renderFoolrsForSuburban(),
          this.renderBedroomCount(),
        ]
          .filter(Boolean)
          .join(', ')}
      </div>
    );
  }

  private getType = () => {
    const type = this.offer.presenters.simplifiedCard.getType();

    return `${type.charAt(0).toUpperCase()}${type.substring(1)}`;
  };

  private renderArea(): string | null {
    const roomArea = this.offer.presenters.getRoomArea();
    const totalArea = this.offer.presenters.getTotalArea();

    const isSuburban = this.offer.isSuburban();
    const isFlat = this.offer.isFlat();
    const isBed = this.offer.isBed();

    const landArea = isSuburban && this.offer.presenters.simplifiedCard.getLandArea();

    if (isBed && totalArea) {
      return `${totalArea} м\u00B2`;
    }

    if (isFlat && roomArea) {
      return `${roomArea} м\u00B2`;
    } else if (totalArea) {
      return `${totalArea} м\u00B2`;
    }

    if (isSuburban && landArea) {
      return landArea;
    }

    return null;
  }

  private renderFloor(): string | null {
    const isSuburban = this.offer.isSuburban();
    const isGarage = this.offer.isGarage();

    if (!isSuburban && !isGarage) {
      return this.offer.presenters.simplifiedCard.getFloorRange();
    }

    return null;
  }

  private renderBedroomCount(): string | null {
    const isSuburban = this.offer.isSuburban();

    if (isSuburban) {
      return this.offer.presenters.getBedroomsCount();
    }

    return null;
  }

  private renderFoolrsForSuburban(): string | undefined {
    const isSuburban = this.offer.isSuburban();

    if (isSuburban) {
      return this.offer.presenters.getFloorCount();
    }

    return undefined;
  }
}
