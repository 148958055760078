import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectRealtorVacancies } from '../../../selectors/vacancies/selectRealtorVacancies';
import { VacancyItemContainer } from './VacancyItemContainer';
import { AllVacanciesLinkContainer } from './AllVacanciesLinkContainer';
import { Vacancies } from '../../../components/vacancies/Vacancies';

export const VacanciesContainer = () => {
  const { vacancies, totalCount } = useSelector(selectRealtorVacancies);
  const vacancy = vacancies[0];

  if (!totalCount || !vacancy) {
    return null;
  }

  return <Vacancies vacancy={<VacancyItemContainer />} allVacanciesLink={<AllVacanciesLinkContainer />} />;
};
