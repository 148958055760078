import * as React from 'react';

export const AlarmSVG = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <g fill="none" fillRule="evenodd">
      {/* tslint:disable-next-line:max-line-length */}
      <path
        fill="currentColor"
        d="M12.96 13.98h-12c-.738 0-1.2-.805-.83-1.448l6-10.482a.954.954 0 0 1 1.66 0l6 10.48c.368.644-.093 1.45-.83 1.45"
      />
      <path
        fill="#FFF"
        d="M8 12.03c0 .58-.465 1.05-1.04 1.05s-1.042-.47-1.042-1.05c0-.58.467-1.05 1.04-1.05.577 0 1.043.47 1.043 1.05M6.96 5.49c-.575 0-1.042.47-1.042 1.05 0 .58.314 4.172.89 4.172h.303c.577 0 .89-3.592.89-4.172 0-.58-.466-1.05-1.04-1.05"
      />
    </g>
  </svg>
);
