import * as React from 'react';
import { useSelector } from 'react-redux';

import { isAnswerFormVisible, isEditAvailable, isReplyAvailable } from './helpers';
import { selectUserAuthenticated } from '../../selectors';
import { selectAnswerFormState } from '../../selectors/answerForm/selectAnswerFormState';
import { selectAnswerFormReviewId } from '../../selectors/answerForm/selectAnswerFormReviewId';
import { selectAnswerFormAnswerId } from '../../selectors/answerForm/selectAnswerFormAnswerId';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { edit, open } from '../../store/answer_form';
// eslint-disable-next-line import/no-restricted-paths
import { requestAuthentication } from '../../../browser/utils/authentication';
import { AnswerFormContainer } from '../../components/answer_form/container';
import { selectRealtyUserId } from '../../selectors/user';
import { IReviewAnswer } from '../../types/review';
import { ReviewContainer } from '../review';
import { AnswerMobile } from '../../components/AnswerMobile';

type IAnswerProps = {
  answer: IReviewAnswer;
  reviewId: string;
};

export const AnswerMobileContainer: React.FunctionComponent<IAnswerProps> = ({ answer, reviewId }) => {
  const dispatch = useAppDispatch();

  const realtyUserId = useSelector(selectRealtyUserId);

  const answerFormState = useSelector(selectAnswerFormState);
  const answerFormReviewId = useSelector(selectAnswerFormReviewId);
  const answerFormAnswerId = useSelector(selectAnswerFormAnswerId);

  const isUserAuthenticated = useSelector(selectUserAuthenticated);

  const isCanEdit = isEditAvailable(answer, {
    realtyUserId,
    answerFormState,
    answerFormReviewId,
    answerFormAnswerId,
    reviewId,
  });

  const isCanReply = isReplyAvailable(answer, {
    realtyUserId,
    answerFormState,
    answerFormReviewId,
    answerFormAnswerId,
    reviewId,
  });

  const isAnswerReviewFormVisible = isAnswerFormVisible(answer.answerId, {
    realtyUserId,
    answerFormState,
    answerFormReviewId,
    answerFormAnswerId,
    reviewId,
  });

  const handleEditClick = React.useCallback(() => {
    const { answerId, text } = answer;

    dispatch(edit(reviewId, answerId, text));
  }, [answer, dispatch, reviewId]);

  const handleReplyClick = React.useCallback(() => {
    const { answerId } = answer;

    if (!isUserAuthenticated) {
      requestAuthentication(`answer_${reviewId}_${answerId}`);
    }

    dispatch(open(reviewId, answerId));
  }, [answer, isUserAuthenticated, dispatch, reviewId]);

  return (
    <>
      <AnswerMobile>
        <ReviewContainer
          type="answer"
          review={answer}
          key={`answer_${answer.answerId}`}
          onReplyClick={isCanReply ? handleReplyClick : undefined}
          onEditClick={isCanEdit ? handleEditClick : undefined}
        />
      </AnswerMobile>
      {isAnswerReviewFormVisible && <AnswerFormContainer key={`answer_answer_form_${answer.answerId}`} />}
    </>
  );
};
