import { ca } from '@cian/analytics';

export const trackReviewClick = () => {
  ca('eventSite', {
    action: 'add_review',
    category: 'Specialist',
    label: '',
    name: 'oldevent',
  });
};

export const trackReviewSucceed = () => {
  ca('eventSite', {
    action: 'send_review',
    category: 'Specialist',
    label: '',
    name: 'oldevent',
  });
};
