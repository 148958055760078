import * as React from 'react';
import { UIText1, Tooltip } from '@cian/ui-kit';

import { WORK_HONESTLY_MESSAGES } from '../../constants';

import * as styles from './ApprovalNew.css';

export enum EApprovalType {
  HasVerifiedDocuments = 'hasVerifiedDocuments',
  CanDoOnlineDeal = 'canDoOnlineDeal',
}

export interface IApprovalNewProps {
  type: EApprovalType;
  onlineDealLink?: string;
  onOpenLabel?: () => void;
}

export const ApprovalNew = ({ type, onlineDealLink, onOpenLabel }: IApprovalNewProps) => {
  switch (type) {
    case EApprovalType.HasVerifiedDocuments:
      return (
        <div className={styles['container']} data-testid="approval-has-verified-documents">
          <div className={`${styles['icon']} ${styles['has-verified-documents']}`} />
          <UIText1>{WORK_HONESTLY_MESSAGES.agent}</UIText1>
        </div>
      );

    case EApprovalType.CanDoOnlineDeal:
      return (
        <Tooltip
          placement="bottom-start"
          theme="white"
          onOpen={onOpenLabel}
          title={
            <div className={styles['tooltip-content']}>
              Риелтор может провести сделку онлайн — через{' '}
              <a href={onlineDealLink} target="_blank" rel="noreferrer">
                Циан.Сделку
              </a>
            </div>
          }
        >
          <div className={`${styles['container']} ${styles['with-tooltip']}`} data-testid="approval-can-do-online-deal">
            <div className={`${styles['icon']} ${styles['can-do-online-deal']}`} />
            <UIText1>Проводит онлайн-сделки</UIText1>
          </div>
        </Tooltip>
      );

    default:
      return null;
  }
};
