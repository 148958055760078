import { IAppState, IContext } from '../store';
import {
  fetchGetServiceStatusForSpecialist,
  IGetServiceStatusForSpecialistRequest,
  IGetServiceStatusForSpecialistResponse200,
  IGetServiceStatusForSpecialistResponse400,
} from '../repositories/demand/v2/get-service-status-for-specialist';
import { TThunkDispatch } from '../types/redux';

export const getServiceStatusForSpecialist = (parameters: IGetServiceStatusForSpecialistRequest) => {
  return async (dispatch: TThunkDispatch, getState: () => IAppState, context: IContext) => {
    const { httpApi, logger } = context;

    try {
      const res = await fetchGetServiceStatusForSpecialist<
        IGetServiceStatusForSpecialistResponse200,
        IGetServiceStatusForSpecialistResponse400
      >({
        httpApi,
        parameters,
      });

      if (res.statusCode !== 200) {
        throw new Error(res.response.message);
      }

      return res.response.serviceEnabled;
    } catch (error) {
      logger.error(error, {
        domain: 'getServiceStatusForSpecialist.ts',
        message: 'Unable to get service status for specialist',
      });

      throw error;
    }
  };
};
