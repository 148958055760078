import * as React from 'react';
import { NoSsr } from '@cian/react-utils';

import { Counters, ICounter } from './Counters';
import { Avatar } from './Avatar';
import { DescriptionRow } from './DescriptionRow';
import { IAgentCard } from '../../../types/agentCard';
import { Name } from './Name';
import { Rating } from './Rating';
import { Section } from '../../common/section';

import * as styles from './AboutRealtorDesktop.css';

interface IAboutBlock {
  label: string;
  text: string;
  action?(): void;
}
export interface IAboutRealtorDesktopProps {
  about: IAboutBlock[];
  avatarUrl: IAgentCard['avatarUrl'];
  counters: ICounter[];
  isAvatarRound: boolean;
  isCianPartner?: boolean;
  name: IAgentCard['name'];
  onDescriptionClick(): void;
  ratingSubtitle: string;
  rate: number;
  hasRgrParticipation: boolean;
  contacts: React.ReactNode;
  badge: React.ReactNode;
}

export const AboutRealtorDesktop = ({
  about,
  avatarUrl,
  counters,
  isAvatarRound,
  isCianPartner,
  name,
  rate,
  ratingSubtitle,
  hasRgrParticipation,
  contacts,
  badge,
}: IAboutRealtorDesktopProps) => {
  return (
    <Section className={styles['container']}>
      <div className={styles['row']}>
        <NoSsr>
          <Avatar isRound={isAvatarRound} avatarUrl={avatarUrl} />
        </NoSsr>
        <Name isCianPartner={isCianPartner} name={name} hasRgrParticipation={hasRgrParticipation} />
      </div>
      <NoSsr>
        <div className={styles['row']}>
          <div className={styles['column']}>
            <Rating text={ratingSubtitle} value={rate} />
            {badge && <div className={styles['badge']}>{badge}</div>}
          </div>
          <Counters counters={counters} />
        </div>
      </NoSsr>
      <div className={styles['description-container']}>
        {about.map(({ label, text, action }) => (
          <DescriptionRow key={label} label={label} text={text} action={action} />
        ))}
      </div>
      {contacts}
    </Section>
  );
};
