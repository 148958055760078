import * as React from 'react';
import sanitize from 'sanitize-html';

import { EReviewFormType, ReviewForm as ReviewFormComponent } from '../review_form_component';
import { EAnswerFormState } from '../../store/answer_form';
import { getServerError } from '../../utils/antiObscene';
import { TUser } from '../../store';
import { TDeviceType } from '../../types/device';

import * as styles from './index.css';

export interface IAnswerFormStoreProps {
  state: EAnswerFormState | undefined;
  edit: boolean | undefined;
  errorMessage?: string;
  textErrorMarked?: string;
  user: TUser;
  text: string | undefined;
  deviceType: TDeviceType;
}

export interface IAnswerFormDispatchProps {
  changeText(text: string): void;
  clickSubmitButton(): void;
  submit(): void;
  reset(): void;
}

export type TAnswerFormProps = IAnswerFormStoreProps & IAnswerFormDispatchProps;

export class AnswerForm extends React.Component<TAnswerFormProps, {}> {
  private reviewForm: ReviewFormComponent | null = null;

  public componentDidMount() {
    if (this.reviewForm) {
      this.reviewForm.focus();
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: TAnswerFormProps) {
    if (nextProps.state === EAnswerFormState.Succeed && this.props.state !== nextProps.state) {
      this.props.reset();
    }
  }

  public render() {
    const { state, user, deviceType } = this.props;

    if (!user.isAuthenticated) {
      return null;
    }

    const { edit, text, changeText } = this.props;

    return (
      // @ts-ignore
      <ReviewFormComponent
        ref={ref => (this.reviewForm = ref)}
        type={!edit ? EReviewFormType.Answer : EReviewFormType.AnswerEdit}
        name={user.displayName}
        avatarSrc={user.avatarUrl}
        text={text || ''}
        loading={state === EAnswerFormState.Submitted}
        onTextChange={changeText}
        customStarsDescriptionText="Оцените специалиста"
        customRateEntityErrorText="Оцените специалиста"
        customPlaceholder="Отзыв"
        onSubmit={this.handleSubmit}
        onCancel={this.handleCancel}
        containerStyle={styles['answer_form']}
        serverError={state === EAnswerFormState.Failed ? this.getServerErrorMessage() : undefined}
        description={this.getDescription()}
        onSubmitButtonClick={this.props.clickSubmitButton}
        deviceType={deviceType}
      />
    );
  }

  private getServerErrorMessage = () => {
    const { errorMessage } = this.props;

    return getServerError(errorMessage) || 'Не удалось опубликовать ответ. Попробуйте ещё раз';
  };

  private getDescription = () => {
    const { textErrorMarked } = this.props;

    let descriptionElement;
    if (textErrorMarked) {
      descriptionElement = (
        <div
          className={styles['description']}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: sanitize(textErrorMarked),
          }}
        />
      );
    }

    return descriptionElement;
  };

  private handleSubmit = () => {
    const { state, submit } = this.props;

    if (state === EAnswerFormState.Submitted) {
      return;
    }

    submit();
  };

  private handleCancel = () => {
    const { reset } = this.props;

    reset();
  };
}
