import { connect } from 'react-redux';

import { IAppState } from '../../../../store';
import {
  simplifiedOfferCardPopupCloseAction,
  simplifiedOfferCardPopupOpenAction,
} from '../../../../store/simplified_card_popups';
import { Buttons } from './index';
import { IButtonsDispatchProps, IButtonsOwnProps, IButtonsStoreProps } from './types';
import { TThunkDispatch } from '../../../../types/redux';

function mapStateToProps(state: IAppState) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch: TThunkDispatch) {
  return {
    onPopupMoreClose: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupCloseAction(offerId, 'more'));
    },
    onPopupMoreOpen: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupOpenAction(offerId, 'more'));
    },
    onPopupReportClose: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupCloseAction(offerId, 'report'));
    },
    onPopupReportOpen: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupOpenAction(offerId, 'report'));
    },
  };
}

export const ButtonsContainer = connect<IButtonsStoreProps, IButtonsDispatchProps, IButtonsOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Buttons);
