import * as React from 'react';
import { mergeStyles } from '@cian/utils';
import sanitize from 'sanitize-html';

import { EReviewFormType, ReviewForm as ReviewFormComponent } from '../review_form_component';
// eslint-disable-next-line import/no-restricted-paths
import { requestAuthentication } from '../../../browser/utils/authentication';
// eslint-disable-next-line import/no-restricted-paths
import { scrollToElement } from '../../../browser/utils/scroll';
import { EReviewFormState } from '../../store/review_form';
import { trackReviewClick, trackReviewSucceed } from './tracking';
import { getServerError } from '../../utils/antiObscene';
import { TUser } from '../../store';
import { TDeviceType } from '../../types/device';

import * as styles from './index.css';

export interface IReviewFormStoreProps {
  state: EReviewFormState;
  lastOpen: Date | undefined;
  edit: boolean | undefined;
  errorMessage?: string;
  textErrorMarked?: string;
  user: TUser;
  rating: number | undefined;
  text: string | undefined;
  reviewsTotalCount: number;
  realtorRealtyUserId: number;
  deviceType: TDeviceType;
}

export interface IReviewFormDispatchProps {
  open(): void;
  changeRating(rating: number): void;
  changeText(text: string): void;
  clickSubmitButton(): void;
  submit(): void;
  reset(): void;
}

export interface IReviewFormOwnProps {
  staticForm?: boolean;
  className?: string;
}

export type TReviewFormProps = IReviewFormStoreProps & IReviewFormDispatchProps & IReviewFormOwnProps;

let scrolledTo = false;

export class ReviewForm extends React.Component<TReviewFormProps, {}> {
  private reviewForm: ReviewFormComponent | null = null;

  public componentDidMount() {
    if (this.props.state !== EReviewFormState.Initial) {
      if (this.reviewForm) {
        this.reviewForm.focus();
      }
    }

    if (!scrolledTo && window.location.hash.slice(1) === 'new') {
      if (this.props.user.isAuthenticated) {
        this.props.open();

        const reviewFormElement = document.getElementById('review_form');
        if (reviewFormElement) {
          window.setTimeout(() => scrollToElement(reviewFormElement), 0);
          scrolledTo = true;
        }
      }
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: TReviewFormProps) {
    if (nextProps.state === EReviewFormState.Succeed && this.props.state !== nextProps.state) {
      if (!nextProps.edit) {
        trackReviewSucceed();
      }

      this.props.reset();
    }
  }

  public componentDidUpdate(prevProps: TReviewFormProps) {
    if (
      this.props.state !== EReviewFormState.Initial &&
      this.props.lastOpen &&
      this.props.lastOpen !== prevProps.lastOpen
    ) {
      if (this.reviewForm) {
        this.reviewForm.focus();
      }
    }
  }

  public render() {
    const content = this.renderContent();

    if (!content) {
      return null;
    }

    return (
      <div id="review_form" className={styles['container']}>
        {content}
      </div>
    );
  }

  private renderContent() {
    const { edit, user, state, staticForm, realtorRealtyUserId, className, deviceType } = this.props;

    if (state === EReviewFormState.Initial || (edit && staticForm)) {
      const { reviewsTotalCount } = this.props;

      if ((user.isAuthenticated && realtorRealtyUserId === user.realtyUserId) || !reviewsTotalCount) {
        return null;
      }

      return (
        // @ts-ignore
        <ReviewFormComponent
          type={EReviewFormType.Inactive}
          customStarsDescriptionText="Оцените специалиста"
          customRateEntityErrorText="Оцените специалиста"
          customPlaceholder="Отзыв"
          avatarSrc={user.isAuthenticated ? user.avatarUrl : undefined}
          noAvatar={this.isWithoutAvatar()}
          onOpen={this.handleOpen}
          containerStyle={{ ...mergeStyles(styles['review_form'], className) }}
          deviceType={deviceType}
        />
      );
    }

    if ((user.isAuthenticated && realtorRealtyUserId === user.realtyUserId) || !user.isAuthenticated) {
      return null;
    }

    const { rating, text, changeRating, changeText } = this.props;

    const placeholder =
      'Оцените процесс вашего сотрудничества, укажите качества,' +
      ' которые проявил специалист. Выводы о компетентности риелтора следует подтвердить фактами';

    return (
      <ReviewFormComponent
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={(ref: any) => (this.reviewForm = ref)}
        type={!edit ? EReviewFormType.Review : EReviewFormType.ReviewEdit}
        name={user.displayName}
        avatarSrc={user.avatarUrl}
        rating={rating || 0}
        text={text || ''}
        customStarsDescriptionText="Оцените специалиста"
        customRateEntityErrorText="Оцените специалиста"
        customPlaceholder={placeholder}
        loading={state === EReviewFormState.Submitted}
        onRatingChange={changeRating}
        onTextChange={changeText}
        noAvatar={this.isWithoutAvatar()}
        onCancel={this.handleCancel}
        onSubmit={this.handleSubmit}
        containerStyle={styles['review_form']}
        serverError={state === EReviewFormState.Failed ? this.getServerErrorMessage() : undefined}
        description={this.getDescription()}
        minLength={35}
        onSubmitButtonClick={this.props.clickSubmitButton}
        deviceType={deviceType}
      />
    );
  }

  private getServerErrorMessage = () => {
    const { errorMessage } = this.props;

    return getServerError(errorMessage) || 'Не удалось опубликовать отзыв. Попробуйте ещё раз';
  };

  private getDescription = () => {
    const { textErrorMarked } = this.props;

    let descriptionElement;
    if (textErrorMarked) {
      descriptionElement = (
        <div
          className={styles['description']}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: sanitize(textErrorMarked),
          }}
        />
      );
    }

    return descriptionElement;
  };

  private isWithoutAvatar() {
    const { user, reviewsTotalCount } = this.props;

    return !(user.isAuthenticated && reviewsTotalCount);
  }

  private handleOpen = () => {
    if (this.props.user.isAuthenticated) {
      this.props.open();
    } else {
      requestAuthentication('new');
    }

    trackReviewClick();
  };

  private handleSubmit = () => {
    const { state, submit } = this.props;

    if (state === EReviewFormState.Submitted) {
      return;
    }

    submit();
  };

  private handleCancel = () => {
    const { reset } = this.props;

    reset();
  };
}
