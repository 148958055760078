import { IHttpApi } from '@cian/http-api/shared/http';
import { IModel, IModelResponse } from '@cian/http-api/shared/model';

import { offerHelper } from '../offer/presenters';
import { IOffer } from './offer';

export type TPostFavoriteModel = IModel<IFavoriteRequest, IModelResponse<IFavoriteResponse>>;

export const postFavoriteModel: TPostFavoriteModel = {
  apiType: 'legacy',
  assertStatusCodes: [200],
  method: 'POST',
  microserviceName: 'monolith-python',
  pathApi: '/ajax/',
};

export interface IFavoriteRequest {
  action: 'assing' | 'remove';
  oid: number;
}

export interface IFavoriteResponse {
  status: string;
  count: {
    total: number;
  };
}

export const changeFavorite = (httpApi: IHttpApi, offer: IOffer, isFavorite: boolean): Promise<IFavoriteResponse> => {
  const helper = offerHelper(offer);

  const dealType = helper.isSale() ? 'sale' : 'rent';

  let offerType = 'flat';
  if (helper.isCommercial()) {
    offerType = 'commercial';
  } else if (helper.isSuburban()) {
    offerType = 'suburban';
  }

  return httpApi
    .fetch(
      {
        ...postFavoriteModel,
        parameters: {
          action: isFavorite ? 'assign' : 'remove',
          oid: offer.id,
        },
        pathApi: `/ajax/${dealType}/${offerType}/favorites`,
      },
      {
        bodyAsEncodeString: true,
        requestConfig: {
          headers: [['Content-Type', 'application/x-www-form-urlencoded']],
        },
      },
    )
    .then(
      res => {
        return res.response;
      },
      err => {
        return err;
      },
    );
};
