import * as React from 'react';
import { UIText1 } from '@cian/ui-kit/typography';

import * as styles from './AboutRealtorMobileSection.css';

interface IProps {
  pictureElement: JSX.Element;
  title: string;
  text: string;
}

export const AboutRealtorMobileSection = ({ pictureElement, title, text }: IProps) => (
  <div className={styles['container']}>
    <div className={styles['icon']}>{pictureElement}</div>
    <div className={styles['text']}>
      <UIText1 color="gray60_100">{title}</UIText1>
      <br />
      <UIText1>{text}</UIText1>
    </div>
  </div>
);
