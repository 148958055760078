import { OKIcon, VKIcon } from '../../../components/contacts';
import { SocialType } from './types';

export const getIcon = (socialType: SocialType) => {
  switch (socialType) {
    case 'vk':
      return VKIcon;
    case 'ok':
      return OKIcon;
  }
};
