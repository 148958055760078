import { Store } from 'redux';
import { NotFoundError } from '@cian/peperrors/shared';
import { getConfig } from '@cian/config/browser';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';

import { getReviewsRealtorPage, renderReactApp } from './internal';
import { IAppState, IContext } from '../../../shared/store';

export function renderApp(store: Store<IAppState>) {
  const config = getConfig();
  const httpApi = getHttpApi();

  const rootElement = document.getElementById('realtor-reviews-frontend');

  if (!rootElement) {
    throw new NotFoundError({
      message: 'Unable to find element #realtor-reviews-frontend',
      domain: 'services#renderApp',
    });
  }

  const context: IContext = {
    config,
    httpApi,
    logger: getLogger(),
  };

  const reviewsRealtorPage = getReviewsRealtorPage(context);

  renderReactApp({
    app: reviewsRealtorPage,
    store,
    context,
    rootElement,
  });
}
