import * as React from 'react';
import { connect } from 'react-redux';
import { plural } from '@cian/utils/lib/shared/plural';

import { ELocationTypeId, IOffer } from '../../../../api/offer';
import { UndergroundIcon } from '../../../../components/underground_icon';
import { AddressPathItem, TAddressPathItem } from '../../../offer_card/address/address_path_item';

import * as styles from './index.css';

export interface IAddressOwnProps {
  offer: IOffer;
}

export type IProps = IAddressOwnProps;

export class Address extends React.PureComponent<IProps, {}> {
  public render() {
    return (
      <div className={styles['address']}>
        {this.renderUnderground()}
        {this.renderAddress()}
      </div>
    );
  }

  private renderAddress() {
    const { offer } = this.props;
    const { geo } = offer;

    if (!geo) {
      return null;
    }

    /**
     * Если внутри geo.address уже есть метро, то подставлять его вручную на фронте
     * не нужно
     */
    const isGeoAddressWithMetro = geo.address.find(({ type }) => type === 'metro');

    const undergroundStation = this.getUnderground();

    const cityIndex = geo.address.reduce(
      (cIndex, { locationTypeId }, index) => (locationTypeId === ELocationTypeId.City ? index + 1 : cIndex),
      1,
    );

    const addressLine = geo.address.map(({ fullName, qs, type }) => ({
      label: fullName,
      qs,
      type: type === 'metro' ? 'metro' : 'address',
    }));

    /**
     * Если есть undergroundStation, но нет метро внутри geo.address,
     * то добавляем метро вручную тут
     */
    if (undergroundStation && !isGeoAddressWithMetro) {
      addressLine.splice(cityIndex, 0, {
        label: `м.\u00A0${undergroundStation.name}`,
        qs: undergroundStation.qs,
        type: 'metro',
      });
    }

    const addressString = addressLine.map(address => address.label).join(', ');

    return (
      <div className={styles['address-links']}>
        <span {...{ itemProp: 'name', content: addressString }} />
        {addressLine.map(({ label, qs, type }, index) => {
          const item = (
            <div
              key={`address-path-item-${index}`}
              className={styles['link-container']}
              onClick={e => e.stopPropagation()}
            >
              <AddressPathItem
                className={styles['address-link']}
                key={`address-path-item-${index}`}
                label={label}
                qs={qs}
                type={type as TAddressPathItem}
              />
            </div>
          );

          return [index > 0 && ', ', item];
        })}
      </div>
    );
  }

  private renderUnderground() {
    const { offer } = this.props;
    const { geo } = offer;
    const underground = this.getUnderground();

    if (!underground) {
      return null;
    }
    const locationId = geo && geo.address.length > 0 ? geo.address[0].id : undefined;

    return (
      <div className={styles['underground-container']}>
        <a
          className={styles['underground-link']}
          href={`/cat.php?${underground.qs}`}
          target="_blank"
          onClick={this.handleUndergroundClick}
          rel="noreferrer"
        >
          <div className={styles['icons']}>
            <UndergroundIcon width={16} height={16} color={`#${underground.lineColor}`} regionId={locationId} />
          </div>
          <div className={styles['underground-name']}>{underground.name}</div>
        </a>

        {underground.time > 0 ? (
          <div className={styles['remoteness']}>
            {underground.time}&nbsp;
            {plural(underground.time, ['минута', 'минуты', 'минут'])}&nbsp;
            {underground.transportType === 'walk' ? 'пешком' : 'на транспорте'}
          </div>
        ) : (
          <div className={styles['remoteness']}>
            &lt;1 минуты&nbsp;
            {underground.transportType === 'walk' ? 'пешком' : 'на транспорте'}
          </div>
        )}
      </div>
    );
  }

  private handleUndergroundClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
  };

  private getUnderground = () => {
    const { offer } = this.props;
    const { geo } = offer;

    if (geo && geo.undergrounds) {
      const offerUndergrounds = geo.undergrounds.concat().sort((a, b) => a.time - b.time);

      return offerUndergrounds.find(underground => underground.transportType === 'walk') || offerUndergrounds[0];
    }

    return undefined;
  };
}

function mapStateToProps() {
  return {};
}

export const AddressContainer = connect<{}, {}, IAddressOwnProps>(mapStateToProps)(Address);
