import { IHttpApi } from '@cian/http-api/shared/http';

import {
  fetchGetComplaintTypes,
  IGetComplaintTypesResponse,
  IGetComplaintTypesResponse400,
  TGetComplaintTypesResponse,
} from '../repositories/moderation-complaints/v3/get-complaint-types';
import {
  EComplaintState,
  fetchCreateComplaint,
  ICreateComplaintResponse,
  ICreateComplaintResponse400,
  TCreateComplaintResponse,
} from '../repositories/moderation-complaints/v2/create-complaint';
import { getBrowserFingerprint } from '../utils/fingerprint';

export const getComplaints = (httpApi: IHttpApi, categoryId: number): Promise<IGetComplaintTypesResponse> =>
  fetchGetComplaintTypes({
    httpApi,
    parameters: { categoryId },
  }).then((res: TGetComplaintTypesResponse) => {
    if (res.statusCode !== 200) {
      throw new Error((res as IGetComplaintTypesResponse400).response.message);
    }

    return res.response;
  });

export interface IAddComplaintParams {
  realtyObjectId: number | null;
  complaintOnSiteId: string | null;
  message: string | null;
  complaintState: EComplaintState;
}

export const addComplaint = (httpApi: IHttpApi, params: IAddComplaintParams): Promise<ICreateComplaintResponse> =>
  fetchCreateComplaint({
    httpApi,
    parameters: {
      ...params,
      userContextInfo: {
        uniqueId: getBrowserFingerprint().toString(),
      },
    },
    config: {
      requestConfig: {
        withCredentials: false,
        headers: [['Content-Type', 'application/json']],
      },
    },
  }).then((res: TCreateComplaintResponse) => {
    if (res.statusCode !== 200) {
      throw new Error((res as ICreateComplaintResponse400).response.message);
    }

    return res.response;
  });
