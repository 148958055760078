import * as React from 'react';
import { useSelector } from 'react-redux';

import { ReviewContainer } from '../review';
import { isAnswerFormVisible, isEditAvailable, isEditFormVisible, isReplyAvailable } from './helpers';
import { IReview } from '../../types/review';
import { selectReviewFormState } from '../../selectors/reviewForm/selectReviewFormState';
import { selectUserAuthenticated } from '../../selectors';
import { selectReviewFormReviewId } from '../../selectors/reviewForm/selectReviewFormReviewId';
import { selectAnswerFormState } from '../../selectors/answerForm/selectAnswerFormState';
import { selectAnswerFormReviewId } from '../../selectors/answerForm/selectAnswerFormReviewId';
import { selectAnswerFormAnswerId } from '../../selectors/answerForm/selectAnswerFormAnswerId';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { edit } from '../../store/review_form';
import { open } from '../../store/answer_form';
// eslint-disable-next-line import/no-restricted-paths
import { requestAuthentication } from '../../../browser/utils/authentication';
import { ReviewFormContainer } from '../../components/review_form/container';
import { AnswerFormContainer } from '../../components/answer_form/container';
import { selectRealtyUserId } from '../../selectors/user';
import { AnswersMobileContainer } from '../answers';

type IReviewProps = {
  review: IReview;
};

export const ReviewMobileContainer: React.FunctionComponent<IReviewProps> = ({ review }) => {
  const dispatch = useAppDispatch();

  const realtyUserId = useSelector(selectRealtyUserId);
  const editFormState = useSelector(selectReviewFormState);
  const editFormReviewId = useSelector(selectReviewFormReviewId);

  const answerFormState = useSelector(selectAnswerFormState);
  const answerFormReviewId = useSelector(selectAnswerFormReviewId);
  const answerFormAnswerId = useSelector(selectAnswerFormAnswerId);

  const isUserAuthenticated = useSelector(selectUserAuthenticated);

  const isCanEdit = isEditAvailable(review, { editFormState, realtyUserId, editFormReviewId });
  const isCanReply = isReplyAvailable(review, {
    editFormState,
    realtyUserId,
    editFormReviewId,
    answerFormState,
    answerFormReviewId,
    answerFormAnswerId,
  });

  const isEditReviewFormVisible = isEditFormVisible(review.reviewId, { editFormState, realtyUserId, editFormReviewId });
  const isAnswerReviewFormVisible = isAnswerFormVisible(review.reviewId, {
    editFormState,
    realtyUserId,
    editFormReviewId,
    answerFormState,
    answerFormReviewId,
    answerFormAnswerId,
  });

  const handleEditClick = React.useCallback(() => {
    const { reviewId, text, rate } = review;

    dispatch(edit(reviewId, text, rate));
  }, [dispatch, review]);

  const handleReplyClick = React.useCallback(() => {
    const { reviewId } = review;

    if (!isUserAuthenticated) {
      requestAuthentication(`review_${reviewId}`);
    }

    dispatch(open(reviewId));
  }, [dispatch, isUserAuthenticated, review]);

  return (
    <>
      <ReviewContainer
        type="review"
        review={review}
        key={`review_${review.reviewId}`}
        onReplyClick={isCanReply ? () => handleReplyClick() : undefined}
        onEditClick={isCanEdit ? () => handleEditClick() : undefined}
      />
      {isEditReviewFormVisible && <ReviewFormContainer key={`review_edit_form_${review.reviewId}`} />}
      {isAnswerReviewFormVisible && <AnswerFormContainer key={`review_answer_form_${review.reviewId}`} />}
      {review.answers.length > 0 && (
        <AnswersMobileContainer
          reviewId={review.reviewId}
          answers={review.answers}
          key={`review_answers_${review.reviewId}`}
        />
      )}
    </>
  );
};
