import { connect } from 'react-redux';
import { plural } from '@cian/utils';

import { IGetBlogListSuccessResponseDataSchema } from '../../types/blogs';
import { IGetCommentListSuccessResponseDataSchema as IComment } from '../../types/comment';
import { IGetQuestionListSuccessResponseDataSchema } from '../../types/question';
import { IAppState } from '../../store';
import { Content } from './index';
import { IAgentCard } from '../../types/agentCard';

export interface IContentProps {
  allAdvicesLink: string | undefined;
  allAdvicesText: string | undefined;
  advices: IComment | undefined;
  allQuestionsLink: string | undefined;
  allQuestionsText: string | undefined;
  avatarUrl?: string;
  name: IAgentCard['name'];
  questions: IGetQuestionListSuccessResponseDataSchema[] | undefined;
  allBlogsLink: string | undefined;
  allBlogsText: string | undefined;
  blogs: IGetBlogListSuccessResponseDataSchema[] | undefined;
  totalAdvicesCount: number;
  totalBlogsCount: number;
  totalQuestionsCount: number;
  rating: number;
  realtorRealtyUserId: number;
}

export type TContentProps = IContentProps;

function mapStateToProps(state: IAppState): IContentProps {
  const { realtyUserId: realtorRealtyUserId, avatarUrl, name } = state.realtor;
  const { totalCount: totalAdvicesCount, rating } = state.advices;
  const { totalCount: totalQuestionsCount } = state.questions;
  const { totalCount: totalBlogsCount } = state.blogs;

  let allAdvicesLink;
  let allAdvicesText;
  let allQuestionsLink;
  let allQuestionsText;
  let allBlogsLink;
  let allBlogsText;
  let advices;
  let blogs;
  let questions;

  if (totalAdvicesCount > 0) {
    advices = state.advices.advices[0];

    allAdvicesLink = `/forum-rieltorov/?comment_user_id=${realtorRealtyUserId}`;
    allAdvicesText =
      totalAdvicesCount === 1
        ? 'Смотреть 1 совет'
        : `Смотреть все ${totalAdvicesCount} ${plural(totalAdvicesCount, ['совет', 'совета', 'советов'])}`;
  }

  if (totalQuestionsCount > 0) {
    questions = state.questions.questions;

    allQuestionsLink = `/forum-rieltorov/?author_id=${realtorRealtyUserId}`;
    allQuestionsText =
      totalQuestionsCount === 1
        ? 'Смотреть 1 вопрос'
        : `Смотреть все ${totalQuestionsCount} ${plural(totalQuestionsCount, ['вопрос', 'вопроса', 'вопросов'])}`;
  }

  if (totalBlogsCount > 0) {
    blogs = state.blogs.blogs;

    allBlogsLink = `/blogs/?author_id=${realtorRealtyUserId}`;
    allBlogsText =
      totalBlogsCount === 1
        ? 'Смотреть 1 запись'
        : `Смотреть все ${totalBlogsCount} ${plural(totalBlogsCount, ['запись', 'записи', 'записей'])}`;
  }

  return {
    advices,
    allAdvicesLink,
    allAdvicesText,
    allBlogsLink,
    allBlogsText,
    allQuestionsLink,
    allQuestionsText,
    avatarUrl,
    blogs,
    name,
    questions,
    rating,
    realtorRealtyUserId,
    totalAdvicesCount,
    totalBlogsCount,
    totalQuestionsCount,
  };
}

export const ContentContainer = connect<IContentProps, {}, {}>(mapStateToProps)(Content);
