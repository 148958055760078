import { EAnswerFormState } from '../../store/answer_form';
import { IReviewAnswer } from '../../types/review';
import { timestampToDate } from '../../utils/time';

interface IAnswerHelperProps {
  realtyUserId: number | undefined;
  reviewId: string;
  answerFormAnswerId?: string;
  answerFormReviewId?: string;
  answerFormState?: EAnswerFormState;
}

/**
 * Проверяет доступность редактирования ответа на основании статуса, даты публикации и id пользователя
 */
export const isEditAvailable = (answer: IReviewAnswer, props: IAnswerHelperProps) => {
  const { realtyUserId, answerFormState, answerFormReviewId, answerFormAnswerId, reviewId } = props;

  return (
    answer.user.realtyUserId === realtyUserId &&
    answer.status !== 'declined' &&
    timestampToDate(answer.created + 20 * 60) > new Date() &&
    (typeof answerFormState === 'undefined' ||
      answerFormReviewId !== reviewId ||
      answerFormAnswerId !== answer.answerId)
  );
};

/**
 * Проверяет доступность добавления ответа на основании статуса ответа, состояния формы и id отзыва, и id ответа
 */
export const isReplyAvailable = (answer: IReviewAnswer, props: IAnswerHelperProps) => {
  const { answerFormState, answerFormReviewId, answerFormAnswerId, reviewId } = props;

  return (
    !isEditAvailable(answer, props) &&
    answer.status !== 'declined' &&
    (typeof answerFormState === 'undefined' ||
      answerFormReviewId !== reviewId ||
      answerFormAnswerId !== answer.answerId)
  );
};

/**
 * Проверяет видимость формы написания / редактирования ответа на основании состояния формы и id отзыва, и id ответа
 */
export const isAnswerFormVisible = (answerId: string, props: IAnswerHelperProps) => {
  const { answerFormState, answerFormReviewId, answerFormAnswerId, reviewId } = props;

  return typeof answerFormState !== 'undefined' && answerFormReviewId === reviewId && answerFormAnswerId === answerId;
};
