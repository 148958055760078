import * as React from 'react';

// eslint-disable-next-line import/no-restricted-paths
import { isStickyPossible, isStickySupported } from '../../../browser/utils';
import { AsideContainer } from '../../containers/AsideContainer';
import { Section } from '../common/section';

import * as styles from './index.css';

let isSticky: boolean;
let top: number;

export class Aside extends React.PureComponent<{}, {}> {
  private container: HTMLDivElement | null = null;
  private content: HTMLDivElement | null = null;
  private placeholder: HTMLDivElement | null = null;

  public componentDidMount() {
    if (!this.container || !this.content || !this.placeholder) {
      return;
    }

    if (!isStickySupported() && isStickyPossible()) {
      this.container.style.position = 'relative';

      const style = window.getComputedStyle(this.container);

      top = parseInt(style.top || '0', 10);
      this.content.style.zIndex = style.zIndex;
      this.content.style.position = 'relative';
      this.container.style.top = String(0);
      this.placeholder.style.overflow = 'hidden';

      this.update();

      document.addEventListener('scroll', this.update);
      document.addEventListener('resize', this.update);
    }
  }

  public componentWillUnmount() {
    document.removeEventListener('scroll', this.update);
    document.removeEventListener('resize', this.update);
  }

  public render() {
    return (
      <div className={styles['container']} ref={ref => (this.container = ref)}>
        <div ref={ref => (this.content = ref)}>
          <Section className={styles['section']}>
            <AsideContainer />
          </Section>
        </div>
        <div ref={ref => (this.placeholder = ref)} />
      </div>
    );
  }

  private stick = () => {
    if (!this.content || isSticky) {
      return;
    }

    this.content.style.position = 'fixed';
    isSticky = true;
  };

  private unstick = () => {
    if (!this.content || !this.placeholder || !isSticky) {
      return;
    }

    this.content.style.position = 'relative';
    this.content.style.width = '';
    this.content.style.top = '';
    this.content.style.left = '';
    this.placeholder.style.height = '';
    this.placeholder.style.width = '';

    isSticky = false;
  };

  private update = () => {
    if (!this.container || !this.placeholder || !this.content) {
      return;
    }

    const rect = this.container.getBoundingClientRect();

    if (rect.top < top) {
      this.placeholder.style.height = `${rect.height}px`;
      this.placeholder.style.width = `${rect.width}px`;

      if (this.container && this.container.parentElement) {
        const parentRect = this.container.parentElement.getBoundingClientRect();

        this.content.style.top = `${Math.min(parentRect.top + parentRect.height - rect.height, top)}px`;
        this.content.style.width = `${rect.width}px`;
        this.content.style.left = `${rect.left}px`;
      }

      this.stick();
    } else {
      this.unstick();
    }
  };
}
