export declare type TVacancyEmployment = 'full' | 'part' | 'piece';

export interface IVacancyCompany {
  age?: string;
  avatarUrl?: string;
  id: number;
  isTrusted?: boolean;
  name?: string;
  profileUrl?: string;
}

export interface IVacancy {
  company?: IVacancyCompany;
  created?: string;
  createdHumanized?: string;
  description: string;
  email?: string;
  employment: TVacancyEmployment;
  id?: number;
  leftToUpdate?: string;
  maxSalary?: number;
  minExperience?: number;
  minSalary?: number;
  phone?: string;
  regionDisplayName?: string;
  regionId: number;
  specialization: string;
  updated?: string;
  updatedHumanized?: string;
  userId?: number;
}

export interface IVacancies {
  vacancies: IVacancy[];
  pageCount: number;
  vacancyCount: number;
}

export interface IVacanciesData {
  vacancies: IVacancy[];
  totalCount: number;
}

export interface IVacanciesRequested {
  type: 'IVacanciesRequested';
}

export interface IVacanciesFetched extends IVacanciesData {
  type: 'IVacanciesFetched';
}

export type TVacanciesActions = IVacanciesRequested | IVacanciesFetched;

export function vacanciesReducer(state: IVacanciesData, action: TVacanciesActions): IVacanciesData {
  switch (action.type) {
    case 'IVacanciesRequested':
      return {
        ...state,
      };

    case 'IVacanciesFetched':
      return {
        ...state,
        totalCount: action.totalCount,
        vacancies: action.vacancies,
      };

    default:
      return state || {};
  }
}
