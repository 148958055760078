import { mergeStyles } from '@cian/utils/lib/shared/style';
import * as React from 'react';

import { IOffer } from '../../../../api/offer';

import * as styles from './index.css';

interface IDescriptionProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  offer: IOffer;
}

export class Description extends React.PureComponent<IDescriptionProps, {}> {
  public render() {
    const { offer, className } = this.props;
    const { description } = offer;

    if (!description) {
      return null;
    }

    const descriptionNodes: React.ReactNode[] = [];
    let currentPosition = 0;

    if (offer.descriptionWordsHighlighted) {
      offer.descriptionWordsHighlighted.forEach(range => {
        descriptionNodes.push(
          description.substring(currentPosition, range[0]),
          <span key={range.toString()} className={styles['label']}>
            {description.substring(range[0], range[1])}
          </span>,
        );
        currentPosition = range[1];
      });

      if (currentPosition < description.length) {
        descriptionNodes.push(description.substring(currentPosition));
      }
    } else {
      descriptionNodes.push(description);
    }

    return (
      <div {...mergeStyles(styles['container'], className)} data-name="OfferDescription">
        {descriptionNodes}
      </div>
    );
  }
}
