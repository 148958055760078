/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TCreatePersonalMessageModel,
  ICreatePersonalMessageRequest,
  IMappers,
  ICreatePersonalMessageResponse,
  ICreatePersonalMessageResponseError,
  TCreatePersonalMessageResponse,
} from './types';

const defaultConfig: TCreatePersonalMessageModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'demand',
  pathApi: '/v2/create-personal-message/',
  hostType: 'api',
} as TCreatePersonalMessageModel;

function createCreatePersonalMessageModel(parameters: ICreatePersonalMessageRequest): TCreatePersonalMessageModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ICreatePersonalMessageOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: ICreatePersonalMessageRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchCreatePersonalMessage<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: ICreatePersonalMessageOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TCreatePersonalMessageResponse
> {
  const { response, statusCode } = await httpApi.fetch(createCreatePersonalMessageModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ICreatePersonalMessageResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as ICreatePersonalMessageResponseError);
    }
  }

  return { response, statusCode } as TCreatePersonalMessageResponse;
}

export { defaultConfig, createCreatePersonalMessageModel, fetchCreatePersonalMessage };
