import * as React from 'react';
import { Button, ModalFullscreen, Responsive, UIHeading2, UIText1, UIText2 } from '@cian/ui-kit';

import { IPersonalLeadFormProps } from '../../PersonalLeadForm';

import * as styles from './styles.css';

type TSuccessBlockProps = Pick<IPersonalLeadFormProps, 'openSuccessMobileModal' | 'handleCloseMobileModal'>;

export const SuccessBlock: React.VFC<TSuccessBlockProps> = ({ openSuccessMobileModal, handleCloseMobileModal }) => {
  return (
    <>
      <Responsive desktop>
        <div className={styles['success-block']} data-name="SuccessBlock">
          <UIText2>
            Спасибо за заявку!
            <br /> Риелтор получит её и свяжется с вами{' '}
          </UIText2>
        </div>
      </Responsive>
      <Responsive phone tablet>
        <ModalFullscreen theme="white" open={openSuccessMobileModal} onClose={handleCloseMobileModal}>
          <div className={styles['mobile-content']} data-name="SuccessBlock">
            <UIHeading2>Спасибо за заявку!</UIHeading2>
            <div className={styles['text-wrapper']}>
              <UIText1>Риелтор получит её и свяжется с вами</UIText1>
            </div>
            <Button fullWidth onClick={handleCloseMobileModal} theme="fill_primary">
              Назад
            </Button>
          </div>
        </ModalFullscreen>
      </Responsive>
    </>
  );
};
