import * as React from 'react';

import { Section } from '../../components/common/section';

import * as styles from './index.css';

interface IVacanciesProps {
  vacancy: JSX.Element;
  allVacanciesLink: JSX.Element;
}

export const Vacancies = ({ vacancy, allVacanciesLink }: IVacanciesProps) => {
  return (
    <Section id="vacancies" className={styles['vacancies']}>
      <h2 className={styles['vacancies-title']}>Вакансии специалиста</h2>
      {vacancy}
      {allVacanciesLink}
    </Section>
  );
};
