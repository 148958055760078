// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IAgentModerationInfoSchema } from '../agent_moderation_info/AgentModerationInfoSchema';
import type { ISocialNetworkSchema } from '../social_network_type/SocialNetworkSchema';
import type { ISocialProfilesSchema } from '../social_profile_type/SocialProfilesSchema';

export interface IAgentForCardV2Schema {
  /** Тип аккаунта **/
  accountType?: EAccountType;
  /** Время регистрации на ЦИАН **/
  age: string;
  /** Абсолютный URL до аватара **/
  avatarUrl?: string;
  /** Место работы, если тип агента специалист **/
  companyName?: string;
  /** Контактный email указанный агентом **/
  contactEmail?: string;
  /** Подменный номер телефона **/
  ctPhoneNumber?: string;
  /** "обо мне" профиля агента **/
  description?: string;
  /** Текстовое описание с какого кода работает **/
  experience?: string;
  /** Участвует в акции **/
  hasFairplay: boolean;
  /** Статус участия в РГР **/
  hasRgrParticipation: boolean;
  /** Включены ли чаты **/
  isChatsEnabled: boolean;
  /** Пользователь на тарифе "Застройщик" **/
  isDeveloper?: boolean;
  /** Аватар мастер агента **/
  miniAvatarUrl?: string;
  /** Информация от модерации **/
  moderationInfo?: IAgentModerationInfoSchema;
  /** Имя агента **/
  name?: string;
  /** ID пользователя на Realty **/
  realtyUserId: number;
  /** Регион работы **/
  regionsNames?: string[];
  /** Специализация **/
  skills?: string[];
  /** Skype пользователя **/
  skype?: string;
  /** Социальные сети через ID в них **/
  socialNetworks?: ISocialNetworkSchema[];
  /** Социальные сети через ссылки на профиль **/
  socialProfiles?: ISocialProfilesSchema[];
  /** Статус публикации **/
  status: EStatus;
  /** Статус пользователя **/
  userStatus: EUserStatus;
  /** Ссылка на вебсайт **/
  website?: string;
}
export enum EAccountType {
  /** Агенство **/
  'Agency' = 'agency',
  /** Управляющая компания **/
  'ManagementCompany' = 'managementCompany',
  /** Отдел аренды **/
  'RentDepartment' = 'rentDepartment',
  /** Специалист **/
  'Specialist' = 'specialist',
}
export enum EStatus {
  /** Скрыто **/
  'Hidden' = 'hidden',
  /** Опубликовано **/
  'Published' = 'published',
}
export enum EUserStatus {
  /** Активный **/
  'Active' = 'active',
  /** Заблокированный **/
  'Blocked' = 'blocked',
  /** Удаленный **/
  'Deleted' = 'deleted',
}
