import { connect } from 'react-redux';

import { AnswersList, IAnswersListDispatchProps, IAnswersListOwnProps, IAnswersListStoreProps } from '.';
import { IAppState } from '../../store';
import { edit, open } from '../../store/answer_form';
import { TThunkDispatch } from '../../types/redux';

function mapStateToProps(state: IAppState): IAnswersListStoreProps {
  return {
    answerFormAnswerId: state.answerForm.answerId,
    answerFormReviewId: state.answerForm.reviewId,
    answerFormState: state.answerForm.state,
    realtyUserId: state.user.isAuthenticated ? state.user.realtyUserId : undefined,
    userIsAuthenticated: state.user.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch: TThunkDispatch): IAnswersListDispatchProps {
  return {
    openAnswerForm: (reviewId: string, answerId: string) => dispatch(open(reviewId, answerId)),
    openEditForm: (reviewId: string, answerId: string, text: string) => dispatch(edit(reviewId, answerId, text)),
  };
}

export const AnswersListContainer = connect<IAnswersListStoreProps, IAnswersListDispatchProps, IAnswersListOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(AnswersList);
