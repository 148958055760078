import * as React from 'react';
import { UIText1 } from '@cian/ui-kit/typography';

interface IResponseTimeMobileProps {
  responseTimeText: string;
}

export const ResponseTimeMobile: React.FunctionComponent<IResponseTimeMobileProps> = ({ responseTimeText }) => (
  <UIText1>Время ответа: {responseTimeText}</UIText1>
);
