import * as React from 'react';
import { Ref } from 'react';
import { Stars } from '@cian/stars-component';
import { getFormattedDate } from '@cian/utils/lib/shared/date_time';
import { IStyleArgument, mergeStyles } from '@cian/utils/lib/shared/style';
import { UIHeading4 } from '@cian/ui-kit';
import { NoSsr } from '@cian/react-utils';

import * as styles from './index.css';

export interface IReviewProps {
  reviewId: string;
  containerStyle?: IStyleArgument;
  name: string;
  date: Date;
  rating?: number;
  innerRef?: Ref<HTMLElement>;
  onReplyClick?(): void;
  onEditClick?(): void;
  avatar: JSX.Element | null;
  socnetworks?: JSX.Element[];
  pollButtons: JSX.Element | null;
  textElement: JSX.Element;
}
export const Review: React.FunctionComponent<IReviewProps> = ({
  reviewId,
  containerStyle,
  name,
  date,
  rating,
  onReplyClick,
  onEditClick,
  innerRef,
  avatar,
  socnetworks,
  pollButtons,
  textElement,
}) => (
  <article ref={innerRef} {...mergeStyles(styles['container'], containerStyle)}>
    {avatar && <div className={styles['avatar']}>{avatar}</div>}
    <div className={styles['content']}>
      <header>
        <div className={styles['title']} data-name="ReviewTitle">
          <UIHeading4 title={name}>{name}</UIHeading4>
          {socnetworks
            ? socnetworks.map((element, index) => (
                <div key={index} className={styles['socnetwork']}>
                  {element}
                </div>
              ))
            : null}
          {pollButtons}
        </div>
        <div className={styles['info']} data-name="ReviewInfo">
          <NoSsr>
            {rating && (
              <Stars
                theme="filled"
                containerClassName={styles['stars']}
                unitClassName={styles['star']}
                value={rating}
                uniqueKey={reviewId}
              />
            )}
            <time className={styles['date']} dateTime={date.toISOString()}>
              {getFormattedDate(date)}
            </time>
          </NoSsr>
        </div>
      </header>
      {textElement}
      <footer className={styles['footer']}>
        <div className={styles['buttons']}>
          {onReplyClick && (
            <button type="button" className={styles['reply_button']} onClick={onReplyClick}>
              Ответить
            </button>
          )}
          {onEditClick && (
            <button type="button" className={styles['edit_button']} onClick={onEditClick}>
              Редактировать
            </button>
          )}
        </div>
        {pollButtons}
      </footer>
    </div>
  </article>
);
