import * as React from 'react';
import { IStyleArgument } from '@cian/utils';
import { useSelector } from 'react-redux';

import { Avatar } from '../../components/avatar';
import { Review, Text } from '../../components/review';
import { DeviceType } from '../../types/device';
import { timestampToDate } from '../../utils/time';
import { IReviewModeration } from '../../types/review';
import { selectDeviceType } from '../../selectors/pageData/selectDeviceType';

interface IModerationReviewContainerProps {
  containerStyle?: IStyleArgument;
  moderation: IReviewModeration | undefined;
}

export const ModerationReviewContainer: React.FunctionComponent<IModerationReviewContainerProps> = ({
  containerStyle,
  moderation,
}) => {
  const deviceType = useSelector(selectDeviceType);

  if (!moderation) {
    return null;
  }

  const { comment, reason, date } = moderation;

  if (!reason) {
    return null;
  }

  const noAvatar = deviceType === DeviceType.Phone;
  const avatar = noAvatar ? null : <Avatar src={undefined} />;
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const textElement = <Text text={getText(comment, reason)} />;

  return (
    <Review
      reviewId="moderation-review"
      containerStyle={containerStyle}
      avatar={avatar}
      socnetworks={[]}
      pollButtons={null}
      textElement={textElement}
      name="Модератор"
      date={timestampToDate(date as number)}
    />
  );
};

function getText(comment: string | undefined, reason: string) {
  return comment ? `${reason}. ${comment.charAt(0).toUpperCase()}${comment.substr(1)}` : reason;
}
