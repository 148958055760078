import * as React from 'react';

import { IOffer } from '../../../../api/offer';
import { offerHelper } from '../../../../offer/presenters';

import * as styles from './index.css';

export interface ILeasePeriodProps {
  offer: IOffer;
}

export class LeasePeriod extends React.Component<ILeasePeriodProps, {}> {
  public render() {
    if (this.props.offer.dealType !== 'rent') {
      return null;
    }

    const offer = offerHelper(this.props.offer);
    const leasePeriod = offer.presenters.getLeasePeriod();

    if (leasePeriod) {
      return (
        <div className={styles['lease-period']}>
          {`${leasePeriod.charAt(0).toUpperCase()}${leasePeriod.substring(1)}`}
        </div>
      );
    }

    return null;
  }
}
