import { IAuthenticatedUser } from '../store';

export function canModerateAnnouncements({ permissions }: IAuthenticatedUser): boolean {
  return (permissions && permissions.canModerateAnnouncements) || false;
}

export function canModerateUsers({ permissions }: IAuthenticatedUser): boolean {
  return (permissions && permissions.canModerateUsers) || false;
}

export function canViewUsers({ permissions }: IAuthenticatedUser): boolean {
  return (permissions && permissions.canViewUsers) || false;
}

export function canUseHiddenBase({ permissions }: IAuthenticatedUser): boolean {
  return (permissions && permissions.canUseHiddenBase) || false;
}
