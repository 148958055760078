import { IOffer, LandAreaUnitType } from '../../api/offer';

const landStatus: { [key: string]: string } = {
  farm: 'фермерское хоз-во',
  gardening: 'садоводство',
  gardeningNonProfitPartnership: 'СНТ',
  individualHousingConstruction: 'ИЖС',
  industrialLand: 'земля промназначения',
  investmentProject: 'инвестпроект',
  settlements: 'участок поселений',
  suburbanNonProfitPartnership: 'ДНП',
};

export function suburbanHelper(offer: IOffer) {
  return {
    getLandArea: () => getLandArea(offer),
    getSteadStatus: () => getSteadStatus(offer),
  };
}

function getSteadStatus(offer: IOffer) {
  if (offer.land && offer.land.status && offer.land.status in landStatus) {
    return landStatus[offer.land.status];
  } else {
    return undefined;
  }
}

function getLandArea(offer: IOffer) {
  if (offer.land && offer.land.area) {
    const unitTypes: { [K in LandAreaUnitType]: string } = {
      hectare: 'га',
      sotka: 'сот.',
    };

    return `участок ${Number(offer.land.area)} ${
      (offer.land.areaUnitType && unitTypes[offer.land.areaUnitType]) || 'сот.'
    }`;
  }

  return undefined;
}
