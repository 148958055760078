import * as React from 'react';

import * as styles from './styles.css';

export interface IApprovalNewWrapperProps {
  children: React.ReactNode;
}

export const ApprovalNewWrapper = ({ children }: IApprovalNewWrapperProps) => {
  return <section className={styles['container']}>{children}</section>;
};
