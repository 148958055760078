import { ca } from '@cian/analytics';

export function trackVacancyClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'CardAgents',
    action: 'click_vacancies',
    label: '',
  });
}
