import { ILocation } from '../types/location';
import { ISearchCatalogItemSchema } from '../repositories/geo-temp-layer/entities/catalog_item/SearchCatalogItemSchema';
import { prepareLocation } from './prepareLocation';

export const prepareLocations = (locations?: ISearchCatalogItemSchema[] | null): ILocation[] => {
  if (!locations) {
    return [];
  }

  return locations.map(prepareLocation);
};
