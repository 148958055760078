import { IconSuccess16, TooltipDesktop, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './SuperAgentTooltip.css';

type TSuperAgentLevelView1Props = {
  tooltipText: string;
  children: React.ReactElement;
  onOpenTooltip(): void;
};

export const SuperAgentTooltip = ({ tooltipText, children, onOpenTooltip }: TSuperAgentLevelView1Props) => {
  const key = React.useId();

  return (
    <TooltipDesktop
      theme="white"
      onOpen={onOpenTooltip}
      title={
        <ul className={styles['tooltip']}>
          {tooltipText.split(',').map((textLine, i) => (
            <li key={`${key}${i}`} className={styles['item']}>
              <IconSuccess16 color="success_100" />
              <UIText2>{textLine}</UIText2>
            </li>
          ))}
        </ul>
      }
      placement="bottom"
    >
      <div>{children}</div>
    </TooltipDesktop>
  );
};
