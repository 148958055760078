import { ca } from '@cian/analytics';

export const trackMoreReviewsClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Specialist',
    action: 'Click',
    label: 'more_reviews',
  });
};
