import * as React from 'react';
import { mergeStyles } from '@cian/utils';
import { UIText1 } from '@cian/ui-kit/typography';

import * as styles from './ResponseRateMobile.css';

export type ResponseRateType = 'high' | 'low';

interface IResponseRateMobileProps {
  rateType: ResponseRateType;
  rateValue: number;
}

const getRateStyles = (rateType: ResponseRateType) =>
  mergeStyles(styles['rate'], rateType === 'high' ? styles['rate_high'] : styles['rate_low']);

const getTextColor = (rateType: ResponseRateType) => (rateType === 'high' ? 'success_100' : 'warning_100');

export const ResponseRateMobile: React.FunctionComponent<IResponseRateMobileProps> = ({ rateType, rateValue }) => (
  <div className={styles['container']}>
    <span {...getRateStyles(rateType)}>
      <UIText1 color={getTextColor(rateType)}>{rateValue}%</UIText1>
    </span>
    <span> </span>
    <UIText1>частота ответов</UIText1>
  </div>
);
