import * as React from 'react';
import { FormField, MaskedInput, Responsive, UIText3 } from '@cian/ui-kit';

import { IPersonalLeadFormProps } from '../../../index';
import { IPersonalLeadFormData } from '../../../../../store/personal_leads_form';

import * as styles from '../styles.css';

export interface IPhoneProps {
  fieldData: IPersonalLeadFormData['phone'];
  onChange: IPersonalLeadFormProps['handleSetPhone'];
  onBlur: IPersonalLeadFormProps['handleBlurPhone'];
}

export const Phone = ({ fieldData, onChange, onBlur }: IPhoneProps) => {
  return (
    <FormField
      withoutMargin
      required
      label="Номер для связи"
      errorMessage={fieldData.error ? 'Введите ваш номер' : undefined}
    >
      <div className={styles['phone-field-container']}>
        <Responsive desktop>
          <UIText3 as="span" color="gray40_100">
            Мы не спамим и не передаем номера телефонов третьим лицам
          </UIText3>
        </Responsive>
        <MaskedInput
          invalid={fieldData.error}
          value={fieldData.value}
          onChange={(value: string) => {
            onChange(value);
          }}
          onBlur={onBlur}
          mask="+7 000 000-00-00"
          placeholder="+7"
        />
      </div>
    </FormField>
  );
};
