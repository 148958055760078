/**
 * Будет заменен новым компонентом при редизайне боковой карточки
 */

import * as React from 'react';
import { useSelector } from 'react-redux';

import { Approval } from '../../components/Approval';
import { WORK_HONESTLY_MESSAGES } from '../../constants';
import { selectHasVerifiedDocuments, selectAgentLevelInfo } from '../../selectors';

export const ApprovalContainer = () => {
  const agentLevelInfo = useSelector(selectAgentLevelInfo);
  const hasVerifiedDocuments = useSelector(selectHasVerifiedDocuments);

  if (agentLevelInfo || !hasVerifiedDocuments) {
    return null;
  }

  return <Approval message={WORK_HONESTLY_MESSAGES.agent} />;
};
