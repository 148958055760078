import { IStyleProps, mergeStyles } from '@cian/utils';
import * as React from 'react';

import style from './index.css';

export interface IPromoInfoProps {
  name: string;
  href: string;
  isSpecProject?: boolean;
  containerStyle?: IStyleProps | string;
}

export class PromoInfo extends React.Component<IPromoInfoProps> {
  public static defaultProps: IPromoInfoProps = {
    href: '',
    isSpecProject: false,
    name: '',
  };

  public render() {
    return (
      <a href={this.props.href} target="_blank" rel="noreferrer">
        <div
          {...mergeStyles(
            style['promo-info'],
            !this.props.isSpecProject && style['promo-sale-info'],
            this.props.containerStyle,
          )}
        >
          <span className={style['promo-info-text']}>{this.props.name}</span>
        </div>
      </a>
    );
  }
}
