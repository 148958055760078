/* eslint-disable react/forbid-elements */
import * as React from 'react';

export const ExperienceIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1H1H29H30V2V19H32C33.1046 19 34 19.8954 34 21V23H38C39.1046 23 40 23.8954 40 25V38C40 39.1046 39.1046 40 38 40H29H19H18H1H0V39V2V1ZM28 19H26C24.8954 19 24 19.8954 24 21V23H20C18.8954 23 18 23.8954 18 25V38H2V3H28V19ZM32 21V23H26V21H32ZM20 25H26H32H38V30H32C32 28.8954 31.1046 28 30 28H28C26.8954 28 26 28.8954 26 30H20V25ZM26 32H20V38H29H38V32H32C32 33.1046 31.1046 34 30 34H28C26.8954 34 26 33.1046 26 32ZM4 5H5H17H18V6V18V19H17H5H4V18V6V5ZM6 14.6822V7H16V14.6822C15.3961 13.7739 14.5512 13.0395 13.5566 12.5704C13.8378 12.1136 14 11.5757 14 11C14 9.34315 12.6569 8 11 8C9.34315 8 8 9.34315 8 11C8 11.5757 8.16219 12.1136 8.44336 12.5704C7.44881 13.0395 6.60394 13.7739 6 14.6822ZM12 11C12 11.5523 11.5523 12 11 12C10.4477 12 10 11.5523 10 11C10 10.4477 10.4477 10 11 10C11.5523 10 12 10.4477 12 11ZM7.12602 17C7.57006 15.2748 9.13616 14 11 14C12.8638 14 14.4299 15.2748 14.874 17H7.12602ZM4 23H15V25H4V23ZM4 27V29H12V27H4ZM28 32V30H30V32H28Z"
      fill="#737A8E"
    />
  </svg>
);
