import { plural } from '@cian/utils/lib/shared/plural';
import * as React from 'react';

import { IUnderground } from '../../../../api/offer';
import { UndergroundIcon } from '../../../underground_icon';

const style = require('./index.css');

export interface IUndergroundProps {
  url: string;
  locationId?: number;
  underground: IUnderground;
  onClick?(): void;
}

export const Underground: React.FunctionComponent<IUndergroundProps> = ({ url, locationId, underground, onClick }) => {
  return (
    <div className={style['underground']}>
      <div className={style['underground-ico']}>
        <UndergroundIcon width={16} height={16} color={`#${underground.lineColor}`} regionId={locationId} />
      </div>
      <a href={url} className={style['underground-header']} target="_blank" onClick={onClick} rel="noreferrer">
        {underground.name}
      </a>

      {underground.time > 0 ? (
        <div className={style['underground-distance']}>
          {underground.time}&nbsp;
          {plural(underground.time, ['минута', 'минуты', 'минут'])}&nbsp;
          {underground.transportType === 'walk' ? 'пешком' : 'на транспорте'}
        </div>
      ) : (
        <div className={style['underground-distance']}>
          &lt;1 минуты&nbsp;
          {underground.transportType === 'walk' ? 'пешком' : 'на транспорте'}
        </div>
      )}
    </div>
  );
};
