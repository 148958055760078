import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  AboutRealtorMobileSection,
  ExperienceIcon,
  SkillsIcon,
  RegionIcon,
} from '../../../../components/aboutRealtor/mobile';
import { selectRealtor } from '../../../../selectors/realtor/selectRealtor';

export const AboutRealtorAdditionalInfoContainer: React.FunctionComponent<{}> = () => {
  const { experience, skills, regionsNames } = useSelector(selectRealtor);

  return (
    <>
      {!!experience && (
        <AboutRealtorMobileSection pictureElement={<ExperienceIcon />} title="Опыт работы" text={experience} />
      )}
      {!!(skills && skills.length) && (
        <AboutRealtorMobileSection pictureElement={<SkillsIcon />} title="Специализация" text={skills.join('. ')} />
      )}
      {!!(regionsNames && regionsNames.length) && (
        <AboutRealtorMobileSection
          pictureElement={<RegionIcon />}
          title="Регион работы"
          text={regionsNames.join(', ')}
        />
      )}
    </>
  );
};
