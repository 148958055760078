import { mergeStyles } from '@cian/utils/lib/shared/style';
import * as React from 'react';

const style = require('./index.css');

export type TAddressPathItem = 'address' | 'metro';

interface IAddresPathProps {
  label: string;
  qs: string;
  type: TAddressPathItem;
  url?: string;
  className?: string;
}

export class AddressPathItem extends React.Component<IAddresPathProps> {
  public render() {
    const { label, qs } = this.props;

    return (
      <span key={`offer-card-${qs}`} {...mergeStyles([style['item'], this.props.className])}>
        {label}
      </span>
    );
  }
}
