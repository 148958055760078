import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectRealtorRealtyUserId } from '../../selectors';

export const useSrcChat = () => {
  const realtyUserId = useSelector(selectRealtorRealtyUserId);

  return useMemo(() => {
    if (!realtyUserId) {
      return null;
    }

    const src = `/dialogs/?hostType=frame&agentId=${realtyUserId}`;

    return src;
  }, [realtyUserId]);
};
