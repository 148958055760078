import { ca } from '@cian/analytics';

import { EDealTypeEx, EObjectType } from '../../repositories/demand/v2/create-personal-message';
import { TDeviceType } from '../../types/device';
import { ILocation } from '../../types/location';

export interface IAnswers {
  leadType?: EDealTypeEx;
  objectType?: EObjectType;
  location?: ILocation;
  name: string;
  comment: string;
  phone: string;
}

interface ITrackProps {
  realtorId: number;
  deviceType: TDeviceType;
  answers: IAnswers;
}

interface ITrackingEventProps extends ITrackProps {
  action: string;
  label?: string;
  anonymousUserId: string | null;
}

const prepareData = (nameValue: string, commentValue: string, phoneValue: string) => {
  const name = nameValue || undefined;
  const phone = phoneValue ? 'phone' : undefined;
  const comment = commentValue ? 'comment' : undefined;

  return {
    name,
    phone,
    comment,
  };
};

export const trackSelectRegion =
  ({ realtorId, answers, deviceType, action, anonymousUserId }: ITrackingEventProps) =>
  (region: number) => {
    const { leadType, objectType, name: nameValue, comment: commentValue, phone: phoneValue } = answers;
    const { name, phone, comment } = prepareData(nameValue, commentValue, phoneValue);

    ca('eventSite', {
      name: 'oldevent',
      category: 'Personal_request_to_realtor',
      action: `Click_${action}_sopr`,
      label: region,
      page: {
        pageType: 'Personal_request_from',
        siteType: deviceType === 'desktop' ? 'desktop' : 'mobile',
        extra: {
          funnel: `${leadType}/${objectType}/${region}/${name}/${phone}/${comment}`,
          type: anonymousUserId,
        },
      },
      products: [
        {
          id: realtorId,
          offerType: 'agent',
        },
      ],
    });
  };

export const trackingEvent = ({
  realtorId,
  answers,
  deviceType,
  action,
  label,
  anonymousUserId,
}: ITrackingEventProps) => {
  const { leadType, objectType, location, name: nameValue, comment: commentValue, phone: phoneValue } = answers;
  const { name, phone, comment } = prepareData(nameValue, commentValue, phoneValue);

  ca('eventSite', {
    name: 'oldevent',
    category: 'Personal_request_to_realtor',
    action,
    label,
    page: {
      pageType: 'Personal_request_from',
      siteType: deviceType === 'desktop' ? 'desktop' : 'mobile',
      extra: {
        funnel: `${leadType}/${objectType}/${location?.id}/${name}/${phone}/${comment}`,
        type: anonymousUserId,
      },
    },
    products: [
      {
        id: realtorId,
        offerType: 'agent',
      },
    ],
  });
};
