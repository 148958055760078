import * as React from 'react';

import { IMoreButtonContainerProps, getCianType } from '../index';
import { Button } from '../../../../../components/button';
import { ReportPopup } from '../report_popup';

import * as styles from '../index.css';

type MoreButtonContentProps = Pick<
  IMoreButtonContainerProps,
  | 'offer'
  | 'realtyUserId'
  | 'userCanModerateAnnouncements'
  | 'userCanModerateUsers'
  | 'userCanViewUsers'
  | 'onDocLinkClicked'
  | 'onPDFLinkClicked'
> & {
  isReporting: boolean;
  onReportClosed: () => void;
  onReportClicked?: () => void;
};

export const MoreButtonContent = React.forwardRef<HTMLDivElement, MoreButtonContentProps>(
  (
    {
      offer,
      realtyUserId,
      userCanModerateAnnouncements,
      userCanModerateUsers,
      userCanViewUsers,
      isReporting,
      onDocLinkClicked,
      onPDFLinkClicked,
      onReportClicked,
      onReportClosed,
    },
    ref,
  ) => {
    const userIsModerator = userCanModerateAnnouncements || userCanModerateUsers || userCanViewUsers;
    const userIsOwner = (!offer.isImported && realtyUserId && realtyUserId === offer.userId) || false;

    if (isReporting) {
      return (
        <div ref={ref} className={styles['content']}>
          <ReportPopup offer={offer} onClose={onReportClosed} />
        </div>
      );
    }

    return (
      <div ref={ref} className={styles['content']}>
        <div>
          {userIsModerator && (
            <div className={styles['popup']}>
              <ul className={styles['links']}>
                {userCanModerateAnnouncements && (
                  <li className={styles['links-link']}>
                    <a
                      rel="noreferrer"
                      // tslint:disable-next-line:max-line-length
                      href={`https://service.cian.ru/moderate-announcements/index?AnnFilter.OfferType=${offer.dealType}&AnnFilter.Author=${offer.user.cianUserId}&AnnFilter.selectType=All`}
                      target="_blank"
                    >
                      Проверять
                    </a>
                  </li>
                )}
                {userCanModerateAnnouncements && (
                  <li className={styles['links-link']}>
                    <a
                      rel="noreferrer"
                      // tslint:disable-next-line:max-line-length
                      href={`https://service.cian.ru/moderate-announcements/index?AnnFilter.CianObjectId=${offer.cianId}`}
                      target="_blank"
                    >
                      Объявление
                    </a>
                  </li>
                )}
                {userCanModerateUsers && (
                  <li className={styles['links-link']}>
                    <a
                      rel="noreferrer"
                      href={`https://service.cian.ru/customers/edit/${offer.user.cianUserId}`}
                      target="_blank"
                    >
                      Редактировать клиента
                    </a>
                  </li>
                )}
                {userCanViewUsers && (
                  <li className={styles['links-link']}>
                    <a
                      rel="noreferrer"
                      href={`https://service.cian.ru/customers/view/${offer.user.cianUserId}`}
                      target="_blank"
                    >
                      Клиент
                    </a>
                  </li>
                )}
              </ul>
            </div>
          )}
          {userIsModerator && <hr className={styles['delimiter']} />}
          {userIsOwner && (
            <ul className={styles['links']}>
              <li className={styles['links-link']}>
                <a
                  rel="noreferrer"
                  href={`/realty/add1.aspx?cianId=${offer.cianId}&cianType=${getCianType(
                    offer.dealType,
                    offer.offerType,
                  )}`}
                  target="_blank"
                >
                  Редактировать
                </a>
              </li>
              <li className={styles['links-link']}>
                <a
                  rel="noreferrer"
                  href={`/realty/add2.aspx?cianId=${offer.cianId}&cianType=${getCianType(
                    offer.dealType,
                    offer.offerType,
                  )}`}
                  target="_blank"
                >
                  Добавить фото
                </a>
              </li>
              <li className={styles['links-link']}>
                <a rel="noreferrer" href={`https://my.cian.ru/journal?offer_id=${offer.cianId}`} target="_blank">
                  История
                </a>
              </li>
            </ul>
          )}
          {userIsOwner && <hr className={styles['delimiter']} />}
          <ul className={styles['links']}>
            <li className={styles['links-link']}>
              <a
                rel="noreferrer"
                href={offer.fullUrl.replace('.ru/', '.ru/export/pdf/')}
                target="_blank"
                onClick={onDocLinkClicked}
              >
                Скачать в PDF
              </a>
            </li>
            <li className={styles['links-link']}>
              <a
                rel="noreferrer"
                href={offer.fullUrl.replace('.ru/', '.ru/export/docx/')}
                target="_blank"
                onClick={onPDFLinkClicked}
              >
                Скачать в DOC
              </a>
            </li>
          </ul>
          <hr className={styles['delimiter']} />
          <div className={styles['report']}>
            <Button theme="danger" mode="light" onClick={onReportClicked}>
              Пожаловаться
            </Button>
          </div>
        </div>
      </div>
    );
  },
);

MoreButtonContent.displayName = 'MoreButtonContent';
