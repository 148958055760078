import { UIHeading2 } from '@cian/ui-kit/typography';
import * as React from 'react';
import { mergeStyles } from '@cian/utils';

import * as styles from './contactsMobile.css';

interface IProps {
  className?: string;
  children: React.ReactNode;
}

export const ContactsMobile: React.FunctionComponent<IProps> = ({ className, children }) => {
  return (
    <div {...mergeStyles(styles['container'], className)}>
      <div className={styles['header']}>
        <UIHeading2>Контакты</UIHeading2>
      </div>
      {React.Children.map(children, child => !!child && <div className={styles['item']}>{child}</div>)}
    </div>
  );
};
