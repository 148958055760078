import { IAgentCard } from '../../../types/agentCard';

export const getWebsiteHref = (website: string) => (website.startsWith('http') ? website : `http://${website}`);

export const getActivityCounter = (questionsCount: number, blogsCount: number, advicesCount: number) =>
  questionsCount + blogsCount + advicesCount;

export const getRieltorDescription = ({
  descriptionText,
  skills,
  regionsNames,
  companyName,
  onDescriptionOpen,
}: {
  descriptionText?: string;
  skills?: IAgentCard['skills'];
  regionsNames?: IAgentCard['regionsNames'];
  companyName?: IAgentCard['companyName'];
  onDescriptionOpen?(): void;
}) => {
  const blocks = [];

  if (descriptionText) {
    blocks.push({ label: 'О себе', text: descriptionText, action: onDescriptionOpen });
  }

  if (skills && skills.length > 0) {
    blocks.push({ label: 'Специализация', text: skills.join(', ') });
  }

  if (regionsNames && regionsNames.length > 0) {
    blocks.push({ label: 'Регион работы', text: regionsNames.join(', ') });
  }

  if (companyName) {
    blocks.push({ label: 'Агентство', text: companyName });
  }

  return blocks;
};
