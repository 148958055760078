/* eslint-disable react/forbid-elements */
import * as React from 'react';

import * as styles from './index.css';

export interface IReportPopupPrevButtonProps {
  onClick(): void;
}

export const ReportPopupPrevButton = ({ onClick }: IReportPopupPrevButtonProps) => (
  <button className={styles['button_back']} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.925 10" width="6" height="10">
      {/* tslint:disable-next-line:max-line-length */}
      <path
        fill="currentColor"
        d="M.29 4.342L4.297.182c.474-.324 1.12-.204 1.446.27.23.334.244.77.037 1.12L2.495 5.06 5.78 8.43c.29.496.126 1.134-.37 1.425-.347.203-.78.19-1.113-.038L.29 5.66C.13 5.496.03 5.287 0 5.062c0-.27.104-.527.29-.72z"
      />
    </svg>
  </button>
);
