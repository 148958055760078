import * as React from 'react';
import { FormField, SelectDesktop } from '@cian/ui-kit';

import { EObjectType } from '../../../../../repositories/demand/v2/create-personal-message';
import { IPersonalLeadFormProps } from '../../../index';
import { IPersonalLeadFormData } from '../../../../../store/personal_leads_form';

export interface IObjectTypeProps {
  fieldData: IPersonalLeadFormData['objectType'];
  onChange: IPersonalLeadFormProps['handleSetObjectType'];
}

export const ObjectType = ({ fieldData, onChange }: IObjectTypeProps) => {
  return (
    <FormField
      withoutMargin
      required
      label="Какую недвижимость?"
      errorMessage={fieldData.error ? 'Выберите тип недвижимости' : undefined}
    >
      <SelectDesktop
        invalid={fieldData.error}
        onChange={onChange}
        value={fieldData.value}
        title="Тип недвижимости"
        options={[
          {
            label: 'Квартира',
            value: EObjectType.Flat,
          },
          {
            label: 'Дом/дача',
            value: EObjectType.House,
          },
          {
            label: 'Коттедж',
            value: EObjectType.Cottage,
          },
          {
            label: 'Таунхаус',
            value: EObjectType.Townhouse,
          },
          {
            label: 'Часть дома',
            value: EObjectType.HouseShare,
          },
          {
            label: 'Участок',
            value: EObjectType.Land,
          },
          {
            label: 'Комната',
            value: EObjectType.Room,
          },
          {
            label: 'Офис',
            value: EObjectType.Office,
          },
          {
            label: 'Торговая площадь',
            value: EObjectType.ShoppingArea,
          },
          {
            label: 'Помещение свободного назначения',
            value: EObjectType.FreeAppointmentObject,
          },
          {
            label: 'Производство',
            value: EObjectType.Industry,
          },
          {
            label: 'Склад',
            value: EObjectType.Warehouse,
          },
          {
            label: 'Гараж',
            value: EObjectType.Garage,
          },
          {
            label: 'Коммерческая земля',
            value: EObjectType.CommercialLand,
          },
        ]}
      />
    </FormField>
  );
};
