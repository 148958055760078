import * as React from 'react';
import { useSelector } from 'react-redux';

import { RateMobile } from '../../../components/rate';
import { formatRate, convertToFivePointValue } from '../../../components/aside_general_info/utils/formatRate';
import { selectReviews } from '../../../selectors/reviews/selectReviews';

export const RateMobileContainer: React.FunctionComponent<{}> = () => {
  const { entityRate, totalCount } = useSelector(selectReviews);

  if (typeof entityRate !== 'number') {
    return null;
  }

  if (totalCount === 0) {
    return null;
  }

  if (convertToFivePointValue(entityRate) < 1) {
    return null;
  }

  return <RateMobile rate={formatRate(entityRate)} />;
};
