import { connect } from 'react-redux';

import { IAppState } from '../../store';
import { hideMotivationPopup } from '../../store/login_motivation_popup';
import {
  ILoginMotivationPopupDispatchProps,
  ILoginMotivationPopupOwnProps,
  ILoginMotivationPopupStoreProps,
  LoginMotivationPopup,
} from './index';
import { TThunkDispatch } from '../../types/redux';

export const LoginMotivationPopupContainer = connect<
  ILoginMotivationPopupStoreProps,
  ILoginMotivationPopupDispatchProps,
  ILoginMotivationPopupOwnProps
>(
  (state: IAppState) => ({
    shownId: state.loginMotivationPopupOnFavorite.shownId,
  }),
  (dispatch: TThunkDispatch) => ({
    closePopup: () => {
      // @ts-ignore @FIXME:
      dispatch(hideMotivationPopup());
    },
  }),
)(LoginMotivationPopup);
