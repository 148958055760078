import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsChatModalOpen } from '../../selectors/chat/selectIsChatModalOpen';
import { closeChatModal } from '../../store/chat_modal';
import { ChatModal } from '../../components/ChatModal';
import { useSrcChat } from '../../hooks/useChats';

export const ChatModalContainer = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(selectIsChatModalOpen);
  const src = useSrcChat();

  const onClose = React.useCallback(() => {
    dispatch(closeChatModal());
  }, [dispatch]);

  return <ChatModal isOpen={isOpen} src={src} onClose={onClose} />;
};
