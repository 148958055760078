import { connect } from 'react-redux';

import { AnswerForm, IAnswerFormDispatchProps, IAnswerFormStoreProps } from '.';
import { IAppState } from '../../store';
import { changeText, clickSubmitButton, reset, submit } from '../../store/answer_form';
import { TThunkDispatch } from '../../types/redux';

function mapStateToProps(state: IAppState): IAnswerFormStoreProps {
  return {
    edit: state.answerForm.edit,
    errorMessage: state.answerForm.errorMessage,
    state: state.answerForm.state,
    text: state.answerForm.text,
    textErrorMarked: state.answerForm.textErrorMarked,
    user: state.user,
    deviceType: state.pageData.deviceType,
  };
}

function mapDispatchToProps(dispatch: TThunkDispatch): IAnswerFormDispatchProps {
  return {
    changeText: (text: string) => dispatch(changeText(text)),
    clickSubmitButton: () => dispatch(clickSubmitButton()),
    reset: () => dispatch(reset()),
    submit: () => dispatch(submit()),
  };
}

export const AnswerFormContainer = connect<IAnswerFormStoreProps, IAnswerFormDispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps,
)(AnswerForm);
