import { setCookie } from '@cian/utils/lib/browser/cookie';

import { TThunkDispatch } from '../types/redux';

export interface ILoginMotivationPopupState {
  isActive: boolean;
  shownId?: number;
}

export interface IShowMotivationPopupAction {
  type: 'serp/login_motivation_popup/SHOWED_POPUP';
  offerId: number;
}

export interface IHideMotivationPopupAction {
  type: 'serp/login_motivation_popup/HIDDEN_POPUP';
}

export function showMotivationPopup(offerId: number) {
  return (dispatch: TThunkDispatch) => {
    setCookie('reviews_login_motivation_popup', '1', {
      domain: '.cian.ru',
      secure: false,
    });

    dispatch({ type: 'serp/login_motivation_popup/SHOWED_POPUP', offerId });
  };
}

export function hideMotivationPopup() {
  return (dispatch: TThunkDispatch) => {
    dispatch({
      type: 'serp/login_motivation_popup/HIDDEN_POPUP',
    });
  };
}

export type TMotivationPopupActions = IShowMotivationPopupAction | IHideMotivationPopupAction;

export const initialState = {
  isActive: true,
  shownId: undefined,
};

export function showMotivationPopupReducer(
  state: ILoginMotivationPopupState = initialState,
  action: TMotivationPopupActions,
): ILoginMotivationPopupState {
  switch (action.type) {
    case 'serp/login_motivation_popup/SHOWED_POPUP':
      return {
        ...state,
        isActive: false,
        shownId: action.offerId,
      };
    case 'serp/login_motivation_popup/HIDDEN_POPUP':
      return {
        ...state,
        shownId: undefined,
      };
    default:
      return state;
  }
}
