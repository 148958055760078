import * as React from 'react';

import { stopPropagation } from '../../utils/stopPropagation';

import * as styles from './styles.css';

export const RgrTooltip = () => {
  return (
    <div className={styles['container']} onClick={stopPropagation}>
      <h4 className={styles['title']}>Агент входит в РГР</h4>

      <a
        className={styles['link']}
        href="https://hc.cian.ru/hc/ru/articles/4409480755348"
        target="_blank"
        rel="noreferrer"
      >
        Подробнее
      </a>
      <div className={styles['content']}>
        Стандарты качества услуг агента сертифицированы, деятельность застрахована
      </div>
    </div>
  );
};
