import * as React from 'react';
import { UIText2 } from '@cian/ui-kit';
import { Stars } from '@cian/stars-component';

import * as styles from './AboutRealtorDesktop.css';

interface IRatingProps {
  text: string;
  value: number;
}

export const Rating = ({ text, value }: IRatingProps) => {
  return (
    <div className={styles['rating']}>
      <Stars percentage containerClassName={styles['rate-value']} value={value} uniqueKey="realtor-rating" />
      <div className={styles['rating-desctiption']}>
        <UIText2>{text}</UIText2>
      </div>
    </div>
  );
};
