import * as React from 'react';
import { UIHeading4, UIText1, IconPhone16, UIText2 } from '@cian/ui-kit';

import { SocialProfiles } from './SocialProfiles';
import { SocialItem } from './SocialItem';
import { IAgentCard, ISocialProfiles } from '../../types/agentCard';

import * as styles from './RealtorContacts.css';

interface IRealtorContactsProps {
  fullPhone: string | undefined;
  formattedPhone: string;
  hiddenPhone: string;
  socialProfiles: ISocialProfiles[];
  buttonPhone?: string;
  name: IAgentCard['name'];
  skype: IAgentCard['skype'];
  email: IAgentCard['contactEmail'];
  website: IAgentCard['website'];
  isPhoneMinimized: boolean;
  onPhoneClick(): void;
  isInfoAboutCalltrackingEnabled: boolean;
}

export const RealtorContacts = ({
  fullPhone,
  formattedPhone,
  hiddenPhone,
  socialProfiles,
  name,
  skype,
  email,
  website,
  isPhoneMinimized,
  onPhoneClick,
  isInfoAboutCalltrackingEnabled,
}: IRealtorContactsProps) => {
  const showSocialProfiles = React.useMemo(() => socialProfiles.length > 0, [socialProfiles.length]);

  if (!showSocialProfiles && !fullPhone && !email && !website) {
    return null;
  }

  return (
    <div id="realtor-contacts" className={styles['realtor-contacts']}>
      <header className={styles['realtor-contacts-header']}>
        <UIHeading4>Контакты</UIHeading4>
      </header>
      <div className={styles['container']}>
        {fullPhone && (
          <div className={styles['phone-container']} data-name="RealtorContactsPhone">
            <IconPhone16 color="gray60_100" />
            {isPhoneMinimized ? (
              <div className={styles['hidden-phone']} onClick={onPhoneClick}>
                <UIText1>{`${hiddenPhone}`}</UIText1>
                <UIText1 color="primary_100"> Показать</UIText1>
              </div>
            ) : (
              <div className={styles['full-phone']}>
                <a href={`tel:${fullPhone}`} className={styles['phone']} data-name="RealtorContactsLink">
                  <UIText1> {formattedPhone}</UIText1>
                </a>
                {isInfoAboutCalltrackingEnabled && (
                  <div className={styles['calltracking-info']}>
                    <UIText2>Номер только для звонков, сообщения не дойдут</UIText2>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <div className={styles['info']} data-name="RealtorContactsInfo">
          {showSocialProfiles && <SocialProfiles socialProfiles={socialProfiles} name={name} />}
          {skype && <SocialItem type="skype" text={skype} url={`skype:${skype}`} />}
          {email && <SocialItem type="email" text={email} url={`mailto:${email}`} />}
          {website && <SocialItem type="website" text={website} />}
        </div>
      </div>
    </div>
  );
};
