import { connect } from 'react-redux';

import { IReviewFormDispatchProps, IReviewFormStoreProps, ReviewForm } from '.';
import { IAppState } from '../../store';
import { changeRating, changeText, clickSubmitButton, open, reset, submit } from '../../store/review_form';
import { TThunkDispatch } from '../../types/redux';

function mapStateToProps(state: IAppState): IReviewFormStoreProps {
  return {
    edit: state.reviewForm.edit,
    errorMessage: state.reviewForm.errorMessage,
    lastOpen: state.reviewForm.lastOpen,
    rating: state.reviewForm.rating,
    realtorRealtyUserId: state.realtor.realtyUserId,
    reviewsTotalCount: state.reviews.totalCount,
    state: state.reviewForm.state,
    text: state.reviewForm.text,
    textErrorMarked: state.reviewForm.textErrorMarked,
    user: state.user,
    deviceType: state.pageData.deviceType,
  };
}

function mapDispatchToProps(dispatch: TThunkDispatch): IReviewFormDispatchProps {
  return {
    changeRating: (rating: number) => dispatch(changeRating(rating)),
    changeText: (text: string) => dispatch(changeText(text)),
    clickSubmitButton: () => dispatch(clickSubmitButton()),
    open: () => dispatch(open()),
    reset: () => dispatch(reset()),
    submit: () => dispatch(submit()),
  };
}

export const ReviewFormContainer = connect<IReviewFormStoreProps, IReviewFormDispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps,
)(ReviewForm);
