export function isStickySupported() {
  const testNode = document.createElement('div');
  if (
    ['', '-webkit-', '-moz-', '-ms-'].some(prefix => {
      try {
        // tslint:disable-next-line:no-conditional-assignment
        testNode.style.position = prefix + 'sticky';
      } catch (e) {
        /* empty */
      }

      return testNode.style.position !== '';
    })
  ) {
    return true;
  }

  return false;
}

export function isStickyPossible() {
  if (typeof window.getComputedStyle !== 'undefined') {
    return true;
  }

  return false;
}
