import * as React from 'react';
import { useSelector } from 'react-redux';
import { Responsive } from '@cian/ui-kit';
import { defaultTo } from 'ramda';

import { selectRealtor } from '../../../selectors/realtor/selectRealtor';
import { AboutRealtorMobile } from '../../../components/aboutRealtor/mobile';
import { GeneralInfoMobile } from '../../../components/generalInfo';
import { ChatStatisticsMobileContainer } from '../../chatStatistics/mobile';
import { PhotoMobile } from '../../../components/photo';
import { RateMobileContainer } from '../../rate/mobile';
import { selectChatStatistics } from '../../../selectors/chatStatistics/selectChatStatistics';
import { AboutRealtorAdditionalInfoContainer } from './additionalInfo';
import { getRateType } from '../../chatStatistics/utils';
import { getSuperAgentBadge } from '../../../components/SuperAgentLevelViewContainer';

interface IAboutRealtorMobileContainerProps {
  headerBar: JSX.Element;
  className?: string;
}

export const AboutRealtorMobileContainer: React.FunctionComponent<IAboutRealtorMobileContainerProps> = ({
  headerBar,
  className,
}) => {
  const { name, companyName, age, hasFairplay, avatarUrl } = useSelector(selectRealtor);
  const { responseRate } = useSelector(selectChatStatistics);
  const { moderationInfo } = useSelector(selectRealtor);
  const responseRateType = responseRate ? getRateType(responseRate) : null;
  const badge = getSuperAgentBadge(defaultTo(null, moderationInfo));

  return (
    <AboutRealtorMobile
      headerBar={headerBar}
      responseRateType={responseRateType}
      generalInfo={<GeneralInfoMobile name={name} companyName={companyName} age={age} />}
      chatStatistics={<ChatStatisticsMobileContainer />}
      photo={
        <>
          <Responsive phone>
            <PhotoMobile size={70} url={avatarUrl} hasFairplay={hasFairplay} />
          </Responsive>
          <Responsive tablet>
            <PhotoMobile size={100} url={avatarUrl} hasFairplay={hasFairplay} />
          </Responsive>
        </>
      }
      rate={<RateMobileContainer />}
      additionalInfo={<AboutRealtorAdditionalInfoContainer />}
      className={className}
      badge={badge}
    />
  );
};
