// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IPutAnswerRequest,
  TPutAnswerModel,
  IMappers,
  TPutAnswerResponse,
  IPutAnswerResponse,
  IPutAnswerResponseError,
} from './types';

export const defaultConfig: TPutAnswerModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'reviews',
  pathApi: '/v3/put-answer/',
  requestType: 'json',
} as TPutAnswerModel;

export function createPutAnswerModel(parameters: IPutAnswerRequest): TPutAnswerModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IPutAnswerOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IPutAnswerRequest;
}

export async function fetchPutAnswer<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IPutAnswerOptions<TResponse200, TResponse400>): Promise<TPutAnswerResponse | TResponse200 | TResponse400> {
  const { statusCode, response, headers } = await httpApi.fetch(createPutAnswerModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IPutAnswerResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IPutAnswerResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TPutAnswerResponse;
}
