import * as React from 'react';
import { mergeStyles } from '@cian/utils';

const style = require('./index.css');

export interface IHiddenBaseLabelProps {
  containerStyle?: React.CSSProperties | string;
}

export const HiddenBaseLabel = (props: IHiddenBaseLabelProps) => {
  return (
    <div {...mergeStyles(props.containerStyle)}>
      <div className={style['label']}>
        <div className={style['lock']}></div>
        Видят только агенты
      </div>
    </div>
  );
};
