import { UIText1 } from '@cian/ui-kit';
import { plural } from '@cian/utils';
import * as React from 'react';

interface IProps {
  count: number;
  onClick: () => void;
}

export const LoadMoreMobile: React.FunctionComponent<IProps> = ({ count, onClick }) => (
  <UIText1 color="primary_100" onClick={onClick}>
    Ещё {count} {plural(count, ['отзыв', 'отзыва', 'отзывов'])}
  </UIText1>
);
