import { ca } from '@cian/analytics';

export const trackPhonesOpen = (id: number) => {
  ca('eventSite', {
    action: 'open_specialist',
    category: 'Phones',
    label: 'agent',
    name: 'oldevent',
    page: {
      pageType: 'CardAgent',
      siteType: 'mobile',
      extra: {
        label: 'call',
      },
    },
    products: [
      {
        id,
        offerType: 'agent',
      },
    ],
  });
};
