import * as React from 'react';
import { Outside, DropdownPopover } from '@cian/ui-kit';
import { IStyleConfig, mergeStyles } from '@cian/utils';

import { IOffer } from '../../../../api/offer';
import { offerHelper } from '../../../../offer/presenters';
import { PopupContent, UserInfoContent } from './components';

import * as style from './index.css';

export interface IUserInfoProps {
  offer: IOffer;
  userIsAuthenticated: boolean;
  content: React.ReactNode;
  avatar?: React.ReactNode | null;
  containerStyle?: IStyleConfig;
  userInfoStyle?: IStyleConfig;
  userInfoStyleAction?: IStyleConfig;
  isReverseOrder?: boolean;
  baseUrl?: string;

  onPopupOpen?(): void;
  onPopupClose?(): void;
}

export interface IUserInfoState {
  isPopupVisible: boolean;
}

export class UserInfoPopup extends React.Component<IUserInfoProps, IUserInfoState> {
  public state = {
    isPopupVisible: false,
  };

  public componentDidUpdate(prevProps: IUserInfoProps, prevState: IUserInfoState) {
    if (prevState.isPopupVisible !== this.state.isPopupVisible) {
      if (this.state.isPopupVisible) {
        if (this.props.onPopupOpen) {
          this.props.onPopupOpen();
        }
      } else {
        if (this.props.onPopupClose) {
          this.props.onPopupClose();
        }
      }
    }
  }

  public render() {
    const { avatar, content, userIsAuthenticated, userInfoStyle, userInfoStyleAction, isReverseOrder } = this.props;
    const { isPopupVisible } = this.state;

    const offer = offerHelper(this.props.offer);

    const userId = offer.presenters.getCianUserId();

    return (
      <div
        {...mergeStyles([style['container'], isReverseOrder && style['container-reverse'], this.props.containerStyle])}
      >
        <Outside onOutside={this.handleOutsideClick}>
          <DropdownPopover
            open={isPopupVisible}
            flip={false}
            sameWidth="minWidth"
            content={
              <PopupContent
                isReverseOrder={Boolean(isReverseOrder)}
                isUserAuthenticated={userIsAuthenticated}
                offer={this.props.offer}
                userId={userId}
              />
            }
          >
            <UserInfoContent
              isPopupVisible={isPopupVisible}
              userInfoStyle={userInfoStyle}
              userInfoStyleAction={userInfoStyleAction}
              content={content}
              onClick={this.handleInfoClick}
            />
          </DropdownPopover>
        </Outside>
        {avatar}
      </div>
    );
  }

  private handleInfoClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      isPopupVisible: !this.state.isPopupVisible,
    });
  };

  private handleOutsideClick = () => {
    if (!this.state.isPopupVisible) {
      return;
    }

    this.setState({
      isPopupVisible: false,
    });
  };
}
