import * as React from 'react';
import { IStyleConfig, mergeStyles } from '@cian/utils';

import { IOfferPhoto } from '../../../api/offer';
import { Gallery } from '../../gallery';

const style = require('./index.css');

export type PhotoProps = {
  photos: IOfferPhoto[];
  containerStyle?: IStyleConfig;
  controlButtonsFullHeight?: boolean;
  galleryControlsHidden: boolean;
  onSlideChanged?(): void;
};

export const PhotoContainer = ({
  photos,
  containerStyle,
  controlButtonsFullHeight,
  galleryControlsHidden,
  onSlideChanged,
}: PhotoProps) => {
  const sortedPhotos = photos.sort((_, b) => (b.isDefault ? -1 : 1));
  const defaultPhotoImg = sortedPhotos[0];
  const defaulPhotoUrl = defaultPhotoImg ? defaultPhotoImg.fullUrl : '';

  return (
    <div {...mergeStyles(style['container'], containerStyle)}>
      <span {...{ itemProp: 'url', content: defaulPhotoUrl }} />
      <Gallery
        photos={photos}
        controlsHidden={galleryControlsHidden}
        onSlideChanged={onSlideChanged}
        controlButtonsFullHeight={controlButtonsFullHeight}
      />
    </div>
  );
};
