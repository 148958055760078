import * as React from 'react';

import { IOffer } from '../../../../api/offer';

import * as styles from './index.css';

export interface ILandStatusProps {
  offer: IOffer;
}

type TLandStatus =
  | 'individualHousingConstruction'
  | 'farm'
  | 'gardening'
  | 'industrialLand'
  | 'countryhouseNonCommercialPartnership'
  | 'investmentProject';

const LandStatuses = {
  countryhouseNonCommercialPartnership: 'countryhouseNonCommercialPartnership' as TLandStatus,
  farm: 'farm' as TLandStatus,
  gardening: 'gardening' as TLandStatus,
  individualHousingConstruction: 'individualHousingConstruction' as TLandStatus,
  industrialLand: 'industrialLand' as TLandStatus,
  investmentProject: 'investmentProject' as TLandStatus,
};

const LansStatusName = {
  [LandStatuses.individualHousingConstruction]: 'ИЖС',
  [LandStatuses.farm]: 'Фермерское хозяйство',
  [LandStatuses.gardening]: 'Садоводство',
  [LandStatuses.industrialLand]: 'Земля промназначения',
  [LandStatuses.countryhouseNonCommercialPartnership]: 'ДНП',
  [LandStatuses.investmentProject]: 'Инвестпроект',
};

export class LandStatus extends React.Component<ILandStatusProps, {}> {
  public render() {
    const isLand = this.props.offer.category && this.props.offer.category.indexOf('land') >= 0;

    if (!isLand) {
      return null;
    }

    let landStatus: string | undefined;

    if (this.props.offer.land && this.props.offer.land.status) {
      landStatus = LansStatusName[this.props.offer.land.status];
    }

    if (landStatus) {
      return <div className={styles['land-status']}>{landStatus}</div>;
    }

    return null;
  }
}
