import * as React from 'react';
import { UIHeading5, UIText2 } from '@cian/ui-kit/typography';

import { ILocation } from '../../../../types/location';

import * as styles from './styles.css';

export interface ISuggestProps {
  suggest: ILocation[];
  onItemClick(value: ILocation): void;
}

export function Suggest({ suggest, onItemClick }: ISuggestProps) {
  return (
    <div className={styles['suggest']} data-testid="suggest-popup">
      {suggest.map(item => {
        const onClick = () => onItemClick(item);

        return (
          <div key={item.id} className={styles['suggest-item']} onClick={onClick} data-testid="item">
            <UIText2>{item.displayName}</UIText2>
          </div>
        );
      })}
      {!suggest.length && (
        <div className={styles['suggest-error']} data-testid="error">
          <UIHeading5>Ничего не найдено</UIHeading5>
          <UIText2>Укажите другой город или регион</UIText2>
        </div>
      )}
    </div>
  );
}
