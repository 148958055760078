import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectRealtor, selectUserAuthenticated, selectRealtyUserId } from '../../selectors';
import { openChatModal } from '../../store/chat_modal';
// eslint-disable-next-line import/no-restricted-paths
import { requestAuthentication } from '../../../browser/utils/authentication';

export function useChats() {
  const { isChatsEnabled, realtyUserId: realtorRealtyUserId } = useSelector(selectRealtor);
  const realtyUserId = useSelector(selectRealtyUserId);

  const isOwnPage = Number(realtorRealtyUserId) && realtyUserId ? Number(realtorRealtyUserId) === realtyUserId : false;

  const isUserAuthenticated = useSelector(selectUserAuthenticated);
  const dispatch = useDispatch();

  const handleOpenChat = useCallback(() => {
    if (isUserAuthenticated) {
      dispatch(openChatModal());
    } else {
      requestAuthentication();
    }
  }, [dispatch, isUserAuthenticated]);

  return useMemo(
    () => ({
      isEnabled: isChatsEnabled && !isOwnPage,
      openChat: handleOpenChat,
    }),
    [handleOpenChat, isChatsEnabled, isOwnPage],
  );
}
