import { ISocialProfiles } from '../../../types/agentCard';
import { SocialType } from './types';

export const getSocialProfilesList = (profiles: ISocialProfiles[]) => {
  const socialNetworkTypes: SocialType[] = ['ok', 'vk'];
  const result: Array<{ socialType: SocialType; url: string }> = [];

  socialNetworkTypes.forEach(type => {
    const foundProfile = profiles.find(profile => profile.socialType === type);
    if (!foundProfile) {
      return;
    }

    result.push({
      socialType: type,
      url: foundProfile.url,
    });
  });

  return result;
};
