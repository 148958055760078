import * as React from 'react';

import { offerHelper } from '../../../../../offer/presenters';
import { Phone } from '../../../../offer_card/phone';
import { IButtonsProps } from '../types';

export type PhoneButtonProps = Pick<IButtonsProps, 'offer' | 'isSimplified' | 'onPhoneOpened' | 'buttonClassName'> & {
  isButtonHidden: boolean;
};

export const PhoneButton = React.memo(({ offer, buttonClassName, isSimplified, onPhoneOpened }: PhoneButtonProps) => {
  const phone = offerHelper(offer).presenters.getPhone();

  if (!phone) {
    return null;
  }

  return (
    <div className={buttonClassName || ''}>
      <Phone
        isSimplified={isSimplified}
        workTimeInfo={offer.workTimeInfo}
        phone={phone}
        onPhoneOpened={onPhoneOpened}
      />
    </div>
  );
});

PhoneButton.displayName = 'PhoneButton';
