import * as React from 'react';

import { IOffer } from '../../../../api/offer';
import { offerHelper } from '../../../../offer/presenters';

import * as styles from './index.css';

export interface IDeadlineProps {
  offer: IOffer;
}

export class Deadline extends React.Component<IDeadlineProps, {}> {
  public render() {
    const offer = offerHelper(this.props.offer);

    if (this.props.offer.offerType === 'commercial' || this.props.offer.offerType === 'suburban' || offer.isRent()) {
      return null;
    }

    const buildingDeadline = offer.presenters.getBuildingDeadline();

    if (!buildingDeadline) {
      return null;
    }

    return (
      <div className={styles['deadline']}>
        {`${buildingDeadline.charAt(0).toUpperCase()}${buildingDeadline.substring(1)}`}
      </div>
    );
  }
}
