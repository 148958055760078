import { Stars } from '@cian/stars-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { formatRate } from '../../components/aside_general_info/utils/formatRate';
import { LoadMoreMobile, ReviewsMobile } from '../../components/reviews';
import { selectReviews } from '../../selectors/reviews/selectReviews';
import { fetchReviews } from '../../store/reviews';
import { NUMBER_OF_REVIEWS_ON_ADAPTIVE } from '../../constants/adaptive';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { ReviewMobileContainer } from './ReviewMobileContainer';
import { NewReviewButtonContainer } from '../NewReviewButtonContainer';
import { NoReviewsContainer } from '../NoReviewsContainer';
import { trackMoreReviewsClick } from './tracking';

interface IReviewsMobileContainerProps {
  className?: string;
}

export const ReviewsMobileContainer: React.FunctionComponent<IReviewsMobileContainerProps> = ({ className }) => {
  const { reviews, totalCount, entityRate } = useSelector(selectReviews);

  const dispatch = useAppDispatch();
  const handleLoadMoreClick = React.useCallback(() => {
    dispatch(fetchReviews(NUMBER_OF_REVIEWS_ON_ADAPTIVE));

    trackMoreReviewsClick();
  }, [dispatch]);

  if (!Array.isArray(reviews) || reviews.length === 0) {
    return <NoReviewsContainer isMobile />;
  }

  const rate = entityRate ? formatRate(entityRate) : null;
  const reviewsToLoadCount = Math.min(NUMBER_OF_REVIEWS_ON_ADAPTIVE, totalCount - reviews.length);
  const loadMore =
    reviewsToLoadCount > 0 ? <LoadMoreMobile count={reviewsToLoadCount} onClick={handleLoadMoreClick} /> : null;

  const newReviewButton = <NewReviewButtonContainer />;

  return (
    <ReviewsMobile
      count={totalCount}
      rate={rate}
      stars={entityRate ? <Stars percentage value={entityRate} uniqueKey="reviews-mobile" /> : null}
      loadMore={loadMore}
      className={className}
      newReviewButton={newReviewButton}
    >
      {reviews.map(review => (
        <ReviewMobileContainer review={review} key={review.reviewId} />
      ))}
    </ReviewsMobile>
  );
};
