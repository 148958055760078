import * as React from 'react';
import { useSelector } from 'react-redux';
import { Planet16 } from '@cian/ui-kit/prev';

import { ContactItemMobile, ContactsMobile, SkypeIcon } from '../../../components/contacts';
import { selectRealtor } from '../../../selectors/realtor/selectRealtor';
import { getHref, getIcon, getSocialProfilesList } from '../utils';
import { trackContactClick } from '../tracking';

interface IProps {
  className?: string;
}

const clickHandlerMap = {
  site: () => trackContactClick('site'),
  skype: () => trackContactClick('skype'),
  vk: () => trackContactClick('vk'),
  ok: () => trackContactClick('ok'),
};

export const ContactsMobileContainer: React.FunctionComponent<IProps> = ({ className }) => {
  const { website, skype, socialProfiles } = useSelector(selectRealtor);
  const socialProfileList = getSocialProfilesList(socialProfiles || []);

  if (!website && !skype && socialProfileList.length === 0) {
    return null;
  }

  return (
    <ContactsMobile className={className}>
      {website && <ContactItemMobile icon={<Planet16 color="gray_icons_100" />} text={website} />}
      {skype && (
        <ContactItemMobile icon={<SkypeIcon />} text={skype} href={`skype:${skype}`} onClick={clickHandlerMap.skype} />
      )}
      {socialProfileList.map((profile, index) => {
        const { socialType, url } = profile;
        const IconComponent = getIcon(socialType);

        return (
          <ContactItemMobile
            icon={<IconComponent />}
            text={getHref(url, { withoutProtocol: true })}
            href={getHref(url)}
            key={index}
            onClick={clickHandlerMap[socialType]}
          />
        );
      })}
    </ContactsMobile>
  );
};
