import * as React from 'react';

import { canModerateAnnouncements, canModerateUsers, canViewUsers } from '../../../../../utils/user';
import { IAuthenticatedUser } from '../../../../../store';
import { IButtonsProps } from '../types';
import { getCianType } from '../../../../offer_card/menu/more';

import * as styles from '../index.css';

export type MorePopupProps = Pick<IButtonsProps, 'offer' | 'user'> & {
  onPdfClick: () => void;
  onDocClick: () => void;
};

export const MorePopup = React.forwardRef(
  ({ offer, user, onPdfClick, onDocClick }: MorePopupProps, ref: React.RefObject<HTMLDivElement>) => {
    const userCanModerateAnnouncements = canModerateAnnouncements(user as IAuthenticatedUser);
    const userCanModerateUsers = canModerateUsers(user as IAuthenticatedUser);
    const userCanViewUsers = canViewUsers(user as IAuthenticatedUser);

    const userIsModerator = userCanModerateAnnouncements || userCanModerateUsers || userCanViewUsers;
    const userIsOwner =
      (!offer.isImported &&
        (user as IAuthenticatedUser).realtyUserId &&
        (user as IAuthenticatedUser).realtyUserId === offer.userId) ||
      false;

    return (
      <div className={styles['more-popup']} ref={ref}>
        {userIsModerator && (
          <ul className={styles['more-list']}>
            {userCanModerateAnnouncements && (
              <li className={styles['more-item']}>
                <a
                  rel="noreferrer"
                  className={styles['more-link']}
                  // tslint:disable-next-line:max-line-length
                  href={`https://service.cian.ru/moderate-announcements/index?AnnFilter.OfferType=${offer.dealType}&AnnFilter.Author=${offer.user.cianUserId}&AnnFilter.selectType=All`}
                  target="_blank"
                >
                  Проверять
                </a>
              </li>
            )}
            {userCanModerateAnnouncements && (
              <li className={styles['more-item']}>
                <a
                  rel="noreferrer"
                  className={styles['more-link']}
                  // tslint:disable-next-line:max-line-length
                  href={`https://service.cian.ru/moderate-announcements/index?AnnFilter.CianObjectId=${offer.cianId}`}
                  target="_blank"
                >
                  Объявление
                </a>
              </li>
            )}
            {userCanModerateUsers && (
              <li className={styles['more-item']}>
                <a
                  rel="noreferrer"
                  className={styles['more-link']}
                  href={`https://service.cian.ru/customers/edit/${offer.user.cianUserId}`}
                  target="_blank"
                >
                  Редактировать клиента
                </a>
              </li>
            )}
            {userCanViewUsers && (
              <li className={styles['more-item']}>
                <a
                  rel="noreferrer"
                  className={styles['more-link']}
                  href={`https://service.cian.ru/customers/view/${offer.user.cianUserId}`}
                  target="_blank"
                >
                  Клиент
                </a>
              </li>
            )}
          </ul>
        )}
        {userIsModerator && <hr className={styles['delimiter']} />}
        {userIsOwner && (
          <ul className={styles['more-list']}>
            <li className={styles['more-item']}>
              <a
                rel="noreferrer"
                className={styles['more-link']}
                // tslint:disable-next-line:max-line-length
                href={`/realty/add1.aspx?cianId=${offer.cianId}&cianType=${getCianType(
                  offer.dealType,
                  offer.offerType,
                )}`}
                target="_blank"
              >
                Редактировать
              </a>
            </li>
            <li className={styles['more-item']}>
              <a
                rel="noreferrer"
                className={styles['more-link']}
                // tslint:disable-next-line:max-line-length
                href={`/realty/add2.aspx?cianId=${offer.cianId}&cianType=${getCianType(
                  offer.dealType,
                  offer.offerType,
                )}`}
                target="_blank"
              >
                Добавить фото
              </a>
            </li>
            <li className={styles['more-item']}>
              <a
                rel="noreferrer"
                className={styles['more-link']}
                href={`https://my.cian.ru/journal?offer_id=${offer.cianId}`}
                target="_blank"
              >
                История
              </a>
            </li>
          </ul>
        )}
        {userIsOwner && <hr className={styles['delimiter']} />}
        <ul className={styles['more-list']}>
          <li className={styles['more-item']}>
            <a
              rel="noreferrer"
              className={styles['more-link']}
              href={offer.fullUrl.replace('.ru/', '.ru/export/pdf/')}
              target="_blank"
              onClick={onPdfClick}
            >
              Скачать PDF
            </a>
          </li>
          <li className={styles['more-item']}>
            <a
              rel="noreferrer"
              className={styles['more-link']}
              href={offer.fullUrl.replace('.ru/', '.ru/export/docx/')}
              target="_blank"
              onClick={onDocClick}
            >
              Скачать DOC
            </a>
          </li>
        </ul>
      </div>
    );
  },
);

MorePopup.displayName = 'MorePopup';
