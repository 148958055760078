import { plural } from '@cian/utils';
import * as React from 'react';

import { Link } from '../Link';
import { IOffer, IOfferInfo } from '../../api/offer';
import { TUser } from '../../store';
import { ISimplifiedOfferCardPopupsState } from '../../store/simplified_card_popups';
import {
  trackAllOffersClick,
  trackDocLinkClicked,
  trackFavoriteAdded,
  trackFavoriteRemoved,
  trackMapClosed,
  trackMapOpened,
  trackMenuOpened,
  trackPDFLinkClicked,
  trackPhoneOpened,
  trackPhotoSlideChanged,
} from '../../tracking/offer';
import { CommercialOfferCard } from '../offer_card/commercial_card';
import { SimplifiedOfferCard } from '../simplified_offer_card';

import * as styles from './index.css';

export interface IOffersStoreProps {
  offers: IOfferInfo[];
  popupsState: ISimplifiedOfferCardPopupsState;
  user: TUser;
}

export interface IOffersDispatchProps {
  open(): void;
  onFavoriteChanged(offer: IOffer, isFavorite: boolean): void;
}

export type TOffersProps = IOffersStoreProps & IOffersDispatchProps;

const OFFERS: [string, string, string] = ['предложение', 'предложения', 'предложений'];

export class Offers extends React.Component<TOffersProps, {}> {
  public render() {
    return (
      <noindex>
        <div id="offers" className={styles['container']}>
          {this.props.offers.map(offerCategory => {
            return (
              <div key={offerCategory.link} className={styles['offer_category']}>
                <div className={styles['name']} data-name="OffersCategoryName">
                  {offerCategory.name}
                </div>
                {Number(offerCategory.count) > 1 && (
                  <div className={styles['link']}>
                    <Link onClick={trackAllOffersClick} target="_blank" href={offerCategory.link}>
                      Смотреть все {offerCategory.count} {plural(+offerCategory.count, OFFERS)}
                    </Link>
                  </div>
                )}

                {offerCategory.offers.map((offer, index) => {
                  const { user } = this.props;

                  const isCommercialOffer = offer.offerType === 'commercial';
                  const onPhoneOpened = () => trackPhoneOpened(offer, index + 1);

                  return (
                    <div key={offer.id} className={styles['offer']}>
                      {isCommercialOffer && (
                        <CommercialOfferCard
                          offer={offer}
                          user={user}
                          onPhotoSlideChangedOnce={trackPhotoSlideChanged}
                          onMapOpened={trackMapOpened}
                          onMapClosed={trackMapClosed}
                          onMenuOpened={trackMenuOpened}
                          onPDFLinkClicked={trackPDFLinkClicked}
                          onDocLinkClicked={trackDocLinkClicked}
                          onPhoneOpened={onPhoneOpened}
                          onFavoriteChange={this.onFavoriteChanged}
                        />
                      )}
                      {!isCommercialOffer && (
                        <SimplifiedOfferCard
                          popupsState={this.props.popupsState}
                          offer={offer}
                          user={user}
                          onFavoriteChange={this.onFavoriteChanged}
                          onPhoneOpened={onPhoneOpened}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </noindex>
    );
  }

  private onFavoriteChanged = (offer: IOffer, isFavorite: boolean) => {
    const trackingLabel = offer.gaLabel;

    if (isFavorite) {
      trackFavoriteAdded(trackingLabel);
    } else {
      trackFavoriteRemoved(trackingLabel);
    }

    this.props.onFavoriteChanged(offer, isFavorite);
  };
}
