import * as React from 'react';

import { IOffer } from '../../../../../api/offer';

import * as styles from '../index.css';

type PopupContentProps = {
  isReverseOrder: boolean;
  isUserAuthenticated: boolean;
  offer: IOffer;
  userId?: number;
};

type PopupLinkProps = Pick<PopupContentProps, 'userId' | 'offer'>;

const PopupLink = React.memo(({ userId, offer }: PopupLinkProps) => {
  const userQuery = userId ? `id_user=${userId}&` : '';
  const offerTypeForUserUrl = offer.offerType === 'commercial' ? 'offices' : offer.offerType;

  return (
    <div className={styles['all-offers']}>
      <a
        rel="noreferrer"
        className={styles['offer-link']}
        href={`/cat.php?${userQuery}deal_type=${offer.dealType}&` + `${offerTypeForUserUrl}=yes&engine_version=2`}
        target="_blank"
      >
        Все объявления агента
      </a>
    </div>
  );
});

export const PopupContent = React.forwardRef(
  ({ isReverseOrder, isUserAuthenticated, userId, offer }: PopupContentProps, ref: React.Ref<HTMLDivElement>) => {
    if (!userId) {
      return null;
    }

    if (isReverseOrder) {
      return (
        <div className={styles['popup_content']} ref={ref}>
          <PopupLink userId={userId} offer={offer} />
        </div>
      );
    }

    return (
      <div className={styles['popup_content']} ref={ref}>
        {isUserAuthenticated && <hr className={styles['delimiter']} />}
        <PopupLink userId={userId} offer={offer} />
      </div>
    );
  },
);

PopupContent.displayName = 'PopupContent';
PopupLink.displayName = 'PopupLink';
