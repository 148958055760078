export type IChatStatistics = IGetAgentStatsResponseSchema;

export interface IChatStatisticsFetched {
  type: 'IChatStatisticsFetched';
  statistics: IChatStatistics;
}

interface IGetAgentStatsResponseSchema {
  agentId: number;
  averageResponseTime: number;
  responseRate: number;
}

export type TChatStatisticsActions = IChatStatisticsFetched;

const defaultState = {};

export function chatStatisticsReducer(state: IChatStatistics, action: TChatStatisticsActions): IChatStatistics {
  switch (action.type) {
    case 'IChatStatisticsFetched':
      return {
        ...state,
        ...action.statistics,
      };

    default:
      return state || defaultState;
  }
}
