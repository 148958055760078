/* eslint-disable max-lines */

import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import {
  EDealTypeEx,
  EObjectType,
  fetchCreatePersonalMessage,
  ICreatePersonalMessageResponse200,
  ICreatePersonalMessageResponse400,
} from '../repositories/demand/v2/create-personal-message';
import { IAppState, IContext, IThunkActionCreator } from '.';
import { ILocation } from '../types/location';
import { IGeoExV4Schema } from '../repositories/demand/entities/geo/GeoExV4Schema';
import { TThunkDispatch } from '../types/redux';

enum IPersonalLeadFormActionTypes {
  SetTypes = 'PersonalLeadForm/SET_LEAD_TYPES',
  SetObjectType = 'PersonalLeadForm/SET_OFFER_TYPE',
  SetLocation = 'PersonalLeadForm/SET_LOCATION',
  ChangeName = 'PersonalLeadForm/CHANGE_NAME',
  ChangePhone = 'PersonalLeadForm/CHANGE_PHONE',
  ChangeComment = 'PersonalLeadForm/CHANGE_COMMENT',
  SetFieldErrors = 'PersonalLeadForm/SET_ERRORS',
  SetStatus = 'PersonalLeadForm/SET_STATUS',
  SetErrorMessage = 'PersonalLeadForm/SET_ERROR_MESSAGE',
  Reset = 'PersonalLeadForm/RESET',
}

export enum IFormStatus {
  INITIAL = 'initial',
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface IPersonalLeadFormData {
  leadType: { value: EDealTypeEx | undefined; error: boolean };
  objectType: { value: EObjectType | undefined; error: boolean };
  location: { value: ILocation | undefined; error: boolean };
  name: { value: string; error: boolean };
  phone: { value: string; error: boolean };
  comment: { value: string; error: boolean };
}
export interface IPersonalLeadFormState {
  data: IPersonalLeadFormData;
  status: IFormStatus;
  errorMessage: string | null;
}

export interface ISetPersonalleadTypeAction {
  type: IPersonalLeadFormActionTypes.SetTypes;
  leadType: EDealTypeEx;
}

export interface ISetPersonalLeadObjectTypeAction {
  type: IPersonalLeadFormActionTypes.SetObjectType;
  objectType: EObjectType;
}

export interface ISetPersonalLeadLocationAction {
  type: IPersonalLeadFormActionTypes.SetLocation;
  location: ILocation;
}

export interface IChangePersonalLeadNameAction {
  type: IPersonalLeadFormActionTypes.ChangeName;
  name: IPersonalLeadFormState['data']['name']['value'];
}

export interface IChangePersonalLeadPhoneAction {
  type: IPersonalLeadFormActionTypes.ChangePhone;
  phone: IPersonalLeadFormState['data']['phone']['value'];
}

export interface IChangePersonalLeadCommentAction {
  type: IPersonalLeadFormActionTypes.ChangeComment;
  comment: IPersonalLeadFormState['data']['comment']['value'];
}

export interface IPersonalLeadSetFieldErrorsAction {
  type: IPersonalLeadFormActionTypes.SetFieldErrors;
  fields: (keyof IPersonalLeadFormData)[];
}
export interface IPersonalLeadSetStatusAction {
  type: IPersonalLeadFormActionTypes.SetStatus;
  status: IFormStatus;
}

export interface IPersonalLeadSetErrorMessageAction {
  type: IPersonalLeadFormActionTypes.SetErrorMessage;
  errorMessage: string | null;
}

export interface IPersonalLeadResetAction {
  type: IPersonalLeadFormActionTypes.Reset;
}

export type TPersonalLeadFormActions =
  | ISetPersonalleadTypeAction
  | ISetPersonalLeadObjectTypeAction
  | ISetPersonalLeadLocationAction
  | IChangePersonalLeadNameAction
  | IChangePersonalLeadPhoneAction
  | IChangePersonalLeadCommentAction
  | IPersonalLeadSetFieldErrorsAction
  | IPersonalLeadSetStatusAction
  | IPersonalLeadSetErrorMessageAction
  | IPersonalLeadResetAction;

export function setleadType(leadType: EDealTypeEx) {
  return (dispatch: TThunkDispatch) => {
    dispatch({
      type: IPersonalLeadFormActionTypes.SetTypes,
      leadType,
    });
  };
}

export function setObjectType(objectType: EObjectType) {
  return (dispatch: TThunkDispatch) => {
    dispatch({
      type: IPersonalLeadFormActionTypes.SetObjectType,
      objectType,
    });
  };
}

export function setLocation(location: ILocation) {
  return (dispatch: TThunkDispatch) => {
    dispatch({
      type: IPersonalLeadFormActionTypes.SetLocation,
      location,
    });
  };
}

export function changeName(name: IPersonalLeadFormState['data']['name']['value']) {
  return (dispatch: TThunkDispatch) => {
    dispatch({
      type: IPersonalLeadFormActionTypes.ChangeName,
      name,
    });
  };
}

export function changePhone(phone: IPersonalLeadFormState['data']['phone']['value']) {
  return (dispatch: TThunkDispatch) => {
    dispatch({
      type: IPersonalLeadFormActionTypes.ChangePhone,
      phone,
    });
  };
}

export function changeComment(comment: IPersonalLeadFormState['data']['comment']['value']) {
  return (dispatch: TThunkDispatch) => {
    dispatch({
      type: IPersonalLeadFormActionTypes.ChangeComment,
      comment,
    });
  };
}

export function setFieldsErrors(fields: (keyof IPersonalLeadFormData)[]) {
  return (dispatch: TThunkDispatch) => {
    dispatch({
      type: IPersonalLeadFormActionTypes.SetFieldErrors,
      fields,
    });
  };
}

export function resetForm() {
  return (dispatch: TThunkDispatch) => {
    dispatch({
      type: IPersonalLeadFormActionTypes.Reset,
    });
  };
}

export function submitPersonalLeadForm({
  leadType,
  name,
  phone,
  comment,
  objectType,
  geo,
}: {
  leadType: IPersonalLeadFormData['leadType']['value'];
  name: IPersonalLeadFormData['name']['value'];
  phone: IPersonalLeadFormData['phone']['value'];
  comment: IPersonalLeadFormData['comment']['value'];
  objectType: IPersonalLeadFormData['objectType']['value'];
  geo: IGeoExV4Schema;
}): IThunkActionCreator {
  return async (dispatch: TThunkDispatch, getState: () => IAppState, context: IContext) => {
    // Этот кейс отсекается валидацией
    if (!leadType || !geo || !objectType) {
      dispatch({
        type: IPersonalLeadFormActionTypes.SetStatus,
        status: IFormStatus.ERROR,
      });
      dispatch({
        type: IPersonalLeadFormActionTypes.SetErrorMessage,
        errorMessage: 'Что-то пошло не так, попробуйте ещё раз',
      });
    }

    dispatch({
      type: IPersonalLeadFormActionTypes.SetStatus,
      status: IFormStatus.LOADING,
    });

    const { httpApi } = context;

    const specialistIds = [Number(getState().realtor.realtyUserId)];
    const parameters = {
      specialistIds,
      comment,
      dealTypeEx: leadType as EDealTypeEx,
      fullName: name,
      phone: phone.replace(/([-, ])/g, ''),
      source: 'agentCatalogCardSimple',
      objectType: objectType as EObjectType,
      geo,
    };

    try {
      const { statusCode, response } = await fetchCreatePersonalMessage<
        ICreatePersonalMessageResponse200,
        ICreatePersonalMessageResponse400
      >({
        parameters,
        httpApi,
      });

      if (statusCode === 200) {
        dispatch({
          type: IPersonalLeadFormActionTypes.SetStatus,
          status: IFormStatus.SUCCESS,
        });

        return;
      }

      throw new HttpBadStatusCodeError({
        message: response.message,
        statusCode,
        domain: 'personal_leads_form.ts',
        details: {
          code: response.errors[0].code,
        },
      });
    } catch (error) {
      const isServerErrorOrUnknownType = error.statusCode === 500 || error.type === 'UnknownError';
      const errorMessage = isServerErrorOrUnknownType ? 'Что-то пошло не так, попробуйте ещё раз' : error.message;
      dispatch({
        type: IPersonalLeadFormActionTypes.SetStatus,
        status: IFormStatus.ERROR,
      });
      dispatch({
        type: IPersonalLeadFormActionTypes.SetErrorMessage,
        errorMessage,
      });
      const logger = context.logger;
      logger.error(error);

      throw error;
    }
  };
}

export const initialState: IPersonalLeadFormState = {
  data: {
    leadType: { value: undefined, error: false },
    objectType: { value: undefined, error: false },
    location: { value: undefined, error: false },
    name: { value: '', error: false },
    phone: { value: '', error: false },
    comment: { value: '', error: false },
  },
  status: IFormStatus.INITIAL,
  errorMessage: null,
};

export function personalLeadFormReducer(
  state: IPersonalLeadFormState = initialState,
  action: TPersonalLeadFormActions,
): IPersonalLeadFormState {
  switch (action.type) {
    case IPersonalLeadFormActionTypes.SetTypes: {
      return { ...state, data: { ...state.data, leadType: { value: action.leadType, error: false } } };
    }

    case IPersonalLeadFormActionTypes.SetObjectType: {
      return { ...state, data: { ...state.data, objectType: { value: action.objectType, error: false } } };
    }

    case IPersonalLeadFormActionTypes.SetLocation: {
      return { ...state, data: { ...state.data, location: { value: action.location, error: false } } };
    }

    case IPersonalLeadFormActionTypes.ChangeName:
      return { ...state, data: { ...state.data, name: { value: action.name, error: false } } };

    case IPersonalLeadFormActionTypes.ChangePhone:
      return { ...state, data: { ...state.data, phone: { value: action.phone, error: false } } };

    case IPersonalLeadFormActionTypes.ChangeComment:
      return { ...state, data: { ...state.data, comment: { value: action.comment, error: false } } };

    case IPersonalLeadFormActionTypes.SetFieldErrors: {
      const { data } = state;

      const newFormData = Object.keys(data).reduce(
        (result, field) =>
          action.fields.includes(field as keyof IPersonalLeadFormData)
            ? {
                ...result,
                [field]: { ...result[field as keyof IPersonalLeadFormData], error: true },
              }
            : result,
        state.data,
      );

      return { ...state, data: newFormData };
    }

    case IPersonalLeadFormActionTypes.SetStatus:
      return { ...state, status: action.status };

    case IPersonalLeadFormActionTypes.SetErrorMessage:
      return { ...state, errorMessage: action.errorMessage };

    case IPersonalLeadFormActionTypes.Reset:
      return initialState;

    default:
      return state;
  }
}
