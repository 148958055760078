/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Store } from 'redux';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { ApplicationContext } from '../../../../shared/utils/applicationContext';
import { IAppState, IContext } from '../../../../shared/store';

interface IDependencies {
  app: JSX.Element;
  store: Store<IAppState>;
  rootElement: HTMLElement;
  context: IContext;
}

export function renderReactApp({ store, app, rootElement, context }: IDependencies) {
  ReactDOM.hydrateRoot(
    rootElement,
    <ApplicationContext.Provider value={context}>
      <Provider store={store as any}>{app}</Provider>
    </ApplicationContext.Provider>,
  );
}
