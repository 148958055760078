/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IRangeExSchema } from '../../entities/range/RangeExSchema';
import { IGeoExV4Schema } from '../../entities/geo/GeoExV4Schema';
import { IPriceRangeExSchema } from '../../entities/price_range/PriceRangeExSchema';

export type TCreatePersonalMessageModel = IModel<ICreatePersonalMessageRequest, TCreatePersonalMessageResponse>;

export interface ICreatePersonalMessageRequest {
  /** Площадь **/
  area?: IRangeExSchema | null;
  /** Комментарий **/
  comment?: string | null;
  /** Требуемая срочность завершения сделки **/
  dealPeriod?: EDealPeriod | null;
  /** Тип сделки **/
  dealTypeEx: EDealTypeEx;
  /** E-mail **/
  email?: string | null;
  /** Первичная/вторичная недвижимость **/
  firstOrSecond?: EFirstOrSecond | null;
  /** ФИО **/
  fullName: string;
  /** Геоданные **/
  geo: IGeoExV4Schema;
  /** Объект в залоге у банка **/
  isBankDeposit?: boolean | null;
  /** В кредит **/
  isCredit?: boolean | null;
  /** Объявление уже размещено? **/
  isHasOffer?: boolean | null;
  /** Вы получили объект в наследство? **/
  isInheritance?: boolean | null;
  /** В числе собственников есть несовершеннолетние? **/
  isMinorOwners?: boolean | null;
  /** Уже накоплен первоначальный взнос? **/
  isMortgageInitialPayment?: boolean | null;
  /** Уже выбрали проект дома под строительство? **/
  isProjectExists?: boolean | null;
  /** Готовность снизить цену, в случае необходимости **/
  isReadyToLower?: boolean | null;
  /** Тестовая заявка **/
  isTest?: boolean | null;
  /** Наличие одобрения ипотеки от банка **/
  mortgageBankApprove?: EMortgageBankApprove | null;
  /** Рассматриваете вариант покупки участка и постройки {дома}? **/
  needLandForBuilding?: boolean | null;
  /** Хотите подобрать проект дома и рассчитать его примерную стоимость? **/
  needProject?: boolean | null;
  /** Тип объекта **/
  objectType: EObjectType;
  /** Требуемый тип недвижимости **/
  offerType?: EOfferType | null;
  /** Телефон **/
  phone: string;
  /** Цена **/
  price?: IPriceRangeExSchema | null;
  /** Как планируете покупать квартиру? **/
  purchaseMethod?: EPurchaseMethod | null;
  /** Тип аренды **/
  rentType?: ERentType | null;
  /** Количество комнат **/
  roomCount?: ERoomCount[] | null;
  /** Источник заявки **/
  source: string;
  /** Идентификаторы пользователей, которым предназначается заявка (realty) **/
  specialistIds: number[];
}

export type TCreatePersonalMessageResponse = ICreatePersonalMessageResponse200 | ICreatePersonalMessageResponse400;

export interface ICreatePersonalMessageResponse200 extends IModelResponse<ICreatePersonalMessageResponse> {
  statusCode: 200;
}

export interface ICreatePersonalMessageResponse400 extends IModelResponse<ICreatePersonalMessageResponseError> {
  statusCode: 400;
}

export interface ICreatePersonalMessageResponse {
  /** ID заявки **/
  id: number;
  /** Статус ответа **/
  status: EStatus;
}

export interface ICreatePersonalMessageResponseError {
  errors: any[];
  message: string;
}

export type TResponses = ICreatePersonalMessageResponse | ICreatePersonalMessageResponseError;

export enum EStatus {
  /** Дубликат **/
  Duplicate = 'duplicate',
  /** Ошибка **/
  Failed = 'failed',
  /** Успешно **/
  Ok = 'ok',
}

export enum EDealPeriod {
  /** В течение месяца **/
  OneMonth = 'oneMonth',
  /** В течение недели **/
  OneWeek = 'oneWeek',
  /** В течение 6 месяцев **/
  SixMonths = 'sixMonths',
  /** В течение 3 месяцев **/
  ThreeMonths = 'threeMonths',
  /** В течение 12 месяцев **/
  TwelveMonths = 'twelveMonths',
  /** В течение 2 недель **/
  TwoWeek = 'twoWeek',
}

export enum EDealTypeEx {
  /** Покупаю **/
  Buy = 'buy',
  /** Сдаю **/
  Rent = 'rent',
  /** Продаю **/
  Sale = 'sale',
  /** Продаю, чтобы купить **/
  SellToBuy = 'sellToBuy',
  /** Арендую **/
  Tenant = 'tenant',
}

export enum EFirstOrSecond {
  /** Неважно **/
  DoesNotMatter = 'doesNotMatter',
  /** Новостройка **/
  First = 'first',
  /** Вторичка **/
  Second = 'second',
}

export enum EMortgageBankApprove {
  /** В процессе **/
  InProcess = 'inProcess',
  /** Нет **/
  No = 'no',
  /** Да **/
  Yes = 'yes',
}

export enum EObjectType {
  /** Койко место **/
  Bed = 'bed',
  /** Здание **/
  Building = 'building',
  /** Готовый бизнес **/
  Business = 'business',
  /** Автосервис **/
  CarService = 'carService',
  /** Коммерческая земля **/
  CommercialLand = 'commercialLand',
  /** Коттедж **/
  Cottage = 'cottage',
  /** Бытовые услуги **/
  DomesticServices = 'domesticServices',
  /** Квартира **/
  Flat = 'flat',
  /** Доля в квартире **/
  FlatShare = 'flatShare',
  /** Помещение свободного назначения **/
  FreeAppointmentObject = 'freeAppointmentObject',
  /** Гараж **/
  Garage = 'garage',
  /** Дом **/
  House = 'house',
  /** Часть дома **/
  HouseShare = 'houseShare',
  /** Производство **/
  Industry = 'industry',
  /** Участок **/
  Land = 'land',
  /** Продажа квартиры в новостройке **/
  NewBuildingFlat = 'newBuildingFlat',
  /** Офис **/
  Office = 'office',
  /** Общепит **/
  PublicCatering = 'publicCatering',
  /** Комната **/
  Room = 'room',
  /** Торговая площадь **/
  ShoppingArea = 'shoppingArea',
  /** Таунхаус **/
  Townhouse = 'townhouse',
  /** Склад **/
  Warehouse = 'warehouse',
}

export enum EOfferType {
  /** коммерческая **/
  Commercial = 'commercial',
  /** квартиры **/
  Flat = 'flat',
  /** новостройки **/
  Newobject = 'newobject',
  /** загородка **/
  Suburban = 'suburban',
}

export enum EPurchaseMethod {
  /** Наличные **/
  Cash = 'cash',
  /** Материнский капитал **/
  MaternalCapital = 'maternalCapital',
  /** Ипотека **/
  Mortgage = 'mortgage',
  /** Другое **/
  Other = 'other',
}

export enum ERentType {
  /** Любой **/
  Any = 'any',
  /** Посуточно **/
  Daily = 'daily',
  /** На несколько месяцев **/
  FewMonths = 'fewMonths',
  /** От года **/
  Long = 'long',
}

export enum ERoomCount {
  /** Пятикомнатная квартира **/
  FiveRoom = 'fiveRoom',
  /** Четырехкомнатная квартира **/
  FourRoom = 'fourRoom',
  /** Многокомнатная квартира (6 и более комнат) **/
  Large = 'large',
  /** Однокомнатная квартира **/
  OneRoom = 'oneRoom',
  /** Квартира со свободной планировкой **/
  OpenPlan = 'openPlan',
  /** Студия **/
  Studio = 'studio',
  /** Трехкомнатная квартира **/
  ThreeRoom = 'threeRoom',
  /** Двухкомнатная квартира **/
  TwoRoom = 'twoRoom',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: ICreatePersonalMessageResponse): TResponse200;
  400(response: ICreatePersonalMessageResponseError): TResponse400;
}
