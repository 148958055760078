import { ILogger } from '@cian/logger/shared';
import { IHttpApi } from '@cian/http-api/shared/http';

import { createPutReviewModel, IPutReviewRequest, TPutReviewResponse } from '../repositories/reviews/v3/put-review';
import { IPutAnswerRequest, createPutAnswerModel, TPutAnswerResponse } from '../repositories/reviews/v3/put-answer';
import { IGetReviewsRequest, TGetReviewsResponse, createGetReviewsModel } from '../repositories/reviews/v2/get-reviews';

export function getReviews(client: IHttpApi, options: IGetReviewsRequest, logger: ILogger) {
  return client
    .fetch({
      ...createGetReviewsModel(options),
    })
    .then((response: TGetReviewsResponse) => {
      if (response.statusCode !== 200) {
        const message = `Unable to get realtor reviews with id ${options.realtyUserId}.`;
        const error = response.response.message;

        const meta = {
          realtorId: options.realtyUserId,
        };

        logger.error(`${message} ${error}`, meta);

        /*
        Если API ответило, но мы отзывы доставить как фичу не можем,
        то валим страницу с ошибкой и логгируем кастомное сообщение.
        Позже сюда подъедет деградация (вместо ошибки отдадим заглушку в стор).
      */
        throw new Error(response.response.message);
      }

      return response.response.data;
    })
    .catch(error => {
      const message = `Fail to get reviews for realtor with id ${options.realtyUserId}.`;

      const meta = {
        realtorId: options.realtyUserId,
      };

      logger.error(`${message} ${error}`, meta);

      /*
      Если API не ответило вообще, то валим страницу с ошибкой.
    */
      throw new Error(error);
    });
}

export function putReview(client: IHttpApi, options: IPutReviewRequest) {
  return client
    .fetch({
      ...createPutReviewModel(options),
    })
    .then((response: TPutReviewResponse) => {
      if (response.statusCode !== 200) {
        return response.response;
      }

      return response.response.data;
    });
}

export function putAnswer(client: IHttpApi, options: IPutAnswerRequest) {
  return client
    .fetch({
      ...createPutAnswerModel(options),
    })
    .then((response: TPutAnswerResponse) => {
      if (response.statusCode !== 200) {
        return response.response;
      }

      return response.response.data;
    });
}
