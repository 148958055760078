import { TDeviceType } from '../types/device';

export function parseDecimal(value: string | number | undefined): number | undefined {
  if (typeof value !== 'string' && typeof value !== 'number') {
    return undefined;
  }

  let result = Number(value);

  if (typeof value === 'string') {
    result = Number(value.replace(',', '.'));
  }

  if (isNaN(result)) {
    return undefined;
  }

  return result;
}

export function getInitialDeviceWidth(deviceType: TDeviceType): number {
  switch (deviceType) {
    case 'phone':
      return 320;
    case 'tablet':
      return 768;
    case 'desktop':
    default:
      return 1025;
  }
}
