import * as React from 'react';
import { useState, useCallback, useRef } from 'react';
import { Image } from '@cian/ui-kit';

import { Slider, SliderClassComponent } from '../slider';
import { IGalleryProps } from './types';
import { Controls, Counter } from './components';

import * as style from './index.css';

export const Gallery = ({ photos, controlsHidden, controlButtonsFullHeight, onSlideChanged }: IGalleryProps) => {
  const items = photos.map(image => {
    return <Image src={image.fullUrl} key={image.id} alt="Изображение галлереи" preloader={true} objectFit="fill" />;
  });
  const sliderRef = useRef<SliderClassComponent>(null);
  const [isSliderInitialized, setIsSliderInitialized] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const onSliderInitialized = useCallback(() => setIsSliderInitialized(true), [setIsSliderInitialized]);
  const handleSlideChanged = useCallback(
    (index: number) => {
      setCurrentImageIndex(index);
      if (onSlideChanged) {
        onSlideChanged(index);
      }
    },
    [onSlideChanged],
  );
  const handleNextClick = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.next();
    }
  }, [sliderRef]);
  const handlePrevClick = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.prev();
    }
  }, [sliderRef]);

  return (
    <div className={style.container}>
      <Slider ref={sliderRef} onSliderInitialized={onSliderInitialized} onSlideChange={handleSlideChanged}>
        {items}
      </Slider>
      <div className={style.controls} style={{ display: controlsHidden ? 'none' : '' }}>
        <Controls
          onNextClick={handleNextClick}
          onPrevClick={handlePrevClick}
          itemsTotal={photos.length}
          sliderInitialized={isSliderInitialized}
          controlButtonsFullHeight={controlButtonsFullHeight}
        />
        <Counter currentIndex={currentImageIndex} itemsTotal={photos.length} sliderInitialized={isSliderInitialized} />
      </div>
    </div>
  );
};
