/* eslint-disable react/forbid-elements */
import { Button } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';
import * as React from 'react';

import { CloseBtn } from '../../components/close_button';

import styles from './index.css';

export interface ILoginMotivationPopupStoreProps {
  shownId: number | undefined;
}

export interface ILoginMotivationPopupDispatchProps {
  closePopup(): void;
}

export interface ILoginMotivationPopupOwnProps {
  id: number;
}

type TLoginMotivationPopupProps = ILoginMotivationPopupStoreProps &
  ILoginMotivationPopupDispatchProps &
  ILoginMotivationPopupOwnProps;

export class LoginMotivationPopup extends React.PureComponent<TLoginMotivationPopupProps, {}> {
  public componentDidUpdate(prevProps: TLoginMotivationPopupProps) {
    if (this.isShown()) {
      document.addEventListener('click', this.closePopup);
    } else if (this.props.shownId !== this.props.id && prevProps.id === prevProps.shownId) {
      document.removeEventListener('click', this.closePopup);
    }
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.closePopup);
  }

  public render() {
    if (!this.isShown()) {
      return null;
    }

    return (
      <div {...mergeStyles([styles['popup'], styles['popup--visible']])} onClick={this.handleClickContainer}>
        <div className={styles['popup-container']}>
          <CloseBtn isTransparent onClick={this.closePopup} className={styles['close']} />
          <h3 {...mergeStyles([styles['text'], styles['title']])}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16">
              {/* tslint:disable-next-line:max-line-length */}
              <path
                fill="#2e9e00"
                d="M14.073 2.8L7.8 9.073l-1.927-2.2a1.094 1.094 0 1 0-1.637 1.454l2.71 3.073c.2.218.49.364.781.364h.037c.29 0 .563-.11.763-.328l7.091-7.09a1.11 1.11 0 0 0 0-1.546 1.109 1.109 0 0 0-1.545 0z"
              />
              {/* tslint:disable-next-line:max-line-length */}
              <path
                fill="#2e9e00"
                d="M14.91 6.91c-.6 0-1.092.49-1.092 1.09 0 .255-.018.51-.054.764-.364 2.872-2.855 5.054-5.764 5.054-3.2 0-5.818-2.618-5.818-5.818C2.182 4.8 4.8 2.182 8 2.182c1.036 0 2.055.273 2.927.8a1.091 1.091 0 0 0 1.491-.382 1.091 1.091 0 0 0-.382-1.49A7.887 7.887 0 0 0 8 0a8 8 0 0 0 0 16c4 0 7.418-2.982 7.927-6.964A9.73 9.73 0 0 0 16 8c0-.6-.49-1.09-1.09-1.09z"
              />
            </svg>
            Добавлено в избранное
          </h3>
          <div className={styles['content']}>
            <p className={styles['text']}>Войдите, для просмотра избранного с любого устройства</p>
            <div className={styles['button-container']}>
              <Button size="XS" onClick={this.handleClickEnter} theme="fill_secondary">
                Войти
              </Button>
              <button onClick={this.handleClickCreate} className={styles['button-create']}>
                Зарегистрироваться
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private handleClickContainer = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  private isShown() {
    return this.props.shownId && this.props.id === this.props.shownId;
  }

  private closePopup = () => {
    if (this.isShown()) {
      this.props.closePopup();
    }
  };

  private handleClickEnter = () => {
    this.closePopup();

    if (window.waitForLoginPopup) {
      window.waitForLoginPopup();
    }
  };

  private handleClickCreate = () => {
    this.closePopup();

    if (window.waitForRegistrationPopup) {
      window.waitForRegistrationPopup();
    }
  };
}
