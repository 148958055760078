const FLOCTORY_CHECK_TIMEOUT = 1500;
/**
 * Название события, которое отправляем в dataLayer.push,
 * для показа системного попапа разрешения пуш уведомлений
 */
export const FLOCKTORY_KEY_EVENT = 'show_push_cian_site';

export const checkFlocktoryLoad: () => Promise<IFlocktory> = () => {
  return new Promise(resolve => {
    let timeIntervalFlocktoryCheck: number;

    const flocktoryAttempt = () => {
      if (window.flocktory && typeof window.flocktory.getSubscriptionStatus === 'function') {
        clearTimeout(timeIntervalFlocktoryCheck);

        return resolve(window.flocktory);
      }
      // @ts-ignore
      timeIntervalFlocktoryCheck = setTimeout(flocktoryAttempt, FLOCTORY_CHECK_TIMEOUT);
    };

    // @ts-ignore
    timeIntervalFlocktoryCheck = setTimeout(flocktoryAttempt, FLOCTORY_CHECK_TIMEOUT);
  });
};
