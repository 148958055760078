import * as React from 'react';
import { NoSsr } from '@cian/react-utils';

import { VacanciesContainer } from '../../../../containers/Vacancies/VacanciesContainer/VacanciesContainer';
import { AboutRealtorDesktopContainer } from '../../../../containers/aboutRealtor';
import { Aside } from '../../../aside';
import { Section } from '../../../common/section';
import { ContentContainer } from '../../../content/container';
import { OffersContainer } from '../../../offers/container';
import { ReviewFormContainer } from '../../../review_form/container';
import { ReviewsHeaderContainer } from '../../../reviews_header/container';
import { ReviewsListContainer } from '../../../reviews_list/container';
import { ChatModalContainer } from '../../../../containers/ChatModalContainer';

import * as styles from './index.css';

export const ViewDesktop = () => (
  <div className={styles['container']}>
    <div className={styles['back_button']}>
      <a className={styles['back_button-link']} href="/realtors/">
        ← Все специалисты
      </a>
    </div>
    <div className={styles['content']}>
      <main className={styles['main']}>
        <AboutRealtorDesktopContainer />
        <Section className={styles['reviews']}>
          <ReviewsHeaderContainer />
          <ReviewsListContainer />
          <ReviewFormContainer staticForm />
        </Section>
        <ContentContainer />
        <VacanciesContainer />
      </main>
      <aside className={styles['aside']}>
        <Aside />
      </aside>
      <NoSsr>
        <OffersContainer />
      </NoSsr>
      <NoSsr>
        <ChatModalContainer />
      </NoSsr>
    </div>
  </div>
);
