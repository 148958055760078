import * as React from 'react';

import * as styles from './index.css';

export interface IReportPopupNextItemProps {
  url: string;
  name: string;
}

export const ReportPopupLinkItem = ({ url, name }: IReportPopupNextItemProps) => {
  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (url) {
      const objectUrl = window.location.href;
      window.open(`${url}&object_url=${objectUrl}`, '_blank');
    }
  };

  return (
    <li className={styles['links-link']}>
      <a href="#" className={styles['links-link-anchor']} onClick={handleLinkClick}>
        {name}
      </a>
    </li>
  );
};
