import { EAnswerFormState } from '../../store/answer_form';
import { EReviewFormState } from '../../store/review_form';
import { IReview } from '../../types/review';
import { timestampToDate } from '../../utils/time';

interface IReviewHelperProps {
  realtyUserId: number | undefined;
  editFormReviewId?: string;
  editFormState?: EReviewFormState;
}

interface IAnswerHelperProps {
  reviewId?: number;
  answerFormAnswerId?: string;
  answerFormReviewId?: string;
  answerFormState?: EAnswerFormState;
}

export type TAnswerHelperProps = IReviewHelperProps & IAnswerHelperProps;

/**
 * Проверяет доступность редактирования  отзыва на основании статуса, даты публикации и id пользователя, и id отзыва
 */
export const isEditAvailable = (review: IReview, props: IReviewHelperProps) => {
  const { realtyUserId, editFormState, editFormReviewId } = props;

  return (
    review.status !== 'declined' &&
    review.user.realtyUserId === realtyUserId &&
    timestampToDate(review.created + 20 * 60) > new Date() &&
    (typeof editFormState === 'undefined' || editFormReviewId !== review.reviewId)
  );
};

/**
 * Проверяет доступность добавления ответа на основании статуса ответа, состояния формы отзыва и формы ответа, а также id отзыва и id ответа
 */
export const isReplyAvailable = (review: IReview, props: TAnswerHelperProps) => {
  const { editFormState, editFormReviewId, answerFormState, answerFormReviewId, answerFormAnswerId } = props;

  return (
    !isEditAvailable(review, props) &&
    review.status !== 'declined' &&
    (typeof answerFormState === 'undefined' ||
      answerFormReviewId !== review.reviewId ||
      typeof answerFormAnswerId !== 'undefined') &&
    (typeof editFormState === 'undefined' || editFormReviewId !== review.reviewId)
  );
};

/**
 * Проверяет видимость формы  редактирования отзыва на основании состояния формы и id отзыва
 */
export const isEditFormVisible = (reviewId: string, props: IReviewHelperProps) => {
  const { editFormState, editFormReviewId } = props;

  return typeof editFormState !== 'undefined' && editFormReviewId === reviewId;
};

/**
 * Проверяет видимость формы написания / редактирования ответа на основании состояния формы и id отзыва, и id ответа
 */
export const isAnswerFormVisible = (reviewId: string, props: TAnswerHelperProps) => {
  const { answerFormState, answerFormReviewId, answerFormAnswerId } = props;

  return (
    typeof answerFormState !== 'undefined' &&
    answerFormReviewId === reviewId &&
    typeof answerFormAnswerId === 'undefined'
  );
};
