import { IAppState, IContext, IThunkActionCreator } from './index';
import { prepareLocations } from '../mappers/prepareLocations';
import { ILocation } from '../types/location';
import {
  fetchSearchRegionsCities,
  ISearchRegionsCitiesResponse200,
  ISearchRegionsCitiesResponse400,
} from '../repositories/geo-temp-layer/v1/search-regions-cities';
import { TThunkDispatch } from '../types/redux';

export interface IGetRegionsParams {
  request: string;
}

export const getLocations = (params: IGetRegionsParams): IThunkActionCreator<Promise<ILocation[]>> => {
  return async (dispatch: TThunkDispatch, getState: () => IAppState, context: IContext) => {
    const { httpApi, logger } = context;
    const defaultData: ILocation[] = [];

    try {
      const regionsData = await fetchSearchRegionsCities<
        ISearchRegionsCitiesResponse200,
        ISearchRegionsCitiesResponse400
      >({
        httpApi,
        parameters: {
          query: params.request,
        },
      });

      if (regionsData.statusCode === 200) {
        return prepareLocations(regionsData.response.items);
      }

      if (regionsData.statusCode !== 400) {
        logger.error('Unable to retrieve location data');
      }

      return defaultData;
    } catch (error) {
      logger.error(error);

      return defaultData;
    }
  };
};
