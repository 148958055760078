import * as React from 'react';

import * as styles from './index.css';

export interface IReportPopupNextItemProps {
  name: string;
  onNextLinkClick(): void;
}

export const ReportPopupNextItem = ({ name, onNextLinkClick }: IReportPopupNextItemProps) => (
  <li className={styles['links-link']}>
    <a
      href="#"
      className={styles['links-link-anchor']}
      onClick={event => {
        event.preventDefault();
        onNextLinkClick();
      }}
    >
      {name}
    </a>
  </li>
);
