import * as React from 'react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { trackPhonesOpen } from './tracking';
import { selectPageData, selectRealtor, selectIsCalltrackingModalOpen } from '../../selectors';
import { CalltrackingCallModal } from '../../components/CalltrackingCallModal';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { closeCalltrackingModal } from '../../store/calltracking_modal';

export const CalltrackingCallModalContainer = () => {
  const dispatch = useAppDispatch();

  const { entityId } = useSelector(selectPageData);
  const { ctPhoneNumber } = useSelector(selectRealtor);
  const isModalOpen = useSelector(selectIsCalltrackingModalOpen);

  const fullPhone = React.useMemo(() => {
    return ctPhoneNumber ? `tel:${ctPhoneNumber}` : '';
  }, [ctPhoneNumber]);

  const handleModalClose = useCallback(() => {
    dispatch(closeCalltrackingModal());
  }, [dispatch]);

  const handleCallClick = useCallback(() => {
    trackPhonesOpen(entityId);
  }, [entityId]);

  return (
    <CalltrackingCallModal
      phone={fullPhone}
      isModalOpen={isModalOpen}
      onCallClick={handleCallClick}
      onClose={handleModalClose}
    />
  );
};
