/* eslint-disable prettier/prettier */
import * as React from 'react';
import { IStyleProps, mergeStyles } from '@cian/utils';

import { IOffer } from '../../../api/offer';
import { Button } from '../../../components/button';
import { IAuthenticatedUser, TUser } from '../../../store';
import { canModerateAnnouncements, canModerateUsers, canViewUsers } from '../../../utils/user';
import { LoginMotivationPopupContainer } from '../../login_motivation_popup/container';
import { LikeButtonContainer } from './like/container';
import { MoreButton } from './more';

import style from './index.css';

export interface IOfferMenuProps {
  containerStyle?: IStyleProps;
  likeButtonStyle?: IStyleProps;
  callbackButtonStyle?: IStyleProps;
  mapButtonStyle?: IStyleProps;
  moreButtonStyle?: IStyleProps;
  offer: IOffer;

  isCallbackVisible: boolean;
  isFavoriteActive: boolean;
  isMapActive: boolean;
  isMoreActive: boolean;

  isReporting: boolean;
  onComplainStateChanged(isReporting: boolean): void;

  user: TUser;

  // Боль
  onMenuOpened?(): void;
  toggleFavorite(value: boolean): void;
  toggleMore(value: boolean): void;
  onCallbackClick(): void;
  onMenuClosed?(): void;
  onCommentClicked(): void;
  onPDFLinkClicked?(): void;
  onDocLinkClicked?(): void;
  onHideFromPrintClicked?(): void;
  onMapButtonClick(): void;
}

export class OfferMenu extends React.Component<IOfferMenuProps> {
  public render() {
    const {
      offer,
      containerStyle,
      likeButtonStyle,
      callbackButtonStyle,
      mapButtonStyle,
      moreButtonStyle,

      isFavoriteActive,
      isMapActive,
      isMoreActive,
      isReporting,
      toggleFavorite,
      toggleMore,

      isCallbackVisible,
      onCallbackClick,

      user,
    } = this.props;

    return (
      <div {...mergeStyles(style['container'], containerStyle)}>
        <div className={style['like-container']}>
          <LikeButtonContainer
            isActive={!!offer.isFavorite}
            buttonStyle={!isFavoriteActive && !isMapActive && !isMoreActive ? likeButtonStyle : undefined}
            toggleActivity={toggleFavorite}
          />
          <LoginMotivationPopupContainer id={this.props.offer.id} />
        </div>
        {isCallbackVisible && (
          <Button
            theme="primary"
            mode="light"
            {...mergeStyles(style['callback'], !isMapActive && !isMoreActive ? callbackButtonStyle : undefined)}
            onClick={onCallbackClick}
          >
            Перезвоните мне
          </Button>
        )}
        <Button
          theme="primary"
          mode="light"
          {...mergeStyles(style['btn-map'], !isMapActive && !isMoreActive ? mapButtonStyle : undefined)}
          onClick={this.onMapButtonClick}
        >
          <div className={style['icn-btn-map']}></div>
          {isMapActive ? 'Скрыть карту' : 'Показать на карте'}
        </Button>
        <MoreButton
          offer={offer}
          isActive={isMoreActive}
          containerStyle={!isMapActive && !isMoreActive ? moreButtonStyle : undefined}
          toggleActivity={toggleMore}
          isReporting={isReporting}
          onComplainStateChanged={this.props.onComplainStateChanged}
          realtyUserId={(user as IAuthenticatedUser).realtyUserId}
          userCanModerateAnnouncements={canModerateAnnouncements(user as IAuthenticatedUser)}
          userCanModerateUsers={canModerateUsers(user as IAuthenticatedUser)}
          userCanViewUsers={canViewUsers(user as IAuthenticatedUser)}
          onMenuOpened={this.props.onMenuOpened}
          onCommentClicked={this.props.onCommentClicked}
          onPDFLinkClicked={this.props.onPDFLinkClicked}
          onDocLinkClicked={this.props.onDocLinkClicked}
          onHideFromPrintClicked={this.props.onHideFromPrintClicked}
        />
      </div>
    );
  }

  private onMapButtonClick = () => {
    this.props.onMapButtonClick();
  };
}
