/* eslint-disable react/forbid-elements */
import * as React from 'react';

export const IconChevron = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6">
    {/* tslint:disable-next-line:max-line-length */}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.628.281L5 3.68 8.372.281a.948.948 0 0 1 1.348 0 .966.966 0 0 1 0 1.36L5.674 5.718a.948.948 0 0 1-1.349 0L.28 1.641a.968.968 0 0 1 0-1.36.95.95 0 0 1 1.349 0z"
    />
  </svg>
);
