import * as React from 'react';

import { ISocialProfiles } from '../../types/agentCard';
import { SocialItem } from './SocialItem';

interface ISocialProfilesProps {
  socialProfiles: ISocialProfiles[];
  name?: string;
}

export const SocialProfiles = ({ socialProfiles, name }: ISocialProfilesProps) => {
  return (
    <>
      {socialProfiles.map(socialProfile => {
        if (!['vk', 'ok'].includes(socialProfile.socialType) || !socialProfile.url) {
          return null;
        }

        return (
          <SocialItem
            key={socialProfile.socialType}
            type={socialProfile.socialType}
            url={socialProfile.url}
            text={name}
          />
        );
      })}
    </>
  );
};
