import { resolve as urlResolve } from './url';

export function requestAuthentication(anchorHash?: string) {
  /* istanbul ignore else  */
  if (window.waitForLoginPopup) {
    if (anchorHash) {
      window.history.pushState({}, window.document.title, urlResolve(window.location.href, `#${anchorHash}`));
    }

    window.waitForLoginPopup();
  }
}
