import * as React from 'react';
import cx from 'classnames';

import { LinkProps } from './types';

import * as styles from './styles.css';

export const Link: React.FC<LinkProps> = React.memo(props => {
  const { className, children, rel } = props;
  const protectedRel = rel ? (rel.includes('noreferrer') ? rel : `${rel} noreferrer`) : 'noreferrer';

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a {...props} className={cx(styles.link, className)} rel={protectedRel}>
      {children}
    </a>
  );
});

Link.displayName = 'Link';
