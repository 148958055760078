import { IGetCommentListSuccessResponseDataSchema as IComment } from '../types/comment';

export type TAdvicesActions = IAdvicesFetched;

export interface IAdvicesData {
  rating: number;
  advices: IComment[];
  totalCount: number;
}

export interface IAdvicesFetched extends IAdvicesData {
  type: 'IAdvicesFetched';
}

const defaultState = {
  advices: [],
  rating: 0,
  totalCount: 0,
};

export function advicesReducer(state: IAdvicesData = defaultState, action: TAdvicesActions): IAdvicesData {
  switch (action.type) {
    case 'IAdvicesFetched':
      return {
        ...state,
        advices: action.advices,
        rating: action.rating,
        totalCount: action.totalCount,
      };

    default:
      return state;
  }
}
