import * as React from 'react';
import { useSelector } from 'react-redux';

import { RealtorContacts } from '../../components/RealtorContacts';
import { getFormattedPhoneNumber } from '../../utils/formatter';
import { hideLastDigits } from './helpers';
import { selectRealtorName, selectPageData, selectRealtor } from '../../selectors';
import { trackPhonesOpen } from './tracking';
import { useApplicationContext } from '../../utils/applicationContext';

export const ContactsContainer = () => {
  const [phonesMinimized, setPhonesMinimized] = React.useState(true);

  const realtor = useSelector(selectRealtor);
  const name = useSelector(selectRealtorName);
  const { entityId } = useSelector(selectPageData);

  const { config } = useApplicationContext();

  const isInfoAboutCalltrackingEnabled = config.get<boolean>('realtorReviews.infoAboutCalltracking.Enabled');

  const { skype, socialProfiles, phone, contactEmail, website } = React.useMemo(
    () => ({
      socialProfiles: realtor.socialProfiles || [],
      phone: realtor.ctPhoneNumber,
      skype: realtor.skype,
      contactEmail: realtor.contactEmail,
      website: realtor.website,
    }),
    [realtor.contactEmail, realtor.ctPhoneNumber, realtor.skype, realtor.socialProfiles, realtor.website],
  );

  const formattedPhone = React.useMemo(() => {
    return phone ? getFormattedPhoneNumber(phone) : '';
  }, [phone]);

  const hiddenPhone = React.useMemo(() => (formattedPhone ? hideLastDigits(formattedPhone) : ''), [formattedPhone]);

  const handlePhoneClick = React.useCallback(() => {
    trackPhonesOpen(entityId);
    setPhonesMinimized(false);
  }, [entityId]);

  return (
    <RealtorContacts
      fullPhone={phone}
      formattedPhone={formattedPhone}
      hiddenPhone={hiddenPhone}
      socialProfiles={socialProfiles}
      name={name}
      skype={skype}
      email={contactEmail}
      website={website}
      isPhoneMinimized={phonesMinimized}
      onPhoneClick={handlePhoneClick}
      isInfoAboutCalltrackingEnabled={Boolean(isInfoAboutCalltrackingEnabled)}
    />
  );
};
