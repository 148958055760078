import * as React from 'react';
import { DropdownPopover } from '@cian/ui-kit';

import { IOffer } from '../../../../api/offer';
import { ReliableIcon } from '../../../../components/offer_card/address/reliable_icon';
import { offerHelper } from '../../../../offer/presenters';
import { trackJKLinkClicked } from '../../../../tracking/offer';
import { ReliablePopupContent } from '../../../offer_card/address/components';

import * as styles from './index.css';

export interface IJkNameStoreProps {
  isReliableActive: boolean;
}

export interface IJkNameOwnProps {
  offer: IOffer;
}

type IProps = IJkNameStoreProps & IJkNameOwnProps;

type JkNameState = {
  isReliablePopupVisible: boolean;
};

export class JkName extends React.Component<IProps, JkNameState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      isReliablePopupVisible: false,
    };
  }

  public setPupupVisibility = (isReliablePopupVisible: boolean) => {
    this.setState({ isReliablePopupVisible });
  };

  public render() {
    const offer = offerHelper(this.props.offer);

    if (offer.isRent()) {
      return null;
    }

    const { isReliableActive } = this.props;
    const { geo, newbuilding } = this.props.offer;
    const isReliable = isReliableActive && newbuilding && newbuilding.showJkReliableFlag;

    const reliableIcon = (href: string) =>
      isReliable && (
        <DropdownPopover
          open={this.state.isReliablePopupVisible}
          flip={false}
          sameWidth="minWidth"
          content={
            <ReliablePopupContent
              href={href}
              onMouseEnter={() => this.setPupupVisibility(true)}
              onMouseLeave={() => this.setPupupVisibility(false)}
            />
          }
        >
          <ReliableIcon
            wrapperClassName={styles['external-reliable-icon']}
            onMouseEnter={() => this.setPupupVisibility(true)}
            onMouseLeave={() => this.setPupupVisibility(false)}
          />
        </DropdownPopover>
      );

    if (geo && geo.jk && geo.jk.displayName) {
      const { fullUrl } = geo.jk;

      return (
        <div className={styles['container']}>
          {!fullUrl && geo.jk.displayName}
          {fullUrl && (
            // 19px - ширина иконки "Надежный выбор"
            <div style={{ marginLeft: isReliable ? 19 : 0 }}>
              {reliableIcon(fullUrl)}
              <a
                href={fullUrl}
                className={styles['building-link']}
                target="_blank"
                onClick={this.handleNameLinkClick}
                rel="noreferrer"
              >
                {geo.jk.displayName}
              </a>
            </div>
          )}
        </div>
      );
    }

    /**
     * Без понятия, зачем это здесь, но пока не трогаем;
     */
    return <div></div>;
  }

  private handleNameLinkClick = () => {
    trackJKLinkClicked(this.props.offer);
  };
}
