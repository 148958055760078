// Код из https://nodejs.org/api/url.html#urlresolvefrom-to на замену resolve из пакета 'url'
export function resolve(from: string, to: string) {
  const resolvedUrl = new URL(to, new URL(from, 'resolve://'));

  if (resolvedUrl.protocol === 'resolve:') {
    // `from` is a relative URL.
    const { pathname, search, hash } = resolvedUrl;

    return pathname + search + hash;
  }

  return resolvedUrl.toString();
}
