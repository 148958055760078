export type TModalsState = string[];

interface IModalOpenedAction {
  id: string;
  type: 'IModalOpenedAction';
}

interface IModalClosedAction {
  id: string;
  type: 'IModalClosedAction';
}

export function openModal(id: string): IModalOpenedAction {
  return {
    id,
    type: 'IModalOpenedAction',
  };
}

export function closeModal(id: string): IModalClosedAction {
  return {
    id,
    type: 'IModalClosedAction',
  };
}

export type TModalState = string[];
export type TModalActions = IModalClosedAction | IModalOpenedAction;

export const defaultModalsState: TModalsState = [];

export function modalsReducer(state: TModalState = defaultModalsState, action: TModalActions): TModalState {
  switch (action.type) {
    case 'IModalOpenedAction':
      if (!state.includes(action.id)) {
        return state.concat([action.id]);
      }

      return state;

    case 'IModalClosedAction':
      if (state.includes(action.id)) {
        return state.filter(modalId => modalId !== action.id);
      }

      return state;

    default:
      return state;
  }
}
