/* eslint-disable react/forbid-elements */
import * as React from 'react';
import { Component } from 'react';

import * as styles from './index.css';

export interface IAvatarProps {
  src?: string;
}

export class Avatar extends Component<IAvatarProps, {}> {
  public render() {
    return <div className={styles['container']}>{this.renderAvatar()}</div>;
  }

  private renderAvatar() {
    const { src } = this.props;

    if (!src) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.09 18.09" width="20" height="20">
          {/* tslint:disable-next-line:max-line-length */}
          <path
            fill="#fff"
            d="M9.005 9.547c2.65 0 3.537-2.66 3.776-4.841.305-2.66-.94-4.708-3.776-4.708S4.924 2.019 5.229 4.706c.238 2.18 1.179 4.84 3.776 4.841zm7.619 6.65a15.412 15.412 0 0 0-.331-2.514 4.72 4.72 0 0 0-1.325-3.125 7.925 7.925 0 0 0-1.855-.719 8.59 8.59 0 0 1-.795-.292 4.717 4.717 0 0 1-6.625 0c-.272.13-.55.245-.834.346-.63.147-1.239.37-1.815.665a4.717 4.717 0 0 0-1.325 3.071c-.163.81-.26 1.633-.292 2.46 0 .639.318.732.901.918.734.238 1.482.43 2.24.572a19.8 19.8 0 0 0 8.956 0 18.14 18.14 0 0 0 2.239-.572c.583-.186.927-.28.9-.918l-.039.108z"
          />
        </svg>
      );
    }

    return <img className={styles['image']} src={src} />;
  }
}
