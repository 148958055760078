import * as React from 'react';
import { FormField, RadioButtonGroup } from '@cian/ui-kit';

import { EDealTypeEx } from '../../../../../repositories/demand/v2/create-personal-message';
import { IPersonalLeadFormData } from '../../../../../store/personal_leads_form';
import { IPersonalLeadFormProps } from '../../../index';

export interface ILeadTypeProps {
  fieldData: IPersonalLeadFormData['leadType'];
  onChange: IPersonalLeadFormProps['handleSetType'];
}

export const LeadType = ({ fieldData, onChange }: ILeadTypeProps) => {
  return (
    <FormField
      withoutMargin
      required
      label="Что нужно сделать?"
      errorMessage={fieldData.error ? 'Выберите тип сделки' : undefined}
    >
      <RadioButtonGroup
        invalid={fieldData.error}
        spliced={false}
        onChange={onChange}
        value={fieldData.value}
        options={[
          {
            label: 'Купить',
            value: EDealTypeEx.Buy,
          },
          {
            label: 'Продать',
            value: EDealTypeEx.Sale,
          },
          {
            label: 'Продать, чтобы купить',
            value: EDealTypeEx.SellToBuy,
          },
          {
            label: 'Снять',
            value: EDealTypeEx.Tenant,
          },
          {
            label: 'Сдать',
            value: EDealTypeEx.Rent,
          },
        ]}
      />
    </FormField>
  );
};
