import * as React from 'react';
import { Button } from '@cian/ui-kit/button';
import { IconMail16 } from '@cian/ui-kit/icons';

interface IActionMessageMobileProps {
  onClick: () => void;
}

export const ActionMessageMobile: React.FunctionComponent<IActionMessageMobileProps> = ({ onClick }) => {
  return (
    <Button onClick={onClick} fullWidth beforeIcon={<IconMail16 color="white_100" />}>
      Написать
    </Button>
  );
};
