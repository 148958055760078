/* eslint-disable react/forbid-elements */
import * as React from 'react';

export const OKIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM4.6025 8.13282C4.31817 8.66029 4.64099 8.91242 5.37756 9.34315C6.00372 9.70821 6.86873 9.84172 7.4244 9.89513L5.2347 11.8829C4.59739 12.4594 5.6234 13.3843 6.26024 12.8201L7.98431 11.2508L8.01667 11.2802L8.01737 11.2808C8.66543 11.8692 9.28422 12.431 9.70838 12.8223C10.3457 13.3887 11.3712 12.4716 10.7409 11.8851C10.7201 11.8664 10.2659 11.4554 9.77541 11.0114L9.77153 11.0079C9.14741 10.443 8.46609 9.82634 8.54416 9.89732C9.10679 9.84392 9.95885 9.70253 10.5776 9.34534L10.5771 9.3449C11.3137 8.91198 11.6365 8.66029 11.3564 8.13282C11.1871 7.83341 10.7307 7.58303 10.1231 8.01594C10.1231 8.01594 9.30253 8.60907 7.97921 8.60907C6.65543 8.60907 5.83537 8.01594 5.83537 8.01594C5.22821 7.58084 4.76994 7.83341 4.6025 8.13282ZM7.42446 9.89513L7.4244 9.89513C7.42029 9.89886 7.34501 9.96721 7.42446 9.89513ZM10.4628 5.48734C10.4628 6.85664 9.34605 7.96894 7.97848 7.96894C6.61052 7.96894 5.49381 6.85664 5.49381 5.48734C5.49381 4.1123 6.61052 3 7.97848 3C9.34605 3 10.4628 4.1123 10.4628 5.48734ZM9.1986 5.48734C9.1986 4.80882 8.65047 4.26004 7.97848 4.26004C7.3061 4.26004 6.75797 4.80841 6.75836 5.48734C6.75836 6.16012 7.30649 6.70891 7.97848 6.70891C8.65047 6.70891 9.1986 6.16012 9.1986 5.48734Z"
      fill="#0468FF"
    />
  </svg>
);
