export type TPushSubscriptionState = boolean;

export interface IPushSubscriptionSetValueAction {
  type: 'IPushSubscriptionSetValue';
  value: boolean;
}

export function pushSubscriptionSetValue(value: boolean): IPushSubscriptionSetValueAction {
  return {
    type: 'IPushSubscriptionSetValue',
    value,
  };
}

export type TPushSubscriptionReducer = (
  state: TPushSubscriptionState,
  action: IPushSubscriptionSetValueAction,
) => TPushSubscriptionState;

const defaultState: TPushSubscriptionState = false;

export type TSubscriptionActions = IPushSubscriptionSetValueAction;

export const pushSubscriptionReducer: TPushSubscriptionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'IPushSubscriptionSetValue':
      return action.value;

    default:
      return state;
  }
};
