import { plural } from '@cian/utils';

import { IAgentCard } from '../../../types/agentCard';
import { TReviewsState } from '../../../store/reviews';
import { formatRate } from '../../../components/aside_general_info/utils/formatRate';

export const DESCRIPTION_DISPLAY_LENGTH = 260;

export const isLongDescription = (description: IAgentCard['description']) =>
  Boolean(description && description.length > DESCRIPTION_DISPLAY_LENGTH);

export const getDescriptionText = (description: IAgentCard['description'], isMinimized: boolean) => {
  return description && isLongDescription(description) && isMinimized
    ? `${description.slice(0, DESCRIPTION_DISPLAY_LENGTH)}...`
    : description;
};

export const getCounters = ({
  experience,
  age,
  offersCount,
}: {
  experience: IAgentCard['experience'];
  age: IAgentCard['age'];
  offersCount: number;
}) => [
  { label: 'Опыт работы', text: experience || 'не указан' },
  { label: 'На Циан', text: age },
  {
    label: 'В работе',
    text: offersCount > 0 ? `${offersCount} ${plural(offersCount, ['объект', 'объекта', 'объектов'])}` : 'нет объектов',
  },
];

export const getRatingSubtitle = ({
  totalCount,
  entityRate,
}: {
  totalCount: TReviewsState['totalCount'];
  entityRate: TReviewsState['entityRate'];
}) =>
  totalCount > 0 && entityRate
    ? `${formatRate(entityRate)}・${totalCount} ${plural(totalCount, ['отзыв', 'отзыва', 'отзывов'])} `
    : 'Нет оценок и отзывов';
