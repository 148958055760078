import * as React from 'react';
import { mergeStyles } from '@cian/utils';

import { IButtonsProps } from '../types';
import { LikeButtonContainer } from '../../../../offer_card/menu/like/container';
import { LoginMotivationPopupContainer } from '../../../../login_motivation_popup/container';

import * as styles from '../index.css';

export type LikeButtonProps = Pick<IButtonsProps, 'buttonClassName'> & {
  onClick: (value: boolean) => void;
  offerId: IButtonsProps['offer']['id'];
  isFavoriteOffer: boolean;
};

export const LikeButton = React.memo(({ offerId, isFavoriteOffer, buttonClassName, onClick }: LikeButtonProps) => {
  return (
    <div {...mergeStyles([buttonClassName || '', styles['like-container']])}>
      <LikeButtonContainer
        isActive={isFavoriteOffer}
        buttonClassName={styles['like-button']}
        toggleActivity={onClick}
      />
      <LoginMotivationPopupContainer id={offerId} />
    </div>
  );
});

LikeButton.displayName = 'LikeButton';
