import * as React from 'react';
import { mergeStyles } from '@cian/utils';

import * as styles from './index.css';

interface IActionsMobileProps {
  className?: string;
  children: React.ReactNode;
}

export const ActionsMobile: React.FunctionComponent<IActionsMobileProps> = ({ className, children }) => {
  const hasOneButton = React.Children.map(children, child => child)?.length === 1;

  return (
    <div {...mergeStyles(styles['container'], className)}>
      {React.Children.map(
        children,
        child =>
          child && <div {...mergeStyles(styles['item'], hasOneButton ? styles['item-has-one'] : '')}>{child}</div>,
      )}
    </div>
  );
};
