//tslint:disable
export interface IComplaintItem {
  /** Id типа/группы жалобы
в формате ComplaintOnSiteId_ComplaintReasonId **/
  id?: string | null;
  /** заголовок окна, при переходе по данному пункту **/
  title?: string | null;
  /** название пункта **/
  name?: string | null;
  /** текстовая подсказка для textbox **/
  hint?: string | null;
  /** тип пункта,
Transitional - означает промежуточный пункт, при нажатии на который должно открыться новое окно с подпунктами
Finish- означает что это конечный пункт, при нажатии на который должно отправиться сообщение в API на создание
жалобы
Textarea- означает что это конечный пункт, который предполагает ввод текста и кнопку, при нажатии на которую должно
отправиться сообщение в API на создание жалобы
Link - ссылка на страницу **/
  blockType?: EBlockType | null;
  /** вложенные пункты **/
  items?: IComplaintItem[] | null;
  /** список категорий для которых нужно показывать этот пункт, если список пустой, то для всех **/
  categories?: number[] | null;
  /** Ссылка **/
  url?: string | null;
  /** complaintReasonId - данные для передачи в API создания жалобы **/
  linkType?: ELinkType | null;
  /** Является ли комментарий обязательным к заполнению **/
  isCommentRequired?: boolean | null;
}

export enum EBlockType {
  /** Промежуточный = 1 **/
  Transitional = 'transitional',
  /** Конечный = 2 **/
  Finish = 'finish',
  /** Текст-ария = 3 **/
  Textarea = 'textarea',
  /** Промежуточный (Как вы узнали?) = 4 **/
  WithVirtualPath = 'withVirtualPath',
  /** Ссылка **/
  Link = 'link',
}
export enum ELinkType {
  /** Тип жалобы для жалобы от собственника фото **/
  PhotoComplaint = 'photoComplaint',
  /** тип жалобы для жалобы от собственника объекта **/
  ObjectComplaint = 'objectComplaint',
}
