import * as React from 'react';

import { SuperAgentLevelView } from '../SuperAgentLevelView';
import { getTooltipText } from './utils';
import { useApplicationContext } from '../../utils/applicationContext';
import { trackOpenTooltip } from './tracking';

type TSuperAgentLevelViewContainerProps = {
  levelName: string;
};

export const SuperAgentLevelViewContainer = ({ levelName }: TSuperAgentLevelViewContainerProps) => {
  const { config } = useApplicationContext();

  const tooltipText = getTooltipText(config);

  return <SuperAgentLevelView tooltipText={tooltipText} levelName={levelName} onOpenTooltip={trackOpenTooltip} />;
};
