import * as React from 'react';
import { UIHeading4, UIText3 } from '@cian/ui-kit';

import * as styles from './AboutRealtorDesktop.css';

export interface ICounter {
  label: string;
  text: string;
}

interface ICountersProps {
  counters: ICounter[];
}

export const Counters = ({ counters }: ICountersProps) => {
  return (
    <div className={styles['counters']}>
      {counters.map(({ label, text }) => (
        <div key={label} className={styles['counters-item']}>
          <UIText3 color="gray60_100">{label}</UIText3>
          <UIHeading4>{text}</UIHeading4>
        </div>
      ))}
    </div>
  );
};
