/* eslint-disable react/forbid-elements */
import * as React from 'react';

export const VKIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.2982 8.44943C11.1828 8.63714 11.1365 8.73057 11.2982 8.94057C11.3103 8.95779 11.3716 9.02101 11.4642 9.11657C11.8554 9.52042 12.8064 10.5019 12.9745 11.0311C13.0653 11.3423 12.9074 11.5 12.5957 11.5H11.5024C11.2106 11.5 11.0635 11.3348 10.745 10.9773C10.6096 10.8253 10.4432 10.6385 10.2215 10.4149C9.57148 9.78271 9.29313 9.70043 9.13146 9.70043C8.83526 9.70043 8.83601 9.78575 8.84296 10.5786C8.84419 10.7193 8.84562 10.8823 8.84562 11.071C8.84562 11.3423 8.76062 11.5 8.06434 11.5C6.90597 11.5 5.63259 10.7899 4.72839 9.47929C3.37168 7.55929 3 6.106 3 5.81371C3 5.64829 3.06292 5.50043 3.37501 5.50043H4.4688C4.74923 5.50043 4.85465 5.62386 4.96257 5.92943C5.49717 7.50443 6.39928 8.88057 6.77096 8.88057C6.91055 8.88057 6.97347 8.815 6.97347 8.45843V6.83114C6.94823 6.36749 6.7881 6.16705 6.66951 6.0186C6.59627 5.92691 6.53887 5.85506 6.53887 5.75329C6.53887 5.63114 6.64263 5.5 6.81722 5.5H8.53644C8.76687 5.5 8.84479 5.62557 8.84479 5.90671V8.09457C8.84479 8.329 8.95104 8.41129 9.01938 8.41129C9.15813 8.41129 9.27563 8.329 9.52981 8.07229C10.3173 7.18386 10.874 5.81329 10.874 5.81329C10.9428 5.64786 11.0711 5.5 11.3495 5.5H12.4428C12.7733 5.5 12.8416 5.67229 12.7733 5.90671L12.7724 5.90714C12.6349 6.55043 11.3003 8.44729 11.2982 8.44943Z"
      fill="#0468FF"
    />
  </svg>
);
