import * as React from 'react';
import { Button } from '@cian/ui-kit/button';

import { ActionsMobile, ActionCallMobile } from '../../../components/actions';

interface IApplicationButtonProps {
  hasPhone: boolean;
  openModalLeadForm: () => void;
  onPhoneClick: () => void;
  className?: string;
}

export const ApplicationButton: React.FC<IApplicationButtonProps> = ({
  className,
  hasPhone,
  openModalLeadForm,
  onPhoneClick,
}) => {
  return (
    <ActionsMobile className={className}>
      <Button fullWidth onClick={openModalLeadForm}>
        Оставить заявку
      </Button>
      {hasPhone ? <ActionCallMobile onClick={onPhoneClick} /> : null}
    </ActionsMobile>
  );
};
