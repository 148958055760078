interface IOptions {
  serviceDomain?: 'vk.com' | 'ok.ru';
  withoutProtocol?: boolean;
}

export const getHref = (href: string, { serviceDomain, withoutProtocol }: IOptions = {}) => {
  const result = /^(https?:\/\/)?(vk\.com|ok\.ru\/)?(.*)/.exec(href);

  if (!result) {
    return href;
  }

  const defaultProtocol = result[2] || serviceDomain ? 'https://' : 'http://';
  const defaultDomain = serviceDomain ? `${serviceDomain}/` : '';

  const protocol = result[1] || defaultProtocol;
  const domain = result[2] || defaultDomain;
  const path = result[3];

  return `${withoutProtocol ? '' : protocol}${domain}${path}`;
};
