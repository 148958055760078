import { IAgentCard } from '../../types/agentCard';
import { isManagementCompany } from './isManagementCompany';
import { isRentDepartment } from './isRentDepartment';
import { isAgency } from './isAgency';

export const isAvatarRealtorRoundness = (agent: IAgentCard): boolean => {
  const { isDeveloper } = agent;

  return !isAgency(agent) && !isManagementCompany(agent) && !isRentDepartment(agent) && !isDeveloper;
};
