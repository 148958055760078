/* eslint-disable import/no-restricted-paths */
import * as React from 'react';
import { ArticleHeading2, Responsive } from '@cian/ui-kit';
import { getRatingValue, Stars } from '@cian/stars-component';
import { plural } from '@cian/utils/lib/shared/plural';
import { NoSsr } from '@cian/react-utils';

import { IAgentCard } from '../../types/agentCard';
import { parseDecimal } from '../../utils';
import { NewReviewButtonContainer } from '../../containers/NewReviewButtonContainer';

import * as styles from './index.css';

export interface IReviewsHeaderStoreProps {
  count: number;
  realtor: IAgentCard;
  rate: number | undefined;
}

export interface IReviewsHeaderDispatchProps {
  openReviewForm(): void;
}

export type TReviewsHeaderProps = IReviewsHeaderStoreProps & IReviewsHeaderDispatchProps;

export class ReviewsHeader extends React.Component<TReviewsHeaderProps, {}> {
  public render() {
    const { count, realtor, rate } = this.props;

    const formattedRate = parseDecimal(rate) || 0;

    return (
      <header
        itemScope
        className={styles['container']}
        id="reviews"
        itemProp="aggregateRating"
        itemType="http://schema.org/AggregateRating"
      >
        <meta itemProp="itemReviewed" content={realtor.name} />
        <meta itemProp="worstRating" content="1" />
        <meta itemProp="bestRating" content="5" />
        <section className={styles['title-wrapper']} data-name="ReviewsHeaderTitle">
          <ArticleHeading2>
            {'Отзывы'}
            <Responsive desktop>{` о специалисте`}</Responsive>
          </ArticleHeading2>
        </section>
        <div className={styles['rating-summary']}>
          <div className={styles['rating-counters']} data-name="ReviewsHeaderRating">
            {count > 0 && formattedRate * 5 >= 1 && (
              <ArticleHeading2 color="warning_100">{(formattedRate * 5).toFixed(1).replace('.', ',')}</ArticleHeading2>
            )}
            <div className={styles['rate-aside-info']}>
              <span className={styles['invisible']} itemProp="ratingValue">
                {getRatingValue(5, formattedRate, true)}
              </span>
              <NoSsr>
                <Stars
                  percentage
                  containerClassName={styles['rate-value']}
                  value={formattedRate}
                  uniqueKey="reviews-header"
                />
              </NoSsr>
              <ArticleHeading2 color="gray60_100">
                {count > 0 ? `${count} ${plural(count, ['отзыв', 'отзыва', 'отзывов'])}` : 'нет отзывов'}
              </ArticleHeading2>
            </div>
          </div>
          <ul className={styles['action-list']}>
            <li>
              <NewReviewButtonContainer />
            </li>
          </ul>
        </div>
      </header>
    );
  }
}
