import * as React from 'react';
import { HonestWork } from '@cian/honestwork-component';
import { mergeStyles } from '@cian/utils/lib/shared/style';

import { EUserTrustLevel, IOffer } from '../../../../api/offer';
import { offerHelper } from '../../../../offer/presenters';
import { UserInfoPopup } from '../../../offer_card/user_info/popup';
import { PartnerTooltip } from '../../../PartnerTooltip';
import { UserWorkHonestlyAsideInfo } from '../../../UserWorkHonestlyAsideInfo';
import { ApprovalContainer } from '../../../../containers/ApprovalContainer';
import { IUserLevelInfo } from '../../../../types/agentCard';

import * as styles from './index.css';

export interface IAgentOwnProps {
  offer: IOffer;
}

export interface IAgentStoreProps {
  userIsAuthenticated: boolean;
  userLevelInfo?: IUserLevelInfo | null;
}

export interface IAgentDispatchProps {
  onPopupOpen(offerId: number): void;
  onPopupClose(offerId: number): void;
}

export type IAgentProps = IAgentOwnProps & IAgentDispatchProps & IAgentStoreProps;

export class Agent extends React.Component<IAgentProps, {}> {
  public render() {
    const userId = this.props.offer.user && this.props.offer.user.cianUserId;
    const userIdQuery = userId ? `id_user=${userId}&` : '';
    const offerTypeForUserUrl: string =
      this.props.offer.offerType === 'commercial' ? 'offices' : this.props.offer.offerType;

    return (
      <div>
        <UserInfoPopup
          isReverseOrder
          content={this.renderBlock()}
          offer={this.props.offer}
          userIsAuthenticated={this.props.userIsAuthenticated}
          userInfoStyle={styles['user-info']}
          userInfoStyleAction={styles['user-info-action']}
          onPopupClose={this.handlePopupClose}
          onPopupOpen={this.handlePopupOpen}
        />
        {this.props.offer.isTop3 && (
          <a
            href={
              `/cat.php?${userIdQuery}deal_type=${this.props.offer.dealType}&` +
              `${offerTypeForUserUrl}=yes&engine_version=2`
            }
            className={styles['other-offers']}
            target="_blank"
            rel="noreferrer"
          >
            Ещё объекты
          </a>
        )}
      </div>
    );
  }

  private handlePopupClose = () => {
    this.props.onPopupClose(this.props.offer.id);
  };

  private handlePopupOpen = () => {
    this.props.onPopupOpen(this.props.offer.id);
  };

  private renderBlock() {
    const { offer, userLevelInfo } = this.props;
    const { user } = offer;
    const { isCianPartner } = user;
    const offerHelperObject = offerHelper(offer);
    const isPro = offerHelperObject.isPro();
    const userTrustLevel = offerHelperObject.presenters.getUserTrustLevel();
    const isByHomeOwner = offerHelperObject.isByHomeowner();
    const userId = offerHelperObject.presenters.getCianUserId();
    const agentName = offerHelperObject.presenters.getAgentName();
    const companyName = offerHelperObject.presenters.getCompanyName();
    const isFromBuilder = offerHelperObject.isFromBuilder();
    const isFromSeller = offerHelperObject.isFromSeller();

    return (
      <div className={styles['main-info']}>
        {this.renderAvatar()}
        <div {...mergeStyles([styles['name'], styles['agent-section']])}>{agentName || `ID ${userId}`}</div>
        {companyName && <div {...mergeStyles([styles['company-name'], styles['agent-section']])}>{companyName}</div>}
        <div {...mergeStyles([styles['badge-container'], styles['agent-section']])}>
          {userTrustLevel === EUserTrustLevel.Involved && !userLevelInfo && (
            <div {...mergeStyles([styles['badge']])}>
              <HonestWork />
            </div>
          )}
          {userLevelInfo && <UserWorkHonestlyAsideInfo userLevelInfo={userLevelInfo} isProAgent={isPro} />}
          {isPro && !(isFromBuilder || isFromSeller) && !userLevelInfo && (
            <div {...mergeStyles([styles['badge'], styles['text-badge']])}>PRO</div>
          )}
          <ApprovalContainer />
          {isFromBuilder && (
            <div {...mergeStyles([styles['badge'], styles['text-badge'], styles['green']])}>Застройщик</div>
          )}
          {!isFromBuilder && isFromSeller && (
            <div {...mergeStyles([styles['badge'], styles['text-badge'], styles['green']])}>
              Представитель <br /> застройщика
            </div>
          )}
          {isByHomeOwner && <div {...mergeStyles([styles['badge'], styles['text-badge']])}>Собственник</div>}
          {isCianPartner && (
            <PartnerTooltip
              master={<div {...mergeStyles([styles['badge'], styles['text-badge'], styles['green']])}>Партнёр</div>}
            />
          )}
        </div>
      </div>
    );
  }

  private renderAvatar() {
    const user = this.props.offer.user || {};

    return (
      this.props.offer.isTop3 &&
      user.agentAvatarUrl && (
        <img {...mergeStyles([styles['avatar'], styles['agent-section']])} src={user.agentAvatarUrl} />
      )
    );
  }
}
