/* eslint-disable react/no-danger */
import { mergeStyles } from '@cian/utils';
import * as React from 'react';
import { useState, useCallback } from 'react';
import { Responsive } from '@cian/ui-kit';
import sanitize from 'sanitize-html';

import { getText, MAX_REVIEW_LENGTH } from './helpers';

import * as styles from './index.css';

interface ITextProps {
  rating?: number;
  text: string;
  refused?: boolean;
  onFullViewClick?: () => void;
  renderAsHtml?: boolean;
}

export const Text: React.FunctionComponent<ITextProps> = ({ text, rating, refused, renderAsHtml, onFullViewClick }) => {
  const [isFullView, setIsFullView] = useState(false);
  const handleFullViewClick = useCallback(() => {
    setIsFullView(true);

    if (onFullViewClick) {
      onFullViewClick();
    }
  }, [onFullViewClick]);

  const trimmedText = getText(text, isFullView);

  return (
    <>
      <Responsive desktop>
        <p {...mergeStyles(styles['review'], refused ? styles['review--refused'] : '')}>
          {renderAsHtml ? <div dangerouslySetInnerHTML={{ __html: sanitize(text) }} /> : text}
        </p>
      </Responsive>
      <Responsive phone tablet>
        <p {...mergeStyles(styles['review'], refused ? styles['review--refused'] : '')}>
          {renderAsHtml ? <div dangerouslySetInnerHTML={{ __html: sanitize(trimmedText) }} /> : trimmedText}
          {!isFullView && text.length > MAX_REVIEW_LENGTH && (
            <button type="button" className={styles['review_view_all']} onClick={handleFullViewClick}>
              Весь {typeof rating !== 'undefined' ? 'отзыв' : 'ответ'}
            </button>
          )}
        </p>
      </Responsive>
    </>
  );
};
