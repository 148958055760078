/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IUserContextInfoClass } from '../../entities/CreateComplaint/UserContextInfoClass';

export type TCreateComplaintModel = IModel<ICreateComplaintRequest, TCreateComplaintResponse>;

export interface ICreateComplaintRequest {
  userContextInfo?: IUserContextInfoClass | null;
  /** Id объявления **/
  realtyObjectId?: number | null;
  /** Ожидаем строку состоящую из {ComplaintOnSiteId}_{ComplaintReasonId}
нужно потому что у строк стандартного диалогового окна нет своей ComplaintReasonId, и она должна приходить из parent **/
  complaintOnSiteId?: string | null;
  /** Текст жалобы **/
  message?: string | null;
  /** Конечный статус, в который надо перевести жалобу
Обязательно указание жалобщика **/
  complaintState?: EComplaintState | null;
}

export type TCreateComplaintResponse = ICreateComplaintResponse200 | ICreateComplaintResponse400;

export interface ICreateComplaintResponse200 extends IModelResponse<ICreateComplaintResponse> {
  statusCode: 200;
}

export interface ICreateComplaintResponse400 extends IModelResponse<ICreateComplaintResponseError> {
  statusCode: 400;
}

export interface ICreateComplaintResponse {
  /** Идентификатор жалобы **/
  complaintId?: number | null;
  /** Необходим отзыв пользователя **/
  getUserFeedback?: boolean | null;
}

export interface ICreateComplaintResponseError {
  errors: any[];
  message: string;
}

export type TResponses = ICreateComplaintResponse | ICreateComplaintResponseError;

export enum EComplaintState {
  /** Новая **/
  New = 'new',
  /** Прошла проверку на фрод **/
  FrodPassed = 'frodPassed',
  /** Фрод **/
  Frod = 'frod',
  /** Требует удаления **/
  NeedDelete = 'needDelete',
  /** Бесполезная **/
  Useless = 'useless',
  /** Бесполезная — спам **/
  UselessSpam = 'uselessSpam',
  /** Полезная — показать клиенту **/
  UseFull = 'useFull',
  /** Не отправлять в ЛК (бывш. ОК-скрыть) **/
  UsefullHidden = 'usefullHidden',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: ICreateComplaintResponse): TResponse200;
  400(response: ICreateComplaintResponseError): TResponse400;
}
