/* eslint-disable react/forbid-elements */
import { IStyleProps, mergeStyles } from '@cian/utils';
import * as React from 'react';

import { TUser } from '../../../../store';
import { Button } from '../../../button';

import style from './index.css';

export interface ILikeButtonStoreProps {
  user: TUser;
}

export interface ILikeButtonOwnProps {
  isActive: boolean;

  buttonClassName?: string;
  buttonStyle?: IStyleProps;

  toggleActivity(value: boolean): void;
}

export type TLikeButtonProps = ILikeButtonStoreProps & ILikeButtonOwnProps;

export interface ILikeButtonState {
  isActive: boolean;
}

export class LikeButton extends React.Component<TLikeButtonProps, ILikeButtonState> {
  public constructor(props: TLikeButtonProps) {
    super(props);

    this.state = {
      isActive: props.isActive,
    };
  }

  public render() {
    return (
      <Button
        theme="primary"
        mode="light"
        {...mergeStyles(
          style['button'],
          this.props.buttonClassName || '',
          !this.state.isActive && this.props.buttonStyle,
        )}
        onClick={this.handleClick}
      >
        <svg
          {...mergeStyles(style['icon'], this.state.isActive && style['icon--active'])}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 14.004 14"
          width="14"
          height="14"
        >
          {/* tslint:disable-next-line:max-line-length */}
          <path
            stroke="#E84260"
            strokeWidth="2"
            d="M6.99 11.72L1.825 6.677C.727 5.582.725 3.805 1.82 2.707l.015-.015c1.118-1.08 2.962-1.08 4.092.014l.36.35.67.648.694-.62.394-.35.03-.028c1.13-1.095 2.973-1.095 4.104 0 1.1 1.093 1.103 2.87.01 3.968l-.015.015-5.182 5.03z"
          />
        </svg>
        {this.state.isActive ? 'В избранном' : 'В избранное'}
      </Button>
    );
  }

  private handleClick = () => {
    const currentCount = window.__header_favorites_count__ || 0;

    if (this.state.isActive || this.props.user.isAuthenticated || currentCount < 3 || !window.waitForLoginPopup) {
      this.setState({
        isActive: !this.state.isActive,
      });
    }

    this.props.toggleActivity(!this.state.isActive);
  };
}
