/* eslint-disable react/forbid-elements */
import * as React from 'react';
import { mergeStyles } from '@cian/utils';
import cx from 'classnames';
import { Textarea } from '@cian/ui-kit';

import { Button } from '../../../../button';

import * as styles from './index.css';

export interface IReportPopupTextareaItemProps {
  message: string;
  hint: string;
  name: string;
  isSelected: boolean;
  hasMessageError: boolean;
  onDropdownLinkClick(): void;
  onTextAreaValueChange(value: string): void;
  onSubmit(): void;
}

export const ReportPopupTextareaItem = ({
  message,
  hint,
  name,
  isSelected,
  hasMessageError,
  onDropdownLinkClick,
  onTextAreaValueChange,
  onSubmit,
}: IReportPopupTextareaItemProps) => {
  const handleTextAreaValueChange = (e: React.ChangeEvent<HTMLTextAreaElement>, value: string) => {
    onTextAreaValueChange(value);
  };

  return (
    <li {...mergeStyles(styles['links-link'], isSelected && styles['links-link--active'])}>
      {isSelected && (
        <svg
          className={styles['links-link-icon']}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 13.994 9.499"
          width="14"
          height="10"
        >
          {/* tslint:disable-next-line:max-line-length */}
          <path
            fill="#2B87DB"
            d="M13.698.305c-.4-.404-1.052-.408-1.456-.008L5.447 7.01 1.76 3.24c-.396-.4-1.04-.406-1.444-.016-.41.394-.422 1.046-.028 1.456l4.41 4.51c.19.195.452.306.726.31h.01c.27-.002.53-.11.724-.3l7.53-7.44c.406-.4.41-1.05.01-1.455z"
          />
        </svg>
      )}
      <a
        href="#"
        onClick={event => {
          event.preventDefault();
          onDropdownLinkClick();
        }}
        {...mergeStyles(styles['links-link-anchor'], isSelected && styles['links-link-anchor--active'])}
        rel="noreferrer"
      >
        {name}
      </a>
      {isSelected && (
        <div className={cx(styles['textarea'], hasMessageError && styles['textarea--error'])}>
          <Textarea placeholder={hint} value={message} onChange={handleTextAreaValueChange} />
        </div>
      )}
      {isSelected && (
        <Button theme="primary" onClick={onSubmit}>
          Отправить жалобу
        </Button>
      )}
    </li>
  );
};
