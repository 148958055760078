import { numberToPrettyString } from '@cian/utils';

export const getSalary = (minSalaryValue?: number, maxSalaryValue?: number) => {
  let salary = 'Оплата по договорённости';

  if (minSalaryValue || maxSalaryValue) {
    const minSalary = Number(minSalaryValue);
    const maxSalary = Number(maxSalaryValue);

    switch (true) {
      case !!minSalary && !!maxSalary:
        salary = `От ${numberToPrettyString(minSalary)} до ${numberToPrettyString(maxSalary)} ₽/мес.`;
        break;

      case !!minSalary:
        salary = `От ${numberToPrettyString(minSalary)} ₽/мес.`;
        break;

      case !!maxSalary:
        salary = `До ${numberToPrettyString(maxSalary)} ₽/мес.`;
        break;
    }
  }

  return salary;
};
