const MINIMAL_CUT_INDEX = 270;

export const getCutIndex = (text: string) => {
  const firstPart = text.slice(0, MINIMAL_CUT_INDEX);
  const secondPart = text.slice(MINIMAL_CUT_INDEX);
  const firstSpaceIndex = secondPart.indexOf(' ');

  if (~firstSpaceIndex) {
    return firstPart.length + firstSpaceIndex;
  }

  return text.length;
};
