import * as PropTypes from 'prop-types';
import * as React from 'react';
import { DropdownPopover, Outside } from '@cian/ui-kit';

import { MoreButton, MorePopup, LikeButton, Map, PhoneButton, Report } from './components';
import { trackMapClosed, trackMapOpened } from '../../../../tracking/offer';
import { IButtonsProps, IButtonsState } from './types';
import { ReportPopup } from '../../../offer_card/menu/more/report_popup';

export class Buttons extends React.Component<IButtonsProps, IButtonsState> {
  public constructor(props: IButtonsProps) {
    super(props);

    this.state = {
      isMorePopupOpen: false,
      isReportActive: false,
    };
  }

  public reportButtonRef = React.createRef<HTMLDivElement>();
  public reportPopupRef = React.createRef<HTMLDivElement>();
  public moreButtonRef = React.createRef<HTMLDivElement>();
  public morePopupRef = React.createRef<HTMLDivElement>();

  public static contextTypes = {
    isSimilar: PropTypes.bool,
  };

  // eslint-disable-next-line react/no-deprecated
  public componentWillUpdate(nextProps: IButtonsProps, nextState: IButtonsState) {
    if (nextProps.isMapActive !== this.props.isMapActive) {
      if (nextProps.isMapActive) {
        trackMapOpened();
      } else {
        trackMapClosed();
      }
    }

    if (nextState.isMorePopupOpen !== this.state.isMorePopupOpen) {
      if (nextState.isMorePopupOpen) {
        this.props.onPopupMoreOpen(nextProps.offer.id);
      } else {
        this.props.onPopupMoreClose(nextProps.offer.id);
      }
    }

    if (nextState.isReportActive !== this.state.isReportActive) {
      if (nextState.isReportActive) {
        this.props.onPopupReportOpen(nextProps.offer.id);
      } else {
        this.props.onPopupReportClose(nextProps.offer.id);
      }
    }
  }

  // eslint-disable-next-line react/no-deprecated
  public componentWillReceiveProps(nextProps: IButtonsProps) {
    if (!this.state.isReportActive && nextProps.isComplainOpen) {
      this.setState({
        isReportActive: nextProps.isComplainOpen,
      });
    }
  }

  private handleFavoriteClick = (value: boolean) => {
    const { onFavoriteChange } = this.props;

    onFavoriteChange(this.props.offer, value);
  };

  private handleMoreClick = () => {
    this.setState({
      isMorePopupOpen: !this.state.isMorePopupOpen,
    });
  };

  private handleMorePopupClose = () => {
    this.setState({
      isMorePopupOpen: false,
    });
  };

  private handleMapButtonClick = () => {
    const onMapButtonClick = this.props.onMapButtonClick;

    if (onMapButtonClick) {
      onMapButtonClick();
    }
  };

  private handleDownloadClick = () => {
    this.handleMorePopupClose();
  };

  private handleReportClose = () => {
    this.props.onComplainClose();

    this.setState({
      isReportActive: false,
    });
  };

  private handleReportButtonClick = () => {
    this.setState({
      isReportActive: true,
    });
  };

  private isButtonHidden() {
    const { isMorePopupOpen, isReportActive } = this.state;
    const { isMapActive } = this.props;

    return !isMorePopupOpen && !isReportActive && !isMapActive;
  }

  public render() {
    const { containerClassName } = this.props;

    return (
      <div onClick={e => e.stopPropagation()} className={containerClassName || ''}>
        <PhoneButton
          buttonClassName={this.props.buttonClassName}
          offer={this.props.offer}
          isSimplified={this.props.isSimplified}
          isButtonHidden={this.isButtonHidden()}
          onPhoneOpened={this.props.onPhoneOpened}
        />
        <LikeButton
          buttonClassName={this.props.buttonClassName}
          offerId={this.props.offer.id}
          isFavoriteOffer={!!this.props.offer.isFavorite}
          onClick={this.handleFavoriteClick}
        />
        <Map
          buttonClassName={this.props.buttonClassName}
          isMapActive={this.props.isMapActive}
          isButtonHidden={this.isButtonHidden()}
          onClick={this.handleMapButtonClick}
        />
        {this.reportButtonRef.current && this.reportPopupRef.current && (
          <Outside onOutside={this.handleReportClose} insideRefs={[this.reportButtonRef, this.reportPopupRef]}>
            <DropdownPopover
              open={this.state.isReportActive}
              flip={false}
              sameWidth="minWidth"
              content={
                <ReportPopup offer={this.props.offer} onClose={this.handleReportClose} ref={this.reportButtonRef} />
              }
            >
              <Report
                buttonClassName={this.props.buttonClassName}
                onClick={this.handleReportButtonClick}
                ref={this.reportPopupRef}
              />
            </DropdownPopover>
          </Outside>
        )}
        {this.moreButtonRef.current && this.morePopupRef.current && this.reportPopupRef.current && (
          <Outside
            onOutside={this.handleMorePopupClose}
            insideRefs={[this.moreButtonRef, this.morePopupRef, this.reportPopupRef]}
          >
            <DropdownPopover
              open={this.state.isMorePopupOpen}
              flip={false}
              sameWidth="minWidth"
              content={
                <MorePopup
                  offer={this.props.offer}
                  user={this.props.user}
                  onPdfClick={this.handleDownloadClick}
                  onDocClick={this.handleDownloadClick}
                  ref={this.morePopupRef}
                />
              }
            >
              <MoreButton
                buttonClassName={this.props.buttonClassName}
                onClick={this.handleMoreClick}
                ref={this.moreButtonRef}
              />
            </DropdownPopover>
          </Outside>
        )}
      </div>
    );
  }
}
