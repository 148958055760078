import * as React from 'react';
import { Textarea } from '@cian/ui-kit';

import * as styles from '../index.css';

type ReviewTextareaProps = {
  inputRef: React.RefObject<HTMLTextAreaElement>;
  placeholder: string;
  text: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>, value: string) => void;
  disabled: boolean;
  description?: JSX.Element;
};

export const ReviewTextarea = React.forwardRef(
  (
    { inputRef, placeholder, text, onChange, disabled, description }: ReviewTextareaProps,
    ref: React.RefObject<HTMLDivElement>,
  ) => (
    <div ref={ref}>
      <div className={styles['textarea']}>
        <Textarea
          inputRef={inputRef}
          maxLength={5000}
          placeholder={placeholder}
          value={text}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
      {description && <div className={styles['description']}>{description}</div>}
    </div>
  ),
);

ReviewTextarea.displayName = 'ReviewTextarea';
