import * as React from 'react';
import { FormField, Input } from '@cian/ui-kit';

import { ILocation } from '../../types/location';
import { Suggest } from './components';

import * as styles from './styles.css';
export interface ILocationSuggestProps {
  inputValue: string;
  suggest: ILocation[];
  isSuggestShown: boolean;
  placeholder: string;
  isLoading: boolean;
  error: boolean;
  onChange(value: string): void;
  onItemClick(value: ILocation): void;
}
export const LocationSuggest = React.forwardRef(
  (
    {
      onChange,
      error,
      inputValue,
      suggest,
      onItemClick,
      isSuggestShown,
      placeholder,
      isLoading,
    }: ILocationSuggestProps,
    ref: React.RefObject<HTMLDivElement>,
  ) => {
    const handleChange = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
      [onChange],
    );
    const inputProps = {
      loading: isLoading,
      placeholder,
      value: inputValue,
      onChange: handleChange,
      invalid: error,
    };

    return (
      <div className={styles['container']} data-testid="location-suggest" ref={ref}>
        <div className={styles['input-container']}>
          <FormField withoutMargin required label="В каком городе" errorMessage={error ? 'Укажите город' : undefined}>
            <Input {...inputProps} />
          </FormField>
        </div>
        {isSuggestShown && <Suggest suggest={suggest} onItemClick={onItemClick} />}
      </div>
    );
  },
);
LocationSuggest.displayName = 'LocationSuggest';
