import * as React from 'react';
import { useSelector } from 'react-redux';

import { generateVacanciesLink } from './utils/generateVacanciesLink';
import { generateVacanciesText } from './utils/generateVacanciesText';
import { selectRealtorName } from '../../../selectors/realtor/selectRealtorName';
import { selectRealtorVacancies } from '../../../selectors/vacancies/selectRealtorVacancies';
import { AllVacanciesLink } from '../../../components/vacancies/AllVacanciesLink';

export const AllVacanciesLinkContainer = () => {
  const name = useSelector(selectRealtorName);
  const { vacancies, totalCount } = useSelector(selectRealtorVacancies);
  const vacancy = vacancies[0];
  const { regionId } = vacancy;
  const allVacanciesLink = generateVacanciesLink(regionId, name);
  const allVacanciesText = generateVacanciesText(totalCount);

  if (totalCount <= 2) {
    return null;
  }

  return <AllVacanciesLink allVacanciesLink={allVacanciesLink} allVacanciesText={allVacanciesText} />;
};
