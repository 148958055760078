import * as React from 'react';
import { Button } from '@cian/ui-kit/button';
import { IconPhone16 } from '@cian/ui-kit/icons';

interface IActionCallMobileProps {
  hasIcon?: boolean;
  onClick?: () => void;
}

export const ActionCallMobile: React.FunctionComponent<IActionCallMobileProps> = ({ onClick, hasIcon }) => {
  return (
    <Button fullWidth beforeIcon={hasIcon ? <IconPhone16 color="white_100" /> : undefined} onClick={onClick}>
      Позвонить
    </Button>
  );
};
