import * as React from 'react';
import { RoundButton } from '@cian/ui-kit';

import reportImage from '../icons/report.svg';
import { IButtonsProps } from '../types';

export type ReportProps = Pick<IButtonsProps, 'buttonClassName'> & {
  onClick: () => void;
};

export const Report = React.forwardRef(
  ({ buttonClassName, onClick }: ReportProps, ref: React.RefObject<HTMLDivElement>) => {
    const icon = <img src={reportImage} alt="map icon" />;

    return (
      <div className={buttonClassName || ''} ref={ref}>
        <RoundButton size="XS" icon={icon} title="Пожаловаться на объявление" theme="fill_light" onClick={onClick} />
      </div>
    );
  },
);

Report.displayName = 'Report';
