import * as React from 'react';
import { HonestWork } from '@cian/honestwork-component';
import { IStyleConfig, mergeStyles } from '@cian/utils';

import { PartnerTooltip } from '../../PartnerTooltip';
import { EUserTrustLevel, IOffer } from '../../../api/offer';
import { offerHelper } from '../../../offer/presenters';
import { IconBlacklist, IconWhitelist } from './icons';
import { UserInfoPopup } from './popup';

const style = require('./index.css');

export type TUserInfoType = 'vertical' | 'horizontal';

export interface IAppStateProps {
  userIsAuthenticated: boolean;
}

export interface IOwnProps {
  offer: IOffer;
  containerStyle?: IStyleConfig;
  type?: TUserInfoType;
}

export type TUserInfoProps = IAppStateProps & IOwnProps;

export class UserInfo extends React.Component<TUserInfoProps> {
  public static defaultProps = {
    type: 'vertical' as TUserInfoType,
  };

  public render() {
    const isVertical = this.props.type === 'vertical';
    const offer = offerHelper(this.props.offer);
    const isTop3 = offer.isTop3();
    const agentAvatarUrl = offer.presenters.getAgentAvatarUrl();

    let avatar: React.ReactNode = null;

    if (isTop3 && agentAvatarUrl && isVertical) {
      avatar = (
        <div className={`${style['user-avatar']}`}>
          <img src={`${agentAvatarUrl.replace(/(p=\d)$/g, 'p=1')}`} />
        </div>
      );
    }

    const content = isVertical ? this.renderVertical() : this.renderHorizontal();

    return (
      <UserInfoPopup
        avatar={avatar}
        content={content}
        offer={this.props.offer}
        userIsAuthenticated={this.props.userIsAuthenticated}
        containerStyle={mergeStyles(
          isVertical ? style['container'] : style['container-horizontal'],
          this.props.containerStyle,
        )}
        userInfoStyle={mergeStyles(!isVertical && style['user-info--horizontal'])}
      />
    );
  }

  private renderVertical() {
    const offer = offerHelper(this.props.offer);
    const isPro = offer.isPro();
    const isByHomeowner = offer.isByHomeowner();
    const userTrustLevel = offer.presenters.getUserTrustLevel();
    const userPersonalRating = offer.presenters.getUserPersonalRating();
    const userId = offer.presenters.getCianUserId();
    const agentName = offer.presenters.getAgentName();
    const companyName = offer.presenters.getCompanyName();
    const isFromBuilder = offer.isFromBuilder();
    const isFromSeller = offer.isFromSeller();
    const isRepresentative = !!(
      this.props.offer.businessShoppingCenter && this.props.offer.businessShoppingCenter.fromRepresentative
    );
    const accountType = offer.presenters.getAccountType();
    const isShowLabelOfAccountType = this.isCorrectAccountTypeAndTariff(accountType);
    const { isCianPartner } = this.props.offer.user;

    return (
      <div className={style['user-info-container']}>
        <div className={style['user-info-name']}>
          <noindex>{agentName || `ID:${userId}`}</noindex>
        </div>
        {companyName && (
          <div className={style['user-info-company']}>
            <noindex>{companyName}</noindex>
          </div>
        )}
        {isFromBuilder && (
          <div
            className={`${style['user-info-text-badge']} ${style['user-info-text-badge--green']}`}
            title="Объявления, размещаемые самим застройщиком, либо агентством недвижимости с официальным правом продажи по ценам застройщика" // tslint:disable-line:max-line-length
          >
            Застройщик
          </div>
        )}
        {!isFromBuilder && isFromSeller && (
          <div className={`${style['user-info-text-badge']} ${style['user-info-text-badge--green']}`}>
            <span>
              Представитель <br /> застройщика
            </span>
          </div>
        )}
        <div className={`${style['user-info-badges']}`}>
          {userTrustLevel === EUserTrustLevel.Involved && (
            <div onClick={this.handleHonestWorkClick} className={style['user-info-badge']}>
              <HonestWork className={style['honest-work']} />
            </div>
          )}
          {isPro && !(isFromBuilder || isFromSeller) && (
            <div className={style['user-info-badge']}>
              <div className={`${style['user-info-text-badge']}`}>PRO</div>
            </div>
          )}
          {isCianPartner && (
            <div className={style['user-info-badge']}>
              <PartnerTooltip
                master={<div className={`${style['user-info-text-badge']} ${style['green-light']}`}>Партнёр</div>}
              />
            </div>
          )}
          {isByHomeowner && (
            <div className={style['user-info-badge']}>
              <div className={`${style['user-info-text-badge']}`}>Собственник</div>
            </div>
          )}
          {isRepresentative && (
            <div className={style['user-info-badge']}>
              <div className={`${style['user-info-text-badge']} ${style['green-light']}`}>Оф. представитель</div>
            </div>
          )}
          {isShowLabelOfAccountType && !isRepresentative && (
            <div className={style['user-info-badge']}>
              <div className={`${style['user-info-text-badge']} ${style['green-light']}`}>
                {accountType === 'managementCompany' ? 'Управляющая компания' : 'Отдел аренды'}
              </div>
            </div>
          )}
          {userPersonalRating === 'positive' && (
            <div className={style['user-info-badge']}>
              <IconWhitelist isActive />
            </div>
          )}
          {userPersonalRating === 'negative' && (
            <div className={style['user-info-badge']}>
              <IconBlacklist isActive />
            </div>
          )}
        </div>
      </div>
    );
  }

  private renderHorizontal() {
    const offer = offerHelper(this.props.offer);
    const isPro = offer.isPro();
    const isByHomeowner = offer.isByHomeowner();
    const userTrustLevel = offer.presenters.getUserTrustLevel();
    const userPersonalRating = offer.presenters.getUserPersonalRating();
    const userId = offer.presenters.getCianUserId();
    const agencyName = offer.presenters.getAgentName();
    const isRepresentative = !!(
      this.props.offer.businessShoppingCenter && this.props.offer.businessShoppingCenter.fromRepresentative
    );
    const accountType = offer.presenters.getAccountType();
    const isShowLabelOfAccountType = this.isCorrectAccountTypeAndTariff(accountType);
    const { isCianPartner } = this.props.offer.user;

    return (
      <div className={style['user-info-horizontal-container']}>
        <span className={`${style['user-info-name']} ${style['user-info-name--horizontal']}`}>
          <noindex>{agencyName || `ID:${userId}`}</noindex>
        </span>
        <span className={`${style['user-info-badges']} ${style['user-info-badges--horizontal']}`}>
          {isPro && (
            <div className={`${style['user-info-badge']} ${style['user-info-badge--horizontal']}`}>
              <div className={style['user-info-text-badge']}>PRO</div>
            </div>
          )}
          {isCianPartner && (
            <PartnerTooltip
              master={
                <div className={`${style['user-info-badge']} ${style['user-info-badge--horizontal']}`}>
                  <div className={`${style['user-info-text-badge']} ${style['green-light']}`}>Партнёр</div>
                </div>
              }
            />
          )}
          {userTrustLevel === EUserTrustLevel.Involved && (
            <div
              onClick={this.handleHonestWorkClick}
              className={`${style['user-info-badge']} ${style['user-info-badge--horizontal']}`}
            >
              <HonestWork className={style['honest-work']} />
            </div>
          )}
          {isByHomeowner && (
            <div className={`${style['user-info-badge']} ${style['user-info-badge--horizontal']}`}>
              <div className={`${style['user-info-text-badge']}`}>Собственник</div>
            </div>
          )}
          {isRepresentative && (
            <div className={style['user-info-badge']}>
              <div className={`${style['user-info-text-badge']} ${style['green-light']}`}>Оф. представитель</div>
            </div>
          )}
          {isShowLabelOfAccountType && !isRepresentative && (
            <div className={style['user-info-badge']}>
              <div className={`${style['user-info-text-badge']} ${style['green-light']}`}>
                {accountType === 'managementCompany' ? 'Управляющая компания' : 'Отдел аренды'}
              </div>
            </div>
          )}
          {userPersonalRating === 'positive' && (
            <div className={`${style['user-info-badge']} ${style['user-info-badge--horizontal']}`}>
              <IconWhitelist isActive />
            </div>
          )}
          {userPersonalRating === 'negative' && (
            <div className={`${style['user-info-badge']} ${style['user-info-badge--horizontal']}`}>
              <IconBlacklist isActive />
            </div>
          )}
        </span>
      </div>
    );
  }

  private handleHonestWorkClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  private isCorrectAccountTypeAndTariff = (agentAccountType?: string): boolean => {
    const isAccountTypeOfRentOrManagement =
      agentAccountType && ['managementCompany', 'rentDepartment'].indexOf(agentAccountType) > 0;

    return Boolean(isAccountTypeOfRentOrManagement);
  };
}
