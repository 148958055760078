import { connect } from 'react-redux';

import { IReviewsListDispatchProps, IReviewsListStoreProps, ReviewsList } from '.';
import { IAppState } from '../../store';
import { open } from '../../store/answer_form';
import { edit } from '../../store/review_form';
import { fetchReviews } from '../../store/reviews';
import { TThunkDispatch } from '../../types/redux';

function mapStateToProps(state: IAppState): IReviewsListStoreProps {
  return {
    answerFormAnswerId: state.answerForm.answerId,
    answerFormReviewId: state.answerForm.reviewId,
    answerFormState: state.answerForm.state,
    count: state.reviews.totalCount,
    editFormReviewId: state.reviewForm.reviewId,
    editFormState: state.reviewForm.state,
    reviews: state.reviews.reviews,
    realtyUserId: state.user.isAuthenticated ? state.user.realtyUserId : undefined,
    userIsAuthenticated: state.user.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch: TThunkDispatch): IReviewsListDispatchProps {
  return {
    fetchReviews: () => dispatch(fetchReviews()),
    openAnswerForm: (reviewId: string) => dispatch(open(reviewId)),
    openEditForm: (reviewId: string, text: string, rating: number) => dispatch(edit(reviewId, text, rating)),
  };
}

export const ReviewsListContainer = connect<IReviewsListStoreProps, IReviewsListDispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps,
)(ReviewsList);
