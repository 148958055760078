/* eslint-disable react/forbid-elements */
import * as React from 'react';

export const SkillsIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 2H23H34H35V3V5H38H39V6V39V40H38H24H23H16H15H11H10H3H2V39V31H1H0V30V27V26.6667L0.2 26.4L3 22.6667V19V18H4H17V6V5H18H22V3V2ZM12 38H14V35C14 34.4477 13.5523 34 13 34C12.4477 34 12 34.4477 12 35V38ZM16 38V35C16 33.3431 14.6569 32 13 32C11.3431 32 10 33.3431 10 35V38H4V31H22V38H16ZM5 22H21V20H5V22ZM12 24H9V29H12V24ZM14 24V29H17V24H14ZM24 29H23H19V24H21.5L24 27.3333V29ZM4.5 24H7L7 29H3H2V27.3333L4.5 24ZM24 31H25H26V30V27V26.6667L25.8 26.4L23 22.6667V19V18H22H19V7H22H23H34H35H37V38H24V31ZM33 4V5H24V4H33ZM25 10V15H23V10H25ZM33 10H31V15H33V10ZM31 18H33V23H31V18ZM33 26H31V31H33V26Z"
      fill="#737A8E"
    />
  </svg>
);
