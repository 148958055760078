import { connect } from 'react-redux';

import { IJkNameOwnProps, IJkNameStoreProps, JkName } from './index';

const mapStateToProps = () => {
  return {
    isReliableActive: true,
  };
};

export const JkNameContainer = connect<IJkNameStoreProps, {}, IJkNameOwnProps>(mapStateToProps)(JkName);
