import { IReviewAnswer } from '../types/review';
import { IThunkActionCreator, IAppState, IContext } from '.';
import { putAnswer } from '../api/reviews';
import { getDescription } from '../utils/antiObscene';
import { IReviewFormOpened } from './review_form';
import { TThunkDispatch } from '../types/redux';

export enum EAnswerFormState {
  Initial,
  Submitted,
  Succeed,
  Failed,
}

export interface IAnswerFormState {
  edit?: boolean;
  state?: EAnswerFormState;
  reviewId?: string;
  answerId?: string;
  text?: string;
  answer?: IReviewAnswer;
  textErrorMarked?: string;
  errorMessage?: string;
}

export interface IAnswerFormOpened {
  type: 'IAnswerFormOpened';
  edit: boolean;
  reviewId: string;
  answerId?: string;
  text?: string;
}

export function open(reviewId: string, answerId?: string): IThunkActionCreator {
  return (dispatch: TThunkDispatch) => {
    dispatch<IAnswerFormOpened>({
      answerId,
      edit: false,
      reviewId,
      type: 'IAnswerFormOpened',
    });
  };
}

export function edit(reviewId: string, answerId: string, text: string): IThunkActionCreator {
  return (dispatch: TThunkDispatch) => {
    dispatch<IAnswerFormOpened>({
      answerId,
      edit: true,
      reviewId,
      text,
      type: 'IAnswerFormOpened',
    });
  };
}

export interface IAnswerFormTextChanged {
  type: 'IAnswerFormTextChanged';
  text: string;
}

export function changeText(text: string): IThunkActionCreator {
  return (dispatch: TThunkDispatch) => {
    dispatch<IAnswerFormTextChanged>({
      text,
      type: 'IAnswerFormTextChanged',
    });
  };
}

export interface IAnswerFormSubmitted {
  type: 'IAnswerFormSubmitted';
}

export interface IAnswerFormSubmitSucceed {
  type: 'IAnswerFormSubmitSucceed';
  reviewId: string;
  answerId?: string;
  edit: boolean;
  answer: IReviewAnswer;
}

export interface IAnswerFormSubmitFailed {
  type: 'IAnswerFormSubmitFailed';
  errors?: object[];
  message?: string;
}

export function submit(): IThunkActionCreator {
  return (dispatch: TThunkDispatch, getState: () => IAppState, context: IContext) => {
    // tslint:disable-next-line:no-shadowed-variable
    const {
      realtor,
      answerForm: { edit, reviewId, answerId, text },
    } = getState();

    dispatch<IAnswerFormSubmitted>({
      type: 'IAnswerFormSubmitted',
    });

    return putAnswer(context.httpApi, {
      answerId: edit ? answerId : undefined,
      reviewId: reviewId as string,
      text: (text as string).trim(),
      realtyUserId: realtor.realtyUserId,
    })
      .then(response => {
        if ('errors' in response) {
          return dispatch({
            errors: response.errors,
            message: response.message,
            type: 'IAnswerFormSubmitFailed',
          });
        }

        return dispatch<IAnswerFormSubmitSucceed>({
          answer: response as IReviewAnswer,
          answerId: edit ? answerId : undefined,
          edit: edit as boolean,
          reviewId: reviewId as string,
          type: 'IAnswerFormSubmitSucceed',
        });
      })
      .catch(() => {
        return dispatch<IAnswerFormSubmitFailed>({
          type: 'IAnswerFormSubmitFailed',
        });
      });
  };
}

export interface IAnswerFormReseted {
  type: 'IAnswerFormReseted';
}

export function reset(): IThunkActionCreator {
  return (dispatch: TThunkDispatch) => {
    dispatch<IAnswerFormReseted>({
      type: 'IAnswerFormReseted',
    });
  };
}

export interface IAnswerFormClickSubmitButton {
  type: 'IAnswerFormClickSubmitButton';
}

export function clickSubmitButton(): IThunkActionCreator {
  return (dispatch: TThunkDispatch) => {
    dispatch<IAnswerFormClickSubmitButton>({
      type: 'IAnswerFormClickSubmitButton',
    });
  };
}

export type TAnswerFormActions =
  | IAnswerFormOpened
  | IAnswerFormTextChanged
  | IAnswerFormSubmitted
  | IAnswerFormClickSubmitButton
  | IAnswerFormSubmitSucceed
  | IAnswerFormSubmitFailed
  | IAnswerFormReseted
  | IReviewFormOpened;

const initialState = {} as IAnswerFormState;

export function answerFormReducer(
  state: IAnswerFormState = initialState,
  action: TAnswerFormActions,
): IAnswerFormState {
  switch (action.type) {
    case 'IAnswerFormOpened': {
      if (!action.edit) {
        return {
          ...initialState,
          answerId: action.answerId,
          edit: action.edit,
          errorMessage: undefined,
          reviewId: action.reviewId,
          state: EAnswerFormState.Initial,
          textErrorMarked: undefined,
        };
      }

      return {
        ...initialState,
        answerId: action.answerId,
        edit: action.edit,
        errorMessage: undefined,
        reviewId: action.reviewId,
        state: EAnswerFormState.Initial,
        text: action.text,
        textErrorMarked: undefined,
      };
    }

    case 'IAnswerFormTextChanged':
      return {
        ...state,
        text: action.text,
      };

    case 'IAnswerFormSubmitted':
      return {
        ...state,
        errorMessage: undefined,
        state: EAnswerFormState.Submitted,
        textErrorMarked: undefined,
      };

    case 'IAnswerFormSubmitSucceed':
      return {
        ...state,
        answer: action.answer,
        errorMessage: undefined,
        state: EAnswerFormState.Succeed,
        textErrorMarked: undefined,
      };

    case 'IAnswerFormSubmitFailed':
      return {
        ...state,
        errorMessage: action.message,
        state: EAnswerFormState.Failed,
        textErrorMarked: getDescription(state.text, action.errors),
      };

    case 'IAnswerFormClickSubmitButton':
      return {
        ...state,
        errorMessage: undefined,
        textErrorMarked: undefined,
      };

    case 'IReviewFormOpened':
    case 'IAnswerFormReseted':
      return initialState;

    default:
      return state;
  }
}
