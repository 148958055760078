import { IGetQuestionListSuccessResponseDataSchema } from '../types/question';

export type TQuestionsActions = IQuestionsFetched;

export interface IQuestionsData {
  questions: IGetQuestionListSuccessResponseDataSchema[];
  totalCount: number;
}

export interface IQuestionsFetched extends IQuestionsData {
  type: 'IQuestionsFetched';
}

const defaultState = {
  questions: [],
  totalCount: 0,
};

export function questionsReducer(state: IQuestionsData = defaultState, action: TQuestionsActions): IQuestionsData {
  switch (action.type) {
    case 'IQuestionsFetched':
      return {
        ...state,
        questions: action.questions,
        totalCount: action.totalCount,
      };

    default:
      return state;
  }
}
