import * as React from 'react';
import { UIHeading1 } from '@cian/ui-kit';

import { PartnerBadge } from '../../PartnerBadge';
import { PartnerTooltip } from '../../PartnerTooltip';
import { IAgentCard } from '../../../types/agentCard';
import { ApprovalContainerNew } from '../../../containers/ApprovalContainer/ApprovalContainerNew';
import { RgrCertificate } from '../../RgrCertificate';

import * as styles from './AboutRealtorDesktop.css';

export interface INameProps {
  name: IAgentCard['name'];
  isCianPartner?: boolean;
  hasRgrParticipation: boolean;
}

export const Name = ({ name, isCianPartner, hasRgrParticipation }: INameProps) => {
  return (
    <div className={styles['name-container']}>
      <div className={styles['title']}>
        <span className={styles['agent-name']}>
          <UIHeading1>
            <span className={styles['name']} data-name="RealtorName">
              {name}
            </span>
            <span className={styles['badges']}>{isCianPartner && <PartnerTooltip master={<PartnerBadge />} />}</span>
          </UIHeading1>
        </span>

        <ApprovalContainerNew />
      </div>

      {hasRgrParticipation && <RgrCertificate />}
    </div>
  );
};
