/* eslint-disable import/no-restricted-paths */
import * as PropTypes from 'prop-types';
import * as React from 'react';

import { IContext, TSiteVersion } from '../../store';
import { checkFlocktoryLoad } from '../../utils/flocktory';
import { ViewDesktop } from './views/desktop';
import { ViewMobile } from './views/mobile';
import { TFilters } from '../../types/filters';
import {} from '@cian/react-utils';
import { scrollToElement } from '../../../browser/utils/scroll';
import { requestAuthentication } from '../../../browser/utils/authentication';

require('./index.css');

export interface IReviewsRealtorPageDispatchProps {
  pushSubscriptionSetValue(value: boolean): void;
  openReviewForm(): void;
}

export interface IReviewsRealtorPageStateProps {
  siteVersion: TSiteVersion;
  filters: TFilters;
  isAuthenticated: boolean;
}

interface IReviewsRealtorPageProps {
  context: IContext;
}

export type TReviewsRealtorPageProps = IReviewsRealtorPageDispatchProps &
  IReviewsRealtorPageStateProps &
  IReviewsRealtorPageProps;

export class ReviewsRealtorPage extends React.PureComponent<TReviewsRealtorPageProps, {}> {
  public context: IContext;

  public static childContextTypes = {
    abGroup: PropTypes.number,
    config: PropTypes.object,
    httpApi: PropTypes.object,
    logger: PropTypes.object,
  };

  public getChildContext() {
    return this.props.context;
  }

  public componentDidMount() {
    checkFlocktoryLoad().then(flocktory => {
      flocktory.getSubscriptionStatus().then(response => {
        /**
         * Если flocktory вернули поле subscribed и оно равно false,
         * значит текущий пользователь не подписан на пуши и нужно показать попап
         */
        if ('subscribed' in response && !response.subscribed) {
          this.props.pushSubscriptionSetValue(true);
        }
      });
    });

    this.handleOpenReviewForm();
  }

  private handleOpenReviewForm = () => {
    if (this.props.filters.writeReview) {
      if (!this.props.isAuthenticated) {
        requestAuthentication('new');

        return;
      }

      this.props.openReviewForm();

      const reviewFormElement = document.getElementById('review_form');

      if (reviewFormElement) {
        window.setTimeout(() => scrollToElement(reviewFormElement), 0);
      }
    }
  };

  public render() {
    return this.props.siteVersion === 'adaptive' ? <ViewMobile /> : <ViewDesktop />;
  }
}
