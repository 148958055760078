import { IStyleConfig, mergeStyles } from '@cian/utils';
import * as React from 'react';

import { isGoogleBot, isYandexBot } from '../../../utils/user_agent';
import { AlarmSVG } from './icn-alarm';

const style = require('./index.css');

export interface IDescriptionProps {
  containerStyle?: IStyleConfig;
  description: string;
  descriptionWordsHighlighted: [number, number][] | undefined;
  excludedFromActionStyle?: IStyleConfig;
  isExcludedFromAction?: boolean;
  isRecidivist?: boolean;
  recidivistStyle?: IStyleConfig;
  userAgent: string;

  onComplainStateChanged(isReporting: boolean): void;
}

export const Description = (props: IDescriptionProps) => {
  let {
    description,
    descriptionWordsHighlighted,
    excludedFromActionStyle,
    isExcludedFromAction,
    isRecidivist,
    recidivistStyle,
    userAgent,
  } = props;

  if (isGoogleBot(userAgent) || isYandexBot(userAgent)) {
    isRecidivist = false;
    isExcludedFromAction = false;
  }

  const descriptionNodes: React.ReactNode[] = [];
  let currentPosition = 0;

  const complain = (
    <span
      className={style['complaint']}
      onClick={() => {
        props.onComplainStateChanged(true);
      }}
    >
      оставьте жалобу
    </span>
  );

  const recidivistDescription = (
    <span className={style['recidivist-description']} key="recidivist-description">
      Этот агент замечен в размещении недостоверных предложений. Возможно, это объявление вымышленное. Служба качества
      ЦИАН не рекомендует звонить по этому объявлению! Если вы позвонили, но объекта нет, {complain}.{' '}
      <a className={style['link']} href="/help/quality/37645/" target={'_blank'}>
        Подробнее
      </a>
    </span>
  );

  const excludedFromActionDescription = (
    <span className={style['recidivist-description']} key="excluded-from-action-description">
      В объявлениях этого агента встречаются ошибки. Объект может отличаться от предложенного в объявлении! Уточняйте
      параметры объекта до просмотра. Оставьте жалобу, если обнаружите ошибку
    </span>
  );

  if (isRecidivist) {
    descriptionNodes.push(recidivistDescription);
  } else if (isExcludedFromAction) {
    descriptionNodes.push(excludedFromActionDescription);
  } else if (descriptionWordsHighlighted) {
    descriptionWordsHighlighted.forEach(range => {
      descriptionNodes.push(
        description.substring(currentPosition, range[0]),
        <span key={range.toString()} className={style['highlighted-word']}>
          {description.substring(range[0], range[1])}
        </span>,
      );
      currentPosition = range[1];
    });

    if (currentPosition < description.length) {
      descriptionNodes.push(description.substring(currentPosition));
    }
  } else {
    descriptionNodes.push(description);
  }

  const containerStyle = mergeStyles(
    style['description'],
    isRecidivist
      ? mergeStyles(style['recidivist'], excludedFromActionStyle)
      : isExcludedFromAction
        ? mergeStyles(style['excluded-from-action'], recidivistStyle)
        : undefined,
    props.containerStyle,
  );

  return (
    <div {...containerStyle}>
      {isRecidivist || isExcludedFromAction ? (
        <div className={style['svg-container']}>
          <AlarmSVG />
        </div>
      ) : null}
      {descriptionNodes}
    </div>
  );
};
