/* eslint-disable import/no-restricted-paths */

import { plural } from '@cian/utils/lib/shared/plural';
import { mergeStyles } from '@cian/utils/lib/shared/style';
import * as React from 'react';

import { IReviewAnswer } from '../../types/review';
import { requestAuthentication } from '../../../browser/utils/authentication';
import { scrollToElement } from '../../../browser/utils/scroll';
import { EAnswerFormState } from '../../store/answer_form';
import { timestampToDate } from '../../utils/time';
import { ReviewContainer, ModerationReviewContainer } from '../../containers/review';
import { AnswerFormContainer } from '../answer_form/container';
import { IconChevron } from './icons/ic-chevron';

import * as styles from './index.css';

export interface IAnswersListStoreProps {
  userIsAuthenticated: boolean;
  realtyUserId: number | undefined;
  answerFormAnswerId?: string;
  answerFormReviewId?: string;
  answerFormState?: EAnswerFormState;
}

export interface IAnswersListDispatchProps {
  openAnswerForm(reviewId: string, answerId: string): void;
  openEditForm(reviewId: string, answerId: string, text: string): void;
}

export interface IAnswersListOwnProps {
  reviewId: string;
  answers: IReviewAnswer[];
}

export type TAnswersListProps = IAnswersListStoreProps & IAnswersListOwnProps & IAnswersListDispatchProps;

export interface IAnswersListState {
  isShortAnswers: boolean;
}

const MAX_ANSWERS_SHORT_VIEW = 3;

let scrolledTo = false;

export class AnswersList extends React.Component<TAnswersListProps, IAnswersListState> {
  private wantsScrollTo: string | null = null;
  private needScrollTo: HTMLElement | null = null;

  public constructor(props: TAnswersListProps) {
    super(props);

    // tslint:disable-next-line
    if (typeof window !== 'undefined' && window.location.hash) {
      const hash = window.location.hash.slice(1);

      if (hash && hash.startsWith('answer_')) {
        const { reviewId, answers } = props;

        if (answers.some(answer => `answer_${reviewId}_${answer.answerId}` === hash)) {
          this.wantsScrollTo = hash;
        }
      }
    }

    this.state = {
      isShortAnswers: !this.wantsScrollTo,
    };
  }

  public componentDidMount() {
    if (this.wantsScrollTo) {
      this.forceUpdate();
    }

    if (!scrolledTo && this.needScrollTo) {
      window.setTimeout(() => scrollToElement(this.needScrollTo), 0);
      scrolledTo = true;
    }
  }

  public render() {
    const { reviewId, realtyUserId, answers } = this.props;
    const { isShortAnswers } = this.state;

    const answersForRender =
      isShortAnswers && answers.length > MAX_ANSWERS_SHORT_VIEW ? answers.slice(0, MAX_ANSWERS_SHORT_VIEW) : answers;

    return (
      <div className={styles['container']}>
        {answersForRender.map(answer => {
          /**
           * Отображаем отклоненный ответ только для хозяина ответа
           */
          if (answer.status === 'declined' && answer.user.realtyUserId !== realtyUserId) {
            return null;
          }

          const needScrollTo = this.wantsScrollTo && this.wantsScrollTo === `answer_${reviewId}_${answer.answerId}`;

          return (
            <React.Fragment key={`answer_${answer.answerId}`}>
              <ReviewContainer
                type="answer"
                review={answer}
                innerRef={needScrollTo ? (ref: HTMLElement | null) => (this.needScrollTo = ref) : undefined}
                key={`answer_${answer.answerId}`}
                containerStyle={styles['answer_container']}
                onReplyClick={this.isReplyAvailable(answer) ? () => this.handleReplyClick(answer.answerId) : undefined}
                onEditClick={
                  this.isEditAvailable(answer) ? () => this.handleEditClick(answer.answerId, answer.text) : undefined
                }
              />
              <ModerationReviewContainer
                containerStyle={styles['moderation_container']}
                moderation={answer.moderation}
              />
              {this.renderAnswerForm(answer.answerId)}
            </React.Fragment>
          );
        })}
        {answers.length > MAX_ANSWERS_SHORT_VIEW && (
          <button
            type="button"
            onClick={this.onToggleAnswersShort}
            {...mergeStyles(styles['answers_view_all'], !isShortAnswers && styles['answers_view_all--active'])}
          >
            {this.getButtonText()} <IconChevron />
          </button>
        )}
      </div>
    );
  }

  private getButtonText = () => {
    const { answers } = this.props;
    const { isShortAnswers } = this.state;

    if (isShortAnswers) {
      const count = answers.length - MAX_ANSWERS_SHORT_VIEW;

      return `Ещё ${count} ${plural(count, ['ответ', 'ответа', 'ответов'])}`;
    }

    return 'Скрыть';
  };

  private onToggleAnswersShort = () => {
    this.setState({
      isShortAnswers: !this.state.isShortAnswers,
    });
  };

  private handleEditClick = (answerId: string, text: string) => {
    const { reviewId } = this.props;

    this.props.openEditForm(reviewId, answerId, text);
  };

  private handleReplyClick = (answerId: string) => {
    const { reviewId } = this.props;

    if (!this.props.userIsAuthenticated) {
      requestAuthentication(`answer_${reviewId}_${answerId}`);
    } else {
      this.props.openAnswerForm(this.props.reviewId, answerId);
    }
  };

  private renderAnswerForm = (answerId: string) => {
    if (!this.isAnswerFormVisible(answerId)) {
      return null;
    }

    return <AnswerFormContainer />;
  };

  private isEditAvailable(answer: IReviewAnswer) {
    const { realtyUserId, answerFormState, answerFormReviewId, answerFormAnswerId, reviewId } = this.props;

    return (
      answer.user.realtyUserId === realtyUserId &&
      answer.status !== 'declined' &&
      timestampToDate(answer.created + 20 * 60) > new Date() &&
      (typeof answerFormState === 'undefined' ||
        answerFormReviewId !== reviewId ||
        answerFormAnswerId !== answer.answerId)
    );
  }

  private isReplyAvailable(answer: IReviewAnswer) {
    const { answerFormState, answerFormReviewId, answerFormAnswerId, reviewId } = this.props;

    return (
      !this.isEditAvailable(answer) &&
      answer.status !== 'declined' &&
      (typeof answerFormState === 'undefined' ||
        answerFormReviewId !== reviewId ||
        answerFormAnswerId !== answer.answerId)
    );
  }

  private isAnswerFormVisible(answerId: string) {
    const { answerFormState, answerFormReviewId, answerFormAnswerId, reviewId } = this.props;

    return typeof answerFormState !== 'undefined' && answerFormReviewId === reviewId && answerFormAnswerId === answerId;
  }
}
