/* eslint-disable max-lines */
// tslint:disable:max-file-line-count
import { plural } from '@cian/utils';
import { IStyleArgument, mergeStyles } from '@cian/utils/lib/shared/style';
import { Button as UIKitButton, TooltipDesktop, Responsive } from '@cian/ui-kit';
import * as React from 'react';
import { Component, ReactNode, Ref } from 'react';

import { Starts, ReviewTextarea, SubmitButton } from './components';
import { Avatar } from '../avatar';
import { EDeviceType, TDeviceType } from '../../types/device';

import * as styles from './index.css';

export enum EReviewFormType {
  Inactive,
  Review,
  ReviewEdit,
  Answer,
  AnswerEdit,
}

export interface IReviewFormInactiveProps {
  type: EReviewFormType.Inactive;

  customStarsDescriptionText?: string;
  customRateEntityErrorText?: string;
  customPlaceholder?: string;

  onOpen(): void;
}

export interface IReviewFormReviewProps {
  type: EReviewFormType.Review | EReviewFormType.ReviewEdit;

  name: string;
  rating: number;
  text: string;
  loading?: boolean;

  customStarsDescriptionText?: string;
  customRateEntityErrorText?: string;
  customPlaceholder?: string;
  onCancel(): void;
  onRatingChange(value: number): void;
  onTextChange(value: string): void;
  onSubmit(): void;
}

export interface IReviewFormAnswerProps {
  type: EReviewFormType.Answer | EReviewFormType.AnswerEdit;

  name: string;
  text: string;
  loading?: boolean;

  customStarsDescriptionText?: string;
  customRateEntityErrorText?: string;
  customPlaceholder?: string;
  onCancel(): void;
  onTextChange(value: string): void;
  onSubmit(): void;
}

export interface IReviewFormProps {
  deviceType: TDeviceType;
  innerRef?: Ref<HTMLDivElement>;

  containerStyle?: IStyleArgument;
  noAvatar?: boolean;
  avatarSrc?: string;
  serverError?: string;

  minLength?: number;
  description?: JSX.Element;
  onSubmitButtonClick?(): void;
}

export type TReviewFormProps = (IReviewFormInactiveProps | IReviewFormReviewProps | IReviewFormAnswerProps) &
  IReviewFormProps;
export type TReviewFormPropsWithDefaults = TReviewFormProps;
export type IReviewFormActiveProps = (IReviewFormReviewProps | IReviewFormAnswerProps) & IReviewFormProps;

export interface IReviewFormState {
  ratingError?: string;
  textError?: string | ReactNode;
}

export const isReview = (type: EReviewFormType) => {
  return [EReviewFormType.Review, EReviewFormType.ReviewEdit].includes(type);
};

export const isAnswer = (type: EReviewFormType) => {
  return [EReviewFormType.Answer, EReviewFormType.AnswerEdit].includes(type);
};

export const getTypeName = (type: EReviewFormType) => {
  switch (type) {
    case EReviewFormType.Answer:
    case EReviewFormType.AnswerEdit:
      return 'ответа';
    default:
      return 'отзыва';
  }
};

// Текст ошибки, если задана минимальная длина ответа/отзыва и длина сообщения меньше него
export const getLessMinLengthErrorText = (type: EReviewFormType, minLength?: number) => {
  if (!minLength) {
    return '';
  }

  const symWord = plural(minLength, ['буквенный символ', 'буквенных символа', 'буквенных символов']);

  return `Текст ${getTypeName(type)} должен содержать не менее ${minLength} ${symWord}`;
};

/**
 * Количество символов в строке из набора pattern.
 * Если pattern не задан - общее количество символов (после trim)
 * @param text
 * @param pattern
 */
export const getMessageLength = (text: string, pattern?: string) => {
  if (pattern) {
    const reg = new RegExp(pattern, 'g');

    /* istanbul ignore next */
    return ((text || '').match(reg) || []).length;
  }

  return text.trim().length;
};

export class ReviewForm extends Component<TReviewFormProps, IReviewFormState> {
  public textAreaRef: React.RefObject<HTMLTextAreaElement>;

  public state: IReviewFormState = {};

  public constructor(props: TReviewFormProps) {
    super(props);

    this.textAreaRef = React.createRef<HTMLTextAreaElement>();
  }

  public render() {
    const { innerRef, containerStyle, deviceType } = this.props as TReviewFormPropsWithDefaults;

    const isMobile = deviceType === EDeviceType.Phone;

    return (
      <div ref={innerRef} {...mergeStyles(styles['container'], containerStyle, isMobile && styles['container_mobile'])}>
        <div>{this.renderAvatar()}</div>
        <div className={styles['content']}>{this.renderContent()}</div>
      </div>
    );
  }

  private renderContent = () => {
    if (this.props.type === EReviewFormType.Inactive) {
      const { onOpen, customPlaceholder } = this.props;

      const placeholder = customPlaceholder || 'Вопрос или отзыв';

      return (
        <button type="button" className={styles['inactive_button']} onClick={onOpen}>
          {placeholder}
        </button>
      );
    }

    return (
      <>
        {this.renderTitle()}
        <form className={styles['form']}>
          {this.renderStars()}
          {this.renderTextarea()}
        </form>
        <footer className={styles['footer']}>
          {this.renderCancelButton()}
          {this.renderSubmitButton()}
        </footer>
      </>
    );
  };

  private renderTitle = () => {
    const { type, name } = this.props as IReviewFormActiveProps;

    let text = name;
    switch (type) {
      case EReviewFormType.ReviewEdit:
      case EReviewFormType.AnswerEdit:
        text = 'Редактирование';
        break;
      default:
    }

    return (
      <div className={styles['title']} data-name="ReviewFormTitle">
        {text}
      </div>
    );
  };

  private renderStars = () => {
    if (![EReviewFormType.Review, EReviewFormType.ReviewEdit].includes(this.props.type)) {
      return null;
    }

    const { rating, customStarsDescriptionText } = this.props as IReviewFormReviewProps;
    const { ratingError } = this.state;

    return (
      <div className={styles['stars']} data-name="ReviewFormStars">
        <TooltipDesktop open={Boolean(ratingError)} title={ratingError} arrow={true} placement="right" portal={true}>
          <Starts rating={rating} onClick={this.handleRatingChange} />
        </TooltipDesktop>
        <p className={styles['stars-description']}>{customStarsDescriptionText || 'Оцените жилой комплекс'}</p>
      </div>
    );
  };

  private renderAvatar = () => {
    const { noAvatar, avatarSrc } = this.props as TReviewFormPropsWithDefaults;

    if (noAvatar) {
      return null;
    }

    return (
      <Responsive desktop tablet>
        <div className={styles['avatar']}>
          <Avatar src={avatarSrc} />
        </div>
      </Responsive>
    );
  };

  private renderTextarea = () => {
    const { type, text, loading, customPlaceholder, description } = this.props as IReviewFormActiveProps;
    const { textError } = this.state;

    let placeholder = customPlaceholder || 'Вопрос или отзыв';

    switch (type) {
      case EReviewFormType.Answer:
      case EReviewFormType.AnswerEdit:
        placeholder = 'Ответ';
        break;
      default:
    }

    return (
      <TooltipDesktop open={Boolean(textError)} title={textError} arrow={true} placement="bottom-start" portal={true}>
        <ReviewTextarea
          inputRef={this.textAreaRef}
          placeholder={placeholder}
          text={text}
          onChange={this.handleTextChange}
          disabled={Boolean(loading)}
          description={description}
        />
      </TooltipDesktop>
    );
  };

  private renderSubmitButton = () => {
    const { type, loading, serverError } = this.props as IReviewFormActiveProps;

    let text = 'Опубликовать';
    switch (type) {
      case EReviewFormType.Answer:
        text = 'Ответить';
        break;
      case EReviewFormType.ReviewEdit:
      case EReviewFormType.AnswerEdit:
        text = 'Сохранить';
        break;
      default:
    }

    const tooltipContent = this.state.textError || serverError;

    return (
      <span className={styles['submit_button']}>
        <TooltipDesktop
          open={Boolean(tooltipContent)}
          title={tooltipContent}
          placement="bottom"
          arrow={true}
          portal={true}
        >
          <SubmitButton text={text} isLoading={Boolean(loading)} onSubmit={this.handleSubmit} />
        </TooltipDesktop>
      </span>
    );
  };

  private renderCancelButton = () => {
    return (
      <UIKitButton size="XS" theme="fill_secondary" onClick={this.handleCancel}>
        Отменить
      </UIKitButton>
    );
  };

  private handleRatingChange = (index: number) => {
    const { onRatingChange } = this.props as IReviewFormReviewProps;
    const { ratingError } = this.state;

    if (ratingError) {
      this.setState({
        ratingError: undefined,
      });
    }

    onRatingChange(index + 1);
  };

  private handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>, value: string) => {
    const { onTextChange } = this.props as IReviewFormActiveProps;
    const { textError } = this.state;

    if (textError) {
      if (value.trim()) {
        this.setState({
          textError: undefined,
        });
      }
    }

    onTextChange(value);
  };

  private handleSubmit = () => {
    const { type, customRateEntityErrorText, minLength, onSubmitButtonClick } = this.props;
    const { textError } = this.state;

    let isErroneous = false;

    if (typeof onSubmitButtonClick !== 'undefined') {
      onSubmitButtonClick();
    }

    if (isReview(type)) {
      const { ratingError } = this.state;

      if (!ratingError) {
        const { rating } = this.props as IReviewFormReviewProps;

        if (!rating) {
          this.setState({
            ratingError: customRateEntityErrorText || 'Оцените ЖК',
          });

          isErroneous = true;
        }
      } else {
        isErroneous = true;
      }
    }

    if (!textError) {
      const { text } = this.props as IReviewFormActiveProps;

      if (!text.trim()) {
        const typeText = `${getTypeName(type)}`;

        this.setState({
          textError: `Введите текст ${typeText}`,
        });

        isErroneous = true;
      }

      // Проверяем на минимальное количество символов
      if (!isAnswer(type) && minLength && getMessageLength(text, '[а-яёА-ЯЁa-zA-Z]') < minLength) {
        this.setState({
          textError: getLessMinLengthErrorText(type, minLength),
        });

        isErroneous = true;
      }
    } else {
      isErroneous = true;
    }

    if (isErroneous) {
      return;
    }

    const { onSubmit } = this.props as IReviewFormActiveProps;

    onSubmit();
  };

  private handleCancel = () => {
    const { onCancel } = this.props as IReviewFormActiveProps;

    onCancel();
  };

  public focus = () => {
    if (!this.textAreaRef.current) {
      return;
    }

    const { text } = this.props as IReviewFormActiveProps;

    this.textAreaRef.current.focus();
    this.textAreaRef.current.setSelectionRange(text.length, text.length);
  };
}
