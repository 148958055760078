// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IModelResponse, IModel } from '@cian/http-api';
import type { IGetReviewsResponseV2Schema } from '../../entities/web_entities/GetReviewsResponseV2Schema';
import type { IErrorResponseSchema } from '../../entities/schemas/ErrorResponseSchema';

export interface IGetReviewsRequest {
  /**  Идентификатор пользователя, который является агентом  **/
  realtyUserId: number;
  /**  Id последнего отзыва в списке отзывов  **/
  fromLastReviewId?: string;
  /**  Количество отзывов в выдаче  **/
  offset?: number;
  /**  Тип сортировки, по умолчанию useful  **/
  sortType?: ESortType;
  /**  Можно отображать тестовые отзывы  **/
  canViewTest?: boolean;
}
export enum ESortType {
  /** Сначала с наибольшей оценкой **/
  'MaxRate' = 'maxRate',
  /** Сначала с наименьшей оценкой **/
  'MinRate' = 'minRate',
  /** Сначала новые отзывы **/
  'New' = 'new',
  /** Сначала старые отзывы **/
  'Old' = 'old',
  /** Сортировка по полезности отзыва **/
  'Useful' = 'useful',
}

export type IGetReviewsResponse = IGetReviewsResponseV2Schema;

export interface IGetReviewsResponse200 extends IModelResponse<IGetReviewsResponse> {
  statusCode: 200;
}

export type IGetReviewsResponseError = IErrorResponseSchema;

export interface IGetReviewsResponse400 extends IModelResponse<IGetReviewsResponseError> {
  statusCode: 400;
}

export type TGetReviewsResponse = IGetReviewsResponse200 | IGetReviewsResponse400;

export type TGetReviewsModel = IModel<IGetReviewsRequest, TGetReviewsResponse>;

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetReviewsResponse): TResponse200;
  400(response: IGetReviewsResponseError): TResponse400;
}
