export function scrollToElement(anchor: HTMLElement | null, offset: number = 10) {
  let endLocation = 0;
  if (anchor) {
    endLocation = getOffsetTop(anchor, offset);
  }

  const startLocation = window.pageYOffset;
  const distance = endLocation - startLocation;
  const documentHeight = Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight,
  );
  let timeLapsed = 0;
  let start: number | null;
  let percentage: number;
  let position: number;
  let animationHandle: number;

  // tslint:disable-next-line:variable-name
  const stopAnimateScroll = (pos: number, location: number) => {
    const currentLocation = window.pageYOffset;

    if (
      pos === location ||
      currentLocation === location ||
      (startLocation < location && window.innerHeight + currentLocation) >= documentHeight
    ) {
      window.cancelAnimationFrame(animationHandle);

      start = null;

      return true;
    }

    return false;
  };

  const loopAnimateScroll = (timestamp: number) => {
    if (!start) {
      start = timestamp;
    }

    timeLapsed += timestamp - start;
    percentage = timeLapsed / 250;
    percentage = percentage > 1 ? 1 : percentage;
    position = startLocation + distance * (percentage * percentage);

    window.scrollTo(0, Math.floor(position));

    if (!stopAnimateScroll(position, endLocation)) {
      animationHandle = window.requestAnimationFrame(loopAnimateScroll);
      start = timestamp;
    }
  };

  animationHandle = window.requestAnimationFrame(loopAnimateScroll);
}

export function getOffsetTop(anchor: HTMLElement, offset: number) {
  let location = 0;

  if (anchor.offsetParent) {
    do {
      location += anchor.offsetTop;
      // tslint:disable-next-line: no-parameter-reassignment
      // eslint-disable-next-line no-param-reassign
      anchor = anchor.offsetParent as HTMLElement;
    } while (anchor);
  }

  return Math.max(location - offset, 0);
}
