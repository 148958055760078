import { ILocation } from '../types/location';
import { ISearchCatalogItemSchema } from '../repositories/geo-temp-layer/entities/catalog_item/SearchCatalogItemSchema';
import { prepareBoundedBy } from './prepareBoundedBy';

export const prepareLocation = (location: ISearchCatalogItemSchema): ILocation => {
  const { boundedBy, displayName, hasDistricts, hasMetro, id, mainTownId, name } = location;

  return {
    isRegion: Boolean(mainTownId),
    boundedBy: boundedBy ? prepareBoundedBy(boundedBy) : null,
    displayName: displayName || '',
    hasDistricts: hasDistricts || false,
    hasMetro: hasMetro || false,
    id: id || 0,
    name: name || '',
  };
};
