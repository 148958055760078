import * as React from 'react';
import { Button, ArticleHeading2, ArticleParagraph1 } from '@cian/ui-kit';

import { IAgentCard } from '../../types/agentCard';

import * as styles from './index.css';

interface INoReviewsOwnProps {
  isMobile?: boolean;
}

export interface INoReviewsStoreProps {
  isOwnPage: boolean;
  name: IAgentCard['name'];
  showAddReviewButton: boolean;
}

export interface INoReviewsDispatchProps {
  handleOpen(): void;
}

export type TNoReviewsProps = INoReviewsStoreProps & INoReviewsDispatchProps & INoReviewsOwnProps;

export const NoReviews = ({ name, showAddReviewButton, isOwnPage, handleOpen, isMobile }: TNoReviewsProps) => {
  return (
    <div className={styles['container']}>
      <div className={isMobile ? styles['container-mobile_inner'] : styles['container_inner']}>
        <ArticleHeading2>Нет отзывов</ArticleHeading2>
        {!isOwnPage && <ArticleParagraph1>{`Расскажите, что думаете о специалисте ${name}`}</ArticleParagraph1>}
        {showAddReviewButton && !isOwnPage && <Button onClick={handleOpen}>Написать отзыв</Button>}
      </div>
    </div>
  );
};
