import { IconMail16 } from '@cian/ui-kit/icons';
import { IconOKFilled16, IconSkype16, IconVK16 } from '@cian/ui-kit';
import { Planet16 } from '@cian/ui-kit/prev';
import * as React from 'react';

export interface ISocnetworkIconProps {
  type: string;
  link?: string;
}

export const SocnetworkIcon = (props: ISocnetworkIconProps) => {
  let icon: React.ReactNode | null = null;
  const color = 'gray_icons_100';

  switch (props.type) {
    case 'vk':
      icon = <IconVK16 color={color} />;
      break;
    case 'ok':
      icon = <IconOKFilled16 color={color} />;
      break;
    case 'skype':
      icon = <IconSkype16 color={color} />;
      break;

    case 'website':
      icon = <Planet16 color={color} />;
      break;

    case 'email':
      icon = <IconMail16 color={color} />;
      break;

    default:
      icon = null;
  }

  if (!icon) {
    return null;
  }

  return props.link ? (
    <a rel="noreferrer" target="_blank" href={props.link}>
      {icon}
    </a>
  ) : (
    <span>{icon}</span>
  );
};
