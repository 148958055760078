import * as React from 'react';
import { ModalWindow, UiKitRoot } from '@cian/ui-kit';
import { NoSsr } from '@cian/react-utils';

import { PersonalLeadFormContainer } from '../PersonalLeadFormContainer';

interface IModalPersonalLeadFormContainerProps {
  onClose: () => void;
}

export const ModalPersonalLeadFormContainer = ({ onClose }: IModalPersonalLeadFormContainerProps) => {
  return (
    <NoSsr>
      <UiKitRoot deviceType="phone" useStaticDeviceType>
        <ModalWindow
          content={<PersonalLeadFormContainer handleCloseModal={onClose} />}
          escape
          height="100%"
          width="100%"
          onClose={onClose}
          open
          outside
          print
          size="M"
          title="Заявка риелтору"
          fixed
        />
      </UiKitRoot>
    </NoSsr>
  );
};
