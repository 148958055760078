import * as React from 'react';
import { IStyleConfig, mergeStyles } from '@cian/utils';
import { Button } from '@cian/ui-kit';

import { IPhone, IWorkTimeInfo } from '../../../../api/offer';
import { getFormattedPhoneNumber } from '../../../../utils/formatter';

export interface ISimplePhoneButtonProps {
  phone: IPhone;
  text: string | null;
  isSimplified?: boolean;
  workTimeInfo?: IWorkTimeInfo;
  containerStyle?: IStyleConfig;
  onMouseOver?: React.MouseEventHandler<HTMLDivElement>;
  onMouseOut?: React.MouseEventHandler<HTMLDivElement>;
  onPhoneOpened?(): void;
}

enum EPhoneState {
  BUTTON,
  TEXT,
}

export interface ISimplePhoneButtonState {
  type: EPhoneState;
}

const style = require('./index.css');

export class SimplePhoneButton extends React.Component<ISimplePhoneButtonProps, ISimplePhoneButtonState> {
  private phoneNumber: string;

  public static defaultProps = {
    text: 'Показать телефон',
  };

  public constructor(props: ISimplePhoneButtonProps) {
    super(props);

    this.phoneNumber = getFormattedPhoneNumber(`${props.phone.countryCode}${props.phone.number}`);

    this.state = {
      type: EPhoneState.BUTTON,
    };
  }

  public render() {
    const { containerStyle, onMouseOver, onMouseOut, isSimplified, workTimeInfo } = this.props;
    let viewer: React.ReactNode;

    if (this.state.type === EPhoneState.BUTTON && this.props.text) {
      viewer = (
        <Button size="XS" theme="fill_secondary" onClick={this.onClick}>
          {this.props.text}
        </Button>
      );
    } else if (this.state.type === EPhoneState.TEXT) {
      let callFrom = '';
      let callTo = '';

      if (workTimeInfo && workTimeInfo.callFrom && workTimeInfo.callTo) {
        callFrom = workTimeInfo.callFrom.slice(0, 5);
        callTo = workTimeInfo.callTo.slice(0, 5);
      }

      viewer = (
        <div {...mergeStyles([style['text'], isSimplified && style['simplified-text']])}>
          {this.phoneNumber}
          {callFrom && callTo ? (
            <div className={style['work-time']}>
              Работает с {callFrom} до {callTo}
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <div {...mergeStyles([style['container'], containerStyle])} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
        {viewer}
      </div>
    );
  }

  private onClick = () => {
    this.setState({
      type: EPhoneState.TEXT,
    });
    if (this.props.onPhoneOpened) {
      this.props.onPhoneOpened();
    }
  };
}
