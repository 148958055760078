import { ca } from '@cian/analytics';

export const trackOpenTooltip = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'quality_of_service',
    action: 'superagent_tooltip_open',
    label: `tooltip_rieltor_card`,
  });
};
