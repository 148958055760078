import * as React from 'react';

import * as styles from './styles.css';

export interface IAnswersMobileProps {
  children: React.ReactNode;
}

export const AnswersMobile = ({ children }: IAnswersMobileProps) => {
  return <div className={styles['container']}>{children}</div>;
};
