import * as React from 'react';
import { UIText3, TooltipDesktop } from '@cian/ui-kit';

import { RgrTooltip } from './RgrTooltip';

import * as styles from './styles.css';

export const RgrCertificate = () => (
  <TooltipDesktop title={<RgrTooltip />} theme="white" placement="right-start">
    <div className={styles['rgr-container']}>
      <div className={styles['title-icon']} />
      <UIText3 color="gray60_100">Сертификат РГР</UIText3>
    </div>
  </TooltipDesktop>
);
