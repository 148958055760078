export type TDeviceType = 'phone' | 'tablet' | 'desktop';

export const DeviceType = {
  Desktop: 'desktop' as TDeviceType,
  Phone: 'phone' as TDeviceType,
  Tablet: 'tablet' as TDeviceType,
};

export enum EDeviceType {
  Desktop = 'desktop',
  Phone = 'phone',
  Tablet = 'tablet',
}
