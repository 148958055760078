export interface ICalltrackingModal {
  isOpen: boolean;
}

interface ICalltrackingModalOpenedAction {
  type: 'ICalltrackingModalOpenedAction';
}

interface ICalltrackingModalClosedAction {
  type: 'ICalltrackingModalClosedAction';
}

export function openCalltrackingModal(): ICalltrackingModalOpenedAction {
  return {
    type: 'ICalltrackingModalOpenedAction',
  };
}

export function closeCalltrackingModal(): ICalltrackingModalClosedAction {
  return {
    type: 'ICalltrackingModalClosedAction',
  };
}

export type TCalltrackingModalActions = ICalltrackingModalClosedAction | ICalltrackingModalOpenedAction;

export const defaultCalltrackingModalsState: ICalltrackingModal = { isOpen: false };

export function calltrackingModalReducer(
  state: ICalltrackingModal = defaultCalltrackingModalsState,
  action: TCalltrackingModalActions,
): ICalltrackingModal {
  switch (action.type) {
    case 'ICalltrackingModalOpenedAction':
      return {
        ...state,
        isOpen: true,
      };

    case 'ICalltrackingModalClosedAction':
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
}
