import * as React from 'react';
import cx from 'classnames';

import { ReliableIcon } from '../reliable_icon';

import * as styles from '../index.css';

type ReliablePopupContentProps = {
  href: string;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

export const ReliablePopupContent = React.forwardRef(
  ({ href, onMouseEnter, onMouseLeave }: ReliablePopupContentProps, ref: React.RefObject<HTMLDivElement>) => {
    return (
      <div
        className={cx(styles['popup'], styles['reliable-wrapper'])}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref}
      >
        <ReliableIcon className={styles['icon']} />
        <strong className={styles['title']}>Надёжный выбор</strong>
        <p className={styles['paragraph']}>
          Мы проверили этот объект на соответствие <br />
          критериям надёжности
        </p>
        <a href={`${href}#reliable`} className={styles['link']} target="_blank" rel="noreferrer">
          Узнать подробнее
        </a>
      </div>
    );
  },
);

ReliablePopupContent.displayName = 'ReliablePopupContent';
