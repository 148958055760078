import * as React from 'react';
import { Stars } from '@cian/stars-component';
import { NoSsr } from '@cian/react-utils';

import * as styles from '../index.css';

type StartsProps = {
  rating: number;
  onClick: (rating: number) => void;
};

export const Starts = React.forwardRef(({ rating, onClick }: StartsProps, ref: React.RefObject<HTMLDivElement>) => {
  return (
    <div className={styles['stars-tooltip']} ref={ref}>
      <NoSsr>
        <Stars
          data-testid={'stars-container'}
          theme="hollow"
          containerClassName={styles['stars-container']}
          unitClassName={styles['stars-star']}
          value={rating}
          onClick={onClick}
          uniqueKey="review-form-stars"
        />
      </NoSsr>
    </div>
  );
});

Starts.displayName = 'Starts';
