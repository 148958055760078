import * as React from 'react';
import { useSelector } from 'react-redux';

import { VacancyItem } from '../../../components/vacancies/VacancyItem';
import { selectRealtorName } from '../../../selectors/realtor/selectRealtorName';
import { trackVacancyClick } from './tracking/trackVacancyClick';
import { generateVacancyLink } from './utils/generateVacancyLink';
import { getSalary } from './utils/getSalary';
import { getExperienceText } from './utils/getExperienceText';
import { selectRealtorVacancies } from '../../../selectors/vacancies/selectRealtorVacancies';

const EMPLOYMENT_TYPES = {
  full: 'Полная',
  part: 'Частичная',
  piece: 'Сдельная оплата',
};

export const VacancyItemContainer = () => {
  const { vacancies } = useSelector(selectRealtorVacancies);
  const vacancy = vacancies[0];
  const name = useSelector(selectRealtorName);
  const handleVacancyClick = React.useCallback(() => {
    trackVacancyClick();
  }, []);

  const { id, employment, minExperience, specialization, regionDisplayName, regionId, minSalary, maxSalary } = vacancy;
  const vacancyLink = generateVacancyLink(regionId, name, id);
  const salary = getSalary(minSalary, maxSalary);
  const experience = getExperienceText(minExperience);

  return (
    <VacancyItem
      vacancyLink={vacancyLink}
      onClick={handleVacancyClick}
      specialization={specialization}
      salary={salary}
      regionDisplayName={regionDisplayName}
      experience={experience}
      employment={EMPLOYMENT_TYPES[employment]}
    />
  );
};
