/* eslint-disable max-lines */
// tslint:disable:max-file-line-count
import { numberToPrettyString, plural } from '@cian/utils';
import { shortenNumber } from '@cian/utils/lib/shared/shorten_number';
import * as React from 'react';

import {
  BargainTermsSaleTypePresenter,
  BuildingMaterialTypePresenter,
  BuildingQuarter,
  IOffer,
  IPhone,
  LeaseTerm,
  PrimaryMetroRegions,
  TOfferCategory,
  UserTrustLevel,
} from '../../api/offer';
import { gatherAnalyticsInfo } from './analytics_info';
import { commercialHelper } from './commercial';
import { simplifiedCardHelper } from './simplified_card';
import { suburbanHelper } from './suburban';

function isFlat(offer: IOffer): boolean {
  return offer.offerType === 'flat';
}

function isRoom(offer: IOffer): boolean {
  return offer.category === 'roomRent' || offer.category === 'roomSale' || offer.category === 'dailyRoomRent';
}

function isBed(offer: IOffer): boolean {
  return offer.category === 'bedRent' || offer.category === 'dailyBedRent';
}

function isRent(offer: IOffer): boolean {
  return offer.dealType === 'rent';
}

function isDailyRent(offer: IOffer): boolean {
  return Boolean(offer.category && offer.category.indexOf('daily') !== -1);
}

function isSale(offer: IOffer): boolean {
  return offer.dealType === 'sale';
}

function isCommercial(offer: IOffer) {
  return offer.offerType === 'commercial';
}

function isGarage(offer: IOffer): boolean {
  return offer.category === 'garageSale' || offer.category === 'garageRent';
}

function isSuburban(offer: IOffer): boolean {
  return offer.category
    ? Array<TOfferCategory>(
        'cottageRent',
        'cottageSale',
        'dailyHouseRent',
        'houseRent',
        'houseSale',
        'houseShareRent',
        'houseShareSale',
        'landRent',
        'landSale',
        'townhouseRent',
        'townhouseSale',
      ).includes(offer.category)
    : false;
}

function isResidential(offer: IOffer): boolean {
  return offer.category
    ? Array<TOfferCategory>(
        'dailyFlatRent',
        'dailyHouseRent',
        'dailyRoomRent',
        'cottageRent',
        'cottageSale',
        'flatRent',
        'flatSale',
        'flatShareSale',
        'houseRent',
        'houseSale',
        'houseShareRent',
        'houseShareSale',
        'newBuildingFlatSale',
        'roomRent',
        'roomSale',
        'townhouseSale',
        'townhouseRent',
        'landRent',
        'landSale',
      ).includes(offer.category)
    : false;
}

function isFlatShared(offer: IOffer): boolean {
  return offer.category === 'flatShareSale';
}

function isPrimaryMetroRegion(offer: IOffer): boolean {
  if (offer.geo && offer.geo.undergrounds && offer.geo.address) {
    return PrimaryMetroRegions.indexOf(offer.geo.address[0].id) >= 0;
  }

  return false;
}

/* Modificators */

const getWithSquareMeters = (data: string): React.ReactNode => {
  return (
    data && (
      <div>
        {data}
        {'м'}
        <sup>2</sup>
      </div>
    )
  );
};

/* Presenters */

function getTotalArea(offer: IOffer): string | null {
  if (offer.totalArea) {
    return String(Math.round(parseFloat(offer.totalArea)));
  }

  return null;
}

function getRoomArea(offer: IOffer): string | null {
  if (offer.roomArea) {
    return `${Math.round(parseFloat(offer.roomArea))}`;
  }

  return null;
}

function getLivingArea(offer: IOffer): string | null {
  if (offer.livingArea) {
    return `жилая ${Math.round(parseFloat(offer.livingArea))}`;
  }

  return null;
}

function getKitchenArea(offer: IOffer): string | null {
  if (offer.kitchenArea) {
    return `кухня ${Math.round(parseFloat(offer.kitchenArea))}`;
  }

  return null;
}

function getSalePrice(offer: IOffer): string | null {
  let formattedPrice = shortenNumber(offer.bargainTerms.priceRur);
  formattedPrice = formattedPrice.replace(/\d+(.)\d+/, t => t.replace('.', ','));

  return `${formattedPrice} руб.`;
}

function getSalePricePerMeter(offer: IOffer): React.ReactNode | null {
  if (!offer.bargainTerms) {
    return null;
  }

  if (offer.bargainTerms.priceType !== 'all') {
    return `${numberToPrettyString(offer.bargainTerms.priceRur)} руб.`;
  } else if (offer.totalArea) {
    const totalArea = parseFloat(offer.totalArea);

    if (totalArea > 0) {
      const pricePerMeter = Math.round(offer.bargainTerms.priceRur / totalArea);
      const pricePerMeterFormatted = numberToPrettyString(pricePerMeter);

      return getWithSquareMeters(`${pricePerMeterFormatted} руб. за `);
    }
  }

  return null;
}

function getSaleType(offer: IOffer): string | null {
  if (offer.bargainTerms && offer.bargainTerms.saleType) {
    return BargainTermsSaleTypePresenter[offer.bargainTerms.saleType] || null;
  }

  return null;
}

function getLeaseType(offer: IOffer) {
  switch (offer.bargainTerms.leaseType) {
    case 'direct':
      return 'прямая аренда';
    case 'sublease':
      return 'субаренда';
    case 'jointVenture':
      return 'совместная аренда';
    default:
      return undefined;
  }
}

function getOfferLayout(offer: IOffer) {
  switch (offer.layout) {
    case 'mixed':
      return 'смешанная';
    case 'cabinet':
      return 'кабинетная';
    case 'openSpace':
      return 'открытая';
    default:
      return undefined;
  }
}

function getAccessType(offer: IOffer) {
  const type = offer.accessType || (offer.building && offer.building.accessType);

  if (!offer.category || !offer.category.startsWith('office')) {
    return undefined;
  }

  switch (type) {
    case 'free':
      return 'свободный вход';
    case 'passSystem':
      return 'вход по пропускам';
    default:
      return undefined;
  }
}

function getRentPrice(offer: IOffer): string {
  let price = offer.bargainTerms.priceRur;

  if (offer.bargainTerms.utilitiesTerms && !offer.bargainTerms.utilitiesTerms.includedInPrice) {
    price += offer.bargainTerms.utilitiesTerms.price;
  }

  return `${numberToPrettyString(price)} руб./${getPaymentPeriod(offer)}`;
}

function getMoreRentPrice(offer: IOffer): string | null {
  const { utilitiesTerms } = offer.bargainTerms;

  if (isDailyRent(offer)) {
    return null;
  }

  /**
   * Если utilitiesTerms с бекенда приходит null, значит ком. платежи включены,
   * так исторически сложилось
   */
  if (utilitiesTerms === null) {
    return 'Комм. платежи включены без счётчиков';
  }

  if (utilitiesTerms) {
    if (utilitiesTerms.includedInPrice) {
      return 'Комм. платежи включены без счётчиков';
    }

    if (!utilitiesTerms.includedInPrice && utilitiesTerms.price === 0) {
      return 'Комм. платежи не включены';
    }

    if (!utilitiesTerms.includedInPrice && utilitiesTerms.price > 0) {
      const priceRent = offer.bargainTerms.priceRur;
      const priceUtilities = utilitiesTerms.price;

      // tslint:disable-next-line:max-line-length
      return `${numberToPrettyString(priceRent)} \u20bd + ${numberToPrettyString(
        priceUtilities,
      )} \u20bd комм. платежи без счётчиков`;
    }
  }

  return '';
}

function getCommercialRentPrice(offer: IOffer): string {
  const price = offer.priceTotalPerMonthRur;

  return `${numberToPrettyString(price)} руб./${getPaymentPeriod(offer)}`;
}

function getCommercialPerMeterByYear(offer: IOffer): string {
  return `${numberToPrettyString(offer.pricePerUnitAreaPerYearRur)} руб. за м\u00b2 в год`;
}

function getPaymentPeriod(offer: IOffer): string {
  return offer.category && offer.category.indexOf('daily') !== -1 ? 'сутки' : 'мес.';
}

function getClientFee(offer: IOffer): string {
  return offer.bargainTerms.clientFee ? `комиссия ${offer.bargainTerms.clientFee}%` : 'без комиссии';
}

function getFee(offer: IOffer): string {
  const { agentFee, clientFee } = offer.bargainTerms;
  const fee: string[] = [];

  if (agentFee != null) {
    fee.push(`аг.\u00A0${agentFee}%`);
  }

  if (clientFee != null) {
    fee.push(`кл.\u00A0${clientFee}%`);
  }

  return fee.join(', ');
}

function getDeposit(offer: IOffer): string {
  if (offer.bargainTerms.deposit) {
    // tslint:disable-next-line:switch-default
    switch (offer.bargainTerms.currency) {
      case 'eur':
        return `залог \u20AC${numberToPrettyString(offer.bargainTerms.deposit)}`;
      case 'rur':
        return `залог ${numberToPrettyString(offer.bargainTerms.deposit)} руб.`;
      case 'usd':
        return `залог $${numberToPrettyString(offer.bargainTerms.deposit)}`;
    }
  }

  return 'без залога';
}

function getLift(offer: IOffer): string {
  let text = 'нет лифта';

  if (offer.building && offer.building.cargoLiftsCount) {
    text = 'лифт грузовой';
  } else if (offer.building && offer.building.passengerLiftsCount) {
    text = 'лифт пассажирский';
  }

  return text;
}

function getType(offer: IOffer) {
  return findTypeById(
    [
      createSearchType('flat', () => {
        if (offer.flatType === 'studio') {
          return 'Студия';
        }

        if (offer.flatType === 'openPlan') {
          return 'Своб. планировка';
        }

        return offer.roomsCount ? (offer.roomsCount >= 6 ? 'Многокомнатная' : `${offer.roomsCount}-комнатная`) : '';
      }),
      createSearchType('newBuildingFlat', () => {
        if (offer.flatType === 'studio') {
          return 'Студия';
        }

        if (offer.flatType === 'openPlan') {
          return 'Своб. планировка';
        }

        return offer.roomsCount ? (offer.roomsCount >= 6 ? 'Многокомнатная' : `${offer.roomsCount}-комнатная`) : '';
      }),
      createSearchType('dailyFlat', () => {
        if (offer.flatType === 'studio') {
          return 'Студия';
        }

        if (offer.flatType === 'openPlan') {
          return 'Своб. планировка';
        }

        return offer.roomsCount ? (offer.roomsCount >= 6 ? 'Многокомнатная' : `${offer.roomsCount}-комнатная`) : '';
      }),
      createSearchType(
        'room',
        () =>
          (offer.roomsForSaleCount &&
            `${offer.roomsForSaleCount} ${plural(offer.roomsForSaleCount, ['комната', 'комнаты', 'комнат'])}`) ||
          '1 комната',
      ),
      createSearchType('dailyRoom', () => 'Комната'),
      createSearchType('dailyBed', () => 'Койко-место'),
      createSearchType('bed', () => 'Койко-место'),
      createSearchType('office', () => 'Офис'),
      createSearchType('shoppingArea', () => 'Торговая площадь'),
      createSearchType('warehouse', () => 'Склад'),
      createSearchType('freeAppointmentObject', () => 'Своб. назнач.'),
      createSearchType('industry', () => 'Помещение под производство'),
      createSearchType('house', () => 'Дом'),
      createSearchType('cottage', () => 'Коттедж'),
      createSearchType('dailyHouse', () => 'Дом'),
      createSearchType('townhouse', () => 'Таунхаус'),
      createSearchType('land', () => 'Участок'),
      createSearchType('commercialLand', () => 'Участок'),
      createSearchType('houseShare', () => {
        return offer.shareAmount ? offer.shareAmount + ' дома' : 'часть дома';
      }),
      createSearchType('flatShare', () => {
        return offer.shareAmount ? offer.shareAmount + ' квартиры' : 'доля';
      }),
      createSearchType('garage', () => {
        if (!offer.garage) {
          return 'Гараж';
        }

        switch (offer.garage.type) {
          case 'box':
            return 'Бокс';
          case 'garage':
            return 'Гараж';
          case 'parkingPlace':
            return 'Машиноместо';
          default:
            return '';
        }
      }),
    ],
    offer,
  );
}

const isPro = (offer: IOffer): boolean => offer.isPro || false;

const isTop3 = (offer: IOffer): boolean => offer.isTop3 || false;

const isByHomeowner = (offer: IOffer): boolean => offer.isByHomeowner || false;

const isFromDeveloper = (offer: IOffer): boolean => offer.fromDeveloper || false;

const isFromBuilder = (offer: IOffer): boolean => (offer.newbuilding && offer.newbuilding.isFromBuilder) || false;

const isFromSeller = (offer: IOffer): boolean => (offer.newbuilding && offer.newbuilding.isFromSeller) || false;

const getNewbuildingName = (offer: IOffer): string => (offer.newbuilding && offer.newbuilding.name) || '';

const getUserTrustLevel = (offer: IOffer): UserTrustLevel | undefined => offer.user && offer.user.userTrustLevel;

const getUserPersonalRating = (offer: IOffer) => offer.user.personalRating || undefined;

const getCianUserId = (offer: IOffer): number | undefined => offer.user && offer.user.cianUserId;

const getAgentName = (offer: IOffer): string | undefined => (offer.user && offer.user.agencyName) || undefined;

const getAccountType = (offer: IOffer): string | undefined => (offer.user && offer.user.accountType) || undefined;

const getCompanyName = (offer: IOffer): string | undefined =>
  (offer.user && offer.user.subAgentCompanyName) || undefined;

const getAgentAvatarUrl = (offer: IOffer): string | undefined => offer.user && offer.user.agentAvatarUrl;

function getLeasePeriod(offer: IOffer): string | undefined {
  const leaseTermType = offer.bargainTerms.leaseTermType;
  let leasePeriod;

  if (offer.category && offer.category.startsWith('daily')) {
    leasePeriod = 'посуточно';
  } else if (leaseTermType) {
    switch (leaseTermType) {
      case LeaseTerm.Long:
        leasePeriod = 'на длительный срок';
        break;
      case LeaseTerm.FewMonths:
        leasePeriod = 'на несколько месяцев';
        break;
      default:
        break;
    }
  }

  return leasePeriod;
}

interface IOfferSearchType {
  name: string;
  getRightName(): string;
}

function createSearchType(name: string, getRightName: () => string): IOfferSearchType {
  return {
    getRightName,
    name,
  };
}

function findTypeById(values: IOfferSearchType[], offer: IOffer) {
  return values.reduce((acc, item) => {
    if (offer.category === item.name + 'Rent') {
      return item.getRightName();
    } else if (offer.category === item.name + 'Sale') {
      return item.getRightName();
    }

    return acc;
  }, '');
}

function getFloorRange(offer: IOffer): string | null {
  let floorInfo = null;

  if (offer.floorNumber) {
    floorInfo = `${offer.floorNumber} этаж`;

    if (offer.building && offer.building.floorsCount) {
      floorInfo += ` из ${offer.building.floorsCount}`;
    }
  }

  return floorInfo;
}

function getFloorCount(offer: IOffer) {
  if (offer.building && offer.building.floorsCount) {
    return `${offer.building.floorsCount} ${plural(offer.building.floorsCount, ['этаж', 'этажа', 'этажей'])}`;
  }

  return undefined;
}

function getBalcony(offer: IOffer): string {
  return offer.loggiasCount ? 'есть лоджия' : offer.balconiesCount ? 'есть балкон' : 'нет балкона';
}

function getBargainAllowed({ bargainTerms }: IOffer): string {
  return bargainTerms.bargainAllowed ? 'возможен торг' : '';
}

function getPhone(offer: IOffer): IPhone | null {
  if (offer.phones && offer.phones.length) {
    const phone = Object.assign({}, offer.phones && offer.phones[0]);
    if (phone.number) {
      phone.number.replace(/[^0-9.]/g, '');
    }

    return phone;
  } else {
    return null;
  }
}

function getMaterialType(offer: IOffer): string | undefined {
  if (offer.building && offer.building.materialType) {
    return BuildingMaterialTypePresenter[offer.building.materialType];
  }

  return undefined;
}

function getBuildingStatus(offer: IOffer): string {
  if (offer.category === 'newBuildingFlatSale') {
    return 'новостройка';
  }

  return 'вторичка';
}

function getBuildingDeadline(offer: IOffer): string | null {
  let text = '';

  if (offer.building && offer.building.deadline) {
    if (offer.building.deadline.isComplete) {
      text = 'дом сдан';
    } else {
      if (offer.building.deadline.quarter) {
        text = `сдача ГК: ${BuildingQuarter[offer.building.deadline.quarter]} кв. `;
      }
      if (offer.building.deadline.year) {
        text += `${offer.building.deadline.year} года`;
      }
      if (!offer.building.deadline.year && offer.building.buildYear) {
        text += `${offer.building.buildYear} года`;
      }
    }
  }

  return text === '' ? null : text;
}

function getBedroomsCount(offer: IOffer): string | null {
  if (offer.bedroomsCount) {
    return `${offer.bedroomsCount} ${plural(offer.bedroomsCount, ['спальня', 'спальни', 'спален'])}`;
  }

  return null;
}

/* presenters. */

export function offerHelper(offer: IOffer) {
  return {
    commercial: commercialHelper(offer),
    getAnalyticsInfo: () => gatherAnalyticsInfo(offer),
    isBed: () => isBed(offer),
    isByHomeowner: () => isByHomeowner(offer),
    isCommercial: () => isCommercial(offer),
    isDailyRent: () => isDailyRent(offer),
    isFlat: () => isFlat(offer),
    isFlatShared: () => isFlatShared(offer),
    isFromBuilder: () => isFromBuilder(offer),
    isFromDeveloper: () => isFromDeveloper(offer),
    isFromSeller: () => isFromSeller(offer),
    isGarage: () => isGarage(offer),
    isPrimaryMetroRegion: () => isPrimaryMetroRegion(offer),
    isPro: () => isPro(offer),
    isRent: () => isRent(offer),
    isResidential: () => isResidential(offer),
    isRoom: () => isRoom(offer),
    isSale: () => isSale(offer),
    isSuburban: () => isSuburban(offer),
    isTop3: () => isTop3(offer),
    presenters: {
      getAccessType: () => getAccessType(offer),
      getAccountType: () => getAccountType(offer),
      getAgentAvatarUrl: () => getAgentAvatarUrl(offer),
      getAgentName: () => getAgentName(offer),
      getBalcony: () => getBalcony(offer),
      getBargainAllowed: () => getBargainAllowed(offer),
      getBedroomsCount: () => getBedroomsCount(offer),
      getBuildingDeadline: () => getBuildingDeadline(offer),
      getBuildingStatus: () => getBuildingStatus(offer),
      getCianUserId: () => getCianUserId(offer),
      getClientFee: () => getClientFee(offer),
      getCommercialPerMeterByYear: () => getCommercialPerMeterByYear(offer),
      getCommercialRentPrice: () => getCommercialRentPrice(offer),
      getCompanyName: () => getCompanyName(offer),
      getDeposit: () => getDeposit(offer),
      getFee: () => getFee(offer),
      getFloorCount: () => getFloorCount(offer),
      getFloorRange: () => getFloorRange(offer),
      getKitchenArea: () => getKitchenArea(offer),
      getLeasePeriod: () => getLeasePeriod(offer),
      getLeaseType: () => getLeaseType(offer),
      getLift: () => getLift(offer),
      getLivingArea: () => getLivingArea(offer),
      getMaterialType: () => getMaterialType(offer),
      getMoreRentPrice: () => getMoreRentPrice(offer),
      getNewbuildingName: () => getNewbuildingName(offer),
      getOfferLayout: () => getOfferLayout(offer),
      getPaymentPeriod: () => getPaymentPeriod(offer),
      getPhone: () => getPhone(offer),
      getRentPrice: () => getRentPrice(offer),
      getRoomArea: () => getRoomArea(offer),
      getSalePrice: () => getSalePrice(offer),
      getSalePricePerMeter: () => getSalePricePerMeter(offer),
      getSaleType: () => getSaleType(offer),
      getTotalArea: () => getTotalArea(offer),
      getType: () => getType(offer),
      getUserPersonalRating: () => getUserPersonalRating(offer),
      getUserTrustLevel: () => getUserTrustLevel(offer),
      simplifiedCard: simplifiedCardHelper(offer),
    },
    suburban: suburbanHelper(offer),
  };
}
