import { connect } from 'react-redux';

import { IAppState } from '../../../store';
import { Description, IDescriptionProps } from './index';

type TStoreProps = Pick<IDescriptionProps, 'userAgent'>;
type TOwnProps = Pick<
  IDescriptionProps,
  | 'containerStyle'
  | 'description'
  | 'descriptionWordsHighlighted'
  | 'excludedFromActionStyle'
  | 'excludedFromActionStyle'
  | 'isExcludedFromAction'
  | 'isRecidivist'
  | 'recidivistStyle'
  | 'onComplainStateChanged'
>;

function mapStateToProps(state: IAppState): TStoreProps {
  return {
    userAgent: state.userAgent,
  };
}

export const DescriptionContainer = connect<TStoreProps, {}, TOwnProps>(mapStateToProps)(Description);
