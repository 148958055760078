import * as React from 'react';
import { Button, Responsive, UIHeading2, UIText2 } from '@cian/ui-kit';
import { useSelector } from 'react-redux';
import { mergeStyles } from '@cian/utils';

import { IFormStatus } from '../../../../store/personal_leads_form';
import { LocationContainer } from '../../../../containers/LocationContainer';
import { Comment, LeadType, Name, ObjectType, Phone } from './fields';
import { IPersonalLeadFormProps } from '../../PersonalLeadForm';
import { selectPageData } from '../../../../selectors/pageData/selectPageData';

import * as styles from './styles.css';

type TFormProps = Omit<IPersonalLeadFormProps, 'openSuccessMobileModal' | 'handleCloseMobileModal'>;

export const Form: React.VFC<TFormProps> = ({
  handleSetType,
  handleSetObjectType,
  handleSetName,
  handleSetComment,
  handleSetPhone,
  handleSubmit,
  handleTrackSelectRegion,
  handleBlurName,
  handleBlurPhone,
  handleBlurComment,
  leadType,
  objectType,
  name,
  phone,
  comment,
  status,
  errorMessage,
  privacyPolicyUrl,
}) => {
  const { deviceType } = useSelector(selectPageData);
  const isDesktop = deviceType === 'desktop';

  return (
    <div data-name="PersonalLeadForm">
      <Responsive desktop>
        <UIHeading2 as="span" color="black_100" fontWeight="bold">
          Свяжитесь с риелтором
        </UIHeading2>
      </Responsive>

      <div {...mergeStyles(styles['fields-container'], !isDesktop && styles['fields-container-mobile'])}>
        <LeadType fieldData={leadType} onChange={handleSetType} />
        <ObjectType fieldData={objectType} onChange={handleSetObjectType} />
        <LocationContainer handleTrackSelectRegion={handleTrackSelectRegion} />
        <Name fieldData={name} onChange={handleSetName} onBlur={handleBlurName} />
        <Phone fieldData={phone} onChange={handleSetPhone} onBlur={handleBlurPhone} />
        <Comment fieldData={comment} onChange={handleSetComment} onBlur={handleBlurComment} />

        <Button
          loading={status === IFormStatus.LOADING}
          onClick={handleSubmit}
          fullWidth
          size="M"
          disabled={status === IFormStatus.LOADING}
          theme="fill_primary"
        >
          Отправить заявку
        </Button>
      </div>

      {status === IFormStatus.ERROR && (
        <div className={styles['text-error']}>
          <UIText2 color="error_100">{errorMessage}</UIText2>
        </div>
      )}

      <div className={styles['aggreement']} data-name="AgreementInfo">
        <UIText2>Нажимая на кнопку, вы соглашаетесь на обработку</UIText2>
        <a href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
          персональных данных
        </a>
      </div>
    </div>
  );
};
