import { mergeStyles } from '@cian/utils/lib/shared/style';
import * as React from 'react';

import * as styles from './index.css';

export type INoPhotoProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export class NoPhoto extends React.PureComponent<INoPhotoProps, {}> {
  public render() {
    const { className, ...sharedProps } = this.props;

    return <div {...sharedProps} {...mergeStyles([styles['container'], className])} />;
  }
}
