import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectRealtor } from '../../selectors/realtor/selectRealtor';
import { DescriptionMobile } from '../../components/description';
import { trackMoreInfoClick } from '../../components/aboutRealtor/desktop/tracking/tracking';

interface IProps {
  className?: string;
}

export const DescriptionContainer: React.FunctionComponent<IProps> = ({ className }) => {
  const { description } = useSelector(selectRealtor);

  if (!description) {
    return null;
  }

  return <DescriptionMobile text={description} className={className} onExpandClick={trackMoreInfoClick} />;
};
