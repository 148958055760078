/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetComplaintTypesModel,
  IGetComplaintTypesRequest,
  IMappers,
  IGetComplaintTypesResponse,
  IGetComplaintTypesResponseError,
  TGetComplaintTypesResponse,
} from './types';

const defaultConfig: TGetComplaintTypesModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'moderation-complaints',
  pathApi: '/v3/get-complaint-types/',
  hostType: 'api',
} as TGetComplaintTypesModel;

function createGetComplaintTypesModel(parameters: IGetComplaintTypesRequest): TGetComplaintTypesModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetComplaintTypesOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetComplaintTypesRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetComplaintTypes<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetComplaintTypesOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetComplaintTypesResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetComplaintTypesModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetComplaintTypesResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetComplaintTypesResponseError);
    }
  }

  return { response, statusCode } as TGetComplaintTypesResponse;
}

export { defaultConfig, createGetComplaintTypesModel, fetchGetComplaintTypes };
