import { IOffer, IOfferAnalyticsInfo } from '../../api/offer';

export const gatherAnalyticsInfo = (offer: IOffer): IOfferAnalyticsInfo => {
  const newState: IOfferAnalyticsInfo = {
    id: offer.id,
    offerType: 'offer',
    position: 1,
  };

  return newState;
};
