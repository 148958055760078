import * as React from 'react';
import { Button } from '@cian/ui-kit';
import { useSelector } from 'react-redux';

import { selectReviews } from '../../selectors/reviews/selectReviews';
import { selectUserAuthenticated, selectRealtyUserId, selectRealtorRealtyUserId } from '../../selectors';
import { trackReviewClick } from '../../components/review_form/tracking';
// eslint-disable-next-line import/no-restricted-paths
import { requestAuthentication } from '../../../browser/utils/authentication';
// eslint-disable-next-line import/no-restricted-paths
import { scrollToElement } from '../../../browser/utils/scroll';
import { open } from '../../store/review_form';
import { useAppDispatch } from '../../hooks/useAppDispatch';

export const NewReviewButtonContainer = () => {
  const dispatch = useAppDispatch();

  const { totalCount } = useSelector(selectReviews);
  const realtyUserId = useSelector(selectRealtyUserId);
  const userIsAuthenticated = useSelector(selectUserAuthenticated);
  const realtorRealtyUserId = useSelector(selectRealtorRealtyUserId);

  const handleNewReviewClick = React.useCallback(() => {
    trackReviewClick();

    if (!userIsAuthenticated) {
      requestAuthentication('new');

      return;
    }

    dispatch(open());

    const reviewFormElement = document.getElementById('review_form');
    if (reviewFormElement) {
      scrollToElement(reviewFormElement);
    }
  }, [dispatch, userIsAuthenticated]);

  return totalCount > 0 && ((userIsAuthenticated && realtorRealtyUserId !== realtyUserId) || !userIsAuthenticated) ? (
    <Button theme="fill_primary" size="XS" onClick={handleNewReviewClick}>
      Написать отзыв
    </Button>
  ) : null;
};
