/* eslint-disable react/forbid-elements */
import * as React from 'react';
import cx from 'classnames';

import { Button } from '../../../../../components/button';

import * as styles from '../index.css';

type MoreButtonProps = {
  isActive: boolean;
  onToggle: () => void;
};

export const MoreButton = React.forwardRef(
  ({ isActive, onToggle }: MoreButtonProps, ref: React.RefObject<HTMLDivElement>) => {
    return (
      <div ref={ref}>
        <Button theme="primary" mode="light" className={styles['button']} onClick={onToggle}>
          Ещё
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 7.213 4.274"
            className={cx(styles['icon'], {
              [styles['icon--active']]: isActive,
            })}
          >
            <path
              fill="currentColor"
              d="M3.132 4.064l-3-2.89C-.102.832-.015.366.326.13c.24-.164.555-.175.806-.026l2.52 2.37 2.43-2.37C6.44-.106 6.9.012 7.11.368c.147.252.137.566-.028.806l-3 2.89c-.117.115-.268.19-.43.21-.194 0-.38-.075-.52-.21z"
            />
          </svg>
        </Button>
      </div>
    );
  },
);

MoreButton.displayName = 'MoreButton';
