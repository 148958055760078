export interface IAgentChatsState {
  chatId?: string;
}

export interface IAgentChatReceived {
  type: 'IAgentChatReceived';
  payload: {
    chatId: string;
  };
}

export const agentChatReceived = (chatId: string): IAgentChatReceived => ({
  payload: { chatId },
  type: 'IAgentChatReceived',
});

export type TChatActions = IAgentChatReceived;

const initialState: IAgentChatsState = {
  chatId: undefined,
};

export function chatReducer(state: IAgentChatsState = initialState, action: TChatActions): IAgentChatsState {
  switch (action.type) {
    case 'IAgentChatReceived':
      return {
        ...state,
        chatId: action.payload.chatId,
      };

    default:
      return state;
  }
}
