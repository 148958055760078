/* eslint-disable react/forbid-elements */
import * as React from 'react';
import cx from 'classnames';

import * as styles from './index.css';

type ReliableIconProps = React.HTMLAttributes<SVGElement> & {
  wrapperClassName?: string;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

export const ReliableIcon = React.forwardRef(
  ({ wrapperClassName, ...props }: ReliableIconProps, ref: React.LegacyRef<SVGSVGElement>) => {
    return (
      <div className={cx(styles['reliable-wrapper'], wrapperClassName)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
          className={styles['reliable-icon']}
          ref={ref}
          {...props}
        >
          <defs>
            <linearGradient id="a" x1="82.98%" x2="19.15%" y1="12%" y2="79%">
              <stop offset="0%" stopColor="#3BB608" />
              <stop offset="100%" stopColor="#2E9E00" />
            </linearGradient>
          </defs>
          <path
            fill="url(#a)"
            fillRule="evenodd"
            d="M188.08 43.02a97.46 97.46 0 0 1-1.88-1.22A88.59 88.59 0 0 1 175 33a92.35 92.35 0 0 1-11.2 8.8A101.28 101.28 0 0 1 151 49v4.21l5.05-.21v27a1 1 0 0 0 1 1h35.9a1 1 0 0 0 1-1V53.21H199V49a97.24 97.24 0 0 1-7.03-3.63A172.48 172.48 0 0 0 175 75c-3.27-6.68-9.56-15.28-13.99-19.83l4.24-4.9a66.8 66.8 0 0 1 9.49 12.53c3.28-6.3 8.31-13.45 13.35-19.78z"
            transform="translate(-151 -33)"
          />
        </svg>
      </div>
    );
  },
);

ReliableIcon.displayName = 'ReliableIcon';
