import { FormField, Input } from '@cian/ui-kit';
import * as React from 'react';

import { IPersonalLeadFormProps } from '../../../index';
import { IPersonalLeadFormData } from '../../../../../store/personal_leads_form';

export interface INameProps {
  fieldData: IPersonalLeadFormData['name'];
  onChange: IPersonalLeadFormProps['handleSetName'];
  onBlur: IPersonalLeadFormProps['handleBlurName'];
}

export const Name = ({ fieldData, onChange, onBlur }: INameProps) => {
  return (
    <FormField withoutMargin required label="Ваше имя" errorMessage={fieldData.error ? 'Введите ваше имя' : undefined}>
      <Input
        invalid={fieldData.error}
        value={fieldData.value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder="Александр"
      />
    </FormField>
  );
};
