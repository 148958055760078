import * as React from 'react';
import { UIHeading4, UIText1 } from '@cian/ui-kit';

import * as styles from './AboutRealtorDesktop.css';

interface IDescriptionRowProps {
  label: string;
  text: string;
  action?(): void;
}

export const DescriptionRow = ({ label, text, action }: IDescriptionRowProps) => {
  return (
    <div className={styles['row']}>
      <div className={styles['about-title']}>
        <UIHeading4>{label}</UIHeading4>
      </div>
      <span className={styles['about-text']}>
        <UIText1 whiteSpace="pre-line">{text}</UIText1>
        {action && (
          <span className={styles['maximize-button']}>
            <UIText1 color="primary_100" onClick={action}>
              Развернуть
            </UIText1>
          </span>
        )}
      </span>
    </div>
  );
};
