import * as React from 'react';
import { UIHeading3 } from '@cian/ui-kit/typography';
import { Star16 } from '@cian/ui-kit/prev';

interface IRateMobileProps {
  rate: string;
}

export const RateMobile: React.FunctionComponent<IRateMobileProps> = ({ rate }) => {
  return (
    <div>
      <UIHeading3 as="span" textAlign="center">
        <Star16 color={'warning_100'} />
        <span> </span>
        {rate}
      </UIHeading3>
    </div>
  );
};
