const YANDEX_BOTS_USER_AGENTS = [
  'YaDirectFetcher',
  'YandexAccessibilityBot',
  'YandexAdNet',
  'YandexBlogs',
  'YandexBot',
  'YandexCalendar',
  'YandexCatalog',
  'YandexDirect',
  'YandexDirectDyn',
  'YandexFavicons',
  'YandexForDomain',
  'YandexImageResizer',
  'YandexImages',
  'YandexMarket',
  'YandexMedia',
  'YandexMedianaBot',
  'YandexMetrika',
  'YandexMobileBot',
  'YandexNews',
  'YandexOntoDB',
  'YandexOntoDBAPI',
  'YandexPagechecker',
  'YandexScreenshotBot',
  'YandexSearchShop',
  'YandexSitelinks',
  'YandexSpravBot',
  'YandexVerticals',
  'YandexVertis',
  'YandexVideo',
  'YandexVideoParser',
  'YandexWebmaster',
].map(userAgent => userAgent.toLowerCase());

const GOOGLE_BOTS_USER_AGENTS = [
  'APIs-Google',
  'AdsBot-Google',
  'AdsBot-Google-Mobile',
  'AdsBot-Google-Mobile-Apps',
  'Googlebot',
  'Mediapartners-Google',
].map(userAgent => userAgent.toLowerCase());

export function isYandexBot(userAgent: string = ''): boolean {
  const formattedUserAgent = userAgent.toLowerCase();

  return YANDEX_BOTS_USER_AGENTS.some(botUserAgent => formattedUserAgent.includes(botUserAgent));
}

export function isGoogleBot(userAgent: string = ''): boolean {
  const formattedUserAgent = userAgent.toLowerCase();

  return GOOGLE_BOTS_USER_AGENTS.some(botUserAgent => formattedUserAgent.includes(botUserAgent));
}
