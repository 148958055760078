import * as React from 'react';

import { SuperAgentTooltip } from './SuperAgentTooltip';
import { SuperAgentBadge } from './SuperAgentBadge';

type TSuperAgentLevelViewProps = {
  levelName: string;
  tooltipText: string;
  onOpenTooltip(): void;
};

export const SuperAgentLevelView: React.FC<TSuperAgentLevelViewProps> = ({ levelName, tooltipText, onOpenTooltip }) => {
  if (!tooltipText.length) {
    return <SuperAgentBadge levelName={levelName} />;
  }

  return (
    <SuperAgentTooltip tooltipText={tooltipText} onOpenTooltip={onOpenTooltip}>
      <SuperAgentBadge levelName={levelName} />
    </SuperAgentTooltip>
  );
};
