import * as React from 'react';
import { useSelector } from 'react-redux';

import { IReviewAnswer } from '../../types/review';
import { selectRealtyUserId } from '../../selectors';
import { AnswersMobile } from '../../components/AnswersMobile';
import { AnswerMobileContainer } from './AnswerMobileContainer';

interface IAnswersMobileContainerProps {
  reviewId: string;
  answers: IReviewAnswer[];
}

export const AnswersMobileContainer: React.FunctionComponent<IAnswersMobileContainerProps> = ({
  reviewId,
  answers,
}) => {
  const realtyUserId = useSelector(selectRealtyUserId);

  if (!Array.isArray(answers) || answers.length === 0) {
    return null;
  }

  return (
    <AnswersMobile key={`answers_${reviewId}`}>
      {answers.map(answer => {
        /**
         * Отображаем отклоненный ответ только для хозяина ответа
         */
        if (answer.status === 'declined' && answer.user.realtyUserId !== realtyUserId) {
          return null;
        }

        return <AnswerMobileContainer answer={answer} key={answer.answerId} reviewId={reviewId} />;
      })}
    </AnswersMobile>
  );
};
