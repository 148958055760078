import * as React from 'react';
import { UIText1 } from '@cian/ui-kit';

import * as styles from './contactItemMobile.css';

interface IContactItemProps {
  icon: JSX.Element | null;
  href?: string;
  text: string;
  onClick?: () => void;
}

export const ContactItemMobile: React.FunctionComponent<IContactItemProps> = ({ icon, href, text, onClick }) => {
  return (
    <div>
      {href ? (
        <a className={styles['link']} href={href} rel="nofollow noopener noreferrer" target="_blank" onClick={onClick}>
          <span className={styles['icon']}>{icon}</span>
          <UIText1 color="primary_100">{text}</UIText1>
        </a>
      ) : (
        <div className={styles['container']}>
          <span className={styles['icon']}>{icon}</span>
          <UIText1>{text}</UIText1>
        </div>
      )}
    </div>
  );
};
