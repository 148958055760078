import * as React from 'react';
import { RoundButton } from '@cian/ui-kit';

import { IButtonsProps } from '../types';
import mapImage from '../icons/map.svg';

export type MapProps = Pick<IButtonsProps, 'isMapActive' | 'buttonClassName'> & {
  onClick: () => void;
  isButtonHidden: boolean;
};

export const Map = React.memo(({ buttonClassName, isMapActive, onClick }: MapProps) => {
  const title = isMapActive ? 'Скрыть карту' : 'Показать на карте';
  const icon = <img src={mapImage} alt="map icon" />;

  return (
    <div title={title} className={buttonClassName || ''}>
      <RoundButton size="XS" icon={icon} title={title} theme="fill_light" onClick={onClick} />
    </div>
  );
});

Map.displayName = 'Map';
