export interface ISimplifiedOfferCardPopupsState {
  [id: string]: TPopupTypes[];
}

export type TPopupTypes = 'more' | 'agent' | 'report';

export interface ISimplifiedOfferCardPopupOpen {
  type: 'ISimplifiedOfferCardPopupOpen';
  offerId: number;
  popupType: TPopupTypes;
}

export interface ISimplifiedOfferCardPopupClose {
  type: 'ISimplifiedOfferCardPopupClose';
  offerId: number;
  popupType: TPopupTypes;
}

export const simplifiedOfferCardPopupOpenAction = (
  offerId: number,
  popupType: TPopupTypes,
): ISimplifiedOfferCardPopupOpen => ({
  offerId,
  popupType,
  type: 'ISimplifiedOfferCardPopupOpen',
});

export const simplifiedOfferCardPopupCloseAction = (
  offerId: number,
  popupType: TPopupTypes,
): ISimplifiedOfferCardPopupClose => ({
  offerId,
  popupType,
  type: 'ISimplifiedOfferCardPopupClose',
});

export const initialState = {};

export type TSimplifiedOfferCardPopupActions = ISimplifiedOfferCardPopupOpen | ISimplifiedOfferCardPopupClose;

export function simplifiedOfferCardPopupsStateReducer(
  state: ISimplifiedOfferCardPopupsState = initialState,
  action: TSimplifiedOfferCardPopupActions,
) {
  switch (action.type) {
    case 'ISimplifiedOfferCardPopupOpen':
      return {
        ...state,
        [action.offerId]: [...(state[action.offerId] || []), action.popupType],
      };
    case 'ISimplifiedOfferCardPopupClose':
      return {
        ...state,
        [action.offerId]: [...(state[action.offerId] || []).filter(item => item !== action.popupType)],
      };
    default:
      return {};
  }
}
