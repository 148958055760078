/* eslint-disable react/forbid-elements */
import * as React from 'react';

import * as styles from './index.css';

export const ReportPopupSubmitResult = () => (
  <div className={styles['result']}>
    <svg
      className={styles['result-icon']}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 39.936 28.674"
      width="40"
      height="30"
    >
      {/* tslint:disable-next-line:max-line-length */}
      <path
        fill="#2E9E00"
        d="M16.146 28.382c-.388.388-1.017.39-1.407.003L.29 13.937c-.387-.387-.387-1.015 0-1.403l.007-.007L3.41 9.414c.39-.39 1.022-.393 1.413-.004l.003.003L15.44 20.028 35.06.29c.387-.386 1.015-.386 1.403 0 0 .002.002.003.003.004l3.176 3.176c.39.39.392 1.02.003 1.41v.002l-23.5 23.5z"
      />
    </svg>
    <strong className={styles['result-text']}>Спасибо, проверим!</strong>
  </div>
);
