import { connect } from 'react-redux';

import { IOffersDispatchProps, IOffersStoreProps, Offers } from '.';
import { IOffer } from '../../api/offer';
import { IAppState } from '../../store';
import { changeFavorite } from '../../store/favorite';
import { open } from '../../store/review_form';
import { TThunkDispatch } from '../../types/redux';

function mapStateToProps(state: IAppState): IOffersStoreProps {
  return {
    offers: state.offers,
    popupsState: state.simplifiedOfferCardPopupsState,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch: TThunkDispatch): IOffersDispatchProps {
  return {
    onFavoriteChanged: (offer: IOffer, isFavorite: boolean) => {
      // @ts-ignore FIXME:
      dispatch(changeFavorite(offer, isFavorite));
    },
    open: () => dispatch(open()),
  };
}

export const OffersContainer = connect<IOffersStoreProps, IOffersDispatchProps, {}, IAppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Offers);
