export const NBSP = '\u00A0';

export function getFormattedPhoneNumber(phoneNumber: string): string {
  if ((phoneNumber.startsWith('+') && phoneNumber.length === 12) || phoneNumber.length === 11) {
    let result = phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3-$4-$5');

    if (result.startsWith('7')) {
      result = `+${result}`;
    }

    return result;
  }

  return phoneNumber;
}

export function getFormattedTime(time: string): string {
  return time.replace(/(\d{2}):(\d{2}):(\d{2})/, '$1:$2');
}
