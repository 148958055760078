import * as React from 'react';
import { IStyleConfig, mergeStyles } from '@cian/utils';

const style = require('./index.css');

interface IColumnProps {
  columnStyle?: IStyleConfig;
  width: number;
  children: React.ReactNode;
}

export class Column extends React.Component<IColumnProps> {
  public render() {
    return (
      <div {...mergeStyles(style['column'], this.props.columnStyle, { width: `${this.props.width}%` })}>
        {this.props.children}
      </div>
    );
  }
}
