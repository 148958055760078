import * as React from 'react';
import { Outside } from '@cian/ui-kit';
import { useSelector } from 'react-redux';

import { ILocation } from '../../types/location';
import { LocationSuggest } from '../../components/LocationSuggest';
import { useLocationSuggest } from './utils/useLocationSuggest';
import { useLocationSuggestPopup } from './utils/useLocationSuggestPopup';
import { useLocationSuggestValue } from './utils/useLocationSuggestValue';
import { selectLocation } from '../../selectors/personalLeadForm/selectLocation';
import { setLocation } from '../../store/personal_leads_form';
import { useAppDispatch } from '../../hooks/useAppDispatch';

interface ILocationContainerProps {
  handleTrackSelectRegion: (value: number) => void;
}

export const LocationContainer = ({ handleTrackSelectRegion }: ILocationContainerProps) => {
  const dispatch = useAppDispatch();

  const { value, error } = useSelector(selectLocation);

  const onLocationChange = React.useCallback(
    (newLocation: ILocation) => {
      dispatch(setLocation(newLocation));
    },
    [dispatch],
  );

  const { isSuggestShown, onItemClick, togglePopup } = useLocationSuggestPopup({
    onLocationChange,
    handleTrackSelectRegion,
  });

  const { getLocationSuggest, suggest, isFetching } = useLocationSuggest({ togglePopup });

  const { suggestValue, clearSuggestValue, onSuggestionValueChange } = useLocationSuggestValue({
    location: value,
    onLocationChange,
    togglePopup,
    getLocationSuggest,
  });

  const locationSuggestRef = React.createRef<HTMLDivElement>();

  return (
    <Outside active insideRefs={[locationSuggestRef]} onOutside={clearSuggestValue}>
      <LocationSuggest
        ref={locationSuggestRef}
        isLoading={isFetching}
        error={error}
        placeholder="Город или регион"
        inputValue={suggestValue}
        suggest={suggest}
        isSuggestShown={isSuggestShown}
        onChange={onSuggestionValueChange}
        onItemClick={onItemClick}
      />
    </Outside>
  );
};

LocationContainer.displayName = 'LocationContainer';
