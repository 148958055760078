import { findLast } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { IStyleConfig, mergeStyles } from '@cian/utils';
import { DropdownPopover } from '@cian/ui-kit';

import { ELocationTypeId, IOffer } from '../../../api/offer';
import { offerHelper } from '../../../offer/presenters';
import { UndergroundIcon } from '../../underground_icon';
import { AddressPathItem, TAddressPathItem } from './address_path_item';
import { ReliableIcon } from './reliable_icon';
import { Underground } from './underground/index';
import { ReliablePopupContent } from './components';

import * as style from './index.css';

interface IAddressStoreProps {
  isReliableActive: boolean;
}

interface IAddressOwnProps {
  offer: IOffer;
  containerStyle?: IStyleConfig;
}

type IAddressProps = IAddressStoreProps & IAddressOwnProps;

type AddressState = {
  isReliablePopupVisible: boolean;
};

class Address extends React.Component<IAddressProps, AddressState> {
  public constructor(props: IAddressProps) {
    super(props);

    this.state = {
      isReliablePopupVisible: false,
    };
  }

  public render() {
    const { containerStyle, offer } = this.props;

    return (
      <div {...mergeStyles(style['address'], containerStyle)}>
        {this.renderAreaBlock()}
        {this.renderBuildingUrl()}
        {this.renderAddressPath()}
        {!offerHelper(offer).isPrimaryMetroRegion() && this.renderRegionUnderground()}
      </div>
    );
  }

  public setPupupVisibility = (isReliablePopupVisible: boolean) => {
    this.setState({ isReliablePopupVisible });
  };

  private renderAreaBlock = () => {
    const { offer } = this.props;
    const { geo, fullUrl } = offer;

    const underground = offerHelper(offer).isPrimaryMetroRegion() ? this.getUnderground() : undefined;

    if (underground) {
      const locationId = (geo && geo.address.length > 0 && geo.address[0].id) || undefined;

      return <Underground underground={underground} locationId={locationId} url={fullUrl} />;
    } else if (geo) {
      const location = findLast(geo.address, address => {
        return address.type === 'location';
      });

      if (location) {
        return (
          <a href={fullUrl} target="_blank" className={style['header-link']} rel="noreferrer">
            <div className={style['header']}>{location.fullName}</div>
          </a>
        );
      }
    }

    return null;
  };

  private renderBuildingUrl() {
    const { isReliableActive } = this.props;
    const { geo, businessShoppingCenter, newbuilding } = this.props.offer;
    const isReliable = isReliableActive && newbuilding && newbuilding.showJkReliableFlag;

    const reliableIcon = (href: string) =>
      isReliable && (
        <DropdownPopover
          open={this.state.isReliablePopupVisible}
          flip={false}
          sameWidth="minWidth"
          content={
            <ReliablePopupContent
              href={href}
              onMouseEnter={() => this.setPupupVisibility(true)}
              onMouseLeave={() => this.setPupupVisibility(false)}
            />
          }
        >
          <ReliableIcon
            wrapperClassName={style['external-reliable-icon']}
            onMouseEnter={() => this.setPupupVisibility(true)}
            onMouseLeave={() => this.setPupupVisibility(false)}
          />
        </DropdownPopover>
      );

    if (
      businessShoppingCenter &&
      businessShoppingCenter.type &&
      ((businessShoppingCenter.useParentName && businessShoppingCenter.parentName) ||
        (!businessShoppingCenter.useParentName && businessShoppingCenter.name))
    ) {
      const bsCenterTypeName = {
        businessCenter: 'БЦ',
        shoppingCenter: 'ТЦ',
        warehouse: 'СК',
      };

      const name = `${bsCenterTypeName[businessShoppingCenter.type]} «${
        !businessShoppingCenter.useParentName ? businessShoppingCenter.name : businessShoppingCenter.parentName
      }»`;
      const url = !businessShoppingCenter.useParentName
        ? businessShoppingCenter.fullUrl
        : businessShoppingCenter.parentUrl;

      return (
        <div className={style['building']}>
          {!url && name}
          {url && (
            <div>
              {reliableIcon(url)}

              <a href={url} className={style['building-link']}>
                {name}
              </a>
            </div>
          )}
        </div>
      );
    } else if (geo && geo.jk && geo.jk.displayName) {
      const { fullUrl } = geo.jk;

      return (
        <div className={style['building']}>
          {!fullUrl && geo.jk.displayName}
          {fullUrl && (
            <div>
              {reliableIcon(fullUrl)}

              <a href={fullUrl} className={style['building-link']}>
                {geo.jk.displayName}
              </a>
            </div>
          )}
        </div>
      );
    }

    return null;
  }

  private renderAddressPath = () => {
    const { offer } = this.props;

    const { geo } = offer;
    if (!geo) {
      return null;
    }

    const additionalStyle = geo.jk || offer.businessShoppingCenter ? { paddingTop: 0 } : undefined;
    const undergroundStation = this.getUnderground();

    let cityIndex = 1;
    let isGeoAddressWithMetro = false;

    geo.address.forEach(({ locationTypeId, type }, index) => {
      /**
       * Если в geo.address уже есть метро, то вручную
       * на фронте его подставлять не нужно
       */
      if (type === 'metro') {
        isGeoAddressWithMetro = true;
      }

      if (locationTypeId === ELocationTypeId.City) {
        cityIndex = index + 1;
      }
    });

    const addressLine =
      geo.address.map(({ fullName, qs, type }) => ({
        label: fullName,
        qs,
        type: type === 'metro' ? 'metro' : 'address',
      })) || [];

    /**
     * Если есть undergroundStation, но нет метро внутри geo.address,
     * то добавляем метро вручную тут
     */
    if (undergroundStation && !isGeoAddressWithMetro) {
      addressLine.splice(cityIndex, 0, {
        label: `м.\u00A0${undergroundStation.name}`,
        qs: undergroundStation.qs,
        type: 'metro',
      });
    }

    const addressString = addressLine.map(address => address.label).join(', ');

    return (
      <div {...mergeStyles(style['address-path'], additionalStyle)}>
        <span {...{ itemProp: 'name', content: addressString }} />
        {addressLine.map(({ label, qs, type }, index) => {
          const item = (
            <AddressPathItem key={`address-path-item-${index}`} label={label} qs={qs} type={type as TAddressPathItem} />
          );

          return [index > 0 && ', ', item];
        })}
      </div>
    );
  };

  private renderRegionUnderground = () => {
    const { geo } = this.props.offer;
    if (!geo) {
      return null;
    }

    const undergroundStation = this.getUnderground();
    if (!undergroundStation) {
      return null;
    }

    return (
      <div className={style['region-metro']}>
        <UndergroundIcon color={`#${undergroundStation.lineColor}`} width={9} height={9} /> {undergroundStation.name}
      </div>
    );
  };

  private getUnderground = () => {
    const { offer } = this.props;
    const { geo } = offer;

    if (geo && geo.undergrounds) {
      const offerUndergrounds = geo.undergrounds.concat().sort((a, b) => a.time - b.time);

      return offerUndergrounds.find(underground => underground.transportType === 'walk') || offerUndergrounds[0];
    }

    return undefined;
  };
}

const mapStateToProps = () => {
  return {
    isReliableActive: true,
  };
};

export const AddressContainer = connect<IAddressStoreProps, {}, IAddressOwnProps>(mapStateToProps)(Address);
