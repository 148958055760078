import { connect } from 'react-redux';

import { IReviewsRealtorPageDispatchProps, IReviewsRealtorPageStateProps, ReviewsRealtorPage } from '.';
import { IAppState } from '../../store';
import { pushSubscriptionSetValue } from '../../store/push_subscribed';
import { TThunkDispatch } from '../../types/redux';
import { open } from '../../store/review_form';

export function mapDispatchToProps(dispatch: TThunkDispatch): IReviewsRealtorPageDispatchProps {
  return {
    pushSubscriptionSetValue: (value: boolean) => dispatch(pushSubscriptionSetValue(value)),
    openReviewForm: () => dispatch(open()),
  };
}

const mapStateToProps = (state: IAppState) => ({
  siteVersion: state.pageData.siteVersion,
  filters: state.pageData.filters,
  isAuthenticated: state.user.isAuthenticated,
});

export const ReviewsRealtorPageContainer = connect<IReviewsRealtorPageStateProps, IReviewsRealtorPageDispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps,
)(ReviewsRealtorPage);
