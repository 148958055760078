/* eslint-disable react/forbid-elements */
import * as React from 'react';

export const VKIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM9.96628 11.9413C8.86529 12.4381 7.05347 12.4409 5.95718 11.8113C4.39397 10.8969 4.11611 8.83831 5.46466 8.83831C6.00454 8.83831 6.16687 9.2051 6.35423 9.62845C6.51959 10.0021 6.70446 10.4198 7.18561 10.6683C7.65671 10.9072 8.66943 10.9318 9.25805 10.4964C9.83936 10.0695 9.78609 9.39807 9.4701 9.07722C9.11718 8.71978 8.48731 8.57288 7.79465 8.41133C6.84416 8.18965 5.77544 7.9404 5.14189 7.08169C4.66608 6.43771 4.57625 5.30158 5.16121 4.47769C6.18072 3.03848 9.1724 2.95082 10.4588 4.03768C11.6465 5.04605 11.3258 6.38442 10.3674 6.38442C9.85973 6.38442 9.69317 6.06348 9.50675 5.7043C9.26989 5.24791 9.00099 4.72978 7.95964 4.72978C6.61057 4.72978 6.07992 5.78227 7.02996 6.29447C7.38221 6.48642 7.8836 6.60834 8.42621 6.74029C9.86766 7.09081 11.6 7.51207 11.6 9.50463C11.5974 10.6459 10.9597 11.4967 9.96628 11.9413Z"
      fill="#0468FF"
    />
  </svg>
);
