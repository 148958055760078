export const SECONDS_IN_HOUR = 3600;
export const SECONDS_IN_DAY = 86400;

export const getResponseTimeText = (seconds: number) => {
  if (seconds > 0 && seconds <= SECONDS_IN_HOUR) {
    return 'несколько минут';
  }

  if (seconds > SECONDS_IN_HOUR && seconds <= SECONDS_IN_DAY) {
    return 'несколько часов';
  }

  if (seconds > SECONDS_IN_DAY) {
    return 'несколько дней';
  }

  return null;
};
