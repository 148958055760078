import { connect } from 'react-redux';

import { IReviewsHeaderDispatchProps, IReviewsHeaderStoreProps, ReviewsHeader } from '.';
import { IAppState } from '../../store';
import { open } from '../../store/review_form';
import { TThunkDispatch } from '../../types/redux';

function mapStateToProps(state: IAppState): IReviewsHeaderStoreProps {
  return {
    count: state.reviews.totalCount,
    rate: state.reviews.entityRate,
    realtor: state.realtor,
  };
}

function mapDispatchToProps(dispatch: TThunkDispatch): IReviewsHeaderDispatchProps {
  return {
    openReviewForm: () => dispatch(open()),
  };
}

export const ReviewsHeaderContainer = connect<IReviewsHeaderStoreProps, IReviewsHeaderDispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps,
)(ReviewsHeader);
