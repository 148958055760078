import { IGetBlogListSuccessResponseDataSchema } from '../types/blogs';

export type TBlogsActions = IBlogsFetched;

export interface IBlogsData {
  blogs: IGetBlogListSuccessResponseDataSchema[];
  totalCount: number;
}

export interface IBlogsFetched extends IBlogsData {
  type: 'IBlogsFetched';
}

const defaultState = {
  blogs: [],
  totalCount: 0,
};

export function blogsReducer(state: IBlogsData = defaultState, action: TBlogsActions): IBlogsData {
  switch (action.type) {
    case 'IBlogsFetched':
      return {
        ...state,
        blogs: action.blogs,
        totalCount: action.totalCount,
      };

    default:
      return state;
  }
}
