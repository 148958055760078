/**
 * Постепенный переход на новый компонент
 * Старый будует убран когда будет редизайн боковой карточки
 */

import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectAgentLevelInfo, selectHasVerifiedDocuments } from '../../selectors';
import { selectCanDoOnlineDeal } from '../../selectors/e2eDealInfo';
import { useApplicationContext } from '../../utils/applicationContext';
import { ApprovalNew, EApprovalType } from '../../components/Approval/ApprovalNew';
import { ApprovalNewWrapper } from '../../components/ApprovalNewWrapper';
import { trackCanDoOnlineDealLabelShow } from './tracking';

export const ApprovalContainerNew = () => {
  const { config } = useApplicationContext();
  const agentLevelInfo = useSelector(selectAgentLevelInfo);
  const hasVerifiedDocuments = useSelector(selectHasVerifiedDocuments);
  const canDoOnlineDeal = useSelector(selectCanDoOnlineDeal);

  // Листинг сам разбирается, пустить пользователя или отправить его на промку (B или C)
  const onlineDealLink = config.get<string>('e2e-deal-sale-listing-frontend.baseUrl') || '';
  const isOnlineDealLabelEnabled = config.get<string>('realtor-info.isCanDoOnlineDeal.Enabled');

  const hasVerifiedDocumentsApproval = agentLevelInfo?.hasVerifiedDocuments || hasVerifiedDocuments;
  const hasCanDoOnlineDealApproval = canDoOnlineDeal;

  if (!hasVerifiedDocumentsApproval && !hasCanDoOnlineDealApproval) {
    return null;
  }

  return (
    <ApprovalNewWrapper>
      {hasCanDoOnlineDealApproval && isOnlineDealLabelEnabled && (
        <ApprovalNew
          type={EApprovalType.CanDoOnlineDeal}
          onlineDealLink={`${onlineDealLink}?cian_source=specialist_label`}
          onOpenLabel={trackCanDoOnlineDealLabelShow}
        />
      )}
      {hasVerifiedDocumentsApproval && <ApprovalNew type={EApprovalType.HasVerifiedDocuments} />}
    </ApprovalNewWrapper>
  );
};
