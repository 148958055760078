/* eslint-disable max-lines */
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { mergeStyles } from '@cian/utils';
import { VasComponent } from '@cian/vas-tooltip-component';

// eslint-disable-next-line import/no-restricted-paths
import { checkTouchDevice } from '../../../browser/utils/dom';
import { IOffer } from '../../api/offer';
import { ISimplifiedOfferCardPopupsState } from '../../store/simplified_card_popups';
import { trackOfferClick } from '../../tracking/offer';
import { getVasLabel } from '../../utils/getVasLabel';
import { isArrayWithItems } from '../../utils/is';
import { canUseHiddenBase } from '../../utils/user';
import { HiddenBaseLabel } from '../offer_card/hidden_base_label';
import { Map } from '../offer_card/map';
import { TimeLabel } from '../offer_card/time_label';
import { AddressContainer } from './components/address';
import { AgentContainer } from './components/agent/container';
import { ButtonsContainer } from './components/buttons/container';
import { Deadline } from './components/deadline';
import { Description } from './components/description';
import { JkNameContainer } from './components/jk_name/container';
import { LandArea } from './components/landArea';
import { LandStatus } from './components/landStatus';
import { LeasePeriod } from './components/lease_period';
import { PessimisationContainer } from './components/pessimisation/container';
import { Photos } from './components/photos';
import { Price } from './components/price';
import { Title } from './components/title';
import { IAuthenticatedUser, TUser } from '../../store';

import * as styles from './index.css';

export interface IOnFavoriteChange {
  (offer: IOffer, isFavorite: boolean): void;
}

export interface ISimplifiedOfferCardOwnProps {
  offer: IOffer;
  user: TUser;
  onFavoriteChange: IOnFavoriteChange;
  isSimilar?: boolean;

  onPhoneOpened(): void;
}

export interface ISimplifiedOfferCardStateProps {
  popupsState: ISimplifiedOfferCardPopupsState;
}

export interface ISimplifiedOfferCardState {
  isTouchDevice: boolean;
  galleryControlsHidden: boolean;
  isCallbackModalOpened: boolean;
  isMapActive: boolean;
  isMapLoaded: boolean;
  isOfferHidden: boolean;
  isCommentAreaOpened: boolean;
  isComplainOpen: boolean;
}

export type ISimplifiedOfferCardProps = ISimplifiedOfferCardOwnProps & ISimplifiedOfferCardStateProps;

export class SimplifiedOfferCard extends React.PureComponent<ISimplifiedOfferCardProps, ISimplifiedOfferCardState> {
  public constructor(props: ISimplifiedOfferCardProps) {
    super(props);

    this.state = {
      galleryControlsHidden: true,
      isCallbackModalOpened: false,
      isCommentAreaOpened: false,
      isComplainOpen: false,
      isMapActive: false,
      isMapLoaded: false,
      isOfferHidden: false,
      isTouchDevice: false,
    };
  }

  public static childContextTypes = {
    isSimilar: PropTypes.bool,
  };

  public getChildContext() {
    return {
      isSimilar: this.props.isSimilar,
    };
  }

  public componentDidMount() {
    this.setState({
      isTouchDevice: checkTouchDevice(),
    });
  }

  public render() {
    if (!this.props.offer) {
      return null;
    }

    const offer = this.props.offer;

    return (
      <div
        {...mergeStyles([
          styles['offer-container'],
          this.state.isOfferHidden && styles['offer-hidden'],
          this.props.offer.isTop3 && styles['top3'],
          this.props.offer.isColorized && styles['colorized'],
        ])}
      >
        <div
          {...mergeStyles([
            styles['container'],
            this.state.isTouchDevice && styles['touch-device'],
            this.isPopupsOpened() && styles['offer-unclickable'],
          ])}
          onClick={this.handleOfferCardClick}
          onMouseEnter={() => this.setState({ galleryControlsHidden: false })}
          onMouseLeave={() => this.setState({ galleryControlsHidden: true })}
        >
          <div className={styles['main-container']}>
            <div className={styles['media']}>
              <Photos offer={this.props.offer} galleryControlsHidden={this.state.galleryControlsHidden} />
            </div>
            <div className={styles['main']}>
              {this.renderInfo()}
              <div className={styles['panels-container']}>
                {this.renderLeftPanel()}
                {this.renderRightPanel()}
              </div>
            </div>
          </div>
        </div>
        {offer.geo && offer.geo.coordinates && (
          <div {...mergeStyles([styles['map-container'], this.state.isMapActive && styles['map-active']])}>
            <div className={styles['map']}>{this.state.isMapLoaded && <Map coordinates={offer.geo.coordinates} />}</div>
          </div>
        )}
      </div>
    );
  }

  private handleButtonMapClick = () => {
    this.setState({
      isMapActive: !this.state.isMapActive,
      isMapLoaded: true,
    });
  };

  private getAgentPhone = (): string | undefined => {
    const user = this.props.offer && this.props.offer.user;

    // Is callback user and has phones for callback
    if (user && user.isCallbackUser && isArrayWithItems(user.phoneNumbers)) {
      const firstPhoneNumber = user.phoneNumbers[0];

      return `${firstPhoneNumber.countryCode}${firstPhoneNumber.number}`;
    }

    return undefined;
  };

  private toggleCallbackModal = () => {
    this.setState({
      isCallbackModalOpened: !this.state.isCallbackModalOpened,
    });
  };

  private renderInfo() {
    return (
      <div className={styles['info-container']}>
        <div className={styles['info']}>
          <div className={`${styles['info-section']} ${styles['main-info']}`}>
            <div>
              <a href={this.props.offer.fullUrl} className={styles['header']} target="_blank" rel="noreferrer">
                <Title offer={this.props.offer} />
              </a>
              <div onClick={e => e.stopPropagation()}>
                <JkNameContainer offer={this.props.offer} />
              </div>
              <Deadline offer={this.props.offer} />
              <LeasePeriod offer={this.props.offer} />
              <LandStatus offer={this.props.offer} />
              <LandArea offer={this.props.offer} />
              {canUseHiddenBase(this.props.user as IAuthenticatedUser) && Boolean(this.props.offer.isInHiddenBase) && (
                <HiddenBaseLabel containerStyle={styles['base-label']} />
              )}
            </div>
            <div>
              <Price offer={this.props.offer} user={this.props.user} />
            </div>
          </div>
          <div className={styles['info-section']}>
            <AddressContainer offer={this.props.offer} />
          </div>
          <Description {...mergeStyles([styles['info-section'], this.props.offer.isTop3])} offer={this.props.offer} />
          <PessimisationContainer
            offer={this.props.offer}
            onComplainStateChanged={isComplainOpen => this.setState({ isComplainOpen })}
          />
        </div>
        <div className={styles['agent']}>
          <AgentContainer offer={this.props.offer} />
        </div>
      </div>
    );
  }

  private renderLeftPanel() {
    return (
      <ButtonsContainer
        isMapActive={this.state.isMapActive}
        onHideFromPrintClicked={this.handleHideFromPrintClick}
        onMapButtonClick={this.handleButtonMapClick}
        isCallbackVisible={!!this.getAgentPhone()}
        onCallbackClick={this.toggleCallbackModal}
        offer={this.props.offer}
        containerClassName={styles['left-panel']}
        onCommentClick={this.handleCommentClick}
        buttonClassName={styles['button']}
        dynamicButtonClassName={styles['dynamic-button']}
        onFavoriteChange={this.props.onFavoriteChange}
        isComplainOpen={this.state.isComplainOpen}
        onComplainClose={() => this.setState({ isComplainOpen: false })}
        isSimplified
        onPhoneOpened={this.props.onPhoneOpened}
      />
    );
  }

  private renderRightPanel() {
    const {
      offer: { geo },
      offer,
    } = this.props;
    const locationId = (geo && geo.address.length > 0 && geo.address[0].id) || undefined;

    const vasList = [];

    if (offer.isAuction) {
      vasList.push('auction');
    }
    if (offer.isColorized) {
      vasList.push('colorized');
    }
    if (offer.isTop3) {
      vasList.push('top3');
    }
    if (offer.isPremium) {
      vasList.push('premium');
    }
    if (offer.isPaid && (offer.dealType === 'sale' || locationId === undefined || locationId > 2)) {
      vasList.push('payed');
    }

    const vasLabel = getVasLabel(vasList[0]);

    return (
      <div onClick={e => e.stopPropagation()} className={styles['right-panel']}>
        <div className={styles['time']}>
          <TimeLabel added={offer.added} humanizedTime={offer.humanizedTimedelta} />
        </div>
        <div className={styles['vas-list']}>
          <VasComponent label={vasLabel} vasList={vasList} uniqueKey={offer.id} />
        </div>
      </div>
    );
  }

  private handleCommentClick = () => {
    if (this.state.isCommentAreaOpened) {
      return;
    }

    this.setState({
      isCommentAreaOpened: true,
    });
  };

  private handleHideFromPrintClick = () => {
    this.setState({
      isOfferHidden: true,
    });
  };

  private isPopupsOpened() {
    const popupsStateKeys = Object.keys(this.props.popupsState).map(Number);

    return popupsStateKeys.some(offerId => Boolean(this.props.popupsState[offerId].length));
  }

  private handleOfferCardClick = () => {
    if (this.isPopupsOpened()) {
      return;
    }

    trackOfferClick(this.props.offer);
    window.open(this.props.offer.fullUrl, '_blank');
  };
}
