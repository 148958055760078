import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useState } from 'react';
import { getConfig } from '@cian/config/browser';

import { PersonalLeadForm, IPersonalLeadFormProps } from '../../components/PersonalLeadForm';
import {
  IPersonalLeadFormData,
  changeComment,
  changeName,
  changePhone,
  setFieldsErrors,
  setleadType,
  submitPersonalLeadForm,
  setObjectType,
  resetForm,
} from '../../store/personal_leads_form';
import { selectComment } from '../../selectors/personalLeadForm/selectComment';
import { selectFormStatus } from '../../selectors/personalLeadForm/selectFormStatus';
import { selectLeadType } from '../../selectors/personalLeadForm/selectLeadType';
import { selectName } from '../../selectors/personalLeadForm/selectName';
import { selectPhone } from '../../selectors/personalLeadForm/selectPhone';
import { selectObjectType } from '../../selectors/personalLeadForm/selectObjectType';
import { selectLocation } from '../../selectors/personalLeadForm/selectLocation';
import { geoMapper } from './utils/_getGeoObject';
import { selectErrorMessage } from '../../selectors/personalLeadForm/selectErrorMessage';
import { IAnswers, trackingEvent, trackSelectRegion } from './tracking';
import { selectRealtorRealtyUserId } from '../../selectors/realtor/selectRealtorRealtyUserId';
import { selectPageData } from '../../selectors/pageData/selectPageData';
import { selectAnonymousUserId } from '../../selectors/user/selectAnonymousUserId';
import { EDealTypeEx, EObjectType } from '../../repositories/demand/v2/create-personal-message';
import { useAppDispatch } from '../../hooks/useAppDispatch';

const PHONE_LENGTH_WITHOUT_SEVEN = 10;

interface IPersonalLeadFormContainerProps {
  handleCloseModal?: () => void;
}

export const PersonalLeadFormContainer = ({ handleCloseModal }: IPersonalLeadFormContainerProps) => {
  const config = getConfig();
  const privacyPolicyUrl = config.getStrict<string>('realtor-reviews-frontend.privacyPolicyUrl');

  const { deviceType } = useSelector(selectPageData);
  const realtorId = useSelector(selectRealtorRealtyUserId);
  const anonymousUserId = useSelector(selectAnonymousUserId);
  const leadType = useSelector(selectLeadType, shallowEqual);
  const objectType = useSelector(selectObjectType, shallowEqual);
  const name = useSelector(selectName, shallowEqual);
  const phone = useSelector(selectPhone, shallowEqual);
  const comment = useSelector(selectComment, shallowEqual);
  const status = useSelector(selectFormStatus);
  const location = useSelector(selectLocation);
  const errorMessage = useSelector(selectErrorMessage);
  const [openSuccessMobileModal, setOpenSuccessMobileModal] = useState(true);
  const dispatch = useAppDispatch();

  const answersForms = React.useMemo(
    () => ({
      leadType: leadType.value as EDealTypeEx,
      objectType: objectType.value,
      location: location.value,
      name: name.value,
      phone: phone.value,
      comment: comment.value,
    }),
    [comment, leadType, location, name, objectType, phone],
  );

  // общий обработчик событий аналитики
  const handleTrackEvent = React.useCallback(
    (action: string, label: string, answers: IAnswers = answersForms) => {
      trackingEvent({ realtorId, answers, deviceType, action, label, anonymousUserId });
    },
    [anonymousUserId],
  );

  const handleSetType = React.useCallback(
    (_: never, value: EDealTypeEx) => {
      const answers = { ...answersForms, leadType: value };

      dispatch(setleadType(value));
      handleTrackEvent('Click_dealTypeEx_soprx', answers.leadType as EDealTypeEx, answers);
    },
    [dispatch, handleTrackEvent, answersForms],
  );
  const handleSetObjectType = React.useCallback(
    (value: EObjectType) => {
      const answers = { ...answersForms, objectType: value };
      dispatch(setObjectType(value));
      handleTrackEvent('Click_objectTypeEx_sopr', answers.objectType, answers);
    },
    [dispatch, handleTrackEvent, answersForms],
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSetName = React.useCallback((_: any, value: string) => dispatch(changeName(value)), [dispatch]);

  const handleSetPhone = React.useCallback(
    (value: string) => {
      dispatch(changePhone(value));
    },
    [dispatch],
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSetComment = React.useCallback((_: any) => dispatch(changeComment(_.target.value)), [dispatch]);

  const handleTrackSelectRegion = React.useCallback(
    (region: number) => {
      const answers = { ...answersForms };
      trackSelectRegion({ realtorId, deviceType, answers, action: 'region', anonymousUserId })(region);
    },
    [answersForms, deviceType, realtorId, anonymousUserId],
  );

  const handleTrackOpenSuccessModal = React.useCallback(() => {
    handleTrackEvent('Sent', 'Success', answersForms);
  }, [answersForms, handleTrackEvent]);

  const handleBlurName = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (elem: any) => {
      const name = elem.target.value;
      const answers = { ...answersForms, name };
      handleTrackEvent('Click_name_sopr', name, answers);
    },
    [handleTrackEvent, answersForms],
  );

  const handleBlurPhone = React.useCallback(() => {
    const answers = { ...answersForms, phone: 'phone' };
    handleTrackEvent('Click_phone_sopr', 'phone', answers);
  }, [handleTrackEvent, answersForms]);

  const handleBlurComment = React.useCallback(() => {
    const answers = { ...answersForms, comment: 'comment' };
    handleTrackEvent('Click_comment_sopr', 'comment', answers);
  }, [handleTrackEvent, answersForms]);

  const handleCloseMobileModal = React.useCallback(() => {
    if (handleCloseModal) {
      handleCloseModal();
    }

    dispatch(resetForm());
    setOpenSuccessMobileModal(false);
  }, [dispatch, handleCloseModal]);

  const submitForm = React.useCallback(async () => {
    // формируем новый объект с geo вместо location
    const { location, phone, ...restAnswers } = answersForms;
    const formData = { ...restAnswers, phone: `+7${phone}`, geo: geoMapper(location) };

    if (!leadType || !formData.geo || !objectType) {
      return;
    }

    try {
      dispatch(submitPersonalLeadForm(formData));
    } catch (e) {
      handleTrackEvent('Fail_sopr', e.details.code || 'something', answersForms);
    }
  }, [dispatch, handleTrackEvent, answersForms]);

  const handleSubmit = React.useCallback(() => {
    handleTrackEvent('Click', 'SendRequests', answersForms);
    const errors: (keyof IPersonalLeadFormData)[] = [];
    const { leadType, objectType, name, location, phone } = answersForms;

    if (!leadType) {
      errors.push('leadType');
    }

    if (!objectType) {
      errors.push('objectType');
    }

    if (!name.trim()) {
      errors.push('name');
    }

    if (!location) {
      errors.push('location');
    }

    // смотрим длину телефона без лишних символов
    if (!phone || phone.replace(/([ ,+,-])/g, '').length !== PHONE_LENGTH_WITHOUT_SEVEN) {
      errors.push('phone');
    }

    if (errors.length > 0) {
      const label = errors.map(item => `no_${item}`).join(' ');
      handleTrackEvent('Fail_sopr', label, answersForms);
      dispatch(setFieldsErrors(errors));

      return;
    }

    return submitForm();
  }, [handleTrackEvent, answersForms, submitForm, dispatch]);

  const props: IPersonalLeadFormProps = {
    handleSetType,
    handleSetObjectType,
    handleSetName,
    handleSetPhone,
    handleSetComment,
    handleSubmit,
    handleTrackOpenSuccessModal,
    handleTrackSelectRegion,
    handleBlurName,
    handleBlurPhone,
    handleBlurComment,
    handleCloseMobileModal,
    leadType,
    objectType,
    name,
    phone,
    comment,
    status,
    errorMessage,
    openSuccessMobileModal,
    privacyPolicyUrl,
  };

  return <PersonalLeadForm {...props} />;
};
