/* eslint-disable import/no-restricted-paths */
import * as React from 'react';
import { useSelector } from 'react-redux';

import { EReviewFormState, open } from '../../store/review_form';
import { NoReviews } from '../../components/no_reviews/NoReviews';
import { requestAuthentication } from '../../../browser/utils/authentication';
import { selectRealtorName } from '../../selectors/realtor/selectRealtorName';
import { selectReviewFormState } from '../../selectors/reviewForm/selectReviewFormState';
import { selectUserAuthenticated } from '../../selectors/user/selectUserAuthenticated';
import { selectRealtyUserId } from '../../selectors/user/selectRealtyUserId';
import { trackReviewClick } from '../../components/review_form/tracking';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { selectRealtorRealtyUserId } from '../../selectors';

interface INoReviewsContainerProps {
  isMobile?: boolean;
}

export const NoReviewsContainer = ({ isMobile }: INoReviewsContainerProps) => {
  const dispatch = useAppDispatch();

  const name = useSelector(selectRealtorName);
  const realtorRealtyUserId = useSelector(selectRealtorRealtyUserId);
  const realtyUserId = useSelector(selectRealtyUserId);
  const reviewFormState = useSelector(selectReviewFormState);
  const isUserAuthenticated = useSelector(selectUserAuthenticated);

  const isOwnPage = realtorRealtyUserId === realtyUserId;
  const showAddReviewButton = reviewFormState === EReviewFormState.Initial && !isOwnPage;

  const handleOpen = React.useCallback(() => {
    trackReviewClick();

    if (!isUserAuthenticated) {
      requestAuthentication('new');
    } else {
      dispatch(open());
    }
  }, [dispatch, isUserAuthenticated]);

  const props = {
    name,
    showAddReviewButton,
    isOwnPage,
    handleOpen,
    isMobile,
  };

  return <NoReviews {...props} />;
};
