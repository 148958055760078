import * as React from 'react';

import { EDealTypeEx, EObjectType } from '../../repositories/demand/v2/create-personal-message';
import { IFormStatus, IPersonalLeadFormData, IPersonalLeadFormState } from '../../store/personal_leads_form';
import { SuccessBlock } from './components/SuccessBlock/';
import { Form } from './components/Form';

export interface IPersonalLeadFormProps {
  handleSetType: (_: React.ChangeEvent<HTMLInputElement>, value: EDealTypeEx) => void;
  handleSetObjectType: (value: EObjectType) => void;
  handleSetName(_: React.ChangeEvent<HTMLInputElement>, value: string): void;
  handleSetPhone(value: string): void;
  handleSetComment(_: React.ChangeEvent<HTMLTextAreaElement>): void;
  handleSubmit(): void;
  handleTrackOpenSuccessModal: () => void;
  handleTrackSelectRegion: (region: number) => void;
  handleBlurName: React.FocusEventHandler<HTMLInputElement>;
  handleBlurPhone: React.FocusEventHandler<HTMLInputElement>;
  handleBlurComment: () => void;
  handleCloseMobileModal?: () => void;
  leadType: IPersonalLeadFormData['leadType'];
  objectType: IPersonalLeadFormData['objectType'];
  name: IPersonalLeadFormData['name'];
  phone: IPersonalLeadFormData['phone'];
  comment: IPersonalLeadFormData['comment'];
  status: IPersonalLeadFormState['status'];
  errorMessage: IPersonalLeadFormState['errorMessage'];
  openSuccessMobileModal: boolean;
  privacyPolicyUrl: string;
}

export const PersonalLeadForm = (props: IPersonalLeadFormProps) => {
  const { status, handleTrackOpenSuccessModal } = props;
  const { openSuccessMobileModal, handleCloseMobileModal, ...formProps } = props;
  if (status === IFormStatus.SUCCESS) {
    handleTrackOpenSuccessModal();

    return (
      <SuccessBlock handleCloseMobileModal={handleCloseMobileModal} openSuccessMobileModal={openSuccessMobileModal} />
    );
  }

  return <Form {...formProps} />;
};
