import { ca } from '@cian/analytics';

export const trackMoreInfoClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Specialist',
    action: 'Click',
    label: 'more',
  });
};

export const trackTabClick = (tab: string) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Specialist',
    action: 'Click',
    label: tab,
  });
};
