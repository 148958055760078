/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TCreateComplaintModel,
  ICreateComplaintRequest,
  IMappers,
  ICreateComplaintResponse,
  ICreateComplaintResponseError,
  TCreateComplaintResponse,
} from './types';

const defaultConfig: TCreateComplaintModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'moderation-complaints',
  pathApi: '/v2/create-complaint/',
  hostType: 'api',
} as TCreateComplaintModel;

function createCreateComplaintModel(parameters: ICreateComplaintRequest): TCreateComplaintModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ICreateComplaintOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: ICreateComplaintRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchCreateComplaint<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: ICreateComplaintOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TCreateComplaintResponse
> {
  const { response, statusCode } = await httpApi.fetch(createCreateComplaintModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ICreateComplaintResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as ICreateComplaintResponseError);
    }
  }

  return { response, statusCode } as TCreateComplaintResponse;
}

export { defaultConfig, createCreateComplaintModel, fetchCreateComplaint };
