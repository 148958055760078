import * as React from 'react';
import { Ref } from 'react';
import { IStyleArgument } from '@cian/utils';
import { useSelector } from 'react-redux';

import { IReview, IReviewAnswer } from '../../types/review';
import { Review, Text } from '../../components/review';
import { DeviceType } from '../../types/device';
import { timestampToDate } from '../../utils/time';
import { Avatar } from '../../components/avatar';
import { selectDeviceType } from '../../selectors/pageData/selectDeviceType';

export { EReviewRate } from '../../components/review';

type IAnswerProps = {
  type: 'answer';
  review: IReviewAnswer;
};

type IReviewProps = {
  type: 'review';
  review: IReview;
};

type IUnionProps = IAnswerProps | IReviewProps;

interface ICommonProps {
  innerRef?: Ref<HTMLElement>;
  containerStyle?: IStyleArgument;
  onLikeClick?: () => void;
  onDislikeClick?: () => void;
  onReplyClick?: () => void;
  onEditClick?: () => void;
}

type IProps = ICommonProps & IUnionProps;

export const ReviewContainer: React.FunctionComponent<IProps> = props => {
  const deviceType = useSelector(selectDeviceType);

  const { type, review, innerRef, containerStyle, onReplyClick, onEditClick } = props;
  const name = review.user.name;
  const avatarSrc = review.user.avatarUrl || undefined;
  const noAvatar = type !== 'review' && deviceType === DeviceType.Phone;
  const date = timestampToDate(review.created);
  const text = review.text;
  const refused = review.status === 'declined';
  const rating = props.type === 'review' ? props.review.rate : undefined;

  const avatar = noAvatar ? null : <Avatar src={avatarSrc} />;
  const textElement = <Text {...{ deviceType, text, rating, refused }} />;
  const reviewId = 'reviewId' in review ? review.reviewId : review.answerId;

  return (
    <Review
      reviewId={reviewId}
      avatar={avatar}
      pollButtons={null}
      textElement={textElement}
      containerStyle={containerStyle}
      name={name}
      date={date}
      rating={rating}
      innerRef={innerRef}
      onReplyClick={onReplyClick}
      onEditClick={onEditClick}
    />
  );
};
