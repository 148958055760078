import { connect } from 'react-redux';

import { IAppState } from '../../../../store';
import { IPessimisationOwnProps, IPessimisationStoreProps, Pessimisation } from './index';

function mapStateToProps(state: IAppState): IPessimisationStoreProps {
  return {
    userAgent: state.userAgent,
  };
}

export const PessimisationContainer = connect<IPessimisationStoreProps, {}, IPessimisationOwnProps>(mapStateToProps)(
  Pessimisation,
);
