import { useCallback, useState } from 'react';
import { debounce } from 'throttle-debounce';

import { ILocation } from '../../../types/location';
import { getLocations } from '../../../store/getLocations';
import { useAppDispatch } from '../../../hooks/useAppDispatch';

export const MIN_SEARCH_LENGTH = 3;

export interface IUseLocationSuggest {
  togglePopup(value: boolean): void;
}
export const useLocationSuggest = ({ togglePopup }: IUseLocationSuggest) => {
  const dispatch = useAppDispatch();

  const [suggest, setSuggest] = useState<ILocation[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const getLocationSuggest = useCallback(
    async (value: string) => {
      if (value.length < MIN_SEARCH_LENGTH) {
        return;
      }

      setIsFetching(true);

      const locations = await dispatch(getLocations({ request: value }));

      togglePopup(true);
      setSuggest(locations);
      setIsFetching(false);
    },
    [dispatch, togglePopup],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getLocationSuggestDebounced = useCallback(debounce(200, getLocationSuggest), [getLocationSuggest]);

  return {
    suggest,
    getLocationSuggest: getLocationSuggestDebounced,
    isFetching,
  };
};
