import * as React from 'react';
import { mergeStyles } from '@cian/utils';

import { LikeIcon, DislikeIcon } from './icons';

import * as styles from './index.css';

export enum EReviewRate {
  Liked,
  Disliked,
}

interface IPollButtonsProps {
  rate?: EReviewRate;
  likes?: number;
  dislikes?: number;
  onLikeClick?: () => void;
  onDislikeClick?: () => void;
}

export const PollButtons: React.FunctionComponent<IPollButtonsProps> = ({
  rate,
  likes,
  dislikes,
  onLikeClick,
  onDislikeClick,
}) => {
  return (
    <div className={styles['poll']}>
      <button
        type="button"
        {...mergeStyles(styles['vote_up'], rate === EReviewRate.Liked && styles['active'])}
        disabled={!onLikeClick || rate === EReviewRate.Liked}
        onClick={onLikeClick}
      >
        <LikeIcon />
        <span className={styles['votes_count']}>{likes}</span>
      </button>
      <button
        type="button"
        {...mergeStyles(styles['vote_down'], rate === EReviewRate.Disliked && styles['active'])}
        disabled={!onDislikeClick || rate === EReviewRate.Disliked}
        onClick={onDislikeClick}
      >
        <DislikeIcon />
        <span className={styles['votes_count']}>{dislikes}</span>
      </button>
    </div>
  );
};
