import * as React from 'react';
import { IStyleConfig, mergeStyles } from '@cian/utils';

import * as styles from '../index.css';

type UserInfoContentProps = {
  isPopupVisible: boolean;
  content: React.ReactNode;
  userInfoStyle?: IStyleConfig;
  userInfoStyleAction?: IStyleConfig;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
};

export const UserInfoContent = React.forwardRef(
  (
    { isPopupVisible, userInfoStyle, userInfoStyleAction, onClick, content }: UserInfoContentProps,
    ref: React.RefObject<HTMLDivElement>,
  ) => {
    return (
      <div
        {...mergeStyles([
          styles['user-info'],
          isPopupVisible && styles['user-info--active'],
          userInfoStyle,
          isPopupVisible && userInfoStyleAction,
        ])}
        onClick={onClick}
        ref={ref}
      >
        {content}
      </div>
    );
  },
);

UserInfoContent.displayName = 'UserInfoContent';
