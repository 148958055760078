import * as React from 'react';
import { mergeStyles } from '@cian/utils';

import { IAgentCard } from '../../../types/agentCard';
import { NoPhoto } from '../../common/nophoto';

import * as styles from './AboutRealtorDesktop.css';

interface IAvatarProps {
  isRound: boolean;
  avatarUrl: IAgentCard['avatarUrl'];
}

export const Avatar = ({ isRound, avatarUrl }: IAvatarProps) => {
  return (
    <div className={styles['avatar-container']}>
      <div {...mergeStyles(styles['avatar'], isRound && styles['avatar_roundness'])}>
        {avatarUrl ? (
          <div className={styles['avatar_image']} style={{ backgroundImage: `url(${avatarUrl})` }} />
        ) : (
          <NoPhoto className={styles['no_photo']} />
        )}
      </div>
    </div>
  );
};
