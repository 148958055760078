import * as React from 'react';

import { IOffer } from '../../../../api/offer';
import { offerHelper } from '../../../../offer/presenters';

import * as styles from './index.css';

export interface ILandAreaProps {
  offer: IOffer;
}

export class LandArea extends React.Component<ILandAreaProps, {}> {
  public render() {
    const offer = offerHelper(this.props.offer);
    const isSuburban = offer.isSuburban();

    if (!isSuburban || (this.props.offer.category && this.props.offer.category.indexOf('land') >= 0)) {
      return null;
    }

    const landArea = isSuburban && offer.suburban.getLandArea();

    if (landArea) {
      return <div className={styles['land-area']}>{`${landArea.charAt(0).toUpperCase()}${landArea.substring(1)}`}</div>;
    }

    return null;
  }
}
