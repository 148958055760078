import * as React from 'react';
import { mergeStyles, numberToPrettyString } from '@cian/utils';

import { IOffer } from '../../../../api/offer';
import { offerHelper } from '../../../../offer/presenters';
import { TUser } from '../../../../store';

import * as styles from './index.css';

export interface IPriceProps {
  offer: IOffer;
  user: TUser;
  priceClassName?: string;
}

function getCurrency(currency: string) {
  switch (currency) {
    case 'eur':
      return '\u20ac';
    case 'usd':
      return '\u0024';
    case 'percent':
      return '%';
    default:
      return '\u20bd';
  }
}

export class Price extends React.Component<IPriceProps, {}> {
  public render() {
    const offer = offerHelper(this.props.offer);

    if (this.props.offer.offerType === 'commercial' || offer.commercial.isCommercialLand()) {
      return null;
    }

    const terms = this.props.offer.dealType === 'rent' ? this.getRentPriceTerms() : this.getSalePriceTerms();

    return (
      <div>
        {terms.map((term: React.ReactNode, index) => {
          if (term) {
            return (
              <div
                key={index}
                {...mergeStyles([
                  index === 0 ? styles['header'] : styles['term'],
                  index === 0 && this.props.priceClassName,
                ])}
              >
                {term}
              </div>
            );
          }

          return null;
        })}
      </div>
    );
  }

  private renderAgentBonus = () => {
    const agentBonus = this.props.offer.bargainTerms.agentBonus;

    if (this.props.user.isAuthenticated && Boolean(this.props.user.isAgent) && agentBonus) {
      const bonusCurrency =
        agentBonus.paymentType === 'fixed'
          ? getCurrency(agentBonus.currency || '')
          : getCurrency(agentBonus.paymentType);

      return `Бонус агенту ${numberToPrettyString(agentBonus.value)} ${bonusCurrency}`;
    }

    return null;
  };

  private getSalePriceTerms = (): React.ReactNode[] => {
    const offer = offerHelper(this.props.offer);
    const isSuburban = offer.isSuburban();

    const priceData = [offer.presenters.simplifiedCard.getSalePrice()];

    if (!isSuburban) {
      priceData.push(offer.presenters.simplifiedCard.getSalePricePerMeter() as string);
    }

    priceData.push(this.renderAgentBonus());

    return priceData;
  };

  private getRentPriceTerms = (): React.ReactNode[] => {
    const { presenters } = offerHelper(this.props.offer);

    return [
      presenters.simplifiedCard.getRentPrice(),
      presenters.getMoreRentPrice(),
      this.props.user.isAuthenticated && Boolean(this.props.user.isAgent)
        ? presenters.getFee()
        : presenters.getClientFee(),
      presenters.getDeposit(),
      this.renderAgentBonus(),
    ];
  };
}
