import * as React from 'react';

import { ReportPopupPrevButton } from './ReportPopupPrevButton';
import {
  EBlockType,
  IComplaintItem,
} from '../../../../../repositories/moderation-complaints/entities/Models/ComplaintItem';
import { ReportPopupTextareaItem } from './ReportPopupTextareaItem';
import { ReportPopupNextItem } from './ReportPopupNextItem';
import { ReportPopupLinkItem } from './ReportPopupLinkItem';

import * as styles from './index.css';

export interface IReportPopupFormProps {
  selectedComplaint: IComplaintItem | null;
  selectedComplaints: IComplaintItem[];
  selectionTree: number[];
  selected: number | undefined;
  message: string | undefined;
  hasMessageError: boolean;
  onPrev(): void;
  onNext(index: number): void;
  onFinish(complaint: IComplaintItem): void;
  onDropdown(index: number): void;
  onSubmit(complaint: IComplaintItem): void;
  onTextAreaValueChange(message: string): void;
}

export const ReportPopupForm = ({
  selectedComplaint,
  selectedComplaints,
  selectionTree,
  selected,
  message,
  hasMessageError,
  onNext,
  onPrev,
  onFinish,
  onDropdown,
  onTextAreaValueChange,
  onSubmit,
}: IReportPopupFormProps) => {
  const onNextLinkClick = (complaint: IComplaintItem, index: number) => {
    if (complaint.blockType === EBlockType.Transitional) {
      onNext(index);
    } else {
      onFinish(complaint);
    }
  };

  const onDropdownLinkClick = (index: number) => {
    onDropdown(index);
  };

  return (
    <div className={styles['form']}>
      {selectionTree.length > 0 && <ReportPopupPrevButton onClick={onPrev} />}
      <h3 className={styles['header']}>
        Шаг {selectionTree.length + 1}. {!selectedComplaint ? 'На что жалуетесь?' : selectedComplaint.title}
      </h3>
      <ul className={styles['links']}>
        {selectedComplaints.map((complaint, index) => {
          if (complaint.blockType === EBlockType.Link) {
            return <ReportPopupLinkItem key={index} url={complaint.url || ''} name={complaint.name || ''} />;
          } else if (complaint.blockType === EBlockType.Textarea) {
            return (
              <ReportPopupTextareaItem
                key={index}
                name={complaint.name || ''}
                hint={complaint.hint || ''}
                isSelected={selected === index}
                message={message || ''}
                hasMessageError={hasMessageError}
                onDropdownLinkClick={() => onDropdownLinkClick(index)}
                onTextAreaValueChange={onTextAreaValueChange}
                onSubmit={() => onSubmit(complaint)}
              />
            );
          }

          return (
            <ReportPopupNextItem
              key={index}
              name={complaint.name || ''}
              onNextLinkClick={() => onNextLinkClick(complaint, index)}
            />
          );
        })}
      </ul>
    </div>
  );
};
