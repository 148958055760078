import * as React from 'react';
import _ from 'lodash';

import { SuperAgentLevelViewContainer } from '../SuperAgentLevelViewContainer';

interface IAgentModerationInfoSchema {
  qualityLevelInfo?: {
    levelOrder?: number;
    levelName?: string;
    showSuperAgent: boolean;
  };
}

export const getSuperAgentBadge = (moderationInfo: IAgentModerationInfoSchema | null) => {
  const showSuperAgent = _.get(moderationInfo, 'qualityLevelInfo.showSuperAgent', null);
  const levelName = _.get(moderationInfo, 'qualityLevelInfo.levelName', '');

  if (!showSuperAgent) {
    return null;
  }

  return <SuperAgentLevelViewContainer levelName={levelName} />;
};
