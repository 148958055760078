/* Тип здания */
export type TBuildingType =
  | 1
  | 2
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | 30
  | 31
  | 32
  | 33
  | 34
  | 35
  | 36
  | 37
  | 38
  | 39
  | 40
  | 41
  | 42
  | 43
  | 44
  | 45
  | 46
  | 100;
export const BuildingType = {
  AdministrativeBuilding: 14 as const,
  BusinessCenter: 1 as const,
  BusinessCenter2: 22 as const,
  BusinessHouse: 45 as const,
  BusinessPark: 7 as const,
  BusinessQuarter: 25 as const,
  BusinessQuarter2: 44 as const,
  FreeAppointmentObject: 36 as const,
  FreeBuilding: 40 as const,
  IndustrialComplex: 33 as const,
  IndustrialPark: 15 as const,
  IndustrialSite: 34 as const,
  IndustrialWarehouseComplex: 11 as const,
  LogisticsCenter: 12 as const,
  LogisticsComplex: 16 as const,
  Mansion: 23 as const,
  ManufactureBuilding: 38 as const,
  ManufacturingFacility: 35 as const,
  ModularBuilding: 39 as const,
  MultifunctionalComplex: 18 as const,
  OfficeBuilding: 6 as const,
  OfficeCenter: 42 as const,
  OfficeHotelComplex: 21 as const,
  OfficeIndustrialComplex: 8 as const,
  OfficeQuarter: 43 as const,
  OfficeResidentialComplex: 19 as const,
  OfficeWarehouse: 41 as const,
  OfficeWarehouseComplex: 9 as const,
  OldFund: 5 as const,
  Other: 100 as const,
  Outlet: 32 as const,
  PropertyComplex: 37 as const,
  ResidentialComplex: 28 as const,
  ResidentialHouse: 27 as const,
  ShoppingBusinessComplex: 17 as const,
  ShoppingCenter: 29 as const,
  ShoppingCommunityCenter: 31 as const,
  ShoppingEntertainmentCenter: 2 as const,
  SpecializedShoppingCenter: 30 as const,
  StandaloneBuilding: 26 as const,
  Technopark: 24 as const,
  TradingHouse: 46 as const,
  TradingOfficeComplex: 20 as const,
  Warehouse: 13 as const,
  WarehouseComplex: 10 as const,
};
export const BuildingTypeValidator: { [key: string]: TBuildingType } = BuildingType;
export const BuildingTypeName = new Map<TBuildingType, string>([
  [BuildingType.BusinessCenter, 'Бизнес-центр'],
  [BuildingType.ShoppingEntertainmentCenter, 'Торгово-развлекательный центр'],
  [BuildingType.OldFund, 'Старый фонд'],
  [BuildingType.OfficeBuilding, 'Офисное здание'],
  [BuildingType.BusinessPark, 'Бизнес-парк'],
  [BuildingType.OfficeIndustrialComplex, 'Офисно-производственный комплекс'],
  [BuildingType.OfficeWarehouseComplex, 'Офисно-складской комплекс'],
  [BuildingType.WarehouseComplex, 'Складской комплекс'],
  [BuildingType.IndustrialWarehouseComplex, 'Производственно-складской комплекс'],
  [BuildingType.LogisticsCenter, 'Логистический центр'],
  [BuildingType.Warehouse, 'Склад'],
  [BuildingType.AdministrativeBuilding, 'Административное здание'],
  [BuildingType.IndustrialPark, 'Индустриальный парк'],
  [BuildingType.LogisticsComplex, 'Логистический комплекс'],
  [BuildingType.ShoppingBusinessComplex, 'Торгово-деловой комплекс'],
  [BuildingType.MultifunctionalComplex, 'Многофункциональный комплекс'],
  [BuildingType.OfficeResidentialComplex, 'Офисно-жилой комплекс'],
  [BuildingType.TradingOfficeComplex, 'Торгово-офисный комплекс'],
  [BuildingType.OfficeHotelComplex, 'Офисно-гостиничный комплекс'],
  [BuildingType.BusinessCenter2, 'Деловой центр'],
  [BuildingType.Mansion, 'Особняк'],
  [BuildingType.Technopark, 'Технопарк'],
  [BuildingType.BusinessQuarter, 'Бизнес-квартал'],
  [BuildingType.StandaloneBuilding, 'Отдельно стоящее здание'],
  [BuildingType.ResidentialHouse, 'Жилой дом'],
  [BuildingType.ResidentialComplex, 'Жилой комплекс'],
  [BuildingType.ShoppingCenter, 'Торговый центр'],
  [BuildingType.SpecializedShoppingCenter, 'Специализированный торговый центр'],
  [BuildingType.ShoppingCommunityCenter, 'Торгово-общественный центр'],
  [BuildingType.Outlet, 'Аутлет'],
  [BuildingType.IndustrialComplex, 'Производственный комплекс'],
  [BuildingType.IndustrialSite, 'Промплощадка'],
  [BuildingType.ManufacturingFacility, 'Производственный цех'],
  [BuildingType.FreeAppointmentObject, 'Объект свободного назначения'],
  [BuildingType.PropertyComplex, 'Имущественный комплекс'],
  [BuildingType.ManufactureBuilding, 'Производственное здание'],
  [BuildingType.ModularBuilding, 'Модульное здание'],
  [BuildingType.FreeBuilding, 'Свободное здание'],
  [BuildingType.OfficeWarehouse, 'Офисно-складское здание'],
  [BuildingType.OfficeCenter, 'Офисный центр'],
  [BuildingType.OfficeQuarter, 'Офисный квартал'],
  [BuildingType.BusinessQuarter2, 'Деловой квартал'],
  [BuildingType.BusinessHouse, 'Деловой дом'],
  [BuildingType.TradingHouse, 'Торговый дом'],
  [BuildingType.Other, 'Другое'],
]);
export const BuildingTypeBackend = new Map<string, TBuildingType>([
  ['administrativeBuilding', BuildingType.AdministrativeBuilding],
  ['businessCenter', BuildingType.BusinessCenter],
  ['businessCenter2', BuildingType.BusinessCenter2],
  ['businessHouse', BuildingType.BusinessHouse],
  ['businessPark', BuildingType.BusinessPark],
  ['businessQuarter', BuildingType.BusinessQuarter],
  ['businessQuarter2', BuildingType.BusinessQuarter2],
  ['free', BuildingType.FreeBuilding],
  ['freeAppointmentObject', BuildingType.FreeAppointmentObject],
  ['industrialComplex', BuildingType.IndustrialComplex],
  ['industrialPark', BuildingType.IndustrialPark],
  ['industrialSite', BuildingType.IndustrialSite],
  ['industrialWarehouseComplex', BuildingType.IndustrialWarehouseComplex],
  ['logisticsCenter', BuildingType.LogisticsCenter],
  ['logisticsComplex', BuildingType.LogisticsComplex],
  ['mansion', BuildingType.Mansion],
  ['manufactureBuilding', BuildingType.ManufactureBuilding],
  ['manufacturingFacility', BuildingType.ManufacturingFacility],
  ['modular', BuildingType.ModularBuilding],
  ['multifunctionalComplex', BuildingType.MultifunctionalComplex],
  ['officeAndHotelComplex', BuildingType.OfficeHotelComplex],
  ['officeAndResidentialComplex', BuildingType.OfficeResidentialComplex],
  ['officeAndWarehouse', BuildingType.OfficeWarehouse],
  ['officeAndWarehouseComplex', BuildingType.OfficeWarehouseComplex],
  ['officeBuilding', BuildingType.OfficeBuilding],
  ['officeCenter', BuildingType.OfficeCenter],
  ['officeIndustrialComplex', BuildingType.OfficeIndustrialComplex],
  ['officeQuarter', BuildingType.OfficeQuarter],
  ['old', BuildingType.OldFund],
  ['outlet', BuildingType.Outlet],
  ['propertyComplex', BuildingType.PropertyComplex],
  ['residentialComplex', BuildingType.ResidentialComplex],
  ['residentialHouse', BuildingType.ResidentialHouse],
  ['shoppingAndBusinessComplex', BuildingType.ShoppingBusinessComplex],
  ['shoppingAndCommunityCenter', BuildingType.ShoppingCommunityCenter],
  ['shoppingAndEntertainmentCenter', BuildingType.ShoppingEntertainmentCenter],
  ['shoppingCenter', BuildingType.ShoppingCenter],
  ['specializedShoppingCenter', BuildingType.SpecializedShoppingCenter],
  ['standaloneBuilding', BuildingType.StandaloneBuilding],
  ['technopark', BuildingType.Technopark],
  ['tradingHouse', BuildingType.TradingHouse],
  ['tradingOfficeComplex', BuildingType.TradingOfficeComplex],
  ['warehouse', BuildingType.Warehouse],
  ['warehouseComplex', BuildingType.WarehouseComplex],
]);

/* Класс здания */
export type TCommercialClass = 'aPlus' | 'a' | 'bPlus' | 'b' | 'bMinus' | 'cPlus' | 'c' | 'd';
export const CommercialClassName = new Map<TCommercialClass, string>([
  ['aPlus', 'A+'],
  ['a', 'A'],
  ['bPlus', 'B+'],
  ['b', 'B'],
  ['bMinus', 'B-'],
  ['cPlus', 'C+'],
  ['c', 'C'],
  ['d', 'D'],
]);

/* Тип недвижимости */
export type TPropertyType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
export const PropertyType: { [key: string]: TPropertyType } = {
  Building: 5 as const,
  FreeAppointmentObject: 6 as const,
  Garage: 8 as const,
  Land: 7 as const,
  Manufacture: 4 as const,
  Office: 1 as const,
  TradeArea: 3 as const,
  Warehouse: 2 as const,
};
export const PropertyTypeName = new Map<TPropertyType, string>([
  [PropertyType.Office, 'Офис'],
  [PropertyType.Warehouse, 'Склад'],
  [PropertyType.TradeArea, 'Торговая площадь'],
  [PropertyType.Manufacture, 'Производство'],
  [PropertyType.Building, 'Здание'],
  [PropertyType.FreeAppointmentObject, 'Помещение свободного назначения'],
  [PropertyType.Land, 'Земля'],
  [PropertyType.Garage, 'Гараж'],
]);
