import { getCutIndex } from './getCutIndex';

export const formatDescriptionText = (text: string, expanded: boolean) => {
  if (expanded) {
    return text;
  }

  const cutIndex = getCutIndex(text);

  return `${text.slice(0, cutIndex)}${cutIndex < text.length ? '…' : ''}`;
};
