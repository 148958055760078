import * as React from 'react';

import * as styles from './index.css';

interface IVacancyRealtorProps {
  vacancyLink: string;
  specialization: string;
  salary: string;
  regionDisplayName?: string;
  experience: string;
  employment: string;
  onClick(): void;
}

export const VacancyItem = ({
  vacancyLink,
  onClick,
  specialization,
  salary,
  regionDisplayName,
  experience,
  employment,
}: IVacancyRealtorProps) => {
  return (
    <a href={vacancyLink} className={styles['vacancy']} onClick={onClick}>
      <h2 className={styles['vacancy-title']}>{specialization}</h2>
      <div className={styles['vacancy-body']}>
        <div className={styles['vacancy-body_block']}>
          <div className={styles['vacancy-body_title']}>Зарплата</div>
          <div className={styles['vacancy-body_value']}>{salary}</div>
        </div>
        <div className={styles['vacancy-body_block']}>
          <div className={styles['vacancy-body_title']}>Город</div>
          <div className={styles['vacancy-body_value']}>{regionDisplayName}</div>
        </div>
        <div className={styles['vacancy-body_block']}>
          <div className={styles['vacancy-body_title']}>Опыт</div>
          <div className={styles['vacancy-body_value']}>{experience}</div>
        </div>
        <div className={styles['vacancy-body_block']}>
          <div className={styles['vacancy-body_title']}>Занятость</div>
          <div className={styles['vacancy-body_value']}>{employment}</div>
        </div>
      </div>
    </a>
  );
};
