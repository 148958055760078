import { IGeoExV4Schema } from '../../../repositories/demand/entities/geo/GeoExV4Schema';
import { IPersonalLeadFormData } from '../../../store/personal_leads_form';

export const _getGeoObject = (override?: Partial<IGeoExV4Schema>): IGeoExV4Schema => ({
  districtIds: [],
  houseIds: [],
  locationIds: [],
  highwayIds: [],
  streets: [],
  undergroundIds: [],
  ...override,
});

export const geoMapper = (location: IPersonalLeadFormData['location']['value']) => {
  if (!location) {
    return _getGeoObject();
  }

  const geo = _getGeoObject({
    locationIds: [location.id],
  });

  return geo;
};
