/* eslint-disable max-lines */
import { IAppState, IContext, IThunkActionCreator } from '.';
import { getReviews } from '../api/reviews';
import { EReviewsSortType, IGetReviewsRequest, IReview } from '../types/review';
import { IAnswerFormSubmitSucceed } from './answer_form';
import { IReviewFormSubmitSucceed } from './review_form';
import { TThunkDispatch } from '../types/redux';

export type TReviewsState = {
  entityRate?: number;
  reviews: IReview[];
  totalCount: number;
};

export interface IReviewsRequested {
  type: 'IReviewsRequested';
}

export interface IReviewsTotalCountIncrement {
  type: 'IReviewsTotalCountIncrement';
}

export interface IReviewsFetched {
  entityRate?: number;
  reviews: IReview[];
  totalCount: number;
  type: 'IReviewsFetched';
}

export function reviewsTotalCountIncrement(): IReviewsTotalCountIncrement {
  return {
    type: 'IReviewsTotalCountIncrement',
  };
}

export function fetchReviews(offset?: number): IThunkActionCreator {
  return (dispatch: TThunkDispatch, getState: () => IAppState, context: IContext) => {
    dispatch<IReviewsRequested>({
      type: 'IReviewsRequested',
    });
    const { reviews, realtor } = getState();

    const reviewList = reviews.reviews;
    const lastReview = reviewList[reviewList.length - 1];

    const options: IGetReviewsRequest = {
      realtyUserId: realtor.realtyUserId,
      fromLastReviewId: lastReview.reviewId,
      sortType: EReviewsSortType.New,
      offset,
    };

    return getReviews(context.httpApi, options, context.logger).then(reviewsData => {
      const stateReviews = (Array.isArray(reviewList) && reviewList) || [];
      const nextReviews = (Array.isArray(reviewsData.reviews) && reviewsData.reviews) || [];

      return dispatch<IReviewsFetched>({
        entityRate: reviewsData.entityRate,
        reviews: [...stateReviews, ...nextReviews],
        totalCount: reviewsData.totalCount,
        type: 'IReviewsFetched',
      });
    });
  };
}

export type TReviewsActions =
  | IReviewsRequested
  | IReviewsTotalCountIncrement
  | IReviewsFetched
  | IReviewFormSubmitSucceed
  | IAnswerFormSubmitSucceed;

export function reviewsReducer(state: TReviewsState, action: TReviewsActions): TReviewsState {
  switch (action.type) {
    case 'IReviewsTotalCountIncrement':
      return {
        ...state,
        totalCount: ++state.totalCount,
      };

    case 'IReviewsRequested':
      return {
        ...state,
      };

    case 'IReviewsFetched':
      return {
        ...state,
        entityRate: action.entityRate,
        reviews: action.reviews,
        totalCount: action.totalCount,
      };

    case 'IReviewFormSubmitSucceed': {
      if (!action.edit) {
        return {
          ...state,
          reviews: state.reviews.concat(action.review),
        };
      } else {
        const prevReview = state.reviews.find(review => review.reviewId === action.reviewId);
        if (!prevReview) {
          return state;
        }

        const nextReviews = state.reviews.concat();
        nextReviews[nextReviews.indexOf(prevReview)] = action.review;

        return {
          ...state,
          reviews: nextReviews,
        };
      }
    }

    case 'IAnswerFormSubmitSucceed': {
      const prevReview = state.reviews.find(review => review.reviewId === action.reviewId);
      if (!prevReview) {
        return state;
      }

      let nextReview: IReview;
      if (!action.edit) {
        nextReview = {
          ...prevReview,
          answers: prevReview.answers.concat(action.answer),
        };
      } else {
        const prevAnswer = prevReview.answers.find(answer => answer.answerId === action.answerId);
        if (!prevAnswer) {
          return state;
        }

        const nextAnswers = prevReview.answers.concat();
        nextAnswers[nextAnswers.indexOf(prevAnswer)] = action.answer;

        nextReview = {
          ...prevReview,
          answers: nextAnswers,
        };
      }

      const nextReviews = state.reviews.concat();
      nextReviews[nextReviews.indexOf(prevReview)] = nextReview;

      return {
        ...state,
        reviews: nextReviews,
      };
    }

    default:
      return state || {};
  }
}
