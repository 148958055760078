import * as React from 'react';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { Spinner } from '@cian/ui-kit';
import { NoSsr } from '@cian/react-utils';

import { AsideGeneralInfoContainer } from '../../components/aside_general_info/container';
import { useAsync } from '../../utils/useAsync';
import { getServiceStatusForSpecialist } from '../../api/get_service_status_for_specialist';
import { selectRealtorRealtyUserId } from '../../selectors/realtor/selectRealtorRealtyUserId';
import { PersonalLeadFormContainer } from '../PersonalLeadFormContainer';
import { useAppDispatch } from '../../hooks/useAppDispatch';

import * as styles from './AsideContainer.css';

export const AsideContainer = () => {
  const dispatch = useAppDispatch();
  const realtorRealtyUserId = useSelector(selectRealtorRealtyUserId);

  const fetchServiceStatusForSpecialist = useCallback(
    async () => dispatch(getServiceStatusForSpecialist({ userId: realtorRealtyUserId })),
    [dispatch, realtorRealtyUserId],
  );

  const [{ status, result }] = useAsync(fetchServiceStatusForSpecialist, true);

  switch (status) {
    case 'idle':
    case 'pending':
      return (
        <div className={styles.preloader}>
          <NoSsr>
            <Spinner size={32} />
          </NoSsr>
        </div>
      );

    case 'success':
      return result ? <PersonalLeadFormContainer /> : <AsideGeneralInfoContainer />;

    default:
      return <AsideGeneralInfoContainer />;
  }
};
