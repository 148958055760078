import * as React from 'react';

import { AboutRealtorMobileContainer } from '../../../../containers/aboutRealtor';
import { ActionsMobileContainer } from '../../../../containers/actions';
import { ContactsMobileContainer } from '../../../../containers/contacts';
import { DescriptionContainer } from '../../../../containers/description';
import { ReviewsMobileContainer } from '../../../../containers/reviews';
import { AllRealtorLinkMobile } from './allRealtorLinkMobile';
import { ReviewFormContainer } from '../../../review_form/container';
import { ChatModalContainer } from '../../../../containers/ChatModalContainer';
import { CalltrackingCallModalContainer } from '../../../../containers/CalltrackingCallModalContainer';

import * as styles from './index.css';

export const ViewMobile = () => {
  return (
    <div className={styles['container']}>
      <AboutRealtorMobileContainer headerBar={<AllRealtorLinkMobile />} className={styles['block']} />
      <DescriptionContainer className={styles['block']} />
      <ContactsMobileContainer className={styles['block']} />
      <ReviewsMobileContainer className={styles['block']} />
      <ReviewFormContainer className={styles['block']} />
      <ActionsMobileContainer className={`${styles['block']} ${styles['actions']}`} />
      <ChatModalContainer />
      <CalltrackingCallModalContainer />
    </div>
  );
};
