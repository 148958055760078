import * as React from 'react';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

// eslint-disable-next-line import/no-restricted-paths
import { ActionCallMobile, ActionMessageMobile, ActionsMobile } from '../../../components/actions';
import { selectRealtor, selectRealtorRealtyUserId } from '../../../selectors';
import { ModalPersonalLeadFormContainer } from '../../ModalPersonalLeadFormContainer';
import { EStatus, useAsync } from '../../../utils/useAsync';
import { getServiceStatusForSpecialist } from '../../../api/get_service_status_for_specialist';
import { ApplicationButton } from './ApplicationButton';
import { trackClickSendRequests, trackOpenLeadFormModalMobile } from './tracking';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useChats } from '../../../hooks/useChats';
import { openCalltrackingModal } from '../../../store/calltracking_modal';
import { useApplicationContext } from '../../../utils/applicationContext';

interface IActionsMobileContainerProps {
  className?: string;
}

export const ActionsMobileContainer: React.FunctionComponent<IActionsMobileContainerProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const { ctPhoneNumber } = useSelector(selectRealtor);
  const realtorRealtyUserId = useSelector(selectRealtorRealtyUserId);

  const { isEnabled, openChat } = useChats();

  const [isLeadFormModalOpen, setIsLeadFormModalOpen] = useState(false);

  const fetchServiceStatusForSpecialist = useCallback(
    async () => dispatch(getServiceStatusForSpecialist({ userId: realtorRealtyUserId })),
    [dispatch, realtorRealtyUserId],
  );

  const [{ status, result }] = useAsync(fetchServiceStatusForSpecialist, true);

  const { config } = useApplicationContext();

  const isInfoAboutCalltrackingEnabled = config.get<boolean>('realtorReviews.infoAboutCalltracking.Enabled');

  const handlePhoneClick = useCallback(() => {
    if (isInfoAboutCalltrackingEnabled) {
      dispatch(openCalltrackingModal());
    } else {
      window.open(`tel:${ctPhoneNumber}`, '_self');
    }
  }, [ctPhoneNumber, dispatch, isInfoAboutCalltrackingEnabled]);

  const openMessageModal = useCallback(() => {
    openChat();
  }, [openChat]);

  const openModalLeadForm = useCallback(() => {
    trackClickSendRequests(realtorRealtyUserId);
    setIsLeadFormModalOpen(true);
  }, [realtorRealtyUserId]);
  const closeModalLeadForm = useCallback(() => setIsLeadFormModalOpen(false), []);

  if (isLeadFormModalOpen) {
    trackOpenLeadFormModalMobile(realtorRealtyUserId);

    return <ModalPersonalLeadFormContainer onClose={closeModalLeadForm} />;
  }

  if ([EStatus.Pending, EStatus.Idle].includes(status)) {
    return null;
  }

  if (status === EStatus.Success && result) {
    return (
      <ApplicationButton
        hasPhone={Boolean(ctPhoneNumber)}
        onPhoneClick={handlePhoneClick}
        openModalLeadForm={openModalLeadForm}
        className={className}
      />
    );
  }

  return (
    <ActionsMobile className={className}>
      {Boolean(ctPhoneNumber) && <ActionCallMobile hasIcon onClick={handlePhoneClick} />}
      {isEnabled && <ActionMessageMobile onClick={openMessageModal} />}
    </ActionsMobile>
  );
};
