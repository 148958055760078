/* eslint-disable max-lines */
import { UIHeading2, UIHeading3, UIHeading4, UIText1 } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';
import * as React from 'react';

// eslint-disable-next-line import/no-restricted-paths
import { scrollToElement } from '../../../browser/utils/scroll';
import { IGetBlogListSuccessResponseDataSchema } from '../../types/blogs';
import { IGetQuestionListSuccessResponseDataSchema } from '../../types/question';
import { Avatar } from '../avatar';
import { Section } from '../common/section';
import { Text } from '../review';
import { TContentProps } from './container';
import { trackTabClick } from './tracking/tracking';

import * as styles from './index.css';

export type TContentTab = 'advice' | 'question' | 'blog';

export interface IContentState {
  currentTab: TContentTab;
}

export const ADVICE_TAB_NAME = 'Советы';
export const QUESTION_TAB_NAME = 'Вопросы';
export const BLOG_TAB_NAME = 'Блоги';

export class Content extends React.Component<TContentProps, IContentState> {
  public constructor(props: TContentProps) {
    super(props);

    const { questions, blogs, advices } = props;

    let currentTab: TContentTab;

    if (!advices) {
      if (questions && questions.length > 0) {
        currentTab = 'question';

        this.state = {
          currentTab,
        };

        return;
      }

      if (blogs && blogs.length > 0) {
        currentTab = 'blog';

        this.state = {
          currentTab,
        };

        return;
      }
    }
  }

  public state = {
    currentTab: 'advice' as TContentTab,
  };
  public componentDidMount() {
    // tslint:disable-next-line
    if (typeof window !== 'undefined' && window.location.hash && window.location.hash === '#questions') {
      const questionsElement = document.getElementById('questions');

      window.setTimeout(() => scrollToElement(questionsElement), 0);
    }
  }

  public render() {
    const { blogs, questions, advices, totalQuestionsCount, totalAdvicesCount, totalBlogsCount } = this.props;
    const { currentTab } = this.state;

    const isAdvicesExists = !!advices;
    const isQuestionsExists = questions && questions.length > 0;
    const isBlogsExists = blogs && blogs.length > 0;

    if (!isAdvicesExists && !isQuestionsExists && !isBlogsExists) {
      return null;
    }

    const isAdvicesActive = currentTab === 'advice';
    const isQuestionsActive = currentTab === 'question';
    const isBlogsActive = currentTab === 'blog';

    return (
      <Section id="questions" className={styles['advices']}>
        <header className={styles['advices-header']}>
          <UIHeading2>Активность на Циан</UIHeading2>
        </header>

        <div className={styles['tabs']}>
          {isAdvicesExists && (
            <div
              id="tab_activity_advices"
              {...mergeStyles(styles['tab'], isAdvicesActive && styles['tab--active'])}
              onClick={this.handleAdvicesClick}
            >
              {ADVICE_TAB_NAME}
              <span className={styles['tab-counter']}>{totalAdvicesCount}</span>
            </div>
          )}
          {isQuestionsExists && (
            <div
              id="tab_activity_questions"
              {...mergeStyles(styles['tab'], isQuestionsActive && styles['tab--active'])}
              onClick={this.handleQuestionsClick}
            >
              {QUESTION_TAB_NAME}
              <span className={styles['tab-counter']}>{totalQuestionsCount}</span>
            </div>
          )}
          {isBlogsExists && (
            <div
              id="tab_activity_blogs"
              {...mergeStyles(styles['tab'], isBlogsActive && styles['tab--active'])}
              onClick={this.handleBlogsClick}
            >
              {BLOG_TAB_NAME}
              <span className={styles['tab-counter']}>{totalBlogsCount}</span>
            </div>
          )}
        </div>
        {isAdvicesActive && this.renderAdvices()}
        {isQuestionsActive && this.renderQuestions()}
        {isBlogsActive && this.renderBlogs()}
      </Section>
    );
  }

  private handleAdvicesClick = () => {
    this.setState({ currentTab: 'advice' });
    trackTabClick('tab_activity_advices');
  };

  private handleQuestionsClick = () => {
    this.setState({ currentTab: 'question' });
    trackTabClick('tab_activity_questions');
  };

  private handleBlogsClick = () => {
    this.setState({ currentTab: 'blog' });
    trackTabClick('tab_activity_blogs');
  };

  public renderAdvices() {
    const { allAdvicesLink, allAdvicesText, advices, name, totalAdvicesCount, avatarUrl, realtorRealtyUserId, rating } =
      this.props;
    const ratingLink = `/reyting-rieltorov/?location=0&agent_id=${realtorRealtyUserId}`;

    if (!advices) {
      return null;
    }

    const {
      attributes: {
        contentObject: { id, slug, title, subtitle },
        comment,
      },
    } = advices;

    const adviceLink = `/forum-rieltorov-${slug}-${id}`;

    return (
      <div>
        <div className={styles['advice']}>
          <a href={adviceLink} className={styles['advice-link']}>
            <UIHeading3>{title}</UIHeading3>
            <UIText1>{subtitle}</UIText1>
            <div className={styles['advice-answer']}>
              <Avatar src={avatarUrl} />
              <div className={styles['advice-name']}>
                <UIHeading4>{name}</UIHeading4>
                <div className={styles['advices-rating']}>
                  <a className={styles['rating-link']} href={ratingLink}>
                    Рейтинг: {rating}
                  </a>
                </div>
              </div>
            </div>
          </a>
          <Text renderAsHtml text={comment} />
        </div>

        {totalAdvicesCount > 1 && (
          <footer className={styles['footer']}>
            <a
              href={allAdvicesLink}
              className={styles['footer-text']}
              id="activity_all_advices"
              onClick={() => trackTabClick('activity_all_advices')}
            >
              {allAdvicesText}
            </a>
          </footer>
        )}
      </div>
    );
  }

  public renderQuestions() {
    const { allQuestionsLink, allQuestionsText, questions } = this.props;

    if (!questions || !questions.length) {
      return null;
    }

    return (
      <div>
        {questions.map(question => this.renderQuestion(question))}
        <footer className={styles['footer']}>
          <a
            href={allQuestionsLink}
            className={styles['footer-text']}
            id="activity_all_questions"
            onClick={() => trackTabClick('activity_all_questions')}
          >
            {allQuestionsText}
          </a>
        </footer>
      </div>
    );
  }

  public renderQuestion(question: IGetQuestionListSuccessResponseDataSchema) {
    const {
      id,
      attributes: { slug, title, subtitle },
    } = question;

    const questionLink = `/forum-rieltorov-${slug}-${id}`;

    return (
      <div key={id}>
        <div className={styles['question']}>
          <a href={questionLink} className={styles['advice-link']}>
            <UIHeading3>{title}</UIHeading3>
            <UIText1>{subtitle}</UIText1>
          </a>
        </div>
      </div>
    );
  }

  public renderBlogs() {
    const { allBlogsLink, allBlogsText, blogs } = this.props;

    if (!blogs || !blogs.length) {
      return null;
    }

    return (
      <div>
        {blogs.map(blog => this.renderBlog(blog))}
        <footer className={styles['footer']}>
          <a
            href={allBlogsLink}
            className={styles['footer-text']}
            id="activity_all_blogs"
            onClick={() => trackTabClick('activity_all_blogs')}
          >
            {allBlogsText}
          </a>
        </footer>
      </div>
    );
  }

  public renderBlog(blog: IGetBlogListSuccessResponseDataSchema) {
    const {
      id,
      attributes: { slug, title, subtitle },
    } = blog;

    const blogLink = `/blogs-${slug}-${id}`;

    return (
      <div key={id}>
        <div className={styles['question']}>
          <a href={blogLink} className={styles['advice-link']}>
            <UIHeading3>{title}</UIHeading3>
            <UIText1>{subtitle}</UIText1>
          </a>
        </div>
      </div>
    );
  }
}
