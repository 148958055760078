/* eslint-disable max-lines */

import * as React from 'react';
import { HonestWork, PopupDirection } from '@cian/honestwork-component';
import { mergeStyles, plural } from '@cian/utils';
import { Star16 } from '@cian/ui-kit/prev';
import { NoSsr } from '@cian/react-utils';
import { IconFunctionalInformation12, Tooltip } from '@cian/ui-kit';

import { SECONDS_IN_DAY, getResponseTimeText } from './utils/responseTimeText';
import { ApprovalContainer } from '../../containers/ApprovalContainer';
import { IAgentChatsState } from '../../store/chats';
import { IChatStatistics } from '../../store/chat_statistics';
import { ISocialProfiles, IUserLevelInfo } from '../../types/agentCard';
import { TUser } from '../../store';
import { UserWorkHonestlyAsideInfo } from '../UserWorkHonestlyAsideInfo';
import { formatRate } from './utils/formatRate';
import { TDeviceType } from '../../types/device';

import * as styles from './index.css';

export interface IAsideGeneralInfoProps {
  abGroup: number;
  hasFairplay: boolean;
  name: string;
  rate: number | undefined;
  reviewsCount: number;
  socialProfiles?: ISocialProfiles[];
  isChatsEnabled: boolean;
  user: TUser;
  realtorChat: IAgentChatsState;
  statistics: IChatStatistics;
  userLevelInfo?: IUserLevelInfo | null;
  hasVerifiedDocuments: boolean;
  deviceType: TDeviceType;
}

export interface IAsideGeneralInfoState {
  isAdditionalPhonesOpened: boolean;
  isMainPhonesOpened: boolean;
  isAnalyticsSent: boolean;
  isChatMessageSent: boolean;
}

export interface IAsideGeneralInfoDispatchProps {
  onChatMessageSent(chatId: string): void;
}

export type TAsideGeneralInfoProps = IAsideGeneralInfoProps & IAsideGeneralInfoDispatchProps;

export class AsideGeneralInfo extends React.PureComponent<TAsideGeneralInfoProps, IAsideGeneralInfoState> {
  public constructor(props: TAsideGeneralInfoProps) {
    super(props);

    this.state = {
      isAdditionalPhonesOpened: false,
      isAnalyticsSent: false,
      isChatMessageSent: false,
      isMainPhonesOpened: false,
    };
  }

  public render() {
    const {
      name,
      rate,
      reviewsCount,
      hasFairplay,
      statistics: { responseRate, averageResponseTime },
      userLevelInfo,
      hasVerifiedDocuments,
    } = this.props;

    return (
      <div className={styles['container']}>
        <div className={styles['agent_name']}>
          {name}
          {hasFairplay && !userLevelInfo && !hasVerifiedDocuments && (
            <HonestWork direction={PopupDirection.LEFT} className={styles['honest_work']} isPopupDisabled />
          )}
        </div>
        {reviewsCount > 0 && typeof rate === 'number' && rate * 5 >= 1 && (
          <div className={styles['rate_container']}>
            <NoSsr>
              <Star16 color="warning_100" />
            </NoSsr>
            <div className={styles['rate_small']}>{formatRate(rate)}</div>
            <div className={styles['reviews_info']}>{`∙ ${reviewsCount} отзыв${plural(reviewsCount, [
              '',
              'а',
              'ов',
            ])}`}</div>
          </div>
        )}
        {reviewsCount === 0 && <div className={styles['no_reviews']}>Нет отзывов</div>}
        {userLevelInfo && <UserWorkHonestlyAsideInfo userLevelInfo={userLevelInfo} />}
        <ApprovalContainer />
        {(responseRate > 0 || averageResponseTime > 0) && (
          <div className={styles['statistics']}>
            {responseRate > 0 && (
              <div className={styles['stats_param']}>
                <span className={styles['stats_param-description']}>Частота ответов: </span>
                <span
                  {...mergeStyles(
                    styles['stats_param-value'],
                    responseRate > 0.6 && styles['stats_param-value--green'],
                  )}
                >
                  {Math.round(responseRate * 100)}%
                </span>
                <Tooltip
                  placement="bottom-start"
                  theme="white"
                  title={
                    <>
                      Считаем ответы <br />
                      на звонки и чаты <br />
                      за последние 2 месяца
                    </>
                  }
                >
                  <div className={styles['circle']}>
                    <IconFunctionalInformation12 color="primary_100" />
                  </div>
                </Tooltip>
              </div>
            )}
            {averageResponseTime > 0 && (
              <div className={styles['stats_param']}>
                <span className={styles['stats_param-description']}>Время ответа: </span>
                <span
                  {...mergeStyles(
                    styles['stats_param-value'],
                    averageResponseTime < SECONDS_IN_DAY && styles['stats_param-value--green'],
                  )}
                >
                  {getResponseTimeText(averageResponseTime)}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
