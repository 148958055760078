import { ModalFullscreen, IconClose16, Spinner } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './ChatModal.css';
export interface IChatModalProps {
  isOpen: boolean;
  src: string | null;
  onOpen?(): void;
  onClose(): void;
}

export function ChatModal({ isOpen, onClose, onOpen, src }: IChatModalProps) {
  const [isLoading, setIsLoading] = React.useState(true);

  const onLoad = () => setIsLoading(false);

  React.useEffect(() => {
    if (isOpen && onOpen) {
      onOpen();
    }
  }, [isOpen, onOpen]);

  if (!src) {
    return null;
  }

  return (
    <ModalFullscreen onClose={onClose} open={isOpen}>
      <div className={styles['iframe-container']}>
        <span className={styles['close']}>
          <IconClose16 color="black_100" onClick={onClose} />
        </span>
        {isLoading && (
          <span className={styles['spinner']} data-testid="spinner">
            <Spinner size={16} />
          </span>
        )}
        <iframe data-name="ChatModal" className={styles['iframe']} src={src} onLoad={onLoad} onError={onLoad} />
      </div>
    </ModalFullscreen>
  );
}
