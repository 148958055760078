import { ca } from '@cian/analytics';

import { IOffer } from '../api/offer';
import { offerHelper } from '../offer/presenters';

export const trackOfferClick = (offer: IOffer) => {
  const product = offerHelper(offer).getAnalyticsInfo();

  ca('eventSite', {
    action: 'to_card',
    category: 'Listing',
    label: 'card_detail_in_newlist',
    name: 'oldevent',
    products: [product],
  });
};

export const trackAllOffersClick = () => {
  ca('eventSite', {
    action: 'open_all_offers',
    category: 'Specialists',
    label: 'agent',
    name: 'oldevent',
  });
};

export function trackFavoriteAdded(label?: string) {
  ca('eventSite', {
    action: 'add_from_newlist',
    category: 'favorite',
    label,
    name: 'oldevent',
  });
}

export function trackFavoriteRemoved(label?: string) {
  ca('eventSite', {
    action: 'remove_from_newlist',
    category: 'favorite',
    label,
    name: 'oldevent',
  });
}

export function trackPhotoSlideChanged() {
  ca('eventSite', {
    action: 'slide_photo',
    category: 'Listing',
    label: 'from_newlist',
    name: 'oldevent',
  });
}

export function trackMapOpened() {
  ca('eventSite', {
    action: 'open_map',
    category: 'Listing',
    label: 'from_newlist',
    name: 'oldevent',
  });
}

export function trackMapClosed() {
  ca('eventSite', {
    action: 'hide_map',
    category: 'Listing',
    label: 'from_newlist',
    name: 'oldevent',
  });
}

export function trackJKLinkClicked(offer: IOffer) {
  let label = '';

  if (offer.geo && offer.geo.jk && offer.geo.jk.gaGeo) {
    const { moId, oblId, cityId } = offer.geo.jk.gaGeo;
    label = `/newobject_name/mo_id=${moId}/obl_id=${oblId}/city_id=${cityId}/`;
  }

  ca('eventSite', {
    action: 'to_card_newbuilding',
    category: 'Listing',
    label,
    name: 'oldevent',
  });
}

export function trackMenuOpened() {
  ca('eventSite', {
    action: 'moreMenu_open',
    category: 'Listing',
    name: 'oldevent',
  });
}

export function trackPDFLinkClicked() {
  ca('eventSite', {
    action: 'moreMenu_download_pdf',
    category: 'Listing',
    label: 'from_newlist',
    name: 'oldevent',
  });
}

export function trackDocLinkClicked() {
  ca('eventSite', {
    action: 'moreMenu_download_doc',
    category: 'Listing',
    label: 'from_newlist',
    name: 'oldevent',
  });
}

export function trackPhoneOpened(offer: IOffer, position: number, isSimilar?: boolean) {
  const analyticsInfo = offerHelper(offer).getAnalyticsInfo();

  ca('eventSite', {
    action: isSimilar ? 'Open_listing_agent_block_similar' : 'Open_listing_agent_block',
    category: 'Phones',
    label: offer.gaLabel,
    name: 'oldevent',
    products: [
      {
        ...analyticsInfo,
        position,
      },
    ],
  });
}
