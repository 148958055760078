import * as React from 'react';

import { Link } from '../Link';

import * as styles from './index.css';

interface IAllVacanciesLinkProps {
  allVacanciesLink: string;
  allVacanciesText: string;
}

export const AllVacanciesLink = ({ allVacanciesLink, allVacanciesText }: IAllVacanciesLinkProps) => {
  return (
    <Link href={allVacanciesLink} className={styles['vacancies-all_link']}>
      {allVacanciesText}
    </Link>
  );
};
