import * as React from 'react';
import { ModalWindow, UIText1, LinkButton, IconPhone16 } from '@cian/ui-kit';

import * as styles from './CalltrackingCallModal.css';

type TProps = {
  isModalOpen: boolean;
  phone: string;
  onCallClick: () => void;
  onClose: () => void;
};

export const CalltrackingCallModal: React.FC<TProps> = ({ isModalOpen, phone, onCallClick, onClose }) => {
  return (
    <ModalWindow
      open={isModalOpen}
      onClose={onClose}
      size="XS"
      portal={false}
      fixed
      title="Вы будете звонить по защищённому номеру"
      footer={
        <LinkButton
          beforeIcon={<IconPhone16 color="white_100" />}
          size="M"
          fullWidth
          href={phone}
          onClick={onCallClick}
          data-name="CalltrackingCallButton"
        >
          Позвонить
        </LinkButton>
      }
    >
      <div className={styles['container']} data-name="CalltrackingCallModal">
        <UIText1>
          {'На него можно только звонить, смс не дойдут. Написать специалисту получится только в чате.'}
        </UIText1>
      </div>
    </ModalWindow>
  );
};
